<template>
  <div>
    <v-row class="ma-0" align="center">
      <v-btn
        text
        class="text-none mr-1 px-1"
        color="grey darken-1"
        data-testid="follow-up-button"
        :loading="verifyingActiveStatus"
        @click="verifyActiveStatus"
      >
        <v-icon class="mr-1">{{ mdiRefresh }}</v-icon> Follow Up At
        <timestamp-formatter :value="followUpAt" class="ml-1" />
      </v-btn>
      <v-btn
        icon
        class="mr-1"
        color="primary"
        data-testid="download-form"
        v-bind="downloadForm()"
      >
        <v-icon>{{ mdiDownload }}</v-icon>
      </v-btn>
      <v-btn
        icon
        class="mr-1"
        color="info"
        data-testid="preview"
        :href="previewLink"
        target="_blank"
      >
        <v-icon>{{ mdiPrinterEye }}</v-icon>
      </v-btn>
      <div class="mr-1">
        <mapped-form-view-status-selector
          :loading="verifyingForm"
          :status="status"
          @status-update="toggleFormStatus"
        />
      </div>
      <v-btn icon @click="fullscreen = !fullscreen">
        <v-icon v-if="fullscreen">{{ mdiFullscreenExit }}</v-icon>
        <v-icon v-else>{{ mdiFullscreen }}</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>
<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import MappedFormViewStatusSelector from "@/components/form-mapping/MappedFormViewStatusSelector.vue";

import { downloadFileAsLink, parseErrorMessage } from "@/util/helpers";
import { getPreviewUrl } from "@/api/forms.service";
import { useVuetify } from "@/composables/compatible.composables";
import { computed, ref, defineProps } from "vue";
import {
  mdiRefresh,
  mdiDownload,
  mdiPrinterEye,
  mdiFullscreenExit,
  mdiFullscreen
} from "@mdi/js";
import { storeToRefs } from "pinia";
import { useMappedFormStore } from "@/stores/mapped-form";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";

const props = defineProps({
  id: [String, Number]
});

const vuetify = useVuetify();

const snackbar = useSnackbarStore();
const { fullscreen } = storeToRefs(useInstanceStore());

const formMapper = useMappedFormStore(props.id);
const { followUpAt, status, pdfUrl, verifyingForm } = storeToRefs(formMapper);

const verifyingActiveStatus = ref(false);

const previewLink = computed(() => getPreviewUrl(props.id));

function downloadForm() {
  return downloadFileAsLink(pdfUrl.value, vuetify.breakpoint.mdAndDown);
}

async function verifyActiveStatus() {
  verifyingActiveStatus.value = true;
  try {
    await formMapper.verifyStatus();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    verifyingActiveStatus.value = false;
  }
}

async function toggleFormStatus(newStatus) {
  verifyingForm.value = true;
  try {
    await formMapper.updateFormStatus(newStatus);
    status.value = newStatus;
    snackbar.showSuccessSnackbar({
      message: `Successfully updated status to ${newStatus}`
    });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: `Error Verifying Form: ${parseErrorMessage(e)}`,
      timeout: -1
    });
  } finally {
    verifyingForm.value = false;
  }
}
</script>
