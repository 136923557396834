<template>
  <v-card tile flat>
    <v-row class="px-6 py-3">
      <v-spacer />
      <v-btn
        v-if="statementDocument"
        rounded
        color="primary"
        outlined
        class="mr-1 text-none"
        data-testid="statement-view-actions-download"
        v-bind="download()"
      >
        <v-icon> {{ mdiDownload }} </v-icon> Download
      </v-btn>
      <v-btn
        v-if="nonImportableDocument"
        rounded
        color="primary"
        outlined
        class="text-none"
        data-testid="statement-view-actions-download-non-importable"
        v-bind="downloadNonImportableDocument()"
      >
        <v-icon> {{ mdiDownload }} </v-icon> Download PDF Statement
      </v-btn>
      <v-btn
        rounded
        color="primary"
        outlined
        class="text-none"
        data-testid="statement-view-actions-upload-non-importable"
        @click="uploadNonImportableDocument"
      >
        <template v-if="nonImportableDocument">
          <v-icon> {{ mdiFileReplace }} </v-icon> Replace PDF Statement
        </template>
        <template v-else>
          <v-icon> {{ mdiUpload }} </v-icon> Upload PDF Statement
        </template>
      </v-btn>
      <v-btn
        v-if="errorsDocument"
        rounded
        color="primary"
        outlined
        class="mr-1 text-none"
        data-testid="statement-view-actions-download-errors"
        v-bind="downloadErrorDocument()"
      >
        <v-icon> {{ mdiDownload }} </v-icon> Download Error Document
      </v-btn>
      <v-btn
        v-if="deletable"
        rounded
        color="error"
        outlined
        class="text-none"
        data-testid="statement-view-actions-delete"
        @click="handleStatementDelete"
      >
        <v-icon> {{ mdiDelete }} </v-icon> Delete
      </v-btn>
    </v-row>
  </v-card>
</template>

<script setup>
import DocumentDialog from "@/dialogs/DocumentDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { downloadFileAsLink } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { getDocumentUrl } from "@/api/documents.service";
import { useDialogStore } from "@/stores/dialog";
import { mdiDownload, mdiFileReplace, mdiUpload, mdiDelete } from "@mdi/js";
import { useStatementView } from "@/stores/statement-view";
import { useRouter, useVuetify } from "@/composables/compatible.composables";
import { markRaw } from "vue";

const router = useRouter();
const vuetify = useVuetify();
const dialog = useDialogStore();
const statementView = useStatementView();

const {
  document: statementDocument,
  nonImportableDocument,
  errorsDocument,
  deletable
} = storeToRefs(statementView);

function download() {
  return downloadFileAsLink(
    getDocumentUrl(statementDocument.value.uid),
    vuetify.breakpoint.mdAndDown
  );
}

function downloadNonImportableDocument() {
  return downloadFileAsLink(
    getDocumentUrl(nonImportableDocument.value.uid),
    vuetify.breakpoint.mdAndDown
  );
}

function downloadErrorDocument() {
  return downloadFileAsLink(
    getDocumentUrl(errorsDocument.value.uid),
    vuetify.breakpoint.mdAndDown
  );
}

function uploadNonImportableDocument() {
  dialog.showDialog({
    component: markRaw(DocumentDialog),
    scrollable: true,
    title: `${nonImportableDocument.value ? "Replace" : "Add"} Statement PDF`,
    single: true,
    func: async ({ file }) => statementView.uploadNonImportableDocument(file)
  });
}

function handleStatementDelete() {
  return dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure that you want to delete this statement?",
    subtitle: "This cannot be undone",
    func: async () => {
      await statementView.deleteStatement();
      router.replace({ name: "BackNineCommissionsStatements" });
    }
  });
}
</script>
