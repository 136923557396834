import { render, staticRenderFns } from "./ChatViewBody.vue?vue&type=template&id=ac4aa45a"
import script from "./ChatViewBody.vue?vue&type=script&setup=true&lang=js"
export * from "./ChatViewBody.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports