import { setNoteFromRequest } from "@/factories/Note";

export const BEST_TIME_TO_CALL_OPTIONS = ["Morning", "Afternoon", "Evening"];

export function ElectronicApplication(model = {}) {
  return {
    allowCommissionChange: model?.allowCommissionChange || false,
    agent: model?.agent || null,
    parties: model?.parties || null,
    applyLink: model?.applyLink || null,
    approvedDomain: ApprovedDomain(model?.approvedDomain),
    birthdate: model?.birthdate || null,
    canBypassAppointmentHold: model?.canBypassAppointmentHold || false,
    canSkipPreAppointment: model?.canSkipPreAppointment || false,
    canSendChatTemplate: model?.canSendChatTemplate || false,
    carrier: Carrier(model?.carrier),
    case: model?.case || null,
    commissionsPaidTo: {
      id: model?.commissionsPaidTo?.id || null,
      name: model?.commissionsPaidTo?.name || null,
      type: model?.commissionsPaidTo?.type || null
    },
    createdAt: model?.createdAt || null,
    daysPending: model?.daysPending || null,
    editedStatus: model?.editedStatus || null,
    electronicApplicationAccesses: model?.electronicApplicationAccesses || null,
    electronicApplicationCases: model?.electronicApplicationCases || null,
    envelopeExpirationAt: model?.envelopeExpirationAt || null,
    examAddress: model?.examAddress || null,
    examDate: model?.examDate || null,
    examDateTime: model?.examDateTime || null,
    hasPhysician: model?.hasPhysician || null,
    humanApiInvitedAt: model?.humanApiInvitedAt || null,
    humanized: model?.humanized || null,
    id: model?.id || null,
    illustration: model?.illustration || null,
    illustrations: model?.illustrations || [],
    insured: Party(model?.insured),
    jointInsured: Party(model?.jointInsured),
    isCompleted: model?.isCompleted || null,
    isDeletable: model?.isDeletable || false,
    isEditable: model?.isEditable || false,
    isResendable: model?.isResendable || false,
    isRedatable: model?.isRedatable || false,
    latestQuotes: LatestQuotes(model?.latestQuotes),
    locked: Boolean(model?.locked),
    metadata: model?.metadata || null,
    name: model?.name || null,
    namedStep: model?.namedStep || null,
    nextStep: model?.nextStep || null,
    notes: model?.notes || null,
    npn: model?.npn || null,
    orderNumber: model?.orderNumber || null,
    policyNumber: model?.policyNumber || null,
    product: EAppProduct(model?.product),
    referredBy: model?.referredBy || null,
    resumeCarrierEapp: model?.resumeCarrierEapp || false,
    selectedType: model?.selectedType || null,
    signers: model?.signers || null,
    status: model?.status || null,
    step: model?.step || null,
    syncNow: model?.syncNow || null,
    test: model?.test || false,
    tia: model?.tia || null,
    followUpAt: model?.followUpAt || null,
    followUpIsScheduled: model?.followUpIsScheduled || null
  };
}

export function setElectronicApplicationFromRequest(req = {}) {
  const eApp = ElectronicApplication();
  eApp.allowCommissionChange = Boolean(req?.allow_commission_change);
  eApp.agent = req?.agent;
  eApp.applyLink = req?.apply_link;
  eApp.canBypassAppointmentHold = Boolean(req?.can_skip_hold_for_appointment);
  eApp.canSkipPreAppointment = Boolean(req?.can_skip_preappointment);

  eApp.case = req?.case;
  eApp.createdAt = req?.created_at;
  if (req?.commissions_paid_to) {
    eApp.commissionsPaidTo.id = req.commissions_paid_to.id;
    eApp.commissionsPaidTo.name = req.commissions_paid_to.name;
    eApp.commissionsPaidTo.type = req.commissions_paid_to.type;
  }
  eApp.daysPending = req?.days_pending;
  eApp.editedStatus = req?.edited_status;
  eApp.envelopeExpirationAt = req?.envelope_expiration_at;
  eApp.examAddress = req?.exam_address;
  eApp.examDate = req?.exam_date;
  eApp.examDateTime = req?.exam_date_time;
  eApp.hasPhysician = req?.has_physician;
  eApp.humanApiInvitedAt = req?.human_api_invited_at;
  eApp.humanized = req?.humanized;
  eApp.id = req?.id;
  eApp.illustration = req?.illustration;
  eApp.illustrations = req?.illustrations;
  eApp.isCompleted = req?.is_completed;
  eApp.isDeletable = req?.deletable;
  eApp.isEditable = req?.editable;
  eApp.isRedatable = req?.redatable;
  eApp.isResendable = req?.resendable;
  eApp.canSendChatTemplate = req?.can_send_chat_template;
  eApp.loanType = req?.loan_type;
  eApp.metadata = req?.metadata;
  eApp.name = req?.name;
  eApp.namedStep = req?.named_step;
  eApp.nextStep = req?.next_step;
  eApp.npn = req?.agents_npn;
  eApp.locked = Boolean(req?.locked);
  eApp.orderNumber = req?.order_number;
  eApp.policyNumber = req?.policy_number;
  eApp.reduceSpecifiedAmount = req?.reduce_specified_amount;
  eApp.referredBy = req?.referred_by;
  eApp.resumeCarrierEapp = req?.resume_carrier_eapp;
  eApp.selectedType = req?.selected_type;
  eApp.status = req?.status;
  eApp.step = req?.step;
  eApp.syncNow = req?.sync_now;
  eApp.test = req?.test;
  eApp.tia = req?.tia;
  eApp.followUpAt = req?.follow_up_at;
  eApp.followUpIsScheduled = req?.follow_up_at_is_appointment;

  eApp.approvedDomain = setApprovedDomainFromRequest(req?.approved_domain);

  eApp.carrier = setCarrierFromRequest(req?.product?.carrier);

  eApp.product = setEappProductFromRequest(req?.product);

  eApp.latestQuotes = setLatestQuotesFromRequest(req?.latest_quotes);

  eApp.electronicApplicationCases = [];
  req?.electronic_application_cases?.forEach(rawCase => {
    eApp.electronicApplicationCases.push(
      setElectronicApplicationExistingInsuranceFromRequest(rawCase)
    );
  });

  eApp.notes = [];
  req?.notes?.forEach(rawNote => {
    eApp.notes.push(setNoteFromRequest(rawNote));
  });

  eApp.signers = [];
  req?.signers?.forEach(rawSigner => {
    eApp.signers.push(
      setElectronicApplicationSignerFromRequest(rawSigner, eApp.status)
    );
  });

  eApp.electronicApplicationAccesses = [];
  req?.electronic_application_accesses?.forEach(rawAccess => {
    eApp.electronicApplicationAccesses.push(
      setElectronicApplicationAccessFromRequest(rawAccess)
    );
  });

  eApp.parties = [];
  req?.parties?.forEach(rawParty => {
    eApp.parties.push(setPartyFromRequest(rawParty));
  });

  const insured = eApp.parties.find(({ roles }) =>
    roles.some(({ role }) => role === "Insured")
  );
  if (insured) eApp.insured = insured;

  const jointInsured = eApp.parties.find(({ roles }) =>
    roles.some(({ role }) => role === "Joint Insured")
  );

  if (jointInsured) eApp.jointInsured = jointInsured;

  return eApp;
}

function Party(model = {}) {
  return {
    id: model?.id || null,
    type: model?.type || null,
    medicalSources: model?.medicalSources || [],
    name: model?.name || "",
    birthdate: model?.birthdate || "",
    phone: model?.phone || "",
    email: model?.email || "",
    roles: model?.roles || []
  };
}

function setPartyFromRequest(rawModel = {}) {
  const party = Party();
  party.id = rawModel?.id;
  party.medicalSources = rawModel?.medical_sources;
  party.name = rawModel?.name;
  party.birthdate = rawModel?.date;
  party.type = rawModel?.type;
  party.phone = rawModel?.primary_phone;
  party.email = rawModel?.email;

  party.roles = [];
  rawModel?.roles?.forEach(rawRole => {
    party.roles.push({
      beneficiaryAmount: rawRole?.beneficiary_amount,
      beneficiaryQualifier: rawRole?.beneficiary_qualifier,
      role: rawRole?.role,
      relationship: rawRole?.relationship
    });
  });
  return party;
}

function EAppProduct(model = {}) {
  return {
    carrierEDelivery: model?.carrierEDelivery || false,
    chronicIllnessRider: model?.chronicIllnessRider || false,
    ltcRider: model?.ltcRider || null,
    name: model?.name || null,
    id: model?.id || null,
    multiplePolicies: model?.multiplePolicies || null
  };
}

function setEappProductFromRequest(req = {}) {
  const product = EAppProduct();
  product.carrierEDelivery = req?.carrier_e_delivery;
  product.chronicIllnessRider = req?.chronic_illness_rider;
  product.ltcRider = req?.ltc_rider;
  product.name = req?.name;
  product.id = req?.id;
  product.multiplePolicies = req?.multiple_policies;
  return product;
}

function LatestQuotes(model = {}) {
  return {
    insured: QuoteParams(model?.insured),
    jointInsured: QuoteParams(model?.jointInsured)
  };
}

function setLatestQuotesFromRequest(req = {}) {
  const latestQuotes = LatestQuotes();
  latestQuotes.insured = setQuoteParamsFromRequest(req?.insured);
  latestQuotes.jointInsured = setQuoteParamsFromRequest(req?.joint_insured);
  return latestQuotes;
}

function Carrier(model = {}) {
  return {
    avatarUrl: model?.avatarUrl,
    id: model?.id,
    instantAps: model?.instantAps,
    name: model?.name
  };
}
function setCarrierFromRequest(req = {}) {
  const carrier = Carrier();
  carrier.avatarUrl = req?.avatar_url;
  carrier.id = req?.id;
  carrier.instantAps = req?.instant_aps;
  carrier.name = req?.name;
  return carrier;
}

function ApprovedDomain(model = {}) {
  return {
    domain: model?.domain,
    id: model?.id,
    isArchived: model?.isArchived,
    appointmentAssignmentOwnable: {
      id: model?.appointmentAssignmentOwnable?.id || null,
      name: model?.appointmentAssignmentOwnable?.name || null,
      type: model?.appointmentAssignmentOwnable?.type || null
    }
  };
}

function setApprovedDomainFromRequest(rawModel = {}) {
  const approvedDomain = ApprovedDomain();
  approvedDomain.domain = rawModel?.domain;
  approvedDomain.id = rawModel?.id;
  approvedDomain.isArchived = Boolean(rawModel?.archived_at);

  if (rawModel?.appointment_assignment_ownable) {
    approvedDomain.appointmentAssignmentOwnable.id =
      rawModel.appointment_assignment_ownable.id;
    approvedDomain.appointmentAssignmentOwnable.name =
      rawModel.appointment_assignment_ownable.name;
    approvedDomain.appointmentAssignmentOwnable.type =
      rawModel.appointment_assignment_ownable.type;
  }
  return approvedDomain;
}

function InsuredQuoteParams(model = {}) {
  return {
    email: model?.email || null,
    gender: model?.gender || null,
    id: model?.id || null,
    name: model?.name || null,
    primaryPhone: model?.primaryPhone || null,
    primaryRawAddress: model?.primaryRawAddress || null
  };
}

function setInsuredQuoteParamsFromRequest(insured = {}) {
  const params = InsuredQuoteParams();
  params.email = insured?.email;
  params.gender = insured?.gender;
  params.id = insured?.id;
  params.name = insured?.name;
  params.primaryPhone = insured?.primary_phone;
  params.primaryRawAddress = insured?.primary_raw_address;
  return params;
}

function Section1035ExchangeParams(model = {}) {
  return {
    amount: model?.amount || null,
    enabled: model?.enabled || false
  };
}

function setSection1035ExchangeFromRequest(params = {}) {
  const section = Section1035ExchangeParams();
  section.amount = params?.amount;
  section.enabled = params?.enabled;
  return section;
}

function VitalityParams(model = {}) {
  return {
    level: model?.level || "",
    enabled: model?.enabled || false
  };
}

function setVitalityFromRequest(params = {}) {
  const vitality = VitalityParams();
  vitality.level = params?.level;
  vitality.enabled = params?.enabled;
  return vitality;
}

function QuoteParams(model = {}) {
  return {
    ageValue: model?.ageValue || null,
    benefitPeriod: model?.benefitPeriod || null,
    buildText: model?.buildText || null,
    cashBenefitPercentage: model?.cashBenefitPercentage || null,
    cashValue: model?.cashValue || null,
    chronicIllnessRider: model?.chronicIllnessRider || null,
    createdAt: model?.createdAt || null,
    creditingRate: model?.creditingRate || null,
    eliminationPeriod: model?.eliminationPeriod || null,
    faceAmount: model?.faceAmount || null,
    filterChronicIllnessAndLtc: model?.filterChronicIllnessAndLtc || null,
    gender: model?.gender || null,
    health: model?.health || null,
    height: model?.height || null,
    heightText: model?.heightText || null,
    homeHealthCareWaiver: model?.homeHealthCareWaiver || null,
    id: model?.id || null,
    incomeEndAge: model?.incomeEndAge || null,
    incomeSolve: model?.incomeSolve || null,
    incomeStartAge: model?.incomeStartAge || null,
    inflationPercentage: model?.inflationPercentage || null,
    insured: InsuredQuoteParams(model?.insured),
    jointWaiverOfPremium: model?.jointWaiverOfPremium || null,
    lapseProtectionToAge: model?.lapseProtectionToAge || null,
    loanType: model?.loanType || null,
    ltcRider: model?.ltcRider || null,
    ltcRiderPercentage: model?.ltcRiderPercentage || null,
    mode: model?.mode || null,
    monthlyBenefit: model?.monthlyBenefit || null,
    partnerDiscount: model?.partnerDiscount || null,
    payDuration: model?.payDuration || null,
    poolOfMoney: model?.poolOfMoney || null,
    premium: model?.premium || null,
    product: model?.product || null,
    productCategory: model?.productCategory || null,
    rateClass: model?.rateClass || null,
    section1035Exchange: Section1035ExchangeParams(model?.section1035Exchange),
    sharedCare: model?.sharedCare || null,
    smoker: model?.smoker || null,
    solve: model?.solve || null,
    state: model?.state || null,
    targetPremium: model?.targetPremium || null,
    updatedAt: model?.updatedAt || null,
    usages: model?.usages || [],
    validatedRating: model?.validatedRating || null,
    vitality: VitalityParams(model?.vitality),
    weight: model?.weight || null
  };
}

function setQuoteParamsFromRequest(req = {}) {
  const quoteParams = QuoteParams();
  quoteParams.ageValue = req?.age_value;
  quoteParams.benefitPeriod = req?.benefit_period;
  quoteParams.buildText = req?.build_text;
  quoteParams.cashBenefitPercentage = req?.cash_benefit_percentage;
  quoteParams.cashValue = req?.cash_value;
  quoteParams.chronicIllnessRider = req?.chronic_illness_rider;
  quoteParams.createdAt = req?.created_at;
  quoteParams.creditingRate = req?.crediting_rate;
  quoteParams.eliminationPeriod = req?.elimination_period;
  quoteParams.faceAmount = req?.face_amount;
  quoteParams.filterChronicIllnessAndLtc = req?.filter_chronic_illness_and_ltc;
  quoteParams.gender = req?.gender;
  quoteParams.health = req?.health;
  quoteParams.height = req?.height;
  quoteParams.heightText = req?.height_text;
  quoteParams.homeHealthCareWaiver = req?.home_health_care_waiver;
  quoteParams.id = req?.id;
  quoteParams.incomeEndAge = req?.income_end_age;
  quoteParams.incomeSolve = req?.income_solve;
  quoteParams.incomeStartAge = req?.income_start_age;
  quoteParams.inflationPercentage = req?.inflation_percentage;
  quoteParams.insured = setInsuredQuoteParamsFromRequest(req?.insured);

  quoteParams.jointWaiverOfPremium = req?.joint_waiver_of_premium;
  quoteParams.lapseProtectionToAge = req?.lapse_protection_to_age;
  quoteParams.loanType = req?.loan_type;
  quoteParams.ltcRider = req?.ltc_rider;
  quoteParams.ltcRiderPercentage = req?.ltc_rider_percentage;
  quoteParams.mode = req?.mode;
  quoteParams.monthlyBenefit = req?.monthly_benefit;
  quoteParams.partnerDiscount = req?.partner_discount;
  quoteParams.payDuration = req?.pay_duration;
  quoteParams.poolOfMoney = req?.pool_of_money;
  quoteParams.premium = req?.premium;
  quoteParams.product = req?.product;
  quoteParams.productCategory = req?.product_category;
  quoteParams.rateClass = req?.rate_class;

  quoteParams.section1035Exchange = setSection1035ExchangeFromRequest(
    req?.section_1035_exchange
  );

  quoteParams.sharedCare = req?.shared_care;
  quoteParams.smoker = req?.smoker;
  quoteParams.solve = req?.solve;
  quoteParams.state = req?.state;
  quoteParams.targetPremium = req?.target_premium;
  quoteParams.updatedAt = req?.updated_at;
  quoteParams.validatedRating = req?.validated_rating;

  quoteParams.vitality = setVitalityFromRequest(req?.vitality);
  quoteParams.weight = req?.weight;

  quoteParams.usages = [];
  req?.usages?.forEach(rawStatus => {
    const smokingStatus = {
      category: rawStatus?.category,
      frequency: rawStatus?.frequency,
      lastUseDate: rawStatus?.last_use_date
    };
    quoteParams.usages.push(smokingStatus);
  });

  return quoteParams;
}

function ElectronicApplicationAccess(model = {}) {
  return {
    additionalCaseAccess: model?.additionalCaseAccess || null,
    disabled: model?.disabled || null,
    email: model?.email || null,
    id: model?.id || null,
    owner: model?.owner || null,
    name: model?.name || "",
    ownerId: model?.ownerId || null,
    ownerType: model?.ownerType || null,

    link: model?.link || null,
    routerLink: model?.routerLink || null
  };
}

export function setElectronicApplicationAccessFromRequest(model = {}) {
  const access = ElectronicApplicationAccess();
  access.additionalCaseAccess = model?.additional_case_access;
  access.disabled = model?.disabled;
  access.email = model?.email;
  access.id = model?.id;
  access.owner = model?.owner;
  access.ownerId = model?.ownable?.id;
  access.name = model?.ownable?.name;
  access.ownerType = model?.ownable?.type?.model;

  if (access.ownerType === "Agent") {
    access.routerLink = {
      name: "AgentView",
      params: { id: access.ownerId }
    };
  } else if (access.ownerType === "Agency") {
    access.routerLink = {
      name: "AgencyView",
      params: { id: access.ownerId }
    };
  }

  return access;
}

function ElectronicApplicationSigner(model = {}) {
  return {
    signerId: model?.signerId,
    role: model?.role,
    accessCode: model?.accessCode,
    status: model?.status,
    signedAt: model?.signedAt,
    ownerEmail: model?.ownerEmail,
    ownerId: model?.ownerId,
    ownerType: model?.ownerType,
    ownerName: model?.ownerName,
    link: model?.link,
    routerLink: model?.routerLink,
    canResendEnvelope: model?.canResendEnvelope
  };
}

function setElectronicApplicationSignerFromRequest(
  model = {},
  currentEAppStatus
) {
  const signer = ElectronicApplicationSigner();
  signer.ownerName = model.ownable.name;
  signer.ownerEmail = model.ownable.email;
  signer.ownerId = model.ownable.id;
  signer.signerId = model.id;

  signer.role = model.role_name;
  signer.accessCode = model.last_4_type;
  signer.status = model.status;
  signer.statusCode = model.status_code;
  signer.signedAt = model.signed_at;

  if (model.ownable.link === "agents") {
    signer.ownerType = "Agent";
  } else if (model.ownable.link === "agencies") {
    signer.ownerType = "Agency";
  }

  if (signer.ownerType === "Agent") {
    signer.routerLink = {
      name: "AgentView",
      params: {
        id: signer.ownerId
      }
    };
  } else if (signer.ownerType === "Agency") {
    signer.routerLink = {
      name: "AgencyView",
      params: {
        id: signer.ownerId
      }
    };
  }

  signer.canResendEnvelope =
    !signer.signedAt &&
    [
      "Sent",
      "Delivered",
      "AutoResponded",
      "FaxPending",
      "AuthenticationFailed"
    ].includes(signer.statusCode) &&
    !["Quote", "Lead"].includes(currentEAppStatus);

  return signer;
}

function ElectronicApplicationExistingInsurance(model = {}) {
  return {
    carrierName: model?.carrierName,
    policyNumber: model?.policyNumber,
    status: model?.status,
    faceAmount: model?.faceAmount,
    policyDate: model?.policyDate,
    replacementReason: model?.replacementReason,
    businessInsurance: model?.businessInsurance
  };
}

function setElectronicApplicationExistingInsuranceFromRequest(model = {}) {
  const existingInsurance = ElectronicApplicationExistingInsurance();
  existingInsurance.carrierName = model.product?.carrier?.name;
  existingInsurance.policyNumber = model.policy_number;
  existingInsurance.status = model.status;
  existingInsurance.faceAmount = model.face_amount;
  existingInsurance.policyDate = model.policy_date;
  existingInsurance.replacementReason = model.replacement_reason;
  existingInsurance.businessInsurance = model.business_insurance;
  return existingInsurance;
}

function ElectronicApplicationQuote(model = {}) {
  return {
    id: model?.id,
    canDelete: model?.canDelete,
    createdAt: model?.createdAt,
    faceAmount: model?.faceAmount,
    premium: model?.premium,
    targetPremium: model?.targetPremium,
    mode: model?.mode,
    payDuration: model?.payDuration,
    solve: model?.solve,
    state: model?.state,
    firstName: model?.firstName,
    lastName: model?.lastName,
    gender: model?.gender,
    birthdate: model?.birthdate,
    health: model?.health,
    smoker: model?.smoker,
    height: model?.height,
    weight: model?.weight,
    validatedRating: model?.validatedRating,
    productCategory: model?.productCategory,
    cashValue: model?.cashValue,
    ageValue: model?.ageValue,
    creditingRate: model?.creditingRate,
    ltcRider: model?.ltcRider,
    ltcRiderPercentage: model?.ltcRiderPercentage,
    incomeSolve: model?.incomeSolve,
    incomeStartAge: model?.incomeStartAge,
    incomeEndAge: model?.incomeEndAge,
    lapseProtectionToAge: model?.lapseProtectionToAge,
    filterChronicIllnessAndLtc: model?.filterChronicIllnessAndLtc,
    livingBenefits: model?.livingBenefits,
    chronicIllnessRider: model?.chronicIllnessRider,
    loanType: model?.loanType,
    section1035Exchange: model?.section1035Exchange,
    section1035ExchangeRaw: model?.section1035ExchangeRaw,
    vitality: model?.vitality,
    vitalityRaw: model?.vitalityRaw,
    mec: model?.mec,
    discount: model?.discount,
    returnOfPremiumRider: model?.returnOfPremiumRider,
    saveAge: model?.saveAge,
    guaranteedIssue: model?.guaranteedIssue,
    monthlyBenefit: model?.monthlyBenefit,
    poolOfMoney: model?.poolOfMoney,
    eliminationPeriod: model?.eliminationPeriod,
    inflationPercentage: model?.inflationPercentage,
    jointWaiverOfPremium: model?.jointWaiverOfPremium,
    homeHealthCareWaiver: model?.homeHealthCareWaiver,
    sharedCare: model?.sharedCare,
    partnerDiscount: model?.partnerDiscount,
    insuredId: model?.insuredId,
    benefitPeriod: model?.benefitPeriod,
    carrier: {
      id: model?.carrier?.id,
      name: model?.carrier?.name,
      avatarUrl: model?.carrier?.avatarUrl,
      instantAps: model?.carrier?.instantAps
    },
    product: {
      id: model?.product?.id,
      name: model?.product?.name,
      category: model?.product?.category,
      carrierEDelivery: model?.product?.carrierEDelivery,
      ltcRider: model?.product?.ltcRider,
      chronicIllnessRider: model?.product?.chronicIllnessRider,
      advisorUseOnly: model?.product?.advisorUseOnly,
      multiplePolicies: model?.product?.multiplePolicies
    },
    illustration: {
      id: model?.illustration?.id,
      uid: model?.illustration?.uid,
      name: model?.illustration?.name,
      date: model?.illustration?.date,
      category: model?.illustration?.category,
      contentSize: model?.illustration?.contentSize,
      mimeType: model?.illustration?.mimeType
    },
    smokingUsages: model?.smokingUsages || []
  };
}

export function setElectronicApplicationQuoteFromRequest(req) {
  const quote = ElectronicApplicationQuote();
  quote.canDelete = req?.can_delete;
  quote.createdAt = req?.created_at;
  quote.id = req?.id;
  quote.faceAmount = req?.face_amount || 0;
  quote.premium = req?.premium;
  quote.targetPremium = req?.target_premium;
  quote.mode = req?.mode;
  quote.payDuration = req?.pay_duration;
  quote.solve = req?.solve;
  quote.state = req?.state;
  quote.firstName = req?.first_name;
  quote.lastName = req?.last_name;
  quote.gender = req?.gender;
  quote.birthdate = req?.birthdate;
  quote.health = req?.health;
  quote.smoker = req?.smoker;
  quote.height = req?.height;
  quote.weight = req?.weight;
  quote.validatedRating = req?.validated_rating;
  quote.productCategory = req?.product_category;
  quote.cashValue = req?.cash_value;
  quote.ageValue = req?.age_value;
  quote.creditingRate = req?.crediting_rate;
  quote.ltcRider = req?.ltc_rider;
  quote.ltcRiderPercentage = req?.ltc_rider_percentage;
  quote.incomeSolve = req?.income_solve;
  quote.incomeStartAge = req?.income_start_age;
  quote.incomeEndAge = req?.income_end_age;
  quote.lapseProtectionToAge = req?.lapse_protection_to_age;
  quote.filterChronicIllnessAndLtc = req?.filter_chronic_illness_and_ltc;
  quote.livingBenefits = req?.living_benefits;
  quote.chronicIllnessRider = req?.chronic_illness_rider;
  quote.loanType = req?.loan_type;
  quote.section1035Exchange = req?.section_1035_exchange?.amount;
  quote.section1035ExchangeRaw = req?.section_1035_exchange;
  quote.vitality = req?.vitality?.level;
  quote.vitalityRaw = req?.vitality;
  quote.mec = req?.mec;
  quote.discount = req?.discount;
  quote.returnOfPremiumRider = req?.return_of_premium_rider;
  quote.saveAge = req?.save_age;
  quote.guaranteedIssue = req?.guaranteed_issue;
  quote.monthlyBenefit = req?.monthly_benefit;
  quote.poolOfMoney = req?.pool_of_money || 0;
  quote.eliminationPeriod = req?.elimination_period;
  quote.inflationPercentage = req?.inflation_percentage;
  quote.jointWaiverOfPremium = req?.joint_waiver_of_premium;
  quote.homeHealthCareWaiver = req?.home_health_care_waiver;
  quote.sharedCare = req?.shared_care;
  quote.partnerDiscount = req?.partner_discount;
  quote.insuredId = req?.insured_id;
  quote.benefitPeriod = req?.benefit_period;

  quote.product = {
    id: req?.product?.id,
    name: req?.product?.name,
    category: req?.product?.category,
    carrierEDelivery: req?.product?.carrier_e_delivery,
    ltcRider: req?.product?.ltc_rider,
    chronicIllnessRider: req?.product?.chronic_illness_rider,
    advisorUseOnly: req?.product?.advisor_use_only,
    multiplePolicies: req?.product?.multiple_policies
  };

  quote.carrier = {
    id: req?.product?.carrier?.id,
    name: req?.product?.carrier?.name,
    avatarUrl: req?.product?.carrier?.avatar_url,
    instantAps: req?.product?.carrier?.instant_aps
  };

  quote.illustration = {
    id: req?.illustration?.id,
    uid: req?.illustration?.uid,
    name: req?.illustration?.name,
    date: req?.illustration?.date,
    category: req?.illustration?.category,
    contentSize: req?.illustration?.content_size,
    mimeType: req?.illustration?.mime_type
  };

  quote.smokingUsages = [];
  if (Array.isArray(req.usages)) {
    req.usages.forEach(usage => {
      quote.smokingUsages.push({
        status: usage.status,
        category: usage.category,
        frequency: usage.frequency,
        lastUseDate: usage.last_use_date,
        raw: {
          status: usage.status,
          category: usage.category,
          frequency: usage.frequency,
          last_use_date: usage.last_use_date
        }
      });
    });
  }

  return quote;
}

export function ElectronicApplicationTableItem(model = {}) {
  return {
    agentName: model?.agentName || "",
    agentAvatar: model?.agentAvatar || "",
    carrierName: model?.carrierName || "",
    created: model?.created || "",
    updatedAt: model?.updatedAt || "",
    completedAt: model?.completedAt || "",
    followUpAt: model?.followUpAt || null,
    followUpIsScheduled: model?.followUpIsScheduled || null,
    eAppId: model?.eAppId || "",
    insuredName: model?.insuredName || "",
    insuredId: model?.insuredId || 0,
    insuredPhoneMobile: model?.insuredPhoneMobile || "",
    leadStatus: model?.leadStatus || null,
    marketingManager: model?.marketingManager || "",
    mode: model?.mode || 1,
    premium: model?.premium || 0,
    productName: model?.productName || "",
    status: model?.status || "",
    website: model?.website || "",
    refer: Boolean(model?.refer),
    bestTimeToCall: model?.bestTimeToCall || "",
    stepDisplayName: model?.stepDisplayName || ""
  };
}

export function setElectronicApplicationTableItemFromRequest(model = {}) {
  const item = ElectronicApplicationTableItem();

  item.agentName = model?.agent?.name;
  item.agentAvatar = model?.agent?.avatar_url;
  item.carrierName = model?.product?.carrier?.name;
  item.created = model?.created_at;
  item.updatedAt = model?.updated_at;
  item.completedAt = model?.completed_at;
  item.eAppId = model?.id;
  item.followUpAt = model?.follow_up_at;
  item.followUpIsScheduled = model?.follow_up_at_is_appointment;
  item.insuredName = model?.insured?.name;
  item.insuredId = model?.insured?.id;
  item.insuredPhoneMobile = model?.insured?.phone_mobile;
  item.leadStatus = model?.lead_status;
  item.marketingManager = model?.approved_domain?.marketing_manager_name;
  item.premium = model?.premium;
  item.productName = model?.product?.name;
  item.status = model?.edited_status;
  item.website = model?.approved_domain?.domain;
  item.mode = model?.mode;
  item.refer = model?.refer;
  item.bestTimeToCall = model?.best_time_to_call;
  item.stepDisplayName = model?.step_display_name;

  return item;
}

export function ElectronicApplicationTableStats(model = {}) {
  return {
    averagePremium: model?.averagePremium || 0,
    completion: model?.completion || 0,
    conversion: model?.conversion || 0
  };
}

export function setElectronicApplicationTableStatsFromRequest(model = {}) {
  const stats = ElectronicApplicationTableStats();
  stats.averagePremium = model?.average_premium;
  stats.completion = model?.completion;
  stats.conversion = model?.conversion;
  return stats;
}
