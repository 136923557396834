<template>
  <v-autocomplete
    v-model="party"
    autocomplete="false"
    placeholder="Search Contract Parties"
    outlined
    dense
    return-object
    no-filter
    hide-no-data
    v-bind="$attrs"
    :data-testid="dataTestid"
    :label="label"
    :prepend-inner-icon="mdiAccount"
    :item-text="itemTextGenerator"
    :search-input.sync="partySearch"
    :items="parties"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="contractPartySearch"
    @blur="emit('blur')"
    @change="emit('change')"
  >
    <template #message="{ message }">
      <v-row class="pa-3">
        {{ message }}
        <v-spacer />
        <slot name="message" />
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script setup>
import { searchForContractParty } from "@/api/contract-party.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiAccount } from "@mdi/js";
import { ref, watch, defineExpose, defineProps, defineEmits } from "vue";
const props = defineProps({
  value: {
    type: Object,
    required: false,
    default: null
  },
  simple: Boolean,
  superSimple: Boolean,
  dataTestid: { type: String, required: false, default: null },
  label: {
    type: String,
    default: "Contract Party"
  }
});

const emit = defineEmits(["input", "blur", "change"]);

const snackbar = useSnackbarStore();

const parties = ref([]);
const party = ref(null);
const partySearch = ref("");
const loading = ref(false);
const contractPartySearch = ref(null); // templateref

if (props.value != null) {
  party.value = props.value;
  parties.value.push(props.value);
}

watch(
  () => props.value,
  () => {
    parties.value.splice(0, parties.value.length);
    if (!props.value || !Object.keys(props.value).length) {
      party.value = null;
      return;
    }
    party.value = props.value;
    parties.value.push(props.value);
  }
);

function partyEquivalence(a, b) {
  return a?.type === b?.type && a?.id === b?.id;
}

watch(party, () => {
  if (partyEquivalence(party.value, props.value)) return;
  emit("input", party.value);
});

let timer;
watch(partySearch, () => {
  if (timer) clearTimeout(timer);
  timer = setTimeout(getParties, 400);
});

async function getParties() {
  if (!partySearch.value) return;

  if (
    parties.value.some(val => itemTextGenerator(val) === partySearch.value) &&
    parties.value.length === 1
  ) {
    return;
  }

  loading.value = true;
  try {
    const res = await searchForContractParty(partySearch.value);
    parties.value.splice(0, parties.value.length);
    parties.value.push(...res);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

function focus() {
  if (contractPartySearch.value) {
    contractPartySearch.value.$refs.input.focus();
  }
}

function itemTextGenerator(value) {
  if (props.superSimple && value.lastFour) {
    return `${value.name} (${value.lastFour})`;
  }
  return value.name;
}

defineExpose({ focus });
</script>
