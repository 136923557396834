<template>
  <v-card v-if="showActions" tile flat>
    <v-row class="ma-0 pa-3" justify="end">
      <v-btn
        v-if="showCaseLink"
        outlined
        data-testid="action-view-case"
        class="text-none"
        color="orange"
        rounded
        :to="getRouterLink('Case', quoteCase)"
      >
        <v-icon class="mr-1">{{ mdiBriefcase }}</v-icon> View Case
      </v-btn>
      <v-btn
        v-if="showEappLink"
        outlined
        data-testid="action-view-eapp"
        class="text-none"
        color="accent"
        rounded
        :to="{ name: 'ElectronicApplicationView', params: { id: quoteEapp } }"
      >
        <v-icon class="mr-1">{{ mdiRocketLaunch }}</v-icon> View eApp
      </v-btn>
    </v-row>
  </v-card>
</template>

<script setup>
import { getRouterLink } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";
import { mdiBriefcase, mdiRocketLaunch } from "@mdi/js";
import { computed, watch, defineEmits } from "vue";

const emit = defineEmits(["update:show"]);

const { eapp: quoteEapp, case: quoteCase } = storeToRefs(useQuoteViewStore());

const showEappLink = computed(() => Boolean(quoteEapp.value));
const showCaseLink = computed(() => Boolean(quoteCase.value));
const showActions = computed(() => showCaseLink.value || showEappLink.value);

watch(showActions, () => emit("update:show", showActions.value), {
  immediate: true
});
</script>
