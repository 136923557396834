<template>
  <v-data-table
    must-sort
    data-testid="users-table"
    show-expand
    item-key="additional.id"
    :expanded.sync="expanded"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :server-items-length="table.meta.value.total"
    :loading="table.loading.value"
    :options="table.options.value"
    :footer-props="table.footerProps.value"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0" align="center">
        <h1 class="text-h5 pa-3">Users</h1>
        <v-spacer />
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              data-testid="users-table-refresh"
              @click="getData"
            >
              <v-icon> {{ mdiRefresh }} </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <v-divider />
      <generic-table-filter-header
        :header-props="{
          class: 'mt-0 pa-3',
          rounded: false
        }"
        :value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
      <v-divider />
    </template>

    <template #[`item.loggedInAs`]="{ item }">
      <template v-if="item.loggedInAs">
        {{ item.loggedInAs.name }}
      </template>
    </template>
    <template #[`item.highestGroup`]="{ item }">
      {{ item.additional.group }}/{{ item.highestGroup }}
    </template>

    <template #[`item.lastSignInAt`]="{ item }">
      {{ timestampFormatter(item.lastSignInAt, null, "date-time") }}
    </template>

    <template #[`item.mfa`]="{ item }">
      <span :class="successOrErrorClass(item.additional.otpEnrolled)">{{
        item.additional.otpEnrolled ? "Yes" : "No"
      }}</span
      >/<span
        :class="successOrErrorClass(item.additional.otpRequiredForLogin)"
        >{{ item.additional.otpRequiredForLogin ? "Yes" : "No" }}</span
      >
    </template>

    <template #[`item.permissions`]="{ item }">
      {{ permissionsFormatter(item.permissions) }}
    </template>
    <template #[`item.sso`]="{ item }">
      {{ ssoFormatter(item.sso) }}
    </template>

    <template #[`item.data-table-expand`]="{ expand, isExpanded }">
      <v-btn icon data-testid="expand-row" @click.stop="expand(!isExpanded)">
        <v-icon> {{ mdiInformation }} </v-icon>
      </v-btn>
    </template>

    <template #expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="mx-n4">
          <v-divider />
          <user-information
            class="ma-3"
            :id="item.additional.id"
            @refresh="getData"
          />
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script setup>
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import UserInformation from "@/components/users/UserInformation.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { mdiRefresh, mdiInformation } from "@mdi/js";

import {
  listToSentence,
  parseErrorMessage,
  timestampFormatter
} from "@/util/helpers";

import { getUsers } from "@/api/users.service";
import { useSnackbarStore } from "@/stores/snackbar";
import {
  PERMISSION_FILTER_ITEMS,
  PERMISSION
} from "@/constants/permissions.constants.js";
import { useTable } from "@/composables/table.composable";
import { ref } from "vue";

const snackbar = useSnackbarStore();

const expanded = ref([]);
const table = useTable({
  shouldIncludeCancelToken: true,
  getData: getUsers,
  options: new TableOptions(["lastSignInAt"], [true]),
  headers: [
    new TableHeader({
      text: "Email",
      value: "email",
      map: "email",
      sortFilterMap: "email",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Logged in as",
      value: "loggedInAs",
      map: "loggedInAs",
      sortFilterMap: [
        { key: "active_loginable_id", value: "id" },
        { key: "active_loginable_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),

    new TableHeader({
      text: "Group/Highest",
      value: "highestGroup",
      map: "highestGroup"
    }),
    new TableHeader({
      text: "Permissions",
      value: "permissions",
      map: "permissions",
      sortFilterMap: "permissions",
      ...TableHeader.IS_MULTI_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      selectableOptions: PERMISSION_FILTER_ITEMS
    }),
    new TableHeader({
      text: "MFA Enabled/Required",
      value: "mfa",
      map: "mfa"
    }),
    new TableHeader({
      text: "Sign in Method",
      value: "sso",
      map: "sso",
      sortFilterMap: "auth_method",
      selectableOptions: [
        { text: "OAuth", value: "OAuth" },
        { text: "SAML", value: "SAML" }
      ],
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Last Sign in At",
      value: "lastSignInAt",
      map: "lastSignInAt",
      sortFilterMap: "last_sign_in_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "",
      value: "data-table-expand",
      width: "72px"
    }),
    new TableHeader({
      text: "Highest Group",
      value: "highestGroupFilter",
      map: "highestGroupFilter",
      sortFilterMap: "highest_group",
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_NUMBER_RANGE_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "MFA Enabled",
      value: "otpEnrolled",
      map: "otpEnrolled",
      sortFilterMap: "otp_enrolled",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      selectableOptions: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" }
      ]
    }),
    new TableHeader({
      text: "MFA Required",
      value: "otpRequiredForLogin",
      map: "otpRequiredForLogin",
      sortFilterMap: "otp_required_for_login",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      selectableOptions: [
        { text: "Yes", value: "true" },
        { text: "No", value: "false" }
      ]
    }),
    new TableHeader({
      text: "Locked",
      value: "locked",
      map: "locked",
      sortFilterMap: "is_locked",
      ...TableHeader.IS_CHECKBOX_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL
    })
  ]
});

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

function permissionsFormatter(permissions) {
  const items = [];
  if (permissions[PERMISSION.ACCOUNTING]) items.push("Accounting");
  if (permissions[PERMISSION.FORM_MAPPING]) items.push("Form Mapping");
  if (permissions[PERMISSION.SCORECARD]) items.push("Scorecard");

  if (items.length === PERMISSION_FILTER_ITEMS.length) return "All";

  if (items.length) return listToSentence(items);
  return "None";
}

function successOrErrorClass(value) {
  return value ? "success--text" : "error--text";
}

function ssoFormatter(sso) {
  if (sso?.authMethod && sso?.oauthProvider) {
    return `${sso.authMethod}, via ${sso.oauthProvider}`;
  } else if (sso?.authMethod && sso?.emailDomain) {
    return `${sso.authMethod}, via ${sso.emailDomain}`;
  }

  return "Email & Password";
}

getData();
</script>
