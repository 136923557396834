import { set, del } from "vue";
import {
  updateApplicationQuestionLink,
  createApplicationQuestionLink,
  deleteApplicationQuestionLink
} from "@/api/application-question-links.service";

import {
  addFormSetToForm,
  removeFormSetFromForm
} from "@/api/form-sets.service";

import {
  ApplicationQuestionLink,
  NOT_ON_PDF_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  TEXT_VERBATIM_OPTION_TYPE,
  RADIO_OPTION_DIAMETER,
  SIGNATURE_OPTION_TYPE,
  SIGNATURE_DATE_OPTION_TYPE,
  getAqlCopyRequestBody,
  getAqlCreateRequestBody,
  getAqlUpdateRequestBody,
  setApplicationQuestionLinkFromRequest
} from "@/factories/FormMapping";

import {
  fetchForm,
  updateForm,
  pdfUrl,
  pdfSvgUrl,
  verifyActiveStatus,
  deleteFormPage
} from "@/api/forms.service";
import { defineStore } from "pinia";
import { uuidv4 } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";

export const useMappedFormStore = formId =>
  defineStore(`form-mapping-${formId}`, {
    state: () => ({
      id: formId,
      verifyingForm: false,
      readonly: false,
      color: "",
      status: "",
      name: "",
      submissionMethod: "Paper",
      otherFormId: null,
      modelId: 0,
      modelType: "",
      currentPage: 0,
      carrier: null,
      advisor: null,
      category: null,
      currentFieldId: null,
      currentCoordinateIndex: 0,
      currentFieldHasChanges: false,
      currentFieldIsValid: null,
      followUpAt: null,
      form: null,
      formSets: [],
      isContracting: null,
      limits: {},
      dupeOffsetY: 0,
      dupeOffsetX: 0,
      pageCount: 0,
      viewport: {
        width: 1,
        height: 1
      },
      window: {
        width: 1,
        height: 1
      },
      states: [],
      fields: {
        by_id: {},
        ids: []
      },
      activeField: {}
    }),
    getters: {
      isEappSubmission() {
        return this.submissionMethod !== "Paper";
      },
      otherFields() {
        if (!this.otherFormId) return {};
        const otherStore = useMappedFormStore(this.otherFormId);
        return otherStore.fields;
      },
      otherActiveField() {
        if (!this.otherFormId) return null;
        const otherStore = useMappedFormStore(this.otherFormId);
        return otherStore.activeField;
      },
      scale() {
        return +(this.window.width / this.viewport.width).toFixed(2);
      },
      isReadonly() {
        return this.status.includes("Complete");
      },
      pdfUrl() {
        return pdfUrl(this.id);
      },
      pdfSvgUrl() {
        return pdfSvgUrl(this.id);
      },
      incompleteQuestionCount() {
        const incompleteQuestions = this.fields.ids?.filter(field => {
          const aqId = this.fields.by_id[field]?.applicationQuestion?.id;
          return !aqId;
        });
        return incompleteQuestions?.length;
      },
      positionallySortedFields() {
        return this.fields.ids
          .map(id => this.fields.by_id[id])
          .toSorted((a, b) => {
            if (!a.coordinates.length && !b.coordinates.length) return 0;
            if (a.coordinates.length && !b.coordinates.length) return -1;
            if (!a.coordinates.length && b.coordinates.length) return 1;

            const aCoordinate = a.coordinates.find(c => !c.virtual);
            const bCoordinate = b.coordinates.find(c => !c.virtual);
            if (!aCoordinate?.page && !bCoordinate?.page) return 0;
            if (aCoordinate?.page && !bCoordinate?.page) return -1;
            if (!aCoordinate?.page && bCoordinate?.page) return 1;

            if (aCoordinate.page !== bCoordinate.page) {
              return aCoordinate.page - bCoordinate.page;
            }
            const aYPos = Math.ceil(aCoordinate.y / 10) * 10;
            const bYPos = Math.ceil(bCoordinate.y / 10) * 10;

            if (aYPos !== bYPos) return bYPos - aYPos; // Bottom up for y coords

            const aXPos = Math.ceil(aCoordinate.x / 10) * 10;
            const bXPos = Math.ceil(bCoordinate.x / 10) * 10;
            return aXPos - bXPos;
          });
      }
    },
    actions: {
      async initializeForm() {
        this.$reset();
        const {
          model_id,
          status,
          follow_up_at,
          name,
          application_question_links,
          carrier,
          ownable,
          contracting,
          category,
          form_sets,
          form,
          limits,
          states,
          submission_method
        } = await fetchForm(this.id);

        this.modelId = model_id;
        this.modelType = contracting ? "Appointment" : "ElectronicApplication";
        this.status = status;
        this.name = name;
        this.followUpAt = follow_up_at;
        this.isContracting = contracting;
        this.category = category;
        this.formSets = form_sets;
        this.form = form;
        this.limits = limits;
        this.states = states;
        this.submissionMethod = submission_method;

        let advisor;
        if (ownable?.id) {
          advisor = {
            id: ownable.id,
            type: ownable.type.model,
            name: ownable.name
          };
        }

        set(this, "carrier", carrier);
        set(this, "advisor", advisor);

        set(this.fields, "by_id", {});
        this.fields.ids.splice(0, this.fields.length);

        application_question_links.forEach(rawAql => {
          const aql = setApplicationQuestionLinkFromRequest(rawAql);
          set(this.fields.by_id, aql.id, aql);
          this.fields.ids.push(aql.id);
        });
      },
      async copyField(referenceField, referenceFormId) {
        const copiedFieldData = ApplicationQuestionLink(referenceField);
        const copiedField = ApplicationQuestionLink(copiedFieldData);
        copiedField.id = null;
        copiedField.parentQuestion = null;
        copiedField.referenceField = referenceField.id;
        copiedField.referenceForm = referenceFormId;
        if (copiedField.coordinates?.length) {
          for (let i = 0; i < copiedField.coordinates.length; i++) {
            copiedField.coordinates[i].page = this.currentPage;
          }
        }
        const body = getAqlCopyRequestBody(copiedField);
        const newId = await this.createLink({ body });
        this.changeActiveField(newId);
      },
      async copyToActiveField(aql, direction) {
        this.currentFieldHasChanges = true;

        const skippableKeys = ["referenceField", "referenceForm", "id"];
        Object.keys(aql).forEach(attr => {
          if (skippableKeys.includes(attr)) return;
          set(this.activeField, attr, aql[attr]);
        });

        if (direction == null) return;

        const isText = [
          TEXT_OPTION_TYPE,
          SIGNATURE_OPTION_TYPE,
          SIGNATURE_DATE_OPTION_TYPE
        ].includes(aql.pdfFieldType);

        aql.coordinates.forEach(({ height, width, virtual }, index) => {
          if (virtual) return;
          const offsetHeight = isText ? height : RADIO_OPTION_DIAMETER;
          const offsetWidth = isText ? width : RADIO_OPTION_DIAMETER;

          if (direction === "up") {
            this.activeField.coordinates[index].y += offsetHeight;
            this.activeField.coordinates[index].y += +this.dupeOffsetY;
          }
          if (direction === "down") {
            this.activeField.coordinates[index].y -= offsetHeight;
            this.activeField.coordinates[index].y -= +this.dupeOffsetY;
            if (this.activeField.coordinates[index].y < 0) {
              this.activeField.coordinates[index].y = 0;
            }
          }
          if (direction === "left") {
            this.activeField.coordinates[index].x -= offsetWidth;
            this.activeField.coordinates[index].x -= +this.dupeOffsetX;
            if (this.activeField.coordinates[index].x < 0) {
              this.activeField.coordinates[index].x = 0;
            }
          }
          if (direction === "right") {
            this.activeField.coordinates[index].x += offsetWidth;
            this.activeField.coordinates[index].x += +this.dupeOffsetX;
          }
        });
      },
      async deleteApplicationQuestionLink(id) {
        await deleteApplicationQuestionLink(id);

        if (this.currentFieldId === id) {
          let index = this.positionallySortedFields.findIndex(
            val => val.id.toString() === id.toString()
          );
          if (index === 0) index = 1;
          else index--;

          this.currentFieldHasChanges = false;
          if (this.positionallySortedFields.length === 1) {
            this.changeActiveField(null);
          } else {
            this.changeActiveField(this.positionallySortedFields[index].id);
          }
        }
        const index = this.fields.ids.findIndex(i => i === id);
        this.fields.ids.splice(index, 1);
        del(this.fields.by_id, id);
      },
      async updateApplicationQuestionLink(aql) {
        const body = getAqlUpdateRequestBody(aql);
        const updatedAql = await updateApplicationQuestionLink(aql.id, body);

        set(this.fields.by_id, aql.id, updatedAql);
        const childAqls = updatedAql.childAqls;
        if (childAqls?.length) {
          this.storeChildAqls(childAqls);
        }
      },
      async createLink({ body, fieldId = null }) {
        const aql = await createApplicationQuestionLink({
          body,
          form_id: this.id
        });

        if (fieldId) set(this.fields.by_id, fieldId, aql);
        else {
          set(this.fields.by_id, aql.id, aql);
          this.fields.ids.push(aql.id);
        }

        const childAqls = aql.childAqls;
        if (childAqls.length) this.storeChildAqls(childAqls);

        const parentQuestion = aql.parentAql;
        if (parentQuestion?.id) {
          const fieldAlreadyExists = this.fields.by_id[parentQuestion.id];
          const canAdd =
            !fieldAlreadyExists &&
            parentQuestion?.pdfFieldType === NOT_ON_PDF_OPTION_TYPE;

          if (canAdd) {
            const field = parentQuestion.id;
            set(this.fields.by_id, field, parentQuestion);
            this.fields.ids.push(field);
          }
        }
        return aql.id;
      },
      addNewFormSet(formSetId) {
        return addFormSetToForm(formSetId, this.id);
      },
      removeFormSet(formSetId) {
        return removeFormSetFromForm(formSetId, this.id);
      },
      updateFormName() {
        return this.updateForm({ name: this.name });
      },
      updateFormAdvisor() {
        return this.updateForm({
          ownable_type: this.advisor.type,
          ownable_id: this.advisor.id
        });
      },
      updateFormCategory() {
        return this.updateForm({ category: this.category });
      },
      updateFormStatus(status) {
        return this.updateForm({ status });
      },
      updateFormSubmissionMethod() {
        return this.updateForm({ submission_method: this.submissionMethod });
      },
      updateForm(form) {
        return updateForm(this.id, { form });
      },
      updateFormPdf(pdf) {
        return updateForm(this.id, { form: { file: pdf } });
      },
      generateAql(pdfFieldType) {
        const newAql = new ApplicationQuestionLink();
        newAql.pdfFieldType = pdfFieldType;
        if (
          [
            TEXT_VERBATIM_OPTION_TYPE,
            TEXT_OPTION_TYPE,
            SIGNATURE_OPTION_TYPE,
            SIGNATURE_DATE_OPTION_TYPE
          ].includes(pdfFieldType)
        ) {
          newAql.coordinates.push({
            page: this.currentPage,
            x: 5,
            y: this.viewport.height - 23,
            width: 100,
            height: 17,
            uuid: uuidv4()
          });
        }
        return newAql;
      },
      addPdfField(pdfFieldType) {
        const aql = this.generateAql(pdfFieldType);
        const body = getAqlCreateRequestBody(aql);
        return this.createLink({ body });
      },
      storeChildAqls(childAqls) {
        childAqls.forEach(question => {
          if (question.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE) return;
          const fieldAlreadyExists = this.fields.by_id[question.id];
          if (fieldAlreadyExists) return;

          set(this.fields.by_id, question.id, question);
          this.fields.ids.push(question.id);
        });
      },
      async insertChild(parentQuestion, pdfFieldType) {
        const newAql = this.generateAql(pdfFieldType);

        newAql.parentQuestion = parentQuestion;
        const body = getAqlCreateRequestBody(newAql);

        return this.createLink({ body });
      },

      async verifyStatus() {
        this.followUpAt = await verifyActiveStatus(this.id);
      },
      focusFirstField() {
        if (!this.positionallySortedFields.length) return;
        return this.changeActiveField(this.positionallySortedFields[0].id);
      },
      async changeActiveField(changeToField) {
        if (this.currentFieldHasChanges) {
          let warningText = null;
          let confirmText = "Lose Changes";
          let func = null;
          if (this.currentFieldIsValid) {
            func = () => this.updateApplicationQuestionLink(this.activeField);
            confirmText = "Save Changes";
            warningText = "Lose Changes";
          }

          const dialogStore = useDialogStore();
          const { confirm, warning } = await dialogStore.showDialog({
            component: "ConfirmationDialog",
            title: "Unsaved Changes",
            subtitle:
              "You have unsaved changes, please press cancel if you'd like to keep these changes.",
            confirmText,
            warningText,
            func,
            cancelText: "Keep Changes",
            persistent: true
          });
          if (!confirm && !warning) return;
        }

        if (!changeToField) {
          set(this, "activeField", {});
          return null;
        }
        if (!this.fields.ids.length) return;

        const fieldId = changeToField.toString();
        if (!this.fields.by_id[fieldId]) return;
        this.currentFieldHasChanges = false;
        this.currentFieldId = fieldId;
        const field = ApplicationQuestionLink(
          this.fields.by_id[this.currentFieldId]
        );

        set(this, "activeField", field);
        if (field.coordinates) {
          this.currentCoordinateIndex = field.coordinates.findIndex(
            c => !c.virtual
          );
        }
        return field;
      },
      deletePage() {
        return deleteFormPage(this.id, this.currentPage);
      }
    }
  })();
