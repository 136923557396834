import parse from "date-fns/parse";
import format from "date-fns/format";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInYears from "date-fns/differenceInYears";
import differenceInMinutes from "date-fns/differenceInMinutes";
import cloneDeep from "lodash/cloneDeep";
import { logInfo } from "@/logger";
import { useInstanceStore } from "@/stores/instance";
import { serialize } from "object-to-formdata";
import { states } from "@/data/states";
import { computed, isRef } from "vue";
import {
  mdiAccountTie,
  mdiBriefcaseVariant,
  mdiCalculator,
  mdiCalendar,
  mdiCardAccountDetails,
  mdiChartAreaspline,
  mdiCity,
  mdiCube,
  mdiDomain,
  mdiHumanGreetingProximity,
  mdiRocketLaunch
} from "@mdi/js";
import { ROUTES } from "@/router/constants.mjs";

export { cloneDeep };

export const downloadFile = (
  url,
  openInSelf = this._vm.$vuetify.breakpoint.mdAndDown
) => {
  const link = document.createElement("a");
  link.download = true;
  link.href = url;
  link.target = openInSelf ? "_self" : "_blank";
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};
export const downloadFileAsLink = (url, openInSelf = false) => {
  return {
    download: true,
    href: url,
    target: openInSelf ? "_self" : "_blank"
  };
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 *
 * @param {*} file
 * @returns {Promise<{name: string, size: number, type: string, contents: string}>}
 */
export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        name: file.name,
        size: file.size,
        type: file.type,
        contents: reader.result
      });
    };
    reader.onerror = error => reject(error);
  });
}

export const parseErrorMessage = e => {
  const message =
    e?.response?.data?.message ||
    e?.response?.data?.error ||
    e?.response?.data?.errors ||
    e?.response?.data ||
    e?.response ||
    e;

  console.error(message);
  if (Array.isArray(message)) return message.join(". ");
  if (typeof message === "object" && message) {
    const key = Object.keys(message)[0];
    if (key === "config") {
      logInfo(`Config Network Error: ${JSON.stringify(e)}`);
      return "There was an error processing this request. Please try again or contact support.";
    }
    if (key && message[key]) return `${key} ${message[key]}`;
    //fix undefined undefined
    else
      return "There was an error processing this request. Please try again or contact support.";
  }
  return message;
};

export const navigateToExternalLink = (url, openInSelf = false) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = openInSelf ? "_self" : "_blank";
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

export const selectImage = element => {
  const doc = document;
  if (doc.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const shortenLongUsername = name => {
  const MAX_LEN = 30;
  if (name.length < MAX_LEN) return name;
  return name.split(" ")[0];
};

export const documentUrl = uid => {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/api/boss/documents/${uid}/download?download`;
};

export function openWindowWithPost(url, data) {
  const form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";

  for (let key in data) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function listToSentence(textItems) {
  if (!Array.isArray(textItems) || !textItems) return null;
  if (textItems.length === 0) return null;
  if (textItems.length === 1) return textItems[0];
  const allMinusLast = textItems.slice(0, textItems.length - 1).join(", ");
  const oxfordComma = textItems.length > 2 ? "," : "";
  return `${allMinusLast}${oxfordComma} and ${textItems[textItems.length - 1]}`;
}

const MAX_TRUNCATE_LEN = 35;
export function cropOrEllipsis(text) {
  const words = text.split(" ");
  let wordStr = words.join(" ").trim();
  if (words.length < MAX_TRUNCATE_LEN) return wordStr;
  wordStr = words.slice(0, MAX_TRUNCATE_LEN).join(" ").trim();
  if (wordStr.endsWith(".")) return `${wordStr}..`;
  return `${wordStr}...`;
}

export const clickedWithinBoundingBox = ({
  mouseX,
  mouseY,
  rectX,
  rectY,
  rectWidth,
  rectHeight,
  radius,
  isCircle
}) => {
  let wBound = rectX,
    eBound = rectX + rectWidth,
    nBound = rectY,
    sBound = rectY + rectHeight;

  if (isCircle) {
    wBound = rectX - radius;
    eBound = rectX + radius;

    nBound = rectY - radius;
    sBound = rectY + radius;
  }
  const sameX = mouseX >= wBound && mouseX <= eBound;
  const sameY = mouseY >= nBound && mouseY <= sBound;
  return sameX && sameY;
};

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function toDataURL(src, callback, outputFormat) {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    const canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    let dataURL;
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = src;
  }
}

export function someTextValidator(visible, v, minLength = 3) {
  if (!visible) return true;
  if (v?.replace) return v.replace(/ /g, "").length >= minLength;
  return false;
}

function getCurrentPixelRatio() {
  const ctx = document.createElement("canvas").getContext("2d");
  const dpr = window.devicePixelRatio || 1;
  const bsr =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;

  return dpr / bsr;
}

export function createHighDPICanvas(w, h) {
  const ratio = getCurrentPixelRatio();
  const can = document.createElement("canvas");
  can.width = w * ratio;
  can.height = h * ratio;
  can.style.width = w + "px";
  can.style.height = h + "px";
  can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
  return can;
}

export function drawRectangle(
  ctx,
  {
    x,
    y,
    fillColor = "rgba(0,0,0,0)",
    strokeColor = "rgba(0,0,0,0)",
    height,
    width,
    text,
    isDashed,
    comb
  }
) {
  resetContext(ctx);
  ctx.strokeStyle = strokeColor;
  ctx.fillStyle = fillColor;
  ctx.lineWidth = 2;
  if (isDashed) ctx.setLineDash([5, 3]);
  if (text) {
    ctx.font = "12 Arial";
    const middleOffsetX = (2 * x + width) / 2 - 20;
    const middleOffsetY = y + height + 8;
    if (!fillColor) ctx.fillStyle = strokeColor;
    ctx.fillText(text, middleOffsetX, middleOffsetY);
  } else {
    ctx.fillRect(x, y, width, height);
  }

  ctx.strokeRect(x, y, width, height);

  // |---|---| = comb 2, draw 1 line
  if (comb) {
    resetContext(ctx);
    const segmentWidth = width / comb;
    ctx.strokeStyle = "black";
    for (let i = 1; i < comb; i++) {
      ctx.beginPath();
      ctx.moveTo(x + i * segmentWidth, y - 1);
      ctx.lineTo(x + i * segmentWidth, y + height + 1);
      ctx.stroke();
    }
  }
}

export function drawCheckbox(
  ctx,
  {
    x,
    y,
    strokeColor = "rgba(0,0,0,0)",
    fillColor = "rgba(0,0,0,0)",
    radii,
    sideLength,
    isDashed,
    text = null
  }
) {
  resetContext(ctx);
  if (strokeColor) ctx.strokeStyle = strokeColor;
  else ctx.strokeStyle = "rgba(0,0,0,0)";

  if (fillColor) ctx.fillStyle = fillColor;
  else ctx.fillStyle = "rgba(0,0,0,0)";

  ctx.beginPath();

  if (isDashed) ctx.setLineDash([3, 3]);
  ctx.roundRect(
    x - sideLength / 2,
    y - sideLength / 2,
    sideLength,
    sideLength,
    radii
  );
  ctx.stroke();
  if (text) {
    ctx.font = "normal normal 12px sans-serif";
    const middleOffsetX = x - text.length * 2.5;
    const middleOffsetY = y + sideLength + 10;
    ctx.fillStyle = strokeColor;
    ctx.strokeStyle = "rgba(0,0,0,1)";
    ctx.fillText(text, middleOffsetX, Math.abs(middleOffsetY));
  } else {
    ctx.fill();
  }

  drawCircle(ctx, { x, y, strokeColor, fillColor, radius: 1, isDashed });
}

export function drawCircle(
  ctx,
  {
    x,
    y,
    strokeColor = "rgba(0,0,0,0)",
    fillColor = "rgba(0,0,0,0)",
    radius,
    isDashed,
    text = null
  }
) {
  resetContext(ctx);
  if (strokeColor) ctx.strokeStyle = strokeColor;
  else ctx.strokeStyle = "rgba(0,0,0,0)";

  if (fillColor) ctx.fillStyle = fillColor;
  else ctx.fillStyle = "rgba(0,0,0,0)";

  ctx.beginPath();

  if (isDashed) ctx.setLineDash([5, 3]);
  ctx.arc(x, y, 1, 0, 2 * Math.PI, false);
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
  ctx.stroke();
  if (text) {
    ctx.font = "normal normal 12px sans-serif";
    const middleOffsetX = x - text.length * 2.5;
    const middleOffsetY = y + radius + 10;
    ctx.fillStyle = strokeColor;
    ctx.strokeStyle = "rgba(0,0,0,1)";
    ctx.fillText(text, middleOffsetX, Math.abs(middleOffsetY));
  } else {
    ctx.fill();
  }
}

function resetContext(ctx) {
  ctx.strokeStyle = "rgba(0,0,0,0)";
  ctx.fillStyle = "rgba(0,0,0,0)";
  ctx.setLineDash([]);
}

export function generateRandomColor() {
  const o = Math.round,
    r = Math.random,
    s = 255;
  const R = o(r() * s);
  const G = o(r() * s);
  const B = o(r() * s);
  return `rgb(${R}, ${G}, ${B})`;
}

export function currencyFormat(value, decimalLength = 2) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalLength,
    maximumFractionDigits: decimalLength
  });

  if (!value) {
    return "$0";
  }
  return formatter.format(value);
}

export function yearsAndMonths(value) {
  if (value === -1) return "Unlimited";
  const years = Math.trunc(value);
  const months = Math.trunc((value % 1) * 12);

  const text = [];

  if (years) {
    let suffix = "Yrs.";
    if (years === 1) suffix = "Yr.";
    text.push(`${years} ${suffix}`);
  }

  if (months) {
    text.push(`${months} Mo.`);
  }

  return text.join(" ");
}

export function heightText(value) {
  if (value.length !== 2) return value;

  const inchesInFoot = 12;

  const quotient = Math.floor(Number(value) / inchesInFoot);
  const remainder = value % inchesInFoot;

  return `${quotient}' ${remainder}"`;
}

export function parseSoleDay(value) {
  if (!value) {
    return value;
  }
  const parsedDate = parse(value, "yyyy-MM-dd", new Date());
  return format(parsedDate, "MM/dd/yyyy");
}

export function dateTime(value) {
  if (!value) {
    return value;
  }

  return format(new Date(value), "MM/dd/yyyy h:mma");
}

export function numberFormat(value) {
  if (!value && value !== 0) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * @deprecated use formatPercentage
 */
export function percentageFormat(
  value,
  multiplyBy100 = false,
  truncate = false
) {
  let temp = value || 0;
  if (multiplyBy100) temp *= 100;
  let maxLength = 6;
  if (`${temp}`.startsWith("-")) maxLength = 7;
  if (truncate && `${temp}`.length > maxLength) {
    temp = `${temp}`.substring(0, maxLength);
  }
  return `${temp}%`;
}

export function formatPercentage(
  value,
  { isDecimal = false, floor = false, decimalLength = 2 }
) {
  let v = +value;
  if (isDecimal) v *= 100;
  if (floor) v = Math.floor(v);
  if (decimalLength || decimalLength === 0) v = (+v).toFixed(decimalLength);
  return `${v}%`;
}

export function formatToFullState(val) {
  const state = states.find(({ text, full }) => text === val || full === val);
  if (!state) return "N/A";
  return state.full;
}

export function timestampFormatter(
  value,
  timeParser = "none",
  timeFormat = "basic"
) {
  const parsers = {
    none: null,
    "date-time": "MM/dd/yyyy h:mma",
    "sole-day": "yyyy-MM-dd",
    "formatted-day": "MM/dd/yyyy"
  };

  const formats = {
    basic: "MM/dd/yyyy",
    date: "MMM do",
    "full-date-time": "MMMM do, yyyy 'at' h:mm a",
    "full-date": "MMM d, yyyy",
    "date-time": "MM/dd/yyyy h:mma",
    time: "h:mma",
    "full-localize": "EEEE",
    "full-month-date": "MMMM do, yyyy"
  };

  if (!value) return null;

  const parser = parsers[timeParser];
  const dateFormat = formats[timeFormat];

  let parsedDay;

  if (parser) {
    parsedDay = parse(value, parser, new Date());
  } else {
    parsedDay = new Date(value);
  }

  return format(parsedDay, dateFormat);
}

export function payDurationFormat(value) {
  let payDuration = value;
  if (payDuration === -1) payDuration = "Lifetime";
  else if (payDuration === -65) payDuration = "Pay to Age 65";
  return payDuration;
}

export function modeFormat(value, format = "adverb") {
  const adverb = {
    12: "Monthly",
    4: "Quarterly",
    2: "Semi-Annual",
    1: "Annually"
  };

  const abbreviated = {
    12: "Mo.",
    4: "Qtr.",
    2: "Semi-Yr.",
    1: "Yr."
  };

  const formatDict = { adverb, abbreviated };

  const dict = formatDict[format];
  return dict[value] || "Unknown";
}

export function parsedHoursTill(value) {
  if (!value) {
    return "N/A";
  }
  const parsedDate = new Date(value);
  const now = new Date();
  if (parsedDate.getTime() > now.getTime()) {
    return sentenceify(parsedDate, now, false, true);
  }

  return sentenceify(now, parsedDate, true, true);
}

export function parsedDaysTill(value) {
  if (!value) {
    return "N/A";
  }
  const parsedDate = parse(value, "yyyy-MM-dd", new Date());
  const now = new Date();
  if (parsedDate.getTime() > now.getTime()) {
    return sentenceify(parsedDate, now, false, false);
  }

  return sentenceify(now, parsedDate, true, false);
}

const sentenceify = (now, parsedDate, after, includeTime) => {
  if (includeTime) {
    const minutes = differenceInMinutes(now, parsedDate);

    if (minutes === 0) {
      return "Now";
    }

    if (minutes < 60) {
      const sentence = `${minutes} ${minutes === 1 ? "Minute" : "Minutes"}`;
      if (after) {
        return `${sentence} Ago`;
      }
      return `In ${sentence}`;
    }

    const hours = differenceInHours(now, parsedDate);
    if (hours < 24) {
      const sentence = `${hours} ${hours === 1 ? "Hour" : "Hours"}`;
      if (after) {
        return `${sentence} Ago`;
      }
      return `In ${sentence}`;
    }
  }

  const days = differenceInDays(now, parsedDate);
  if (days < 31) {
    if (days === 0 && !includeTime) {
      return "Today";
    } else if (days === 1 && !includeTime) {
      return after ? "Yesterday" : "Tomorrow";
    }
    const sentence = `${days} ${days === 1 ? "Day" : "Days"}`;
    if (after) {
      return `${sentence} Ago`;
    }
    return `In ${sentence}`;
  }

  const months = differenceInMonths(now, parsedDate);
  if (months < 12) {
    const sentence = `${months} ${months === 1 ? "Month" : "Months"}`;
    if (after) {
      return `${sentence} Ago`;
    }
    return `In ${sentence}`;
  }

  const years = differenceInYears(now, parsedDate);
  if (years === 1) {
    if (after) {
      return "A Year Ago";
    }
    return "In a Year";
  }
  if (after) {
    return `${years} Years Ago`;
  }
  return `In ${years} Years`;
};

// We wrap because testing formdata is not happening. TODO -> kill formdata
export async function serializeObject(params) {
  if (process.env.VUE_APP_COMPONENT_TESTING) {
    const serializeObject = async obj => {
      for (const [key, value] of Object.entries(obj)) {
        if (value instanceof File) {
          obj[key] = await convertFileToBase64(value);
        } else if (value instanceof Object) {
          await serializeObject(value);
        } else if (value instanceof Array) {
          await serializeArray(value);
        }
      }
    };

    const serializeArray = async arr => {
      for (const value of arr) {
        if (value instanceof File) {
          arr[arr.indexOf(value)] = await convertFileToBase64(value);
        } else if (value instanceof Object) {
          await serializeObject(value);
        }
      }
    };

    await serializeObject(params);

    return params;
  }
  return serialize(params);
}

/**
 * @deprecated
 */
export function validationCompute(model, validators = []) {
  const success = !model?.$invalid;
  const errorMessages = [];
  if (!model?.$dirty) return { success, errorMessages };
  if (!model.required) errorMessages.push("Required");
  validators.forEach(({ key, message }) => {
    if (!model[key]) errorMessages.push(message);
  });
  return { success, errorMessages };
}

export function computedValidation(
  model,
  validators = [],
  displaySuccessIfEmpty = false
) {
  return computed(() =>
    validationComputeV2(model, validators, displaySuccessIfEmpty)
  );
}

export function validationComputeV2(
  model,
  validators = [],
  displaySuccessIfEmpty = false
) {
  let success = !model?.$invalid;
  if (!displaySuccessIfEmpty) {
    let looselyDefined = Boolean(
      model?.$model || [0, false].includes(model?.$model)
    );
    if (Array.isArray(model?.$model)) {
      looselyDefined = model?.$model.length > 0;
    }
    success = success && looselyDefined;
  }
  const errorMessages = [];
  if (!model?.$dirty) return { success, errorMessages };
  if (Array.isArray(validators)) {
    validators.forEach(({ key, message }) => {
      if (model[key].$invalid) errorMessages.push(message);
    });
  } else if (typeof validators === "object") {
    for (const [key, message] of Object.entries(validators)) {
      if (model[key].$invalid) {
        if (isRef(message)) errorMessages.push(message.value);
        else errorMessages.push(message);
      }
    }
  }
  return { success, errorMessages };
}

export const PRODUCTS = {
  ACCIDENTAL_DEATH: "Accidental Death",
  FINAL_EXPENSE: "Final Expense",
  LTC: "Long Term Care",
  NON_MED: "Non Med Term",
  ACCUMULATION_IUL: "Accumulation IUL",
  PROTECTION_IUL: "Protection IUL",
  PROTECTION_VUL: "Protection VUL",
  UNIVERSAL_LIFE: "Universal Life",
  ROP_TERM: "Return of Premium",
  TERM: "Term",
  WHOLE_LIFE: "Whole Life",
  LINKED_BENEFIT: "Linked Benefit"
};

export const CATEGORIES = {
  ACCIDENTAL_DEATH: "Accidental Death",
  FINAL_EXPENSE: "Final Expense",
  LTC: "Long Term Care",
  NON_MED: "Non Med",
  INDEXED: "Indexed",
  ACCUMULATION: "Accumulation",
  VARIABLE: "Variable",
  PROTECTION: "Protection",
  UNIVERSAL_LIFE: "Universal Life",
  ROP_TERM: "Return of Premium",
  TERM: "Term",
  WHOLE_LIFE: "Whole Life",
  LINKED_BENEFIT: "Linked Benefit"
};

export function categoryToConstantProduct(str) {
  if (!str?.indexOf) return null;
  const rawCategory = str.split(".");
  const [base] = rawCategory;
  const categories = [];
  if (base === "whole_life") {
    if (rawCategory.includes("none")) {
      categories.push(CATEGORIES.WHOLE_LIFE);
    }
    if (rawCategory.includes("final_expense")) {
      categories.push(CATEGORIES.FINAL_EXPENSE);
    }
  }

  if (base === "universal_life") {
    if (rawCategory.includes("variable")) {
      categories.push(CATEGORIES.VARIABLE);
    }
    if (rawCategory.includes("accumulation")) {
      categories.push(CATEGORIES.ACCUMULATION);
    }
    if (rawCategory.includes("protection")) {
      categories.push(CATEGORIES.PROTECTION);
    }
    if (rawCategory.includes("indexed")) {
      categories.push(CATEGORIES.INDEXED);
    }
    if (rawCategory.includes("none")) {
      categories.push(CATEGORIES.UNIVERSAL_LIFE);
    }
    if (rawCategory.includes("linked_benefit")) {
      categories.push(CATEGORIES.LINKED_BENEFIT);
    }
  }

  if (base === "term") {
    if (rawCategory.includes("none")) {
      categories.push(CATEGORIES.TERM);
    }
    if (rawCategory.includes("return_of_premium")) {
      categories.push(CATEGORIES.ROP_TERM);
    }
    if (rawCategory.includes("non_med")) {
      categories.push(CATEGORIES.NON_MED);
    }
    if (rawCategory.includes("ul_term")) {
      categories.push(CATEGORIES.UNIVERSAL_LIFE);
    }
  }

  if (base === "accidental_death") {
    if (rawCategory.includes("accidental_death")) {
      categories.push(CATEGORIES.ACCIDENTAL_DEATH);
    }
  }

  if (base === "ltc") {
    categories.push(CATEGORIES.LTC);
  }

  return categories;
}

export function formatBoolToText(v) {
  if (v === true) return "Yes";
  if (v === false) return "No";
  return "Unspecified";
}

export function formatPartnerDiscount(v) {
  return {
    one: "Married, Applying Separately",
    both: "Married, Applying Jointly"
  }[v];
}

const BENEFIT_PERIOD_OPTIONS = [];
const START = 2;
const END = 7;

for (let i = START; i <= END; i++) {
  BENEFIT_PERIOD_OPTIONS.push({ value: i, text: `${i} Years` });
}

BENEFIT_PERIOD_OPTIONS.push({ value: -1, text: "Unlimited" });

export { BENEFIT_PERIOD_OPTIONS };

export function formatBenefitPeriod(v) {
  if (v === -1) return "Unlimited";
  if (v === 1) return "1 Year";
  return `${v} Years`;
}

const frequencyModes = {
  365: "Daily",
  12: "Monthly",
  2: "Bi-Monthly",
  52: "Weekly"
};

export function formatFrequency(value) {
  return frequencyModes[value] || "Unknown";
}

export const soleDateSort = (a, b) => {
  if (!a.startDate) return -1;
  if (!b.startDate) return -1;
  const aStartDate = parse(a.startDate, "yyyy-MM-dd", new Date());
  const bStartDate = parse(b.startDate, "yyyy-MM-dd", new Date());
  return aStartDate.getTime() - bStartDate.getTime();
};

export function lineFormatter(v) {
  const lines = {
    life: "Life",
    linked_benefit: "Life",
    variable_life: "Life",
    ltc: "LTC",
    annuity: "Annuity",
    disability: "Disability",
    informal: "Informal"
  };

  if (!Array.isArray(v)) {
    return lines[v] || "None";
  }
  return listToSentence(v.map(v => lines[v])) || "None";
}

export function basicArrayEquivalence(arr1, arr2) {
  try {
    const valueDict = {};
    arr1.forEach(v => (valueDict[v] = 1));
    arr2.forEach(v => valueDict[v]++);
    return Object.values(valueDict).every(v => v === 2);
  } catch (e) {
    return false;
  }
}

export function stringLowerComparison(a, b) {
  return `${a}`.toLowerCase() === `${b}`.toLowerCase();
}

export function camelOrPascalToSnake(str) {
  let snakedType = str.replace(/([A-Z])/g, "_$1").toLowerCase();
  if (snakedType.startsWith("_")) snakedType = snakedType.slice(1);
  return snakedType;
}

export const getRouterLink = (type, id, options = {}) => {
  const baseLink = {
    Agent: { name: ROUTES.AGENT, params: { id } },
    AgentSettings: { name: ROUTES.AGENT_SETTINGS, params: { id } },
    Agency: { name: ROUTES.AGENCY, params: { id } },
    AgencySettings: { name: ROUTES.AGENCY_SETTINGS, params: { id } },
    ApprovedDomain: { name: ROUTES.APPROVED_DOMAIN, params: { id } },
    Case: { name: ROUTES.CASE, params: { id } },
    Carrier: { name: ROUTES.CARRIER, params: { id } },
    Appointment: { name: ROUTES.APPOINTMENT, params: { id } },
    PayPeriod: { name: ROUTES.PAY_PERIOD, params: { id } },
    Product: { name: ROUTES.PRODUCT, params: { id } },
    Personnel: { name: ROUTES.PERSONNEL, params: { id } },
    Individual: { name: ROUTES.INDIVIDUAL, params: { id } },
    ElectronicApplication: { name: ROUTES.E_APP, params: { id } },
    ContractParty: { name: ROUTES.CONTRACT_PARTY, params: { id } },
    QuickQuote: { name: ROUTES.IMPAIRED_RISK_QUOTE, params: { id } },
    Quote: { name: ROUTES.QUOTE, params: { id } },
    Statement: { name: ROUTES.STATEMENT, params: { id } }
  }[type];

  if (!baseLink) return null;
  return { ...baseLink, ...options };
};

export const getTypeIcon = type => {
  return {
    Agent: mdiAccountTie,
    Agency: mdiDomain,
    Case: mdiBriefcaseVariant,
    Carrier: mdiCity,
    Appointment: mdiCalendar,
    Product: mdiCube,
    Personnel: mdiHumanGreetingProximity,
    ElectronicApplication: mdiRocketLaunch,
    ContractParty: mdiCardAccountDetails,
    Quote: mdiCalculator,
    QuickQuote: mdiChartAreaspline
  }[type];
};

export const deepMergeObjects = (...objects) => {
  const deepCopyObjects = objects.map(object =>
    JSON.parse(JSON.stringify(object))
  );
  return deepCopyObjects.reduce(
    (merged, current) => ({ ...merged, ...current }),
    {}
  );
};
