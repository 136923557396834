<template>
  <v-app>
    <v-main :style="cssProps">
      <v-fade-transition mode="out-in">
        <router-view name="layout">
          <v-fade-transition mode="out-in">
            <router-view
              :key="$route.path"
              :style="{ 'margin-bottom': fullscreen ? null : '50px' }"
            />
          </v-fade-transition>
        </router-view>
      </v-fade-transition>
      <app-services />
    </v-main>
  </v-app>
</template>

<script>
import AppServices from "@/app-services/AppServices.vue";
import { mapActions, mapState, mapWritableState } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { heartbeat } from "@/api/auth.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useDialogStore } from "@/stores/dialog";
export default {
  name: "App",
  components: {
    AppServices
  },
  data: () => ({
    interval: false,
    attempts: 0,
    isShowingInactivityDialog: false
  }),
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapWritableState(useInstanceStore, ["hasSentRequest"]),
    ...mapWritableState(useInstanceStore, ["fullscreen", "breadcrumb"]),
    ...mapState(useUserStore, {
      userIsLoggedIn: s => s.id,
      group: "group",
      loginable: "loginable"
    }),
    cssProps() {
      const currentTheme =
        this.$vuetify.theme.themes[this.$vuetify.theme.dark ? "dark" : "light"];
      return {
        "--primary-color": currentTheme.primary,
        background: currentTheme.background,
        "--success-color": currentTheme.success,
        "--info-color": currentTheme.info,
        "--warning-color": currentTheme.warning,
        "--error-color": currentTheme.error
      };
    }
  },
  watch: {
    "$route.path"() {
      this.fullscreen = false;
      this.breadcrumb = "";
      this.closeAll();
    },
    userIsLoggedIn(v) {
      if (!v) return;

      this.initHeartbeat();
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar", "showInfoSnackbar"]),
    ...mapActions(useDialogStore, ["closeAll"]),

    initHeartbeat() {
      if (this.interval) clearInterval(this.interval);

      this.interval = setInterval(() => {
        if (
          !this.userIsLoggedIn ||
          !this.hasSentRequest ||
          process.env.NODE_ENV !== "production"
        )
          return;
        heartbeat()
          .catch(parseErrorMessage)
          .finally(() => {
            this.hasSentRequest = false;
          });
      }, 5 * 60 * 1000);
    }
  }
};
</script>
