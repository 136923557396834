<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        data-testid="existing-insurance-table"
        class="transparent-data-table"
        :items="table.mappedItems.value"
        :headers="table.tableHeaders.value"
      >
        <template #[`item.policyDate`]="{ item }">
          <timestamp-formatter :value="item.policyDate" parser="sole-day" />
        </template>
        <template #[`item.faceAmount`]="{ item }">
          <currency-formatter :value="item.faceAmount" :decimal-length="0" />
        </template>
        <template #[`item.businessInsurance`]="{ item }">
          <template v-if="item.businessInsurance === true"> Yes </template>
          <template v-else> No </template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { useTable } from "@/composables/table.composable";
import { useEappViewStore } from "@/stores/eapp-view";
const headers = [
  new TableHeader({
    text: "Carrier",
    value: "carrierName",
    map: "carrierName"
  }),
  new TableHeader({
    text: "Policy Number",
    value: "policyNumber",
    map: "policyNumber"
  }),
  new TableHeader({ text: "Status", value: "status", map: "status" }),
  new TableHeader({
    text: "Amount",
    value: "faceAmount",
    map: "faceAmount"
  }),
  new TableHeader({
    text: "Issue Date",
    value: "policyDate",
    map: "policyDate"
  }),
  new TableHeader({
    text: "Replacement Reason",
    value: "replacementReason",
    map: "replacementReason"
  }),
  new TableHeader({
    text: "Business Insurance",
    value: "businessInsurance",
    map: "businessInsurance"
  })
];

const eapp = useEappViewStore();

const table = useTable({ headers, items: eapp.electronicApplicationCases });
</script>
