<template>
  <v-card flat tile>
    <v-row align="center" class="ma-0">
      <v-col cols="12" md="6" data-testid="party-name">
        <strong style="font-size: 1.2rem">
          {{ name }}
        </strong>
        <br />
        <span>
          {{ type }}
        </span>
      </v-col>
      <v-col v-if="avatarUrl" cols="12" md="6">
        <v-row justify="end" class="ma-0">
          <div>
            <v-img :src="avatarUrl" height="150" contain />
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <party-view-card-items :party="partyView" />
  </v-card>
</template>
<script setup>
import PartyViewCardItems from "@/components/parties/PartyViewCardItems.vue";
import { usePartyView } from "@/stores/party-view";
import { storeToRefs } from "pinia";
const partyView = usePartyView();

const { name, avatarUrl, type } = storeToRefs(partyView);
</script>
