<template>
  <v-data-table
    must-sort
    data-testid="category-states-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :server-items-length="table.meta.value.total"
    :loading="table.loading.value"
    :options="table.options.value"
    :footer-props="footerProps"
    @update:options="updateOptions"
  >
    <template #top>
      <v-row class="ma-0">
        <span class="text-h5"> Category States </span>
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              data-testid="category-states-table-refresh"
              :disabled="table.loading.value"
              @click="getData"
            >
              <v-icon> {{ mdiRefresh }} </v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-row>
      <generic-table-filter-header
        :value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
    </template>
    <template #[`item.category`]="{ item }">
      <friendly-form-category :value="item.category" />
    </template>
    <template #[`item.formName`]="{ item }">
      <router-link
        v-if="item.additional.formId"
        :to="{
          name: 'FormMappingEditor',
          params: { id: item.additional.formId }
        }"
      >
        {{ item.formName }}
      </router-link>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        icon
        color="accent"
        data-testid="edit"
        @click="editCategoryState(item)"
      >
        <v-icon> {{ mdiPencil }}</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";

import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";
import FriendlyFormCategory from "@/components/shared/FriendlyFormCategory.vue";

import { states } from "@/data/states";
import { parseErrorMessage } from "@/util/helpers";
import { getCategoryStates } from "@/api/category-states.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTable } from "@/composables/table.composable";
import { computed } from "vue";
import { CATEGORY_STATES_STATUS } from "@/factories/FormMapping";
import { mdiRefresh, mdiPencil } from "@mdi/js";

const table = useTable({
  headers: [
    new TableHeader({
      text: "Form",
      value: "formName",
      map: "formName",
      sortFilterMap: "form",
      sortKey: "forms.name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "State",
      value: "state",
      map: "stateName",
      sortFilterMap: "state",
      selectableOptions: states.map(val => ({
        text: val.text,
        value: val.text
      })),
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      displayMap: "text",
      sortFilterMap: [{ key: "category", value: "value" }],
      sortKey: "forms.category",
      ...TableHeader.IS_FORM_CATEGORY_SEARCH,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Carrier",
      value: "carrier",
      map: "carrierName",
      sortFilterMap: [{ key: "carrier_id", value: "id" }],
      sortKey: "carriers.name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_CARRIER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Form Set",
      value: "formSetName",
      map: "formSetName",
      sortFilterMap: [{ key: "form_set_id", value: "id" }],
      displayMap: "name",
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_FORM_SET_SEARCH
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      sortFilterMap: "status",
      selectableOptions: CATEGORY_STATES_STATUS,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Actions",
      value: "actions"
    })
  ],
  getData: getCategoryStates
});

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

const dialog = useDialogStore();
const snackbar = useSnackbarStore();

async function getData() {
  try {
    await table.getData();
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

async function editCategoryState({ additional }) {
  const res = await dialog.showDialog({
    component: "CategoryStatesDialog",
    value: additional
  });

  if (res.saved) getData();
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

getData();
</script>
