var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"px-4 py-2 my-2",attrs:{"justify":_setup.sentByMe ? 'end' : 'start'}},[_c('v-col',{staticClass:"py-0 px-2",attrs:{"cols":"12"}},[(_setup.sentByMe)?_c('h5',{staticClass:"pl-3 pb-0",staticStyle:{"font-weight":"400","text-align":"right"}},[_c(_setup.TimestampFormatter,{attrs:{"format":"date-time","value":_setup.message.createdAt}}),(_setup.message.isTemplate)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_setup.mdiFileDocument)+" ")]):_vm._e(),(_setup.message.email || _setup.message.carrierEmail)?_c(_setup.ChatMessageEmailIndicator,{attrs:{"full-email":_setup.message.fullEmail,"message-id":_setup.message.id}}):_vm._e(),(_setup.canDelete)?_c('v-btn',{attrs:{"color":"red","x-small":"","icon":"","data-testid":"delete-message"},on:{"click":_setup.destroy}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_setup.mdiDelete)+" ")])],1):_vm._e()],1):_c('div',[_c('h5',{staticClass:"pb-0",staticStyle:{"font-weight":"400"}},[(_setup.message.ownable)?[_vm._v(" "+_vm._s(_setup.message.ownable.name)+" "),_c(_setup.TimestampFormatter,{attrs:{"format":"date-time","value":_setup.message.createdAt}})]:_vm._e(),(_setup.message.email || _setup.message.carrierEmail)?_c(_setup.ChatMessageEmailIndicator,{attrs:{"full-email":_setup.message.fullEmail,"message-id":_setup.message.id}}):_vm._e(),(_setup.canDelete)?_c('v-btn',{attrs:{"color":"red","icon":"","x-small":"","data-testid":"delete-message"},on:{"click":_setup.destroy}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_setup.mdiDelete)+" ")])],1):_vm._e()],2),(_setup.message.inboundEmail)?_c('h5',{staticClass:"pb-0",staticStyle:{"font-weight":"400"}},[_vm._v(" Replied from "+_vm._s(_setup.message.inboundEmail)+" ")]):_vm._e()])]),_c('div',{staticClass:"px-2",staticStyle:{"display":"flex","flex-direction":"column"},style:({
      width: _setup.usingIframe ? '100%' : null,
      maxWidth: _setup.maxWidth
    })},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"pa-3 chat-message-card",class:{
          'sent-by-me': _setup.sentByMe,
          'bottom-right-point': _setup.sentByMe,
          'sent-by-other': !_setup.sentByMe,
          'bottom-left-point': !_setup.sentByMe
        },attrs:{"data-testid":_setup.sentByMe ? 'sent-message' : 'received-message',"color":_setup.sentByMe ? 'primary' : 'secondary'}},[(_setup.usingIframe)?[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"absolute","top":"15px","right":"15px"},attrs:{"icon":""},on:{"click":_setup.showIframeFullscreen}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_setup.mdiArrowExpandAll)+" ")])],1)]}}],null,false,1184175872)},[_c('span',[_vm._v("View Fullscreen")])]),_c('div',{staticClass:"frame-wrap"},[_c(_setup.TemplateRenderer,{staticClass:"frame",attrs:{"value":_setup.message.html || _setup.message.note}})],1)],1)]:(_setup.message.isTemplate)?_c('div',{staticClass:"mb-0 template-message",domProps:{"innerHTML":_vm._s(_setup.sanitize(_setup.message.note))}}):(_setup.message.html)?_c('div',{staticClass:"mb-0 plain-message",domProps:{"innerHTML":_vm._s(_setup.sanitize(_setup.message.html))}}):(_setup.message.note)?_c(_setup.IncludeLinksInText,{staticClass:"mb-0 plain-message",attrs:{"text":_setup.message.note}}):_vm._e(),_vm._l((_setup.message.documents),function(document,index){return _c('a',_vm._b({key:index,staticClass:"message-link text-decoration-underline clickable ma-1",staticStyle:{"cursor":"pointer"}},'a',_setup.download(document),false),[_vm._v(" "+_vm._s(document.name)+" "),(document.fileSize)?_c('span',[_vm._v("("+_vm._s(document.fileSize)+")")]):_vm._e()])})],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }