export function PaymentCreate(model = {}) {
  return {
    policy: model?.policy || undefined,
    statement: model?.statement || undefined,
    premium: model?.premium || undefined,
    override: model?.override || undefined,
    commissionType: model?.commission_type || "First Year",
    percent: model?.percent || null,
    agent: model?.agent || undefined
  };
}

export function Payment(model = {}) {
  return {
    case: model?.case,
    commissionType: model?.commissionType,
    premium: model?.premium,
    percent: model?.percent,
    paid: model?.paid,
    dollar: model?.dollar,
    statement: model?.statement,
    statementDate: model?.statementDate,
    statementId: model?.statementId,
    appointmentCaseId: model?.appointmentCaseId,
    payorId: model?.payorId
  };
}

export function PaymentToCreateRequest(model) {
  return {
    case: { id: model.policy.id, line: model.policy.line },
    case_id: model.policy.id,
    commission_type: model.commissionType,
    premium: model.premium,
    percent: model.percent / 100,
    paid: 1,
    dollar: model.override,
    statement_date: model.statement.statementDate,
    statement_id: model.statement.id,
    appointment_case_id: model.agent.id,
    payor_id: model.statement.payor.id
  };
}

export function setPaymentFromRequest(raw) {
  const model = Payment();

  model.case = raw.policy;
  model.commissionType = raw.commissionType;
  model.premium = raw.premium;
  model.percent = raw.percent / 100;
  model.paid = 1;
  model.dollar = raw.override;
  model.statement = raw.statement;
  model.statementDate = raw.statement.statement_date;
  model.statementId = raw.statement.id;
  model.appointmentCaseId = raw.agent.id;
  model.payorId = raw.statement.payor.id;

  return model;
}

export function PaymentStatementSearchItem(model = {}) {
  return {
    id: model?.id,
    amount: model?.amount,
    payor: StatementSearchItemPayor(model?.payor),
    statementDate: model?.statementDate
  };
}

export function setPaymentStatementSearchItemFromRequest(raw = {}) {
  const model = PaymentStatementSearchItem();
  model.id = raw.id;
  model.amount = raw.amount;
  model.statementDate = raw.statement_date;
  model.payor = setStatementSearchItemPayorFromRequest(raw.payor);
  return model;
}

function StatementSearchItemPayor(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    payableType: model?.payableType
  };
}

function setStatementSearchItemPayorFromRequest(raw = {}) {
  const model = StatementSearchItemPayor();
  model.id = raw.id;
  model.name = raw.name;
  model.payableType = raw.payable_type;
  return model;
}
