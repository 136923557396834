<template>
  <v-row class="ma-0">
    <v-col cols="12" class="px-0">
      <v-card outlined v-if="canCreateParentQuestion || parentQuestion">
        <!-- Parent Questions -->
        <v-card-title> Parent Question: <v-spacer /> </v-card-title>
        <v-list class="pa-3" v-if="parentQuestion">
          <v-list-item link @click="$emit('view', parentQuestion.id)">
            <v-list-item-content>
              <v-list-item-title>
                {{ parentQuestion.id }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="activeField.requiredParentValue">
                This question is displayed if
                <strong class="accent--text">
                  {{ activeField.requiredParentValue }}
                </strong>
                is selected
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                Missing required value
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!fieldHasPositionalData(parentQuestion)">
              <v-row class="pa-3" align="center">
                <v-icon class="mr-1"> {{ mdiFileHidden }} </v-icon> Field not on
                PDF
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <!-- Child Questions -->

    <v-col
      cols="12"
      class="px-0"
      v-if="canCreateChildQuestions || childQuestions.length"
    >
      <v-card outlined>
        <v-card-title>
          Child Questions: <v-spacer />
          <mapped-form-view-aql-actions
            v-if="canCreateChildQuestions"
            is-child
            :form-id="formId"
          />
        </v-card-title>
        <v-list class="pa-3" v-if="childQuestions.length">
          <v-list-item
            v-for="question in childQuestions"
            :key="question.id"
            link
            @click="$emit('view', question.id)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ question.id }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="question.requiredParentValue">
                If
                <strong class="accent--text">
                  {{ question.requiredParentValue }}
                </strong>
                is selected
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                Missing required value
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="!fieldHasPositionalData(question)">
              <v-row class="pa-3" align="center">
                <v-icon class="mr-1"> {{ mdiFileHidden }} </v-icon> Field not on
                PDF
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import MappedFormViewAqlActions from "@/components/form-mapping/MappedFormViewAqlActions.vue";

import { mdiFileHidden } from "@mdi/js";
import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";
import { computed, defineProps } from "vue";

const props = defineProps({
  formId: [String, Number]
});

const formStore = useMappedFormStore(props.formId);

const { activeField, fields, readonly } = storeToRefs(formStore);

const canCreateChildQuestions = computed(() => {
  if (readonly.value) return false;
  return !activeField.value?.referenceField;
});
const canCreateParentQuestion = computed(() => {
  if (readonly.value) return false;
  if (parentQuestion.value) return false;
  return !activeField.value?.referenceField;
});
const parentQuestion = computed(() => {
  if (!activeField.value?.parentQuestion) return null;
  return fields.value.by_id[activeField.value?.parentQuestion];
});
const childQuestions = computed(() => {
  const childQuestions = [];
  if (!activeField.value?.id) return childQuestions;

  fields.value.ids.forEach(id => {
    const isParent =
      `${fields.value.by_id[id].parentQuestion}` === `${activeField.value.id}`;

    if (isParent) childQuestions.push(fields.value.by_id[id]);
  });

  return childQuestions;
});
function fieldHasPositionalData(field) {
  return Boolean(field?.coordinates?.length);
}
</script>
