<template>
  <v-card v-if="electronicApplicationId" flat tile width="100%">
    <v-row class="ma-0 px-3">
      <v-spacer />
      <v-btn
        rounded
        class="text-none"
        outlined
        color="primary"
        data-testid="action-view-eapp"
        :to="getRouterLink('ElectronicApplication', electronicApplicationId)"
      >
        <v-icon> {{ mdiFileDocument }} </v-icon> View Quote & Apply eApp
      </v-btn>
    </v-row>
  </v-card>
</template>

<script setup>
import { usePartyView } from "@/stores/party-view";
import { mdiFileDocument } from "@mdi/js";
import { storeToRefs } from "pinia";
import { getRouterLink } from "@/util/helpers";

const partyView = usePartyView();

const { electronicApplicationId } = storeToRefs(partyView);
</script>
