<template>
  <v-row class="ma-0" dense>
    <v-col md="3" cols="12">
      <form-categories-search
        v-model="category"
        data-testid="form-category"
        label="Category"
        :readonly="readonly"
        :success="categoryValidation.success"
        :error-messages="categoryValidation.errorMessages"
      >
        <template #append-outer>
          <active-save-indicator
            :controller="savingBuffer.category.controller.value"
          />
        </template>
      </form-categories-search>
    </v-col>
    <v-col v-if="requiresCarrier" md="3" cols="12">
      <carrier-search
        data-testid="form-carrier"
        readonly
        :value="carrier"
        disabled
        :success="carrierValidation.success"
        :error-messages="carrierValidation.errorMessages"
      />
    </v-col>
    <v-col v-if="requiresAdvisor" md="3" cols="12">
      <advisor-search
        v-model="advisor"
        data-testid="form-advisor"
        :disabled="readonly || Boolean(carrier?.id)"
        :success="advisorValidation.success"
        :error-messages="advisorValidation.errorMessages"
        @input="updateFormAdvisor"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model.lazy="name"
        placeholder="Leave blank to default to the file name"
        data-lpignore="true"
        data-testid="form-name"
        outlined
        dense
        class="mt-1"
        label="Form name"
        :disabled="readonly"
        :success="Boolean(name)"
      >
        <template #append-outer>
          <active-save-indicator
            :controller="savingBuffer.name.controller.value"
          />
        </template>
      </v-text-field>
    </v-col>
    <v-col v-if="!readonly" cols="12">
      <file-drag-and-drop
        v-model="form"
        data-testid="form-document"
        :success="fileValidation.success"
        :error-messages="fileValidation.errorMessages"
        accept=".pdf"
        class="has-append-outer-button-no-top"
      >
        <template #append-outer>
          <v-btn
            color="primary"
            class="text-none"
            style="height: 60px"
            data-testid="upload-form"
            :loading="uploadingForm"
            @click="handleFormUpload"
          >
            Upload New Version
          </v-btn>
        </template>
      </file-drag-and-drop>
    </v-col>

    <v-col v-if="requiresSubmissionMethod" cols="12">
      <form-submission-methods-search
        v-model="submissionMethod"
        data-testid="form-submission-method"
        label="Submission Method"
        outlined
        :disabled="readonly"
        :success="submissionMethodValidation.success"
        :error-messages="submissionMethodValidation.errorMessages"
        dense
      >
        <template #append-outer>
          <active-save-indicator
            :controller="savingBuffer.submissionMethod.controller.value"
          />
        </template>
      </form-submission-methods-search>
    </v-col>
    <v-col v-if="requiresFormSets" cols="12">
      <v-row class="ma-0" align="center">
        <v-autocomplete
          label="Form Sets"
          data-testid="form-sets"
          outlined
          dense
          return-object
          :key="formSetAutocompleteKey"
          :items="allFormSets"
          :item-text="val => `${val.id} · ${val.name}`"
          item-value="id"
          :error-messages="formSetValidation.errorMessages"
          @input="addFormSet"
        />
      </v-row>
      <template v-if="formSets.length > 0">
        <h3 class="my-3">Active Form Sets</h3>
        <v-list :key="formSets.length">
          <v-list-item
            class="px-0"
            v-for="formSet in formSets"
            :key="formSet.id"
            data-testid="form-set"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-btn
                  icon
                  color="error"
                  class="mr-3"
                  @click="deleteFormSet(formSet)"
                >
                  <v-icon>{{ mdiDelete }} </v-icon>
                </v-btn>
                {{ formSet.id }} · {{ formSet.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-col>
  </v-row>
</template>

<script setup>
import { parseErrorMessage, computedValidation } from "@/util/helpers";
import { useMappedFormStore } from "@/stores/mapped-form";
import { mdiDelete } from "@mdi/js";

import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import CarrierSearch from "@/components/shared/CarrierSearch.vue";
import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import FormCategoriesSearch from "@/components/shared/FormCategoriesSearch.vue";
import FormSubmissionMethodsSearch from "@/components/shared/FormSubmissionMethodsSearch.vue";

import { VIRTUAL_FORM_CATEGORY_VALUE } from "@/api/forms.service";
import { getAllFormSets } from "@/api/form-sets.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useActiveSave } from "@/composables/active-save.composable";
import { ref, watch, defineProps, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";

const props = defineProps({
  formId: [Number, String]
});

const snackbar = useSnackbarStore();
const formStore = useMappedFormStore(props.formId);
const {
  category,
  carrier,
  advisor,
  name,
  submissionMethod,
  form,
  formSets,
  readonly
} = storeToRefs(formStore);

const savingBuffer = {
  category: useActiveSave(),
  carrier: useActiveSave(),
  advisor: useActiveSave(),
  name: useActiveSave(),
  submissionMethod: useActiveSave()
};

const allFormSets = ref([]);
const uploadingForm = ref(false);

const requiresSubmissionMethod = computed(() =>
  Boolean(carrier.value?.id || category.value === VIRTUAL_FORM_CATEGORY_VALUE)
);
const requiresAdvisor = computed(() => !carrier.value?.id);
const requiresCarrier = computed(() => !advisor.value?.id);
const requiresFormSets = computed(() => carrier.value?.id);

const v$ = useVuelidate(
  {
    form: {
      required: Boolean,
      validSize: val => val?.size > 0
    },
    category: {
      required: Boolean
    },
    carrier: {
      required: v => !requiresCarrier.value || Boolean(v?.id)
    },
    advisor: {
      required: v => !requiresAdvisor.value || Boolean(v?.id)
    },
    formSets: {
      required: v => !requiresFormSets.value || v.length > 0
    },
    submissionMethod: {
      required: v => !requiresSubmissionMethod.value || Boolean(v)
    }
  },
  {
    form,
    category,
    carrier,
    advisor,
    formSets,
    submissionMethod
  },
  { $autoDirty: true, $scope: null }
);

const categoryValidation = computedValidation(v$.value.category, {
  required: "Required"
});

const carrierValidation = computedValidation(v$.value.carrier, {
  required: "Required"
});

const advisorValidation = computedValidation(v$.value.advisor, {
  required: "Required"
});

const submissionMethodValidation = computedValidation(
  v$.value.submissionMethod,
  {
    required: "Required"
  }
);

const formSetValidation = computedValidation(v$.value.formSets, {
  required: "Required"
});

const fileValidation = computedValidation(v$.value.form, {
  required: "Required",
  validSize: "Please reupload this file"
});

async function fetchFormSets() {
  if (!carrier.value?.id) return;
  allFormSets.value.splice(0, formSets.value.length);
  try {
    const res = await getAllFormSets(carrier.value.id);
    allFormSets.value.splice(0, allFormSets.value.length);
    allFormSets.value.push(...res);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    allFormSets.value.splice(0, allFormSets.value.length);
  }
}

function updateFormName() {
  savingBuffer.name.debounceUpdate(formStore.updateFormName);
}

function updateFormCategory() {
  savingBuffer.category.debounceUpdate(formStore.updateFormCategory);
}

function updateSubmissionMethod() {
  savingBuffer.submissionMethod.debounceUpdate(
    formStore.updateFormSubmissionMethod
  );
}

async function updateFormAdvisor() {
  savingBuffer.advisor.update(formStore.updateFormAdvisor);
}

async function handleFormUpload() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;
  uploadingForm.value = true;
  try {
    await formStore.updateFormPdf(form.value);
    location.reload();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    uploadingForm.value = false;
  }
}

const formSetAutocompleteKey = ref(new Date().getTime());
async function addFormSet(formSet) {
  try {
    await formStore.addNewFormSet(formSet.id);
  } catch (e) {
    formSetAutocompleteKey.value = new Date().getTime();
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

async function deleteFormSet(formSet) {
  try {
    await formStore.removeFormSet(formSet.id);
    const index = formSets.value.findIndex(i => i.id === formSet.id);
    if (index !== -1) formSets.value.splice(index, 1);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}

watch(requiresSubmissionMethod, v => {
  if (!v) submissionMethod.value = null;
});
watch(name, updateFormName);
watch(category, updateFormCategory);
watch(submissionMethod, updateSubmissionMethod);
watch(carrier, fetchFormSets, { immediate: true });
</script>
