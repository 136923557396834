<template>
  <div class="parent" ref="parent" v-chatscroll>
    <chat-view-message
      v-for="(message, index) in messages"
      class="message"
      data-testid="chat-message"
      :message="message"
      :key="`${index}-${message.id}`"
      @remove-message="emit('remove-message', $event)"
    />
  </div>
</template>
<script setup>
import ChatViewMessage from "@/components/shared/chat/ChatViewMessage.vue";
import vChatscroll from "@/directives/chatscroll";
import { defineProps, defineEmits, toRefs } from "vue";

const props = defineProps({ messages: Array });
const emit = defineEmits(["remove-message"]);
const { messages } = toRefs(props);
</script>
