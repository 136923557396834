<template>
  <v-autocomplete
    v-model="question"
    autocomplete="false"
    outlined
    dense
    return-object
    no-filter
    hide-no-data
    item-text="name"
    v-bind="$attrs"
    :data-testid="dataTestid"
    :prepend-inner-icon="icon"
    :search-input.sync="questionSearch"
    :items="questions"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    @blur="emit('blur')"
    @change="emit('change')"
  >
    <template #append-outer v-if="slots['append-outer']">
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
</template>

<script setup>
import { getApplicationQuestionsByName } from "@/api/application-question.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { mdiCommentQuestion, mdiHeadQuestionOutline } from "@mdi/js";
import { computed, ref, useSlots, watch, defineProps, defineEmits } from "vue";

const props = defineProps({
  smart: Boolean,
  value: { type: [String, Object], required: false, default: null },
  modelType: { type: String, required: false, default: null },
  modelId: { type: [String, Number], required: false, default: null },
  dataTestid: { type: String, required: false, default: null }
});
const emit = defineEmits(["input", "blur", "change"]);
const slots = useSlots();

const snackbar = useSnackbarStore();

const questions = ref([]);
const question = ref(null);
const questionSearch = ref("");
const loading = ref(false);

if (props.value?.id) {
  questions.value.push(props.value);
  question.value = props.value;
}

const icon = computed(() =>
  props.smart ? mdiHeadQuestionOutline : mdiCommentQuestion
);

async function search() {
  if (!questionSearch.value) return;

  if (
    questions.value.some(v => v?.name === questionSearch.value) &&
    questions.value.length === 1
  ) {
    return;
  }

  try {
    loading.value = true;

    const results = await getApplicationQuestionsByName({
      name: questionSearch.value,
      model: props.modelType,
      smart: props.smart,
      model_id: props.modelId
    });
    questions.value.splice(0, questions.value.length);
    questions.value.push(...results);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

watch(
  () => props.value,
  () => {
    questions.value.splice(0, questions.value.length);
    if (!props.value?.id) {
      question.value = null;
      return;
    }
    question.value = props.value;
    questions.value.push(props.value);
  }
);

watch(question, v => emit("input", v));

let questionTimer;
watch(questionSearch, () => {
  if (questionTimer) clearTimeout(questionTimer);
  questionTimer = setTimeout(search, 200);
});
</script>
