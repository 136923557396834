import { reissueTokenIfExpired } from "@/api/auth.service";

export const validResetPasswordTokenGuard = async (to, from, next) => {
  const passwordResetStore = await import("@/stores/password-reset");
  const store = passwordResetStore.usePasswordResetStore();

  const reset_token = to.query.reset_password_token;
  if (reset_token) {
    const validToken = await reissueTokenIfExpired(reset_token);
    if (validToken.status === 200 && validToken.data.message) {
      store.$patch(validToken.data);
    }
    next();
  } else {
    next({ name: "SignIn" });
  }
};
