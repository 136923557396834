<template>
  <v-fade-transition mode="out-in">
    <v-col v-if="loading" key="loader">
      <v-row dense>
        <v-col cols="12">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="table" />
        </v-col>
      </v-row>
    </v-col>
    <v-row v-else class="width-full" dense key="loaded">
      <v-col cols="12" lg="12" class="pb-0">
        <statement-view-card />
      </v-col>
      <v-col cols="12" class="py-0">
        <statement-view-actions />
      </v-col>
      <v-col cols="12" lg="12" class="pt-0">
        <v-card flat tile>
          <v-tabs v-model="activeTab" show-arrows class="no-bottom-radius">
            <v-tab
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              class="text-none"
              :data-testid="`tab-${tab.title}`"
            >
              <v-icon class="pr-1">{{ tab.icon }}</v-icon>
              <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
                {{ tab.title }}
              </v-badge>
              <template v-else>{{ tab.title }}</template>
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              :eager="tab.eager"
            >
              <component
                :is="tab.component"
                v-bind="tab.componentProps"
                v-on="tab.componentListeners"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-fade-transition>
</template>

<script setup>
import StatementViewCard from "@/components/statements/StatementViewCard.vue";
import StatementViewActions from "@/components/statements/StatementViewActions.vue";
import StatementViewPayments from "@/components/statements/StatementViewPayments.vue";
import StatementViewTransactions from "@/components/statements/StatementViewTransactions.vue";
import StatementViewDetails from "@/components/statements/StatementViewDetails.vue";
import QueuedTransactionsTable from "@/components/queued-transactions/QueuedTransactionsTable.vue";

import { useStatementView } from "@/stores/statement-view";
import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { parseErrorMessage } from "@/util/helpers";
import { useHead } from "@unhead/vue";
import {
  computed,
  markRaw,
  onBeforeUnmount,
  ref,
  defineProps,
  watch
} from "vue";
import { useRouter } from "@/composables/compatible.composables";

const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  }
});

const head = useHead({ title: "Statement Viewer" });

const router = useRouter();

const statementView = useStatementView();
const instance = useInstanceStore();
const snackbar = useSnackbarStore();

const { breadcrumb } = storeToRefs(instance);
const { id } = storeToRefs(statementView);

const pendingCount = ref(0);
const transactionCount = ref(0);

const loading = ref(false);
const activeTab = ref(0);

const tabs = computed(() => {
  return [
    {
      title: "Payments",
      component: markRaw(StatementViewPayments),
      page: "payments",
      componentProps: {
        id: id.value
      }
    },
    {
      title: "Transactions",
      component: markRaw(StatementViewTransactions),
      page: "transactions",
      eager: true,
      componentProps: {
        id: id.value
      },
      componentListeners: {
        total: count => (transactionCount.value = count)
      },
      badgeProps: transactionCount.value
        ? {
            color: "primary",
            content: transactionCount.value,
            "data-testid": "transactions-badge"
          }
        : null
    },
    {
      title: "Details",
      component: markRaw(StatementViewDetails),
      page: "details"
    },
    {
      title: "Pending Transactions",
      component: markRaw(QueuedTransactionsTable),
      page: "pending-transactions",
      eager: true,
      badgeProps: pendingCount.value
        ? {
            color: "primary",
            content: pendingCount.value,
            "data-testid": "pending-badge"
          }
        : null,
      componentListeners: {
        total: count => (pendingCount.value = count)
      },
      componentProps: {
        statementId: id.value
      }
    }
  ];
});

async function getStatement() {
  try {
    loading.value = true;
    await statementView.initialize(props.id);
    breadcrumb.value = id.value;
    head.patch({ title: id.value });
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

onBeforeUnmount(() => (breadcrumb.value = ""));

getStatement();
</script>
