import { defineStore } from "pinia";

import { updateTask, deleteTask, getTask } from "@/api/tasks.service";
import { updateExam, deleteExam, getExam } from "@/api/exams.service";
import {
  updatePhysicianStatement,
  deletePhysicianStatement,
  getPhysicianStatement
} from "@/api/physician-statements.service";
import { createNote, deleteNote } from "@/api/notes.service";

import {
  CASE_TASK_STATUS,
  CASE_TASK_TYPE,
  CaseTask,
  setCaseTaskFromTask
} from "@/factories/Case";

import { NoteToCreateRequest } from "@/factories/Note";

import { set } from "vue";

export const useCaseViewActiveTaskStore = defineStore("case-view-active-task", {
  state: () => ({
    activeTaskKey: null,
    caseId: null,
    ...CaseTask()
  }),
  getters: {
    complete() {
      return this.status === CASE_TASK_STATUS.COMPLETE;
    },
    pusherId() {
      if (!this.config) return;
      return `private-${this.config.notableId}-${this.config.notableType}`;
    },
    isPhysicianStatement() {
      return this.type === CASE_TASK_TYPE.PHYSICIAN_STATEMENT;
    },
    isExam() {
      return this.type === CASE_TASK_TYPE.EXAM;
    },
    configs() {
      return {
        [CASE_TASK_TYPE.TASK]: {
          get: async (caseId, id) => {
            const res = await getTask(caseId, id);
            return setCaseTaskFromTask(res, CASE_TASK_TYPE.TASK);
          },
          update: async (id, task) => {
            const res = await updateTask(id, { case_id: this.caseId, task });
            return setCaseTaskFromTask(res, CASE_TASK_TYPE.TASK);
          },
          delete: (caseId, id) => deleteTask(caseId, id),
          notableId: this.id,
          notableType: "Task"
        },
        [CASE_TASK_TYPE.EXAM]: {
          get: async (caseId, id) => {
            const res = await getExam(caseId, id);
            return setCaseTaskFromTask(res, CASE_TASK_TYPE.EXAM);
          },
          update: async (id, exam) => {
            const res = await updateExam(id, { case_id: this.caseId, exam });
            return setCaseTaskFromTask(res, CASE_TASK_TYPE.EXAM);
          },
          delete: (caseId, id) => deleteExam(caseId, id),
          notableId: this.orderId,
          notableType: "Order"
        },
        [CASE_TASK_TYPE.PHYSICIAN_STATEMENT]: {
          get: async (caseId, id) => {
            const res = await getPhysicianStatement(caseId, id);
            return setCaseTaskFromTask(res, CASE_TASK_TYPE.PHYSICIAN_STATEMENT);
          },
          update: async (id, physician_statement) => {
            const res = await updatePhysicianStatement(id, {
              case_id: this.caseId,
              physician_statement
            });
            return setCaseTaskFromTask(res, CASE_TASK_TYPE.PHYSICIAN_STATEMENT);
          },
          delete: (caseId, id) => deletePhysicianStatement(caseId, id),
          notableId: this.orderId,
          notableType: "Order"
        }
      };
    },
    config() {
      return this.configs[this.type];
    }
  },
  actions: {
    reactiveReplace(caseId, activeTaskKey, res) {
      set(this, "$state", { activeTaskKey, caseId, ...res });
    },
    async initializeActiveTask(caseId, activeTaskKey, id, type) {
      if (!id || !type || !this.configs[type]) return;
      set(this, "$state", { activeTaskKey, caseId, ...CaseTask() });

      const res = await this.configs[type].get(caseId, id);
      this.reactiveReplace(caseId, activeTaskKey, res);
    },
    async updateTask({ assignedTo, date, orderNumber }) {
      const body = {
        follow_up_date: date,
        assignable_id: assignedTo.id,
        assignable_type: assignedTo.type
      };

      const includeOrderNumber = [
        CASE_TASK_TYPE.EXAM,
        CASE_TASK_TYPE.PHYSICIAN_STATEMENT
      ].includes(this.type);

      if (includeOrderNumber) body.order_number = orderNumber;

      const res = await this.config.update(this.id, body);
      this.reactiveReplace(this.caseId, this.activeTaskKey, res);
    },
    async updateStatus(complete) {
      const res = await this.config.update(this.id, {
        status: complete
          ? CASE_TASK_STATUS.COMPLETE
          : CASE_TASK_STATUS.INCOMPLETE
      });

      this.reactiveReplace(this.caseId, this.activeTaskKey, res);
    },
    deleteTask() {
      return this.config.delete(this.caseId, this.id);
    },
    createNote(noteToCreate) {
      return createNote(NoteToCreateRequest(noteToCreate, true));
    },
    async deleteNote(id) {
      await deleteNote(id);
      const noteIndex = this.notes.findIndex(n => n.id === id);
      if (noteIndex === -1) return;
      this.notes.splice(noteIndex, 1);
    },
    // When updating a task, the task is re-initialized based on the incoming data, in addition a new note may/not be created.
    // It's possible that the note is within the new data before the pusher message is received.
    addNewNote(note) {
      if (this.notes.some(n => n.id === note.id)) return;
      this.notes.push(note);
    }
  }
});
