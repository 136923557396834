<template>
  <chat-view
    title="Chat"
    data-testid="party-chat"
    :pusher-id="`private-${id}-${type}`"
    :messages="messages"
    :create-func="sendChatMessage"
    @new-note="newNote"
    @remove-message="removeFromMessages"
  />
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";

import {
  setNoteFromRequest,
  Note,
  NoteToCreateRequest
} from "@/factories/Note";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { usePartyView } from "@/stores/party-view";
import { computed } from "vue";

const partyView = usePartyView();
const user = useUserStore();
const snackbar = useSnackbarStore();

const { notes, type, id } = storeToRefs(partyView);

const messages = computed(() => {
  return [
    {
      note: `Hi ${user.loginable.name}, feel free to make notes here.`,
      ownable: { name: "BackNine" }
    },
    ...notes.value.toSorted(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    )
  ];
});

function removeFromMessages(message) {
  const index = notes.value.findIndex(({ id }) => id === message.id);
  notes.value.splice(index, 1);
}

function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}
async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: note.sendEmail,
    phoneCall: note.sendPhoneCall,
    note: note.message,
    notableId: id.value,
    notableType: type.value
  });
  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}
</script>
