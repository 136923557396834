<template>
  <v-card tile flat>
    <v-card-text>
      <v-row class="ma-0" dense align="center">
        <v-col class="pl-4">
          <strong style="font-size: 1.2rem" data-testid="appointment-name">
            {{ name }}
          </strong>
          <br />
          Code
        </v-col>
        <v-spacer />
        <v-col>
          <v-row justify="end" class="px-3 pb-3">
            <carrier-image
              :image="carrier.avatarUrl"
              :id="carrier.id"
              contain
            />
          </v-row>
        </v-col>
      </v-row>
      <template v-for="(detailGroup, index) in detailGroups">
        <v-divider :key="index + 'divider'" />
        <v-row class="ma-0" :key="index + 'details'">
          <v-col
            v-for="{ component, props, key, on } in detailGroup"
            :key="key"
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="6"
            xs="6"
          >
            <component
              :is="component"
              v-bind="props"
              v-on="on"
              :data-testid="`card-${key}`"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CarrierImage from "@/components/shared/CarrierImage.vue";
import CardItemRouterLink from "@/components/shared/card-items/CardItemRouterLink.vue";
import CardItemText from "@/components/shared/card-items/CardItemText.vue";
import CardItemA from "@/components/shared/card-items/CardItemA.vue";
import CardItemSensitiveInfo from "@/components/shared/card-items/CardItemSensitiveInfo.vue";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";
import { LINE_CODES } from "@/factories/Appointment";

import { getAgencyTin } from "@/api/agencies.service";
import { getAgentSsn } from "@/api/agents.service";

import {
  stringLowerComparison,
  timestampFormatter,
  numberFormat,
  getRouterLink,
  listToSentence
} from "@/util/helpers";

const {
  compBuilder,
  ownable,
  carrier,
  compBuilders,
  assignment,
  status,
  ltcRider,
  states,
  appointmentManager,
  linesOfAuthority,
  productId,
  name,
  insuredName,
  appSignDate,
  daysPending,
  annualized,
  carrierAgreementType
} = storeToRefs(useAppointmentView());

const fullCompBuilder = computed(
  () =>
    compBuilders.value.find(({ value }) => compBuilder.value === value)?.text
);

const product = computed(() => {
  const text = carrier.value.products.find(
    ({ id }) => id === productId.value
  )?.name;
  return text || "No Product Exists for Appointment";
});

const cardDetails = computed(() => {
  const defaultDetails = [];

  if (ownable.value.id) {
    let text = ownable.value.name;
    if (!stringLowerComparison(ownable.value.legalName, ownable.value.name)) {
      text = `${text} (Legal Name: ${ownable.value.legalName})`;
    }
    defaultDetails.push({
      component: CardItemRouterLink,
      key: "Advisor",
      props: {
        title: "Advisor",
        text,
        to: getRouterLink(ownable.value.type, ownable.value.id)
      }
    });
  }

  if (ownable.value.signerId) {
    defaultDetails.push({
      component: CardItemRouterLink,
      key: "Signer",
      props: {
        title: "Signer",
        text: ownable.value.signerName,
        to: getRouterLink(ownable.value.signerType, ownable.value.signerId)
      }
    });
  }

  if (
    assignment.value?.ownableId &&
    ownable.value.id !== assignment.value.ownableId
  ) {
    defaultDetails.push({
      component: CardItemRouterLink,
      key: "Assignment",
      props: {
        title: "Assignment",
        text: `${assignment.value.ownableName} - ${assignment.value.name}`,
        to: getRouterLink("Appointment", assignment.value.id)
      }
    });
  }

  if (status.value) {
    defaultDetails.push({
      component: CardItemText,
      key: "Status",
      props: {
        title: "Status",
        text: status.value
      }
    });
  }

  if (ltcRider.value) {
    defaultDetails.push({
      component: CardItemText,
      key: "LTC Rider",
      props: {
        title: "LTC Rider",
        text: "Yes"
      }
    });
  }

  if (Array.isArray(states.value)) {
    defaultDetails.push({
      component: CardItemText,
      key: "States",
      props: {
        title: "States",
        text: states.value.join(", ") || "None"
      }
    });
  }

  if (appointmentManager.value) {
    defaultDetails.push({
      component: CardItemText,
      key: "Appointment Manager",
      props: {
        title: "Appointment Manager",
        text: appointmentManager.value.name
      }
    });
  }

  if (fullCompBuilder.value) {
    defaultDetails.push({
      key: "Appointment Manager",
      component: CardItemText,
      props: {
        title: "Comp Builders",
        text: fullCompBuilder.value
      }
    });
  }

  if (linesOfAuthority.value.length) {
    defaultDetails.push({
      component: CardItemText,
      key: "Product",
      props: {
        title: "Product",
        text: `${linesOfAuthority.value.join(", ")}: ${product.value}`
      }
    });
  }

  if (name.value && insuredName.value) {
    defaultDetails.push({
      component: CardItemText,
      key: "Applicant Name",
      props: {
        title: "Applicant Name",
        text: insuredName.value
      }
    });
  }

  if (appSignDate.value) {
    defaultDetails.push({
      component: CardItemText,
      key: "App Sign Date",
      props: {
        title: "Application Date",
        text: timestampFormatter(appSignDate.value, "sole-day", "basic")
      }
    });
  }

  let title = "TIN";
  let type = "tin";
  if (ownable.value.type === "Agent") {
    title = "SSN";
    type = "ssn";
  }

  defaultDetails.push({
    component: CardItemSensitiveInfo,
    key: "TIN/SSN",
    props: {
      title,
      type,
      fetchFunc: () => {
        if (ownable.value.type === "Agent")
          return getAgentSsn(ownable.value.id);
        else if (ownable.value.type === "Agency")
          return getAgencyTin(ownable.value.id);
      }
    }
  });

  if (ownable.value.birthdate) {
    defaultDetails.push({
      component: CardItemText,
      key: "Advisor Birthdate",
      props: {
        title: "Advisor Birthdate",
        text: timestampFormatter(ownable.value.birthdate, "sole-day", "basic")
      }
    });
  }

  if (daysPending.value || daysPending.value === 0) {
    defaultDetails.push({
      component: CardItemText,
      key: "Days Pending",
      props: {
        title: "Days Pending",
        text: numberFormat(daysPending.value)
      }
    });
  }

  if (carrierAgreementType.value) {
    defaultDetails.push({
      component: CardItemText,
      key: "Carrier Agreement Type",
      props: {
        title: "Carrier Agreement Type",
        text: carrierAgreementType.value
      }
    });
  }

  if (linesOfAuthority.value.length) {
    defaultDetails.push({
      component: CardItemText,
      key: "Lines of Authority",
      props: {
        title: "Lines of Authority",
        text: listToSentence(linesOfAuthority.value)
      }
    });
  }

  return defaultDetails;
});

const notableDetails = computed(() => {
  const details = [];
  if (annualized.value) {
    details.push({
      component: CardItemText,
      key: "Annualized",
      props: {
        title: "Annualized",
        text: "Yes"
      }
    });
  }

  const isAnnuityLine = linesOfAuthority.value.includes(LINE_CODES.ANNUITY);
  if (carrier.value.annuityPhone && isAnnuityLine) {
    details.push({
      component: CardItemA,
      key: "Annuity Licensing Phone",
      props: {
        title: "Annuity Licensing Phone",
        text: carrier.value.annuityPhone,
        href: `tel:${carrier.value.annuityPhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.annuityPhone
      }
    });
  }

  const isDisabilityLine = linesOfAuthority.value.includes(
    LINE_CODES.DISABILITY
  );
  if (carrier.value.disabilityPhone && isDisabilityLine) {
    details.push({
      component: CardItemA,
      key: "Disability Licensing Phone",
      props: {
        title: "Disability Licensing Phone",
        text: carrier.value.disabilityPhone,
        href: `tel:${carrier.value.disabilityPhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.disabilityPhone
      }
    });
  }

  const isLifeLine = linesOfAuthority.value.some(l =>
    [
      LINE_CODES.LIFE,
      LINE_CODES.LINKED_BENEFIT,
      LINE_CODES.VARIABLE_LIFE
    ].includes(l)
  );
  if (carrier.value.lifePhone && isLifeLine) {
    details.push({
      component: CardItemA,
      key: "Life Licensing Phone",
      props: {
        title: "Life Licensing Phone",
        text: carrier.value.lifePhone,
        href: `tel:${carrier.value.lifePhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.lifePhone
      }
    });
  }

  const isLtcLine = linesOfAuthority.value.includes(LINE_CODES.LTC);
  if (carrier.value.ltcPhone && isLtcLine) {
    details.push({
      component: CardItemA,
      key: "LTC Licensing Phone",
      props: {
        title: "LTC Licensing Phone",
        text: carrier.value.ltcPhone,
        href: `tel:${carrier.value.ltcPhone}`,
        dataOutboundType: "Carrier",
        dataOutboundId: carrier.value.id,
        dataOutboundNumber: carrier.value.ltcPhone
      }
    });
  }

  return details;
});

const detailGroups = computed(() => {
  const groups = [];
  if (cardDetails.value.length) groups.push(cardDetails.value);
  if (notableDetails.value.length) groups.push(notableDetails.value);
  return groups;
});
</script>
