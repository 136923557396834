<template>
  <div>
    <v-card-title>{{ editing ? "Edit" : "Create" }} Transaction</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col v-if="!editing" cols="12" lg="6">
          <statement-search
            v-model="transaction.statement"
            placeholder="Search for a statement"
            autofocus
            data-testid="transaction-statement"
            :disabled="loading"
            :loading="loading"
            v-bind="statementValidation"
          />
        </v-col>
        <v-col cols="12" :lg="editing ? 12 : 6">
          <policy-search
            v-model="transaction.policy"
            transaction
            data-testid="transaction-policy"
            :disabled="fetchingPolicy || loading"
            :loading="fetchingPolicy || loading"
            v-bind="policyValidation"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <v-select
            v-model="transaction.commissionType"
            outlined
            dense
            data-testid="commission-type"
            placeholder="Select Commission Type"
            label="Commission Type"
            :prepend-inner-icon="mdiTable"
            :disabled="loading"
            :loading="loading"
            :items="COMMISSION_TYPES"
            v-bind="commissionTypeValidation"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <currency-input
            v-model="transaction.premium"
            include-decimals
            include-negative
            outlined
            dense
            label="Premium"
            data-testid="premium"
            :prepend-inner-icon="mdiCurrencyUsd"
            :disabled="loading"
            :loading="loading"
            v-bind="premiumValidation"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <currency-input
            v-model="transaction.amount"
            include-decimals
            include-negative
            outlined
            dense
            persistent-hint
            hint=" "
            data-testid="override"
            :prepend-inner-icon="mdiCurrencyUsd"
            :disabled="loading"
            :loading="loading"
            :label="statementFromCarrier ? 'Direct Override' : 'Total Override'"
            v-bind="amountValidation"
          >
            <template #message>
              <span v-if="statementFromCarrier">
                When the statement is from a Carrier, override should be the
                amount paid to BackNine on the Carrier's statement.
              </span>
              <span v-else>
                When the statement is not from a Carrier, override should be the
                total amount BackNine is assumed to receive.
              </span>
              <span v-if="showBackNineWritingAgentHint" class="warning--text">
                Writing agent commissions assigned to BackNine should be
                included in override.
              </span>
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="transaction.memo"
            outlined
            dense
            label="Memo (Optional)"
            rows="2"
            data-testid="transaction-memo"
            :prepend-inner-icon="mdiText"
            :disabled="loading"
            :loading="loading"
            :success="Boolean(transaction.memo)"
          />
        </v-col>
        <v-col cols="12">
          <v-row class="ma-0">
            <v-btn
              class="text-none"
              color="primary"
              data-testid="save-transaction"
              :loading="loading"
              @click="transactionCreate"
            >
              <v-icon class="mr-1"> {{ mdiPlus }} </v-icon>
              {{ editing ? "Save" : "Create" }} Transaction
            </v-btn>
            <div v-if="!editing" class="checkbox-width">
              <v-checkbox
                v-model="preserveFieldValues"
                data-testid="preserve-statement"
                class="mt-1 ml-3"
                label="Preserve Statement"
              />
            </div>
          </v-row>
        </v-col>
        <v-col v-if="simulationErrorMessage" cols="12">
          <v-alert
            data-testid="simulated-transactions-error-messages"
            type="error"
          >
            {{ simulationErrorMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script setup>
import PolicySearch from "@/components/shared/PolicySearch.vue";
import StatementSearch from "@/components/shared/StatementSearch.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { COMMISSION_TYPES } from "@/data/transaction-data";

import { mdiTable, mdiCurrencyUsd, mdiText, mdiPlus } from "@mdi/js";
import { useTransactionCreate } from "@/stores/transaction-create";
import { storeToRefs } from "pinia";
import { computedValidation } from "@/util/helpers";
import useVuelidate from "@vuelidate/core";

import { parseErrorMessage } from "@/util/helpers";

import { createTransaction } from "@/api/transactions.service";

import { useSnackbarStore } from "@/stores/snackbar";

import { computed, set, markRaw, defineEmits, nextTick } from "vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { TransactionToCreateRequest } from "@/factories/Transaction";
import { useDialogStore } from "@/stores/dialog";

const emit = defineEmits(["created"]);
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const transactionCreateStore = useTransactionCreate();
const {
  transaction,
  simulationErrorMessage,
  transactions,

  commissions,
  loading,
  fetchingPolicy,

  preserveFieldValues
} = storeToRefs(transactionCreateStore);

const editing = computed(() => Boolean(transaction.value?.id));

const statementFromCarrier = computed(
  () => transaction.value?.payor?.payableType === "Carrier"
);

const v$ = useVuelidate(
  {
    transaction: {
      policy: { required: v => Boolean(v?.id) },
      commissionType: {
        required: Boolean,
        inList: v => COMMISSION_TYPES.includes(v)
      },
      statement: { required: v => editing.value || Boolean(v?.id) },
      premium: { required: Boolean },
      amount: { required: Boolean }
    }
  },
  { transaction },
  { $autoDirty: true, $scope: null }
);

const statementValidation = computedValidation(v$.value.transaction.statement, {
  required: "Required"
});
const policyValidation = computedValidation(v$.value.transaction.policy, {
  required: "Required"
});
const commissionTypeValidation = computedValidation(
  v$.value.transaction.commissionType,
  { required: "Required", inList: "Must be in list" }
);
const premiumValidation = computedValidation(v$.value.transaction.premium, {
  required: "Required"
});
const amountValidation = computedValidation(v$.value.transaction.amount, {
  required: "Required"
});

const showBackNineWritingAgentHint = computed(() => {
  if (!commissions.value.length) return false;
  //TODO remove magic numbers or stick them in an enum
  return commissions.value.some(
    ({ assignedPayable, payor, paymentType }) =>
      assignedPayable.id === 18 &&
      assignedPayable.type === "Agency" &&
      payor.id !== 84 &&
      paymentType === "Flat"
  );
});

async function create() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  try {
    await createTransaction(TransactionToCreateRequest(transaction.value));
    snackbar.showSuccessSnackbar({
      message: "Successfully Created Transaction",
      timeout: 3000
    });
    const statement = { ...transaction.value.statement };
    transactionCreateStore.$reset();
    if (preserveFieldValues.value) {
      set(transaction.value, "statement", statement);
    }
    nextTick(() => v$.value.$reset());

    emit("created");
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loading.value = false;
  }
}

async function transactionCreate() {
  if (simulationErrorMessage.value) {
    snackbar.showErrorSnackbar({ message: simulationErrorMessage.value });
    return;
  }
  if (!transactionAlreadyExists()) return create();
  return dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    subtitle:
      "This transaction has already been created and nothing has changed. Are you sure that you still want to create a duplicate transaction?",
    title: "Duplicate Transaction Entered",
    func: create
  });
}

function transactionAlreadyExists() {
  return transactions.value.some(
    ({ commissionType, statementId, amount, directCommission }) => {
      const sameCommissionType = commissionType.endsWith(
        transaction.value.commissionType
      );
      const sameStatementId = transaction.value.statement?.id === statementId;
      const samePremium = transaction.value.premium === amount;
      const sameOverride = transaction.value.amount === directCommission;
      return [
        sameCommissionType,
        sameStatementId,
        samePremium,
        sameOverride
      ].every(v => v === true);
    }
  );
}
</script>
