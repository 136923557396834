<template>
  <v-tooltip v-if="notCopyableText" bottom key="not-copyable">
    <template #activator="{ on, attrs }">
      <span v-on="on" v-bind="attrs">
        <v-btn data-testid="not-copyable" icon disabled>
          <v-icon> {{ mdiContentCopy }} </v-icon>
        </v-btn>
      </span>
    </template>
    <span>
      {{ notCopyableText }}
    </span>
  </v-tooltip>
  <v-tooltip v-else bottom key="is-copyable">
    <template #activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        icon
        data-testid="copyable"
        :loading="copyingField"
        @click="copyField"
      >
        <v-icon>{{ mdiContentCopy }}</v-icon>
      </v-btn>
    </template>
    <span>Copy to new field</span>
  </v-tooltip>
</template>

<script setup>
// This component renders for the copied form, not the editing form

import { useFormMappingView } from "@/stores/form-mapping-view";
import { useMappedFormStore } from "@/stores/mapped-form";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { mdiContentCopy } from "@mdi/js";
import { storeToRefs } from "pinia";
import { computed, defineProps } from "vue";

const props = defineProps({
  formId: [Number, String]
});

const snackbar = useSnackbarStore();
const formMappingView = useFormMappingView();

const { copyingField } = storeToRefs(formMappingView);

const { activeField, otherFields } = storeToRefs(
  useMappedFormStore(props.formId)
);

const fieldCopiedTo = computed(() => {
  if (!otherFields.value?.ids?.length) return null;
  const activeQuestionLinkId = activeField.value.id;
  return otherFields.value.ids.find(id => {
    if (!otherFields.value.by_id[id]) return false;
    return otherFields.value.by_id[id].referenceField === activeQuestionLinkId;
  });
});
const hasAlreadyBeenCopiedOnce = computed(() => Boolean(fieldCopiedTo.value));
const notCopyableText = computed(() => {
  if (!activeField.value) return null;

  const hasNoPositionalData = !activeField.value?.coordinates?.length;

  if (hasNoPositionalData) return "Field has no positional data";
  if (hasAlreadyBeenCopiedOnce.value)
    return `Field has already been copied to ${fieldCopiedTo.value}`;

  return null;
});

async function copyField() {
  try {
    await formMappingView.copy(activeField.value);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  }
}
</script>
