import { ActiveSave } from "@/factories/ActiveSave";
import { parseErrorMessage, uuidv4 } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed, ref } from "vue";
export const useActiveSave = config => {
  const controller = ref(ActiveSave(config));
  const snackbar = useSnackbarStore();
  function debounceUpdate(func, timeout = 500) {
    if (controller.value.timer) clearTimeout(controller.value.timer);
    controller.value.timer = setTimeout(() => update(func), timeout);
  }

  // Typically used for single updates
  function update(func) {
    if (controller.value?.promise?.then) {
      controller.value.latestInQueue = true;
    }
    if (!controller.value) return func();
    controller.value.saving = true;
    controller.value.error = false;
    controller.value.saved = false;
    controller.value.promise = func();
    if (controller.value.promise?.then) {
      controller.value.promise
        .then(() => {
          controller.value.saving = false;
          controller.value.saved = true;
          setTimeout(() => {
            controller.value.saved = false;
          }, 250);
        })
        .catch(e => {
          controller.value.saving = false;
          controller.value.error = true;
          controller.value.errorMessage = parseErrorMessage(e);

          snackbar.showErrorSnackbar({
            message: `${controller.value.errorMessage}`,
            timeout: -1
          });
        });
    } else {
      controller.value.saving = false;
    }

    return controller.value.promise;
  }

  // Typically used for aggregate updates
  function attachToPromise(promise) {
    const uuid = uuidv4();
    controller.value.saveQueue.push(uuid);
    controller.value.saving = true;
    controller.value.error = false;
    controller.value.saved = false;
    controller.value.promise = promise;
    if (promise?.then) {
      promise
        .then(() => {
          const index = controller.value.saveQueue.indexOf(uuid);
          controller.value.saveQueue.splice(index, 1);
          if (controller.value.saveQueue.length === 0) {
            controller.value.needsUpdate = false;
            controller.value.saving = false;
            controller.value.saved = true;
            setTimeout(() => {
              controller.value.saved = false;
            }, 1500);
          }
        })
        .catch(e => {
          const index = controller.value.saveQueue.indexOf(uuid);
          controller.value.saveQueue.splice(index, 1);
          if (controller.value.saveQueue.length === 0) {
            controller.value.saving = false;
            controller.value.error = true;
            controller.value.errorMessage = parseErrorMessage(e);

            snackbar.showErrorSnackbar({
              message: `${controller.value.errorMessage}`,
              timeout: -1
            });
          }
        });
    }

    return promise;
  }

  const indicatorVisible = computed(
    () =>
      controller.value.saving ||
      controller.value.saved ||
      controller.value.error
  );
  return {
    controller,
    debounceUpdate,
    update,
    attachToPromise,
    indicatorVisible
  };
};
