<template>
  <v-card>
    <v-card-title>Edit Advisor</v-card-title>
    <v-card-text>
      <integer-input
        v-model="appointmentCase.splitPercent"
        data-lpignore="true"
        :prepend-inner-icon="mdiPercent"
        dense
        type="text"
        inputmode="numeric"
        mask="###"
        outlined
        label="Split Percent"
        data-testid="split-percent"
        :success="splitPercentValidation.success"
        :error-messages="splitPercentValidation.errorMessages"
      />
      <integer-input
        v-model="appointmentCase.splitRenewal"
        data-lpignore="true"
        :prepend-inner-icon="mdiPercent"
        dense
        type="text"
        inputmode="numeric"
        mask="###"
        outlined
        label="Renewal Split Percent"
        data-testid="split-renewal"
        :success="splitRenewalValidation.success"
        :error-messages="splitRenewalValidation.errorMessages"
      />
      <marketing-manager-search
        v-model="marketingManager"
        :prepend-inner-icon="mdiAccountMultiple"
        dense
        outlined
        label="Marketing Manager"
        data-testid="marketing-manager"
        :success="marketingManagerIdValidation.success"
        :error-messages="marketingManagerIdValidation.errorMessages"
      />
      <div class="checkbox-width">
        <v-checkbox
          v-model="appointmentCase.backNineAppointment"
          label="BackNine Appointment"
          readonly
          data-testid="back-nine-appointment"
          :success="appointmentCase.backNineAppointment"
          @click="toggleCommissionable"
        />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" outlined @click="dialog.closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="save-advisor"
        :loading="loading"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import IntegerInput from "@/components/shared/IntegerInput.vue";
import MarketingManagerSearch from "@/components/shared/MarketingManagerSearch.vue";

import { parseErrorMessage, computedValidation } from "@/util/helpers";
import { updateAppointmentCase } from "@/api/appointment-cases.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { AppointmentCase } from "@/factories/Case";
import { computed, defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";

import { mdiPercent, mdiAccountMultiple } from "@mdi/js";

const props = defineProps({ value: Object });
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const loading = ref(false);
const appointmentCase = ref(
  AppointmentCase({
    splitRenewal: props.value.splitRenewal,
    splitPercent: props.value.splitPercent,
    marketingManagerId: props.value.marketingManagerId,
    marketingManagerName: props.value.marketingManagerName,
    appointmentCaseId: props.value.appointmentCaseId,
    backNineAppointment: props.value.backNineAppointment
  })
);

const rules = {
  appointmentCase: {
    splitPercent: {
      required: Boolean
    },
    splitRenewal: {
      required: Boolean
    },
    marketingManagerId: {
      required: Boolean()
    }
  }
};

const state = { appointmentCase };

const v$ = useVuelidate(rules, state, { $autoDirty: true, $scope: false });
const marketingManager = computed({
  get() {
    return {
      id: appointmentCase.value.marketingManagerId,
      name: appointmentCase.value.marketingManagerName
    };
  },
  set(v) {
    appointmentCase.value.marketingManagerId = v?.id;
    appointmentCase.value.marketingManagerName = v?.name;
  }
});

const splitPercentValidation = computedValidation(
  v$.value.appointmentCase.splitPercent,
  { required: "Required" }
);

const splitRenewalValidation = computedValidation(
  v$.value.appointmentCase.splitRenewal,
  {
    required: "Required"
  }
);

const marketingManagerIdValidation = computedValidation(
  v$.value.appointmentCase.marketingManagerId,
  { required: "Required" }
);

async function toggleCommissionable() {
  let subtitle = "Confirm that this appointment is through BackNine";
  if (!appointmentCase.value.backNineAppointment) {
    subtitle = "Confirm that this appointment is not through BackNine";
  }
  const result = await dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Confirm BackNine Appointment",
    subtitle
  });

  if (result?.confirm) {
    appointmentCase.value.backNineAppointment =
      !appointmentCase.value.backNineAppointment;
  }
}
async function save() {
  const isValid = await v$.value.$validate();
  if (!isValid || loading.value) return;
  loading.value = true;
  try {
    const ac = await updateAppointmentCase(appointmentCase.value);
    snackbar.showSuccessSnackbar({
      message: `Successfuly Updated ${appointmentCase.value.advisorName}`,
      timeout: 3000
    });
    dialog.closeDialog({ appointmentCase: ac });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: 3000
    });
  } finally {
    loading.value = false;
  }
}
</script>
