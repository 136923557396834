import { getHttpClient } from "@/http-client";
import { setDocumentFromRequest } from "@/factories/Document";
const baseUrl = "/api/boss/contracting_answers";

export async function addDocumentToAnswer(answerId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${answerId}/documents`,
    body
  );
  if (!data?.success?.length) return [];

  return data.success.map(r => setDocumentFromRequest(r));
}

export async function removeDocumentFromAnswer(answerId, documentUid) {
  return await getHttpClient().delete(
    `${baseUrl}/${answerId}/documents/${documentUid}`
  );
}
