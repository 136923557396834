function BacknineCaseCommission(model = {}) {
  return {
    carrierName: model?.carrierName || null,
    policyNumber: model?.policyNumber || null,
    income: model?.income || null,
    expenses: model?.expenses || null,
    profit: model?.profit || null,
    inforceDate: model?.inforceDate || null,
    marketer: model?.marketer || null
  };
}

export function setBacknineCaseCommissionFromRequest(raw = {}) {
  const item = BacknineCaseCommission();
  if (!raw) return item;

  item.carrierName = raw.carrier?.name;
  item.policyNumber = raw.policy_number;
  item.income = raw.income;
  item.expenses = raw.expenses;
  item.profit = raw.profit;
  item.inforceDate = raw.inforce_date;
  item.marketer = raw.marketer;

  return item;
}

function ProductCommision(model = {}) {
  return {
    carrierName: model?.carrierName || null,
    excess: model?.excess || null,
    firstYear: model?.firstYear || null,
    id: model?.id || null,
    name: model?.name || null,
    qaEnabled: model?.qaEnabled || null,
    renewal: model?.renewal || null
  };
}

export function setProductCommissionFromRequest(raw = {}) {
  const item = ProductCommision();
  if (!raw) return item;

  item.carrierName = raw.carrier?.name;
  item.excess = raw.excess;
  item.firstYear = raw.first_year;
  item.name = raw.name;
  item.id = raw.id;
  item.qaEnabled = raw.qa_enabled;
  item.renewal = raw.renewal;

  return item;
}

export function Commission(model = {}) {
  return {
    id: model?.id || null,
    policy: model?.policy || null,
    advisor: model?.advisor || null,
    payable: model?.payable || null,
    assignedPayable: model?.assignedPayable || null,
    payor: model?.payor || null,
    appointmentCase: model?.appointmentCase || null,
    commissionType: model?.commissionType || null,
    paymentType: model?.paymentType || null,
    percent: model?.percent || null,
    startYear: model?.startYear || null,
    endYear: model?.endYear || null
  };
}

export function CommissionToUnpaidUpdateRequest(model = {}) {
  return {
    commission_id: model.id,
    appointment_case_id: model.appointmentCase.id,
    assigned_payable_id: model.assignedPayable.id,
    assigned_payable_type: model.assignedPayable.type,
    commission_type: model.commissionType,
    payable_id: model.payable.id,
    payable_type: model.payable.type,
    payment_type: model.paymentType,
    payor_id: model.payor.id,
    start_year: model.startYear,
    end_year: model.endYear,
    percent: model.percent / 100
  };
}

export function CommissionToPaidUpdateRequest(model = {}) {
  return {
    commission_id: model.id,
    start_year: model.startYear,
    end_year: model.endYear,
    percent: model.percent / 100
  };
}

export function CommissionToCreateRequest(model = {}) {
  return {
    appointment_case_id: model.appointmentCase.id,
    assigned_payable_id: model.assignedPayable.id,
    assigned_payable_type: model.assignedPayable.type,
    case_id: model.policy.id,
    commission_type: model.commissionType,
    payable_id: model.payable.id,
    payable_type: model.payable.type,
    payment_type: model.paymentType,
    payor_id: model.payor.id,
    start_year: model.startYear,
    end_year: model.endYear,
    percent: model.percent / 100
  };
}
