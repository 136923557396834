import { getHttpClient } from "@/http-client";
import { setIndividualFromRequest } from "@/factories/Party";

const baseUrl = "api/boss/individuals";
export async function fetchIndividual(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setIndividualFromRequest(data?.individual);
}

export function getSensitiveIndividualInformation(id) {
  return new Promise((resolve, reject) => {
    getHttpClient()
      .get(`${baseUrl}/${id}/sensitive`)
      .then(res => {
        resolve(res?.data?.ssn);
      })
      .catch(reject);
  });
}

export function updateIndividual(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, body);
}
