<template>
  <v-card tile flat color="section">
    <v-card-title>Metadata</v-card-title>
    <v-card-text>
      <pre> {{ parsedMetadata }}</pre>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useEappViewStore } from "@/stores/eapp-view";
import { computed } from "vue";

const eapp = useEappViewStore();
const { metadata } = storeToRefs(eapp);

const parsedMetadata = computed(() => {
  try {
    return JSON.parse(metadata.value, null, 2);
  } catch (e) {
    return metadata.value;
  }
});
</script>
