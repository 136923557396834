<template>
  <v-data-table
    data-testid="simulated-payments"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :options="table.options.value"
    :loading="simulatingTransaction"
    item-class="additional.rowClass"
  >
    <template #[`item.actions`]="{ item }">
      <v-tooltip v-if="item.additional.errors" top>
        <template #activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" icon>
            <v-icon color="white"> {{ mdiInformation }} </v-icon>
          </v-btn>
        </template>
        <span> {{ item.additional.errors }} </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { mdiInformation } from "@mdi/js";
import { useTable } from "@/composables/table.composable";
import { watch } from "vue";
import { storeToRefs } from "pinia";
import { useTransactionCreate } from "@/stores/transaction-create";
import { currencyFormat, formatPercentage } from "@/util/helpers";

const { payments, simulatingTransaction } = storeToRefs(useTransactionCreate());

const table = useTable({
  headers: [
    new TableHeader({
      text: "Payable",
      value: "payable",
      map: "payable.name"
    }),
    new TableHeader({
      text: "Assigned Payable",
      value: "assignedPayable",
      map: "assignedPayable.name"
    }),
    new TableHeader({
      text: "Payor",
      value: "payor",
      map: "payor.name"
    }),
    new TableHeader({
      text: "Commission Type",
      value: "commissionType",
      map: "commissionType"
    }),
    new TableHeader({
      text: "Payment Type",
      value: "paymentType",
      map: "paymentType"
    }),
    new TableHeader({
      text: "Dollar",
      value: "premium",
      map: "premium"
    }),
    new TableHeader({
      text: "Percent",
      value: "percent",
      map: "percent"
    }),
    new TableHeader({
      text: "Split",
      value: "split",
      map: "splitPercent"
    }),
    new TableHeader({
      text: "Commission",
      value: "dollar",
      map: "dollar"
    }),
    new TableHeader({
      text: "",
      value: "actions",
      map: ""
    })
  ]
});

watch(
  payments,
  () => {
    table.items.value.splice(0, table.items.value.length);
    payments.value.forEach(p => {
      table.items.value.push({
        ...p,
        premium: currencyFormat(p.premium, 2),
        dollar: currencyFormat(p.dollar, 2),
        percent: formatPercentage(p.percent, { decimalLength: 3 }),
        splitPercent: formatPercentage(p.splitPercent, {
          decimalLength: 0,
          isDecimal: true,
          floor: true
        }),
        rowClass: p.errors ? "error white--text" : null
      });
    });
  },
  { deep: true, immediate: true }
);
</script>
