<template>
  <v-row class="ma-0">
    <v-col
      v-for="statistic in statisticTypes"
      cols="4"
      md="2"
      sm="3"
      :key="`${statistic.text}-body`"
    >
      <v-card>
        <v-card-text>
          <span
            class="grey--text lighten-2"
            :data-testid="`analytics-${statistic.text}`"
          >
            {{ statistic.text }}
          </span>
          <br />
          <strong style="font-size: 18px">
            <number-formatter
              :data-testid="`analytics-${statistic.text}-week`"
              :value="statistics.weekly[statistic.key]"
            />
          </strong>
          this Week
          <br />
          <strong style="font-size: 18px">
            <number-formatter
              :data-testid="`analytics-${statistic.text}-month`"
              :value="statistics.monthly[statistic.key]"
            />
          </strong>
          this Month
          <br />
          <strong style="font-size: 18px">
            <number-formatter
              :data-testid="`analytics-${statistic.text}-all`"
              :value="statistics.all[statistic.key]"
            />
          </strong>
          All Time
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import NumberFormatter from "@/components/shared/formatters/NumberFormatter.vue";
import { storeToRefs } from "pinia";
import { useApprovedDomainView } from "@/stores/approved-domain-view";
const statisticTypes = [
  { text: "Applications", key: "applicationCount" },
  { text: "Leads", key: "leadCount" },
  { text: "Views", key: "viewCount" }
];

const approvedDomainView = useApprovedDomainView();
const { statistics } = storeToRefs(approvedDomainView);
</script>
