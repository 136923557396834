import { getHttpClient } from "@/http-client";

const baseUrl = "/api/boss/reports";

export async function getMyCommissionsReport(id, type) {
  const params = new URLSearchParams();
  params.append("id", id);
  params.append("type", type);
  const { data } = await getHttpClient().get(`${baseUrl}/my_commissions`, {
    params
  });
  return data.graphs;
}

export async function getApplicationsReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/applications`, {
    params
  });
  return data.data;
}

export async function getImpairmentsReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/impairments`, {
    params
  });
  return data;
}

export async function getApsCompletionReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/aps_completion`, {
    params
  });
  return data.report;
}

export async function getFirstApsReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/aps_first_aps`, {
    params
  });
  return data.report;
}

export async function getApsCreationReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/aps_creation`, {
    params
  });
  return data.report;
}

export async function getApsFrequencyReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/aps_frequency`, {
    params
  });
  return data.report;
}

export async function getPremiumReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/premium`, { params });
  return data.data;
}

export async function getCaseDurationReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/case_duration`, {
    params
  });

  const { active, inactive } = data.data;
  return {
    active: setGraphSeriesItemFromRaw(active),
    inactive: setGraphSeriesItemFromRaw(inactive)
  };
}

export async function getEAppsReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/eapps`, { params });
  return data.data;
}

export async function getTasksReport() {
  const { data } = await getHttpClient().get(`${baseUrl}/tasks`);
  return data.case_managers.map(i => ({
    name: i.name,
    taskScore: i.task_score,
    pendingCases: i.pending_cases,
    averageDuration: i.average_duration
  }));
}

let marketingManagerCancelToken = null;
export async function getMarketingManagersReport(params) {
  if (marketingManagerCancelToken !== null) {
    marketingManagerCancelToken.cancel(
      "Operation canceled due to new request."
    );
  }
  marketingManagerCancelToken = getHttpClient().CancelToken.source();
  const res = await getHttpClient().get(
    `${baseUrl}/marketing_managers_profit`,
    {
      cancelToken: marketingManagerCancelToken.token,
      params
    }
  );
  if (!res?.data) return;
  return res.data.data.map(item => ({
    oneYear: item["1 year"],
    oneMonth: item["1 month"],
    fourMonths: item["4 months"],
    allTime: item["All Time"],
    name: item.name,
    id: item.id
  }));
}

export async function getMarketingManagersProductionCreditReport(
  params,
  cancelToken
) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const res = await getHttpClient().get(
    `${baseUrl}/marketing_managers_production_credit`,
    {
      cancelToken: cancelToken.token,
      params
    }
  );
  if (!res?.data) return;

  return {
    items: res.data.map(r => ({
      name: r.name,
      production: r.total_case_production,
      caseCount: r.total_case_count
    }))
  };
}

export async function getScorecardReport() {
  const { data } = await getHttpClient().get(`${baseUrl}/scorecard`);
  return data;
}

export async function getMyCompletedEAppsByProductCategoryReport(params) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/my_completed_eapps_by_product_category`,
    { params }
  );

  return data.series;
}
export async function getAllCompletedEAppsByProductCategoryReport(params) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/all_completed_eapps_by_product_category`,
    { params }
  );

  return data.series;
}

export async function getInstantDecisionsReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/instant-decisions`, {
    params
  });
  return {
    eligible: data?.graphs?.instant_decision_eligible || 0,
    received: data?.graphs?.instant_decision_received || 0
  };
}

export async function getProductionByCarrierReport(params) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/production-by-carrier`,
    {
      params
    }
  );
  return data.map(setCarrierProductionTableItemFromRaw);
}

export async function getPlacementReport(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/placement`, {
    params
  });

  const { all, eapp, paper } = data.data;

  return {
    all: setGraphSeriesItemFromRaw(all),
    eapp: setGraphSeriesItemFromRaw(eapp),
    paper: setGraphSeriesItemFromRaw(paper)
  };
}

function CarrierProductionTableItem(model = {}) {
  return {
    carrier: model?.carrier,
    currNumberOfCases: model?.currNumberOfCases,
    currProductionCredit: model?.currProductionCredit,
    prevNumberOfCases: model?.prevNumberOfCases,
    prevProductionCredit: model?.prevProductionCredit
  };
}

function setCarrierProductionTableItemFromRaw(raw = {}) {
  const item = CarrierProductionTableItem();
  item.carrier = raw?.carrier;
  item.currNumberOfCases = raw?.curr_number_of_cases;
  item.currProductionCredit = raw?.curr_production_credit;
  item.prevNumberOfCases = raw?.prev_number_of_cases;
  item.prevProductionCredit = raw?.prev_production_credit;
  return item;
}

function GraphSeriesItem(model = {}) {
  return {
    name: model?.name,
    data: model?.data
  };
}

function setGraphSeriesItemFromRaw(raw = {}) {
  const item = GraphSeriesItem();
  item.name = raw?.name;
  item.data = raw?.data;
  return item;
}

export function TodoScoreItem(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    score: model?.score,
    averageDuration: model?.averageDuration,
    incompleteTodoCount: model?.incompleteTodos,
    totalTodoCount: model?.totalTodoCount
  };
}

function setTodoScoreItemFromRaw(raw = {}) {
  const item = TodoScoreItem();

  item.id = raw?.id;
  item.name = raw?.name;
  item.score = raw?.todo_score;
  item.averageDuration = raw?.average_duration_past_year;
  item.incompleteTodoCount = raw?.incomplete_todos;
  item.totalTodoCount = raw?.total_todos;

  return item;
}

export async function getMarketingManagersTodoReport() {
  const { data } = await getHttpClient().get(
    `${baseUrl}/marketing_manager_todos`
  );

  return {
    backnine: setTodoScoreItemFromRaw(data.backnine),
    marketingManagers: data.marketing_managers
      .map(setTodoScoreItemFromRaw)
      .toSorted((a, b) => a.name.localeCompare(b.name))
  };
}

export async function getPersonalTodoReport() {
  const { data } = await getHttpClient().get(`${baseUrl}/my_todos`);

  return {
    averageDuration: data.average_duration_past_year,
    incomplete: data.incomplete_todos,
    score: data.todo_score,
    total: data.total_todos
  };
}
