<template>
  <v-card class="preliminary-card" v-if="passwordResetStore.message != ''">
    <v-col>
      <v-row class="ma-0" align="center" justify="center">
        <app-logo large />
      </v-row>
    </v-col>
    <v-card-title
      class="justify-center font-weight-bold text-h5 mb-6"
      data-testid="title"
    >
      {{ passwordResetStore.message }}
    </v-card-title>
  </v-card>
  <v-card class="preliminary-card" v-else>
    <v-form @submit.prevent="updatePassword" autocomplete="off">
      <v-col>
        <v-row class="ma-0" align="center" justify="center">
          <app-logo large />
        </v-row>
      </v-col>
      <v-card-title
        class="justify-center font-weight-bold text-h5 mb-6"
        data-testid="title"
      >
        {{ promptAction }} Password
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="passwordForm.newPassword"
          label="New Password"
          outlined
          dense
          type="password"
          data-testid="new-password"
          :prepend-inner-icon="mdiLock"
          v-bind="newPasswordValidation"
        />
        <v-text-field
          v-model="passwordForm.newPasswordConfirmation"
          label="Confirm New Password"
          outlined
          dense
          type="password"
          data-testid="new-password-confirm"
          :prepend-inner-icon="mdiLockCheck"
          v-bind="newPasswordConfirmationValidation"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="text-none"
          color="primary"
          data-testid="submit"
          type="submit"
          block
          :loading="saving"
        >
          {{ promptAction }} Password
        </v-btn>
      </v-card-actions>
    </v-form>

    <v-card-text class="pt-0">
      Don't need to {{ promptAction.toLowerCase() }} your password?
      <router-link class="text-none" outlined :to="{ name: 'SignIn' }">
        Sign In
      </router-link>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AppLogo from "@/components/AppLogo.vue";

import { changePasswordWithToken } from "@/api/auth.service";
import { computedValidation, parseErrorMessage } from "@/util/helpers";
import {
  passwordValidator,
  passwordValidatorMessages
} from "@/util/vuelidateValidators";
import { useSnackbarStore } from "@/stores/snackbar";
import { mdiLock, mdiLockCheck } from "@mdi/js";
import { useHead } from "@unhead/vue";
import useVuelidate from "@vuelidate/core";
import { ref } from "vue";
import { defineProps } from "vue";
import { useRouter } from "@/composables/compatible.composables";
import { usePasswordResetStore } from "@/stores/password-reset";

const props = defineProps({
  resetPasswordToken: {
    type: String,
    required: true
  },
  promptAction: {
    type: String,
    required: false,
    default: "Change"
  }
});

const router = useRouter();
const snackbar = useSnackbarStore();
const passwordResetStore = usePasswordResetStore();
const saving = ref(false);
const passwordForm = ref({
  newPassword: "",
  newPasswordConfirmation: ""
});

useHead({
  title: `${props.promptAction} Password`
});

const v$ = useVuelidate(
  {
    passwordForm: {
      newPassword: passwordValidator,
      newPasswordConfirmation: {
        matches: (v, vm) => v && v === vm.newPassword
      }
    }
  },
  {
    passwordForm
  },
  {
    $scope: "password-change",
    $autoDirty: true
  }
);

const newPasswordValidation = computedValidation(
  v$.value.passwordForm.newPassword,
  passwordValidatorMessages
);

const newPasswordConfirmationValidation = computedValidation(
  v$.value.passwordForm.newPasswordConfirmation,
  {
    matches: "Passwords must match"
  }
);

async function updatePassword() {
  try {
    const isValid = v$.value.$validate();
    if (!isValid) return;
    saving.value = true;
    await changePasswordWithToken({
      resetPasswordToken: props.resetPasswordToken,
      newPassword: passwordForm.value.newPassword,
      newPasswordConfirmation: passwordForm.value.newPasswordConfirmation
    });
    snackbar.showSuccessSnackbar({ message: "Password successfully updated" });
    router.replace({ name: "SignIn" });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}
</script>
