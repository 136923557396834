import { getHttpClient } from "@/http-client";

const baseUrl = "api/boss/marketing";
export async function getMarketingTemplateHtml(title, template_name) {
  const params = new URLSearchParams();
  params.append("title", title);
  params.append("template_name", template_name);
  const { data } = await getHttpClient().get(
    `${baseUrl}/get_template_html?${params.toString()}`
  );
  return data;
}
export function sendMarketingTemplate(email_string, template_name, title) {
  return getHttpClient().post(`${baseUrl}/send_template`, {
    email_string,
    template_name,
    title
  });
}
