<template>
  <v-row dense class="ma-0 pa-0">
    <v-col cols="12" v-if="renderFields.saveButton">
      <v-btn
        block
        class="text-none"
        data-testid="save-button"
        color="primary"
        :loading="saving"
        :outlined="!currentFieldHasChanges"
        @click="save"
      >
        Save Field {{ currentFieldHasChanges ? "(unsaved changes)" : "" }}
      </v-btn>
    </v-col>

    <v-col cols="12" v-if="renderFields.unlinkButton">
      <v-btn
        block
        color="accent"
        class="text-none"
        data-testid="unlink-button"
        @click="referenceField = null"
      >
        Unlink from {{ referenceField }}
      </v-btn>
    </v-col>

    <v-col cols="12" class="mt-3">
      <application-question-search
        v-model="applicationQuestion"
        label="Application Question"
        placeholder="Search Application Questions"
        data-testid="application-question-search"
        :autofocus="!applicationQuestion"
        :disabled="!disabledFields"
        :model-type="modelType"
        :model-id="modelId"
        v-bind="applicationQuestionValidation"
      />
    </v-col>

    <v-col cols="12" v-if="renderFields.insuredIndex" class="mb-3">
      <h3>Insured or Joint Insured</h3>
      <v-chip-group v-model="insuredIndex">
        <v-chip
          v-for="item in INSURED_INDEX_OPTIONS"
          :data-testid="`required-by-${item.text}`"
          :value="item.value"
          :disabled="!disabledFields"
          :color="insuredIndex === item.value ? 'primary' : null"
          :key="item.value"
        >
          {{ item.text }}
        </v-chip>
      </v-chip-group>
    </v-col>

    <v-col cols="12" v-if="renderFields.smartApplicationQuestion">
      <application-question-search
        v-model="smartApplicationQuestion"
        smart
        clearable
        label="Smart Application Question"
        placeholder="Search Smart Search Application Questions"
        data-testid="smart-application-question-search"
        :disabled="!disabledFields"
        :model-type="modelType"
        :model-id="modelId"
        :success="Boolean(smartApplicationQuestion?.id)"
      />
    </v-col>

    <v-col cols="6" v-if="renderFields.tiaField">
      <div class="checkbox-width">
        <v-checkbox
          v-model="isTiaField"
          class="mt-1"
          outlined
          dense
          label="TIA Field"
          data-testid="tia-field"
          :disabled="!disabledFields"
          :success="Boolean(isTiaField)"
        />
      </div>
    </v-col>

    <v-col v-if="renderFields.docusignField" cols="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="isDocusignField"
          class="mt-1"
          outlined
          dense
          label="Docusign Field"
          data-testid="docusign-field"
          :success="Boolean(isDocusignField)"
          :disabled="!disabledFields"
          @change="clearDocusignDependentFields"
        />
      </div>
    </v-col>

    <v-col v-if="renderFields.docusignRequired" cols="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="isDocusignRequired"
          dense
          class="mt-1"
          label="Docusign Required"
          data-testid="docusign-required"
          :disabled="!disabledFields"
          :success="Boolean(isDocusignRequired)"
        />
      </div>
    </v-col>

    <v-col v-if="renderFields.docusignRequiredOf" cols="6">
      <v-autocomplete
        v-model="docusignRequiredOf"
        label="Required Of"
        multiple
        outlined
        dense
        data-testid="docusign-required-of"
        auto-select-first
        :prepend-inner-icon="mdiAsterisk"
        :items="DOCUSIGN_REQUIRED_OF"
        :disabled="disabledRequiredOf || readonly"
        :success="Boolean(docusignRequiredOf)"
      />
    </v-col>

    <v-col cols="6" v-if="renderFields.additionalForm">
      <div class="checkbox-width">
        <v-checkbox
          v-model="additionalForm"
          label="Supplemental Form"
          dense
          class="mt-1"
          data-testid="supplemental-form"
          :prepend-inner-icon="mdiTextBoxPlus"
          :disabled="!disabledFields"
          :success="Boolean(additionalForm)"
        />
      </div>
    </v-col>

    <v-col v-if="renderFields.comb" cols="6">
      <integer-input
        v-model="comb"
        label="Comb"
        outlined
        dense
        hint="Useful for segmented fields such as birthdate, account number, etc"
        persistent-hint
        data-testid="comb"
        :min="0"
        :max="99"
        :success="Boolean(comb)"
        :disabled="!disabledFields"
        :prepend-inner-icon="mdiLandRowsVertical"
      />
    </v-col>

    <v-col v-if="renderFields.obscureBackground" cols="6">
      <div class="checkbox-width">
        <v-checkbox
          v-model="obscureBackground"
          label="Obscure Background"
          outlined
          dense
          persistent-hint
          data-testid="obscure-background"
          hint="Apply a white background to the input. Typically paired with Comb. Useful for covering symbols"
          :prepend-inner-icon="mdiSquareOpacity"
          :disabled="!disabledFields"
          :success="Boolean(obscureBackground)"
        />
      </div>
    </v-col>

    <v-col
      v-if="renderFields.parentQuestion || renderFields.requiredParentValue"
      cols="12"
    >
      <v-row dense class="ma-0">
        <v-col
          v-if="renderFields.parentQuestion"
          :cols="renderFields.requiredParentValue ? 6 : 12"
        >
          <v-autocomplete
            v-model="parentQuestion"
            label="Parent Question"
            outlined
            dense
            clearable
            auto-select-first
            data-testid="parent-question"
            :item-text="questionSpecificText"
            item-value="id"
            :items="parentFields"
            :disabled="!disabledFields"
            :success="Boolean(parentQuestion)"
            :prepend-inner-icon="mdiGoogleCirclesGroup"
          />
        </v-col>

        <v-col
          v-if="renderFields.requiredParentValue"
          :cols="renderFields.parentQuestion ? 6 : 12"
        >
          <v-autocomplete
            v-model="requiredParentValue"
            label="Required Parent Value"
            outlined
            dense
            auto-select-first
            data-testid="required-parent-value"
            :prepend-inner-icon="mdiAsterisk"
            :items="parentQuestionOptions"
            :disabled="!disabledFields"
            v-bind="requiredParentValueValidation"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="renderFields.verbatimQuestion">
      <v-textarea
        v-model="verbatimQuestion"
        label="Verbatim Question"
        outlined
        dense
        data-testid="verbatim-question"
        :prepend-inner-icon="mdiHelpBox"
        :disabled="!disabledFields"
        v-bind="verbatimQuestionValidation"
      />
    </v-col>

    <v-col cols="12" v-if="renderFields.quoteAndApplyType">
      <v-autocomplete
        v-model="quoteAndApplyType"
        auto-select-first
        outlined
        dense
        label="Quote & Apply Type"
        data-testid="quote-and-apply-type"
        :prepend-inner-icon="mdiWizardHat"
        :items="availableQuoteAndApplyFieldTypes"
        :disabled="!disabledFields"
        v-bind="quoteAndApplyTypeValidation"
      />
    </v-col>

    <v-col v-if="renderFields.fieldOptions" cols="12" align="start">
      <mapped-form-view-aql-field-options
        :key="quoteAndApplyType"
        :validation-scope="validationScope"
        :form-id="formId"
        @input="currentFieldHasChanges = true"
      />
    </v-col>

    <v-col cols="12" v-if="renderFields.requiredValue">
      <v-autocomplete
        v-model="requiredValue"
        auto-select-first
        item-text="value"
        data-testid="required-value"
        label="Required Value"
        outlined
        dense
        clearable
        :prepend-inner-icon="mdiViewListOutline"
        :disabled="!disabledFields"
        :items="fieldOptions"
        v-bind="requiredValueValidation"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { computedValidation, parseErrorMessage, uuidv4 } from "@/util/helpers";

import IntegerInput from "@/components/shared/IntegerInput.vue";
import ApplicationQuestionSearch from "@/components/shared/ApplicationQuestionSearch.vue";
import MappedFormViewAqlFieldOptions from "@/components/form-mapping/MappedFormViewAqlFieldOptions.vue";

import { useMappedFormStore } from "@/stores/mapped-form";
import { storeToRefs } from "pinia";

import {
  computed,
  nextTick,
  onMounted,
  ref,
  watch,
  defineProps,
  set
} from "vue";

import {
  AqlField,
  NOT_ON_PDF_OPTION_TYPE,
  RADIO_OPTION_DIAMETER,
  RADIO_OPTION_TYPE,
  SELECT_OPTION_TYPE,
  TEXT_OPTION_TYPE,
  QUOTE_AND_APPLY_FIELD_TYPES,
  DOCUSIGN_REQUIRED_OF,
  INSURED_INDEX_OPTIONS,
  CHECKBOX_OPTION_TYPE,
  VERBATIM_QUESTION
} from "@/factories/FormMapping";
import { useSnackbarStore } from "@/stores/snackbar";

import {
  mdiAsterisk,
  mdiTextBoxPlus,
  mdiLandRowsVertical,
  mdiSquareOpacity,
  mdiGoogleCirclesGroup,
  mdiHelpBox,
  mdiWizardHat,
  mdiViewListOutline
} from "@mdi/js";
import useVuelidate from "@vuelidate/core";

const props = defineProps({
  formId: [String, Number]
});

const snackbar = useSnackbarStore();
const formStore = useMappedFormStore(props.formId);
const {
  isContracting,
  activeField,
  fields,
  currentFieldHasChanges,
  currentFieldIsValid,
  currentPage,
  viewport,
  readonly,
  modelId,
  modelType
} = storeToRefs(formStore);
const saving = ref(false);
const isMounted = ref(false);

const parentApplicationQuestionLink = computed(
  () => fields.value.by_id[parentQuestion.value]
);

const parentQuestionOptions = computed(() => {
  if (!parentApplicationQuestionLink.value) return [];
  return parentOptions(parentApplicationQuestionLink.value);
});

const parentFields = computed(() => {
  return fields.value.ids.reduce((acc, id) => {
    if (id === activeField.value.id) return acc;
    const options = parentOptions(fields.value.by_id[id]);
    if (!options.length) return acc;
    acc.push(fields.value.by_id[id]);
    return acc;
  }, []);
});

function parentOptions(question) {
  if (!question.coordinates?.length) return [];

  let options = [];
  if (question.pdfFieldType === TEXT_OPTION_TYPE) {
    const coordinate = question.coordinates[0];
    options = coordinate.options || [];
  } else {
    options = question.coordinates.map(v => ({ text: v.text, value: v.value }));
  }
  return options.filter(v => v.value || v.value === 0);
}

const applicationQuestion = computed({
  get() {
    return activeField.value.applicationQuestion;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.applicationQuestion?.id === val?.id;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    set(activeField.value, "applicationQuestion", val);
    if (val?.application_field_type) {
      pdfFieldType.value = val?.application_field_type;
    }
  }
});

const question = computed({
  get() {
    return activeField.value.question;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.question === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    set(activeField.value, "question", val);
  }
});

const smartApplicationQuestion = computed({
  get() {
    return activeField.value.smartApplicationQuestion;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged =
      activeField.value.smartApplicationQuestion?.id === val?.id;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    set(activeField.value, "smartApplicationQuestion", val);
  }
});

const referenceField = computed({
  get() {
    return activeField.value.referenceField;
  },
  set(value) {
    activeField.value.referenceField = value;
    currentFieldHasChanges.value = true;
  }
});

const isTiaField = computed({
  get() {
    return activeField.value.isTiaField;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.isTiaField === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.isTiaField = val;
  }
});

const isDocusignField = computed({
  get() {
    return activeField.value.isDocusignField;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.isDocusignField === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.isDocusignField = val;
  }
});

const isDocusignRequired = computed({
  get() {
    return activeField.value.isDocusignRequired;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.isDocusignRequired === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.isDocusignRequired = val;
  }
});

const docusignRequiredOf = computed({
  get() {
    return activeField.value.docusignRequiredOf;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.docusignRequiredOf === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.docusignRequiredOf = val;
  }
});

const pdfFieldType = computed({
  get() {
    return activeField.value.pdfFieldType;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.pdfFieldType === val;
    if (isUnchanged) return;
    pdfFieldTypeConversion(val);
    currentFieldHasChanges.value = true;
    activeField.value.pdfFieldType = val;
  }
});

const additionalForm = computed({
  get() {
    return activeField.value.additionalForm;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.additionalForm === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.additionalForm = val;
  }
});

const fieldOptions = computed(() => {
  if (!activeField.value.coordinates?.length) return [];
  if (activeField.value.pdfFieldType === TEXT_OPTION_TYPE) {
    const coordinate = activeField.value.coordinates[0];
    return coordinate.options || [];
  }
  return activeField.value.coordinates.map(({ text, value }) => ({
    text,
    value
  }));
});

const requiredValue = computed({
  get() {
    return activeField.value.requiredValue;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.requiredValue === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.requiredValue = val;
  }
});

const obscureBackground = computed({
  get() {
    return activeField.value.obscureBackground;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.obscureBackground === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.obscureBackground = val;
  }
});

const comb = computed({
  get() {
    return activeField.value.comb;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.comb === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.comb = val;
  }
});

const parentQuestion = computed({
  get() {
    return activeField.value.parentQuestion;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.parentQuestion === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.parentQuestion = val;
  }
});

const requiredParentValue = computed({
  get() {
    return activeField.value.requiredParentValue;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.requiredParentValue == val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.requiredParentValue = val;
  }
});

const quoteAndApplyType = computed({
  get() {
    return activeField.value.quoteAndApplyType;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.quoteAndApplyType === val;
    if (isUnchanged) return;

    currentFieldHasChanges.value = true;
    if (pdfFieldType.value === NOT_ON_PDF_OPTION_TYPE) {
      const shouldAddVirtual = [
        RADIO_OPTION_TYPE,
        SELECT_OPTION_TYPE,
        CHECKBOX_OPTION_TYPE
      ].includes(val);
      if (shouldAddVirtual) {
        activeField.value.coordinates = [new AqlField({ virtual: true })];
      } else {
        activeField.value.coordinates = [];
      }
    }
    activeField.value.quoteAndApplyType = val;
  }
});

const verbatimQuestion = computed({
  get() {
    return activeField.value.verbatimQuestion;
  },
  set(val) {
    if (!isMounted.value) return;
    const isUnchanged = activeField.value.verbatimQuestion === val;
    if (isUnchanged) return;
    currentFieldHasChanges.value = true;
    activeField.value.verbatimQuestion = val;
  }
});

const insuredIndex = computed({
  get() {
    return activeField.value.insuredIndex;
  },
  set(val) {
    if (!isMounted.value) return;

    const isUnchanged = activeField.value.insuredIndex === val;
    if (isUnchanged) return;

    currentFieldHasChanges.value = true;
    activeField.value.insuredIndex = val;
  }
});

const isVerbatimQuestion = computed(
  () => applicationQuestion.value?.name === VERBATIM_QUESTION
);

const disabledFields = computed(() => !readonly.value && !referenceField.value);
const disabledRequiredOf = computed(() => {
  if (!applicationQuestion.value?.name) return false;

  let requiredOf = "";
  if (applicationQuestion.value?.requiredOf) {
    requiredOf = JSON.parse(applicationQuestion.value?.requiredOf);
  }

  return docusignRequiredOf.value === requiredOf;
});

const isPdfCheckbox = computed(
  () => pdfFieldType.value === CHECKBOX_OPTION_TYPE
);

const isQnaCheckbox = computed(
  () => quoteAndApplyType.value === CHECKBOX_OPTION_TYPE
);

const isPdfRadio = computed(() => pdfFieldType.value === RADIO_OPTION_TYPE);

const isQnaRadio = computed(
  () => quoteAndApplyType.value === RADIO_OPTION_TYPE
);

const isQnaSelect = computed(
  () => quoteAndApplyType.value === SELECT_OPTION_TYPE
);

const isNotOnPdfType = computed(
  () => pdfFieldType.value === NOT_ON_PDF_OPTION_TYPE
);

const isPdfTextType = computed(() => pdfFieldType.value === TEXT_OPTION_TYPE);

const availableQuoteAndApplyFieldTypes = computed(() => {
  if (isPdfCheckbox.value || isPdfRadio.value) {
    return QUOTE_AND_APPLY_FIELD_TYPES.filter(v => v.radioCompatible);
  }
  if (isPdfTextType.value) {
    return QUOTE_AND_APPLY_FIELD_TYPES.filter(v => v.textCompatible);
  }
  return QUOTE_AND_APPLY_FIELD_TYPES;
});

const renderFields = computed(() => {
  const pdfTypeNeedsOptions = isPdfRadio.value || isPdfCheckbox.value;

  const qnaTypeNeedsOptions =
    isQnaRadio.value || isQnaCheckbox.value || isQnaSelect.value;

  let showFieldOptions = pdfTypeNeedsOptions || qnaTypeNeedsOptions;

  const showQuoteAndApplyType =
    !isDocusignField.value && isVerbatimQuestion.value && !isPdfCheckbox.value;

  if (showQuoteAndApplyType && !quoteAndApplyType.value) {
    showFieldOptions = false;
  } else if (isNotOnPdfType.value) {
    showFieldOptions = qnaTypeNeedsOptions;
  }

  return {
    saveButton: !readonly.value,
    unlinkButton: !readonly.value && Boolean(referenceField.value),
    docusignField:
      isNotOnPdfType.value && !isTiaField.value && !isContracting.value,
    comb: isPdfTextType.value,
    parentQuestion: isVerbatimQuestion.value && !isDocusignField.value,
    obscureBackground: isPdfTextType.value,
    docusignRequired: isDocusignField.value && isNotOnPdfType.value,
    docusignRequiredOf: isDocusignField.value && isNotOnPdfType.value,
    insuredIndex: !isDocusignField.value && isVerbatimQuestion.value,
    tiaField: !isDocusignField.value && !isContracting.value,
    quoteAndApplyType: showQuoteAndApplyType,
    requiredValue:
      !isDocusignField.value && isVerbatimQuestion.value && showFieldOptions,
    requiredParentValue: Boolean(parentApplicationQuestionLink.value),
    fieldOptions: showFieldOptions,
    smartApplicationQuestion: !isContracting.value,
    verbatimQuestion: isVerbatimQuestion.value && !isDocusignField.value,
    additionalForm:
      isNotOnPdfType.value && !isContracting.value && !isDocusignField.value
  };
});

function clearDocusignDependentFields() {
  docusignRequiredOf.value = null;
  isDocusignRequired.value = null;
}

function pdfFieldTypeConversion(newType) {
  const coordCount = activeField.value.coordinates.length;
  const oldType = activeField.value.pdfFieldType;

  // we don't want to override the required yes/no values
  if (isQnaCheckbox.value || isQnaRadio.value) {
    if ([CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(newType)) {
      //we want to give it positional data
      activeField.value.coordinates.forEach((_, index) => {
        activeField.value.coordinates[index].x =
          5 + RADIO_OPTION_DIAMETER * index + 1;
        activeField.value.coordinates[index].y = viewport.value.height - 55;
      });
    } else if (newType === NOT_ON_PDF_OPTION_TYPE) {
      // we want to remove positional data
      activeField.value.coordinates.forEach((_, index) => {
        delete activeField.value.coordinates[index].x;
        delete activeField.value.coordinates[index].y;
      });
    }
    return;
  }

  // yuck...
  if ([CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(newType)) {
    // clear coordinates and create new box coordinate
    activeField.value.coordinates.splice(0, coordCount);
  } else if (newType === TEXT_OPTION_TYPE) {
    activeField.value.coordinates.splice(0, coordCount);
    nextTick(() => {
      activeField.value.coordinates.push({
        page: currentPage.value,
        x: 5,
        y: viewport.value.height - 55,
        width: 100,
        height: 50,
        order: activeField.value.coordinates.length + 1,
        uuid: uuidv4()
      });
    });
  } else if (
    newType === NOT_ON_PDF_OPTION_TYPE &&
    [CHECKBOX_OPTION_TYPE, RADIO_OPTION_TYPE].includes(oldType)
  ) {
    activeField.value.coordinates.forEach((_, index) => {
      delete activeField.value.coordinates[index].x;
      delete activeField.value.coordinates[index].y;
    });
    // clear positional data from coordinates
  } else if (
    newType === NOT_ON_PDF_OPTION_TYPE &&
    oldType === TEXT_OPTION_TYPE
  ) {
    activeField.value.coordinates.splice(0, coordCount);
  }
}

const validationScope = "mapped-form-view-aql";
const v$ = useVuelidate(
  {
    applicationQuestion: {
      required: val => Boolean(val?.id)
    },
    requiredValue: {
      required: val => {
        if (!renderFields.value.requiredValue || !isTiaField.value) return true;
        return Boolean(val) || val === 0;
      }
    },
    question: {
      required: false
    },
    requiredParentValue: {
      required: value =>
        !renderFields.value.requiredParentValue || Boolean(value)
    },
    quoteAndApplyType: {
      required: value => !renderFields.value.quoteAndApplyType || Boolean(value)
    },
    verbatimQuestion: {
      required: value => !renderFields.value.verbatimQuestion || Boolean(value)
    }
  },
  {
    applicationQuestion,
    requiredValue,
    question,
    requiredParentValue,
    quoteAndApplyType,
    verbatimQuestion
  },
  { $scope: validationScope, $autoDirty: true }
);

const applicationQuestionValidation = computedValidation(
  v$.value.applicationQuestion,
  { required: "Required" }
);
const requiredParentValueValidation = computedValidation(
  v$.value.requiredParentValue,
  { required: "Required" }
);
const quoteAndApplyTypeValidation = computedValidation(
  v$.value.quoteAndApplyType,
  { required: "Required" }
);
const verbatimQuestionValidation = computedValidation(
  v$.value.verbatimQuestion,
  { required: "Required" }
);
const requiredValueValidation = computedValidation(v$.value.requiredValue, {
  required: "Required"
});

function questionSpecificText(item) {
  let page;
  if (item.coordinates.length) {
    page = item.coordinates.find(({ page }) => Boolean(page))?.page;
  }
  const pageNum = page || null;
  let additionalText = item.applicationQuestion?.name;
  if (item.verbatimQuestion) {
    additionalText = item.verbatimQuestion.substring(0, 12);
  }

  return [pageNum, item.id, additionalText].filter(Boolean).join(" ");
}

async function save() {
  const isValid = await v$.value.$validate();

  if (!isValid) {
    const invalidAttrs = new Set();
    Object.keys(v$.value).forEach(k => {
      if (k.startsWith("$") || k.startsWith("_")) return;
      if (v$.value[k].$invalid) invalidAttrs.add(k);
    });

    v$.value.$errors.forEach(v => {
      if (["valueModel", "textModel"].includes(v.$property)) {
        invalidAttrs.add("Field Options");
      }
    });

    snackbar.showErrorSnackbar({
      message: `Invalid attributes detected: ${Array.from(invalidAttrs).join(
        ","
      )}`
    });
    return;
  }

  saving.value = true;
  try {
    await formStore.updateApplicationQuestionLink(activeField.value);
    snackbar.showSuccessSnackbar({
      message: "Saved Application Question Link"
    });
    currentFieldHasChanges.value = false;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    saving.value = false;
  }
}

v$.value.$validate();
onMounted(() => nextTick(() => (isMounted.value = true)));

watch(v$, v => (currentFieldIsValid.value = !v.$invalid), {
  deep: true,
  immediate: true
});
</script>
