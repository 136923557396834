<template>
  <v-card>
    <v-card-text>
      <v-data-table
        must-sort
        data-testid="advisor-table"
        :headers="table.tableHeaders.value"
        :items="table.mappedItems.value"
        :server-items-length="table.meta.value.total"
        :loading="table.loading.value"
        :options="table.options.value"
        :footer-props="footerProps"
        @update:options="updateOptions"
      >
        <template #top>
          <v-row class="ma-0" align="center">
            <h1 class="text-h5" data-testid="advisor-table-title">
              <template v-if="props.affiliation?.id">
                Advisors affiliated with {{ props.affiliation.name }}
              </template>
              <template v-else> Advisors </template>
            </h1>
            <v-spacer />
            <v-tooltip v-if="table.filter.value.view !== 'all'" top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="advisor-table-email-data"
                  @click="confirmAndEmailData"
                >
                  <v-icon>{{ mdiFileExport }}</v-icon>
                </v-btn>
              </template>
              <span>Email Data</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  data-testid="advisor-table-refresh"
                  @click="getData"
                >
                  <v-icon>{{ mdiRefresh }}</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-row>
          <generic-table-filter-header
            :value="table.filter.value"
            :headers="table.filterHeaders.value"
            :loading="table.loading.value"
            @update="updateFilter"
          />
        </template>

        <template #[`item.createdAt`]="{ item }">
          <timestamp-formatter :value="item.createdAt" />
        </template>
        <template #[`item.lastSubmit`]="{ item }">
          <timestamp-formatter :value="item.lastSubmit" />
        </template>
        <template #[`item.ytdInforce`]="{ item }">
          <currency-formatter :value="item.ytdInforce" :decimal-length="0" />
        </template>
        <template #[`item.pending`]="{ item }">
          <currency-formatter :value="item.pending" :decimal-length="0" />
        </template>
        <template #[`item.email`]="{ item }">
          <a :href="`mailto:${item.email}`"> {{ item.email }} </a>
        </template>
        <template #[`item.phone`]="{ item }">
          <a
            :href="`tel:${item.phone}`"
            :data-outbound-type="
              item.additional.link === 'agents' ? 'Agent' : 'Agency'
            "
            :data-outbound-id="item.additional.id"
            :data-outbound-number="item.phone"
          >
            {{ item.phone }}
          </a>
        </template>
        <template #[`item.name`]="{ item }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                class="text-none"
                color="primary"
                depressed
                small
                :to="{
                  name:
                    item.additional.link === 'agents'
                      ? 'AgentView'
                      : 'AgencyView',
                  params: {
                    id: item.additional.id
                  }
                }"
              >
                <v-icon
                  v-if="item.additional.link === 'agents'"
                  class="mr-1"
                  small
                >
                  {{ mdiAccountTie }}
                </v-icon>
                <v-icon v-else class="mr-1" small> {{ mdiDomain }} </v-icon>
                {{ item.name }}
              </v-btn>
            </template>
            <span> View {{ item.name }}'s Profile </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import CurrencyFormatter from "@/components/shared/formatters/CurrencyFormatter.vue";
import GenericTableFilterHeader from "@/components/shared/data-table/GenericTableFilterHeader.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import TableOptions from "@/classes/data-table/TableOptions";

import { parseErrorMessage } from "@/util/helpers";
import { states } from "@/data/states";
import { ADVISOR_CATEGORIES } from "@/data/advisor-data";

import { emailAdvisors, getAdvisors } from "@/api/advisors.service";

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useTableStore } from "@/stores/table";

import { useTable } from "@/composables/table.composable";
import { computed, defineProps } from "vue";
import { mdiFileExport, mdiRefresh, mdiAccountTie, mdiDomain } from "@mdi/js";

const props = defineProps({
  affiliation: { type: Object, required: false, default: () => ({}) }
});

const table = useTable({
  getData: getAdvisors,
  emailData: emailAdvisors
});

const userStore = useUserStore();
if (!props.affiliation?.id) {
  table.headers.value.push(
    new TableHeader({
      text: "Type",
      value: "type",
      map: "type",
      sortFilterMap: "model",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_MANDATORY,
      selectableOptions: [
        { text: "Agents", value: "agents" },
        { text: "Agencies", value: "agencies" }
      ]
    })
  );
}
table.headers.value.push(
  new TableHeader({
    text: "Name",
    value: "name",
    map: "name",
    sortFilterMap: "name",
    ...TableHeader.IS_STRING_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_QUERY_FILTER
  })
);

if (userStore.isGroupTwoPlus) {
  table.headers.value.push(
    new TableHeader({
      text: "View",
      value: "view",
      map: "view",
      sortFilterMap: "view",
      selectableOptions: [
        { text: "All Advisors", value: "all" },
        { text: "My Advisors", value: "my" }
      ],
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_MANDATORY,
      ...TableHeader.IS_QUERY_FILTER,
      order: 1
    }),
    new TableHeader({
      text: "Manager",
      value: "manager",
      map: "marketingManagerName",
      sortKey: "marketing_manager_id", //TODO ~ use name instead
      sortFilterMap: [
        { key: "marketing_manager_id", value: "marketing_manager_id" }
      ],
      ...TableHeader.IS_MARKETING_MANAGER_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    })
  );
}

table.headers.value.push(
  new TableHeader({
    text: "Email",
    value: "email",
    map: "email",
    sortFilterMap: "email",
    ...TableHeader.IS_STRING_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_QUERY_FILTER
  }),
  new TableHeader({
    text: "Phone",
    value: "phone",
    map: "phone",
    sortFilterMap: "phone_work",
    ...TableHeader.IS_STRING_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_QUERY_FILTER
  }),
  new TableHeader({
    text: "State",
    value: "state",
    map: "state",
    selectableOptions: states.map(val => ({
      text: val.full,
      value: val.text
    })),
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_AUTOCOMPLETE_FILTER_TYPE,
    ...TableHeader.IS_QUERY_FILTER
  }),
  new TableHeader({
    text: "YTD Inforce",
    value: "ytdInforce",
    map: "ytd",
    sortFilterMap: "ytd_inforce",
    ...TableHeader.IS_STRING_FILTER_TYPE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Pending",
    value: "pending",
    map: "pending",
    sortFilterMap: "pending",
    ...TableHeader.IS_STRING_FILTER_TYPE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Last Submit",
    value: "lastSubmit",
    map: "lastSubmit",
    sortFilterMap: "last_submit",
    ...TableHeader.IS_DATE_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE
  }),
  new TableHeader({
    text: "Created",
    value: "createdAt",
    map: "createdAt",
    sortFilterMap: "created_at",
    ...TableHeader.IS_DATE_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_SORTABLE,
    ...TableHeader.IS_QUERY_FILTER
  }),
  new TableHeader({
    text: "NPN",
    value: "npn",
    map: "npn",
    sortFilterMap: "npn",
    ...TableHeader.IS_ADDITIONAL,
    ...TableHeader.IS_NUMBER_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_QUERY_FILTER
  }),
  new TableHeader({
    text: "Has CRD Number",
    value: "hasCrdNumber",
    map: "hasCrdNumber",
    sortFilterMap: "has_crd_number",
    selectableOptions: [
      { text: "Yes", value: true },
      { text: "No", value: false }
    ],
    ...TableHeader.SEND_FALSE,
    ...TableHeader.IS_SELECT_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_QUERY_FILTER,
    ...TableHeader.IS_ADDITIONAL,
    showIf: filters => filters.type === "agents"
  })
);

if (!props.affiliation?.id) {
  table.headers.value.push(
    new TableHeader({
      text: "Affiliated With",
      value: "affiliatedWith",
      map: "affiliatedWith",
      sortFilterMap: [{ key: "affiliation_id", value: "id" }],
      ...TableHeader.IS_AFFILIATION_SEARCH_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      showIf: filters => filters.type === "agents" && userStore.isGroupTwoPlus
    })
  );
}

table.headers.value.push(
  new TableHeader({
    text: "Shared With",
    value: "additionalAdvisor",
    sortFilterMap: [
      { key: "shared_with_id", value: "id" },
      { key: "shared_with_type", value: "type" }
    ],
    ...TableHeader.IS_QUERY_FILTER,
    ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
    ...TableHeader.IS_FILTERABLE,
    ...TableHeader.IS_ADDITIONAL
  })
);

if (userStore.isGroupTwoPlus) {
  table.headers.value.push(
    new TableHeader({
      text: "Category",
      value: "category",
      map: "category",
      sortFilterMap: "category",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER,
      ...TableHeader.IS_ADDITIONAL,
      selectableOptions: ADVISOR_CATEGORIES
    })
  );
}

let filter = {};
if (userStore.isGroupThreePlus) {
  filter.view = "all";
} else if (userStore.isGroupTwoPlus) {
  filter.view = "my";
}

const { advisorsTable } = storeToRefs(useTableStore());
// agents type is assumed for affiliations, see getData()
if (!props.affiliation?.id) {
  if (advisorsTable.value.filter) {
    Object.keys(advisorsTable.value.filter).forEach(key => {
      if (!table.headers.value.find(header => header.value === key)) return;
      filter[key] = advisorsTable.value.filter[key];
    });
  }
  if (!["agents", "agencies"].includes(filter.type)) filter.type = "agents";
}

table.filter.value = filter;

if (Object.keys(advisorsTable.value.options).length) {
  table.options.value = advisorsTable.value.options;
} else {
  table.options.value = new TableOptions(["createdAt"], [true]);
}

const footerProps = computed(() => {
  return {
    pageText: table.pageTextFormatter(table.options.value, table.meta.value),
    itemsPerPageOptions: [10, 20, 35, 50]
  };
});

function confirmAndEmailData() {
  const dialog = useDialogStore();
  dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Email Data?",
    subtitle: "Please confirm you would like this data emailed to you.",
    func: emailData
  });
}

async function emailData() {
  const snackbar = useSnackbarStore();
  try {
    const additionalFilter = {};
    if (userStore.isGroupOne) additionalFilter.view = "my";
    if (props.affiliation?.id) {
      additionalFilter.affiliation_id = props.affiliation.id;
      additionalFilter.model = "agents";
    }
    await table.emailData(additionalFilter);
    snackbar.showSuccessSnackbar({
      message: `You will receive a copy of this data in your inbox shortly.`,
      timeout: 6000
    });
  } catch (error) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(error),
      timeout: -1
    });
  }
}

async function getData() {
  const additionalFilter = {};
  if (userStore.isGroupOne) additionalFilter.view = "my";
  if (props.affiliation?.id) {
    additionalFilter.affiliation_id = props.affiliation.id;
    additionalFilter.model = "agents";
  }
  try {
    await table.getData(additionalFilter);
  } catch (e) {
    const snackbar = useSnackbarStore();
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateOptions(newOptions) {
  if (table.optionsEquivalence(newOptions)) return;
  table.options.value = newOptions;
  advisorsTable.value.options = newOptions;
  getData();
}

function updateFilter(filter) {
  table.filter.value = filter;
  advisorsTable.value.filter = filter;
  table.resetPage();
  getData();
}

getData();
</script>
