<template>
  <v-data-table
    data-testid="parties-table"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :options="table.options.value"
    :loading="loadingCaseTransactions"
  >
    <template #[`item.paymentTypes`]="{ item }">
      <p v-for="type in item.paymentTypes" :key="type">
        {{ type }}
      </p>
    </template>
  </v-data-table>
</template>

<script setup>
import TableHeader from "@/classes/data-table/TableHeader";
import { useTable } from "@/composables/table.composable";
import { watch } from "vue";
import { useTransactionCreate } from "@/stores/transaction-create";
import { storeToRefs } from "pinia";
import { currencyFormat } from "@/util/helpers";

const transactionCreateStore = useTransactionCreate();
const { parties, loadingCaseTransactions } = storeToRefs(
  transactionCreateStore
);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Payable",
      value: "payable",
      map: "payable.name"
    }),
    new TableHeader({
      text: "Amount",
      value: "amount",
      map: "amount"
    }),
    new TableHeader({
      text: "Payment Types",
      value: "paymentTypes",
      map: "paymentTypes"
    })
  ]
});

watch(
  parties,
  () => {
    table.items.value.splice(0, table.items.value.length);
    parties.value.forEach(p => {
      table.items.value.push({
        ...p,
        amount: currencyFormat(p.amount, 2)
      });
    });
  },
  { immediate: true }
);
</script>
