import { setDocumentFromRequest } from "@/factories/Document";
import { setNoteFromRequest } from "@/factories/Note";
import ContractPartyFactory from "@/factories/ContractPartyFactory";
import CarrierFactory from "@/factories/CarrierFactory";
import { uuidv4, getRouterLink } from "@/util/helpers";
import { Exam, Task, PhysicianStatement } from "@/factories/Task";
import { setInformalOfferFromRequest } from "@/factories/InformalOffer";

export const LINES = [
  { text: "Annuity", value: "annuity" },
  { text: "Disability", value: "disability" },
  { text: "Linked Benefit", value: "linked_benefit" },
  { text: "Life", value: "life" },
  { text: "LTC", value: "ltc" },
  { text: "Variable Life", value: "variable_life" }
];

export const CASE_DOCUMENT_CATEGORIES = [
  { text: "Other", value: null },
  { text: "APS", value: "APS" }
];

export function Case(model = {}) {
  return {
    address: model?.address || null,
    addresses: model?.addresses || [],
    advisors: model?.advisors || [],
    appliedMode: model?.appliedMode || null,
    appliedPremium: model?.appliedPremium || null,
    appliedPremiumTotal: model?.appliedPremiumTotal || null,
    appointmentCases: model?.appointmentCases || [],
    approvedPremium: model?.approvedPremium || 0,
    approvedPremiumTotal: model?.approvedPremiumTotal || 0,
    assignOptions: model?.assignOptions || [],
    benefitAmount: model?.benefitAmount || null,
    benefitMode: model?.benefitMode || null,
    benefitPeriod: model?.benefitPeriod || null,
    carrier: model?.carrier || null,
    carrierEDelivery: model?.carrierEDelivery || null,
    caseConcierge: model?.caseConcierge || null,
    caseManager: model?.caseManager || null,
    cashReceived: model?.cashReceived || null,
    chronicIllnessRider: model?.chronicIllnessRider || null,
    commission: model?.commission || null,
    commissionable: model?.commissionable || null,
    contractParties: model?.contractParties || [],
    conversionExpiry: model?.conversionExpiry || null,
    daysPending: model?.daysPending || null,
    documents: model?.documents || [],
    electronicApplication: model?.electronicApplication || null,
    eliminationPeriod: model?.eliminationPeriod || null,
    exchangeAmount: model?.exchangeAmount || null,
    faceAmount: model?.faceAmount || null,
    gaCode: model?.gaCode || null,
    id: model?.id || null,
    inflation: model?.inflation || null,
    informals: model?.informals || null,
    line: model?.line || null,
    ltcRider: model?.ltcRider || null,
    lumpSum: model?.lumpSum || null,
    messageClient: model?.messageClient || null,
    mode: model?.mode || null,
    nextCallAt: model?.nextCallAt || null,
    notes: model?.notes || [],
    outstanding: model?.outstanding || null,
    policyNumber: model?.policyNumber || null,
    premium: model?.premium || null,
    product: model?.product || null,
    productionCreditTotal: model?.productionCreditTotal || null,
    products: model?.products || [],
    quotes: model?.quotes || [],
    requirements: model?.requirements || [],
    room: model?.room || [],
    saveAge: model?.saveAge || false,
    sameManagerForParty: model?.sameManagerForParty || false,
    showApprovedPremium: model?.showApprovedPremium || false,
    state: model?.state || null,
    status: model?.status || null,
    surrenderChargeExpiration: model?.surrenderChargeExpiration || null,
    targetPremium: model?.targetPremium || null,
    taxType: model?.taxType || null,
    temporaryInsurance: model?.temporaryInsurance || false,
    tia: model?.tia || null,
    timeline: model?.timeline || [],
    totalPremium: model?.totalPremium || null,
    trackingNumber: model?.trackingNumber || null,
    trackingNumberLink: model?.trackingNumberLink || null,
    trackingNumberType: model?.trackingNumberType || null,
    transactions: model?.transactions || []
  };
}

export function setCaseFromRequest(raw = {}) {
  const model = Case();
  model.addresses = raw?.addresses;
  model.appliedMode = raw?.applied_mode;
  model.appliedPremium = raw?.applied_premium?.premium;
  model.appliedPremiumTotal = raw?.applied_premium?.total;
  model.approvedPremium = raw?.premium?.premium;
  model.approvedPremiumTotal = raw?.premium?.total;
  model.benefitAmount = raw?.benefit_amount;
  model.benefitMode = raw?.benefit_mode;
  model.benefitPeriod = raw?.benefit_period;
  model.carrierEDelivery = raw?.carrier_e_delivery;
  model.cashReceived = raw?.cash_received;
  model.chronicIllnessRider = raw?.chronic_illness_rider;
  model.commission = raw?.commission;
  model.commissionable = raw?.commissionable;
  model.conversionExpiry = raw?.conversion_expiry;
  model.daysPending = raw?.days_pending;
  model.electronicApplication = raw?.electronic_application;
  model.eliminationPeriod = raw?.elimination_period;
  model.exchangeAmount = raw?.exchange_amount;
  model.faceAmount = raw?.face_amount;
  model.gaCode = raw?.ga_code;
  model.id = raw?.id;
  model.inflation = raw?.inflation;
  model.line = raw?.line;
  model.ltcRider = raw?.ltc_rider;
  model.lumpSum = raw?.lump_sum;
  model.mode = raw?.mode;
  model.messageClient = raw?.message_client;
  model.nextCallAt = raw?.next_call_at;
  model.outstanding = raw?.outstanding;
  model.policyNumber = raw?.policy_number;
  model.premium = raw?.premium?.premium;
  model.productionCreditTotal = raw?.production_credit_total;
  model.sameManagerForParty = raw?.same_manager_for_party;
  model.saveAge = raw?.save_age;
  model.state = raw?.state;
  model.status = raw?.status;
  model.surrenderChargeExpiration = raw?.surrender_charge_expiration;
  model.targetPremium = raw?.target_premium;
  model.taxType = raw?.tax_type;
  model.tia = raw?.tia;
  model.totalPremium = raw?.premium?.total;
  model.trackingNumber = raw?.tracking_number;
  model.trackingNumberLink = raw?.tracking_number_link;
  model.trackingNumberType = raw?.tracking_number_type;

  model.address = {
    text: raw?.address?.name,
    value: raw?.address?.id
  };
  model.assignOptions = (raw?.assign_options || []).filter(
    v => v.type !== "Vendor"
  ); //Remove once

  model.product = setCaseProductFromRequest(raw?.product);

  model.carrier = new CarrierFactory();
  model.carrier.setFromRequest(raw?.carrier || raw?.product?.carrier);

  model.requirements = [];
  raw?.requirements?.forEach(req => {
    model.requirements.push(setCaseTaskPreviewFromRequest(req));
  });

  model.products = [];
  raw?.products?.forEach(rawProduct => {
    model.products.push(setCaseProductFromRequest(rawProduct));
  });

  model.appointmentCases = [];
  if (raw?.appointment_cases?.length) {
    raw.appointment_cases.forEach(c => {
      model.appointmentCases.push(setAppointmentCaseFromRequest(c));
    });
  }

  model.documents = raw?.documents?.map(setDocumentFromRequest);

  model.timeline = [];
  raw?.timeline?.forEach(rawTime => {
    model.timeline.push(setTimelineItemFromRequest(rawTime));
  });

  model.room = [];
  raw?.room?.forEach(rawAdvisor => {
    model.room.push(setCaseRoomAccessFromRequest(rawAdvisor));
  });

  model.caseManager = model.room.find(c => c.caseManager === true);
  model.caseConcierge = model.room.find(c => c.caseConcierge === true);

  model.notes = [];
  raw?.notes?.forEach(rawNote => {
    model.notes.push(setNoteFromRequest(rawNote));
  });
  model.notes.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  model.contractParties = [];
  raw?.contract_parties?.forEach(rawParty => {
    const party = new ContractPartyFactory();
    party.setFromRequest(rawParty);
    model.contractParties.push(party);
  });

  model.informals = [];
  raw?.informals?.forEach(rawInformal => {
    model.informals.push(setInformalOfferFromRequest(rawInformal));
  });

  model.quotes = [];
  if (raw?.quotes) {
    model.quotes = raw.quotes;
  }

  return model;
}

function CaseProduct(model = {}) {
  return {
    id: model?.id,
    chronicIllnessRider: model?.chronicIllnessRider,
    ltcRider: model?.ltcRider,
    line: model?.line,
    planType: model?.planType,
    name: model?.name
  };
}

function setCaseProductFromRequest(raw) {
  const model = CaseProduct();

  model.id = raw?.id;
  model.chronicIllnessRider = raw?.chronic_illness_rider;
  model.ltcRider = raw?.ltc_rider;
  model.line = raw?.line;
  model.planType = raw?.plan_type;
  model.name = raw?.name;

  return model;
}

export function TimelineItem(model = {}) {
  return {
    date: model?.date || null,
    description: model?.description || null,
    key: model?.key || null
  };
}

function setTimelineItemFromRequest(raw = {}) {
  const model = TimelineItem();
  model.date = raw?.date;
  model.description = raw?.description;
  model.key = raw?.key;
  return model;
}

export function CaseRevenue(model = {}) {
  return {
    statementId: model?.statementId,
    payPeriodId: model?.payPeriodId,
    paidTo: model?.paidTo,
    payor: model?.payor,
    type: model?.type,
    statementDate: model?.statementDate,
    split: model?.split,
    premium: model?.premium,
    commissionPercent: model?.commissionPercent,
    commission: model?.commission,
    key: model?.key
  };
}

export function setCaseRevenueFromRequest(rawModel = {}) {
  const caseRevenue = CaseRevenue();
  caseRevenue.payPeriodId = rawModel?.pay_period_id;
  caseRevenue.paidTo = rawModel?.paid_to;
  caseRevenue.payor = rawModel?.payor;
  caseRevenue.type = rawModel?.type;
  caseRevenue.paymentType = rawModel?.payment_type;
  caseRevenue.statementDate = rawModel?.statement_date;
  caseRevenue.split = rawModel?.split;
  caseRevenue.premium = rawModel?.premium;
  caseRevenue.commissionPercent = rawModel?.commission_percent;
  caseRevenue.commission = rawModel?.commission;
  caseRevenue.statementId = rawModel?.statement_id;
  caseRevenue.key = uuidv4();
  return caseRevenue;
}

export function CaseTransaction(model = {}) {
  return {
    transactionId: model?.transactionId,
    commissionType: model?.commissionType,
    totalPoints: model?.totalPoints,
    amount: model?.amount,
    statementDate: model?.statementDate,
    createdAt: model?.createdAt,
    override: model?.override,
    directCommission: model?.directCommission,
    statementId: model?.statementId,
    createdBy: model?.createdBy,
    memo: model?.memo
  };
}

export function setCaseTransactionFromRequest(rawModel = {}) {
  const caseTransaction = CaseTransaction();

  caseTransaction.id = rawModel?.id;
  caseTransaction.commissionType = rawModel?.commission_type;
  caseTransaction.totalPoints = rawModel?.total_points;
  caseTransaction.amount = rawModel?.amount;
  caseTransaction.statementDate = rawModel?.statement_date;
  caseTransaction.createdAt = rawModel?.created_at;
  caseTransaction.override = rawModel?.override;
  caseTransaction.directCommission = rawModel?.direct_commission;
  caseTransaction.statementId = rawModel?.statement_id;
  caseTransaction.createdBy = rawModel?.created_by;
  caseTransaction.memo = rawModel?.memo;
  caseTransaction.key = uuidv4();

  return caseTransaction;
}

export function AppointmentCase(model = {}) {
  return {
    appointmentId: model.appointmentId || 0,
    advisorType: model?.advisorType || "",
    advisorId: model?.advisorId || 0,
    advisorName: model?.advisorName || "",
    advisorPhone: model?.advisorPhone || "",
    backNineAppointment: model?.backNineAppointment || false,
    insuredName: model?.insuredName || "",
    splitRenewal: model?.splitRenewal || null,
    splitPercent: model?.splitPercent || null,
    marketingManagerId: model?.marketingManagerId || 0,
    marketingManagerName: model?.marketingManagerName || "",
    caseId: model?.caseId || 0,
    appointmentCode: model?.appointmentCode || "",
    appointmentCaseId: model?.appointmentCaseId || 0,
    assignmentName: model?.assignmentName || "",
    assignmentId: model?.assignmentId || 0,
    appointmentRouterLink: model?.appointmentRouterLink || null,
    appointmentAssignmentRouterLink:
      model?.appointmentAssignmentRouterLink || null,
    advisorProfileLink: model?.advisorProfileLink || null,
    advisorSettingsLink: model?.advisorSettingsLink || null
  };
}

export function setAppointmentCaseFromRequest(model = {}) {
  const apptCase = AppointmentCase();

  apptCase.backNineAppointment = model?.commissionable;
  apptCase.appointmentId = model?.appointment?.id;
  apptCase.appointmentCode = model?.appointment?.name;
  apptCase.insuredName = model?.insured?.name;
  apptCase.advisorId = model?.advisor?.id;
  apptCase.advisorType = model?.advisor?.type?.model;
  apptCase.advisorName = model?.advisor?.name;
  apptCase.advisorPhone = model?.advisor?.phone_work;
  apptCase.assignmentName = model?.appointment?.assignment?.name;
  apptCase.assignmentId = model?.appointment?.assignment?.id;
  apptCase.appointmentCaseId = model.id;
  apptCase.marketingManagerId = model?.marketing_manager?.id;
  apptCase.marketingManagerName = model?.marketing_manager?.name;
  apptCase.splitRenewal = Math.trunc((model?.renewal_split || 0) * 100);
  apptCase.splitPercent = Math.trunc((model?.split_percent || 0) * 100);

  if (apptCase.advisorType) {
    const isAgency = apptCase.advisorType === "Agency";
    apptCase.ssnOrTin = isAgency ? "tin" : "ssn";
    apptCase.advisorProfileLink = getRouterLink(
      apptCase.advisorType,
      apptCase.advisorId
    );
    apptCase.advisorSettingsLink = getRouterLink(
      `${apptCase.advisorType}Settings`,
      apptCase.advisorId
    );
  }

  if (apptCase.appointmentId) {
    apptCase.appointmentRouterLink = getRouterLink(
      "Appointment",
      apptCase.appointmentId
    );
  }

  if (apptCase.assignmentId) {
    apptCase.appointmentAssignmentRouterLink = getRouterLink(
      "Appointment",
      apptCase.assignmentId
    );
  }

  return apptCase;
}

export function setRequestFromAppointmentCase(appointmentCase) {
  const ac = AppointmentCase(appointmentCase);
  return {
    appointment_id: ac.appointmentCode,
    case_id: ac.caseId,
    split_percent: (Number(ac.splitPercent) / 100).toFixed(4),
    renewal_split: (Number(ac.splitRenewal) / 100).toFixed(4)
  };
}

export function setUpdateRequestFromAppointmentCase(appointmentCase) {
  const ac = AppointmentCase(appointmentCase);
  return {
    split_percent: ac.splitPercent / 100,
    renewal_split: ac.splitRenewal / 100,
    marketing_manager_id: ac.marketingManagerId,
    commissionable: Boolean(ac.backNineAppointment)
  };
}

function CaseRoomAccess(model = {}) {
  return {
    advisorAccess: model?.advisorAccess || null,
    agent: model?.agent || null,
    avatar: model?.avatar || null,
    canBeCaseManager: model?.canBeCaseManager || null,
    carrierAccess: model?.carrierAccess || null,
    carrierEmail: model?.carrierEmail || null,
    caseAccessId: model?.caseAccessId || null,
    caseConcierge: model?.caseConcierge || null,
    caseManager: model?.caseManager || null,
    disabled: model?.disabled || null,
    email: model?.email || null,
    id: model?.id || null,
    link: model?.link || null,
    name: model?.name || null,
    personnelDepartment: model?.personnelDepartment || null,
    phone: model?.phone || null,
    routerLink: model?.routerLink || null,
    type: model?.type || null,
    userEmail: model?.userEmail || null
  };
}

export function setCaseRoomAccessFromRequest(raw = {}) {
  const model = CaseRoomAccess();
  model.additionalCaseAccess = raw?.additional_case_access;
  model.advisorAccess = raw?.advisor_access;
  model.agent = raw?.agent;
  model.avatar = raw?.avatar_url;
  model.canBeCaseManager = raw?.can_be_case_manager;
  model.carrierAccess = raw?.carrier_access;
  model.carrierEmail = raw?.carrier_email;
  model.caseAccessId = raw?.case_access_id;
  model.caseConcierge = raw?.case_concierge;
  model.caseManager = raw?.case_manager;
  model.disabled = raw?.disabled;
  model.email = raw?.email;
  model.id = raw?.id;
  model.link = raw?.link;
  model.name = raw?.name;
  model.personnelDepartment = raw?.personnel_department;
  model.phone = raw?.phone;
  model.type = raw?.type;
  model.userEmail = raw?.advisor_email;

  if (model.type && model.id) {
    model.routerLink = getRouterLink(model.type, model.id);
  }

  return model;
}

export function CaseTask(model = {}) {
  return {
    assigned: {
      id: model?.assigned?.id || null,
      name: model?.assigned?.name || null,
      type: model?.assigned?.type || null
    },
    createdAt: model?.createdAt || null,
    completedAt: model?.completedAt || null,
    description: model?.description || null,
    documents: model?.documents || [],
    email: model?.email || false,
    followUpDate: model?.followUpDate || null,
    hidden: model?.hidden || false,
    id: model?.id || null,
    notes: model?.notes || [],
    orderId: model?.orderId || null,
    orderNumber: model?.orderNumber || null,
    ownableName: model?.ownableName || null,
    status: model?.status || null,
    type: model?.type || null,
    updatedAt: model?.updatedAt || null,
    vendor: model?.vendor || null
  };
}
export const CASE_TASK_TYPE = {
  TASK: "Task",
  EXAM: "Exam",
  PHYSICIAN_STATEMENT: "PhysicianStatement"
};

export const CASE_TASK_STATUS = {
  COMPLETE: "Completed",
  INCOMPLETE: "Outstanding"
};

export function setCaseTaskFromTask(t = {}, type) {
  let task;
  let includeOrderDetails = false;
  if (type === CASE_TASK_TYPE.TASK) {
    task = Task(t);
  } else if (type === CASE_TASK_TYPE.EXAM) {
    task = Exam(t);
    includeOrderDetails = true;
  } else if (type === CASE_TASK_TYPE.PHYSICIAN_STATEMENT) {
    task = PhysicianStatement(t);
    includeOrderDetails = true;
  } else {
    throw new Error("Invalid task type");
  }
  const caseTask = CaseTask();
  caseTask.assigned = {
    id: task.assigned.id,
    name: task.assigned.name,
    type: task.assigned.type
  };

  caseTask.createdAt = task.createdAt;
  caseTask.completedAt = task.completedAt;
  caseTask.description = task.description;
  caseTask.documents = task.documents;
  caseTask.email = task.email;
  caseTask.followUpDate = task.followUpDate;
  caseTask.hidden = task.hidden;
  caseTask.id = task.id;
  caseTask.notes = task.notes;
  caseTask.notes.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  caseTask.ownableName = task.ownable.name;
  caseTask.status = task.status;
  caseTask.type = type;
  caseTask.updatedAt = task.updatedAt;

  if (includeOrderDetails) {
    caseTask.orderId = task.orderId;
    caseTask.orderNumber = task.orderNumber;
    caseTask.vendor = task.vendor;
  }

  return caseTask;
}

export function CaseTaskPreview(model = {}) {
  return {
    status: model?.status,
    completedAt: model?.completedAt,
    createdAt: model?.createdAt,
    description: model?.description,
    followUp: model?.followUp,
    updatedAt: model?.updatedAt,
    email: model?.email,
    id: model?.id,
    type: model?.type,
    hidden: model?.hidden,
    get key() {
      return `${this.type}-${this.id}`;
    }
  };
}

export function setCaseTaskPreviewFromRequest(raw = {}) {
  const model = CaseTaskPreview();
  model.status = raw?.status;
  model.email = Boolean(raw?.email);
  model.description = raw?.description;
  model.id = raw?.id;
  model.type = raw?.type;
  model.hidden = raw?.hidden;

  if (raw?.dates?.completed_at) {
    model.completedAt = new Date(raw.dates.completed_at);
  }
  if (raw?.dates?.created_at) {
    model.createdAt = new Date(raw.dates.created_at);
  }
  if (raw?.dates?.updated_at) {
    model.updatedAt = new Date(raw.dates.updated_at);
  }
  if (raw?.dates?.follow_up_date) {
    model.followUp = new Date(raw.dates.follow_up_date);
  }

  return model;
}

export function setCaseTaskPreviewFromCaseTask(caseTask = CaseTask()) {
  const model = CaseTaskPreview();
  model.status = caseTask.status;
  model.description = caseTask.description;
  model.id = caseTask.id;
  model.type = caseTask.type;
  model.hidden = caseTask.hidden;
  model.email = caseTask.email;
  if (caseTask.completedAt) {
    model.completedAt = new Date(caseTask.completedAt);
  }
  if (caseTask.followUpDate) {
    model.followUp = new Date(caseTask.followUpDate);
  }
  if (caseTask.updatedAt) {
    model.updatedAt = new Date(caseTask.updatedAt);
  }
  if (caseTask.createdAt) {
    model.createdAt = new Date(caseTask.createdAt);
  }
  return model;
}

export function CaseSearchItem(model = {}) {
  return {
    id: model.id,
    line: model.line || null,
    policyNumber: model.policyNumber || null,
    carrier: CaseSearchItemCarrier(model?.carrier),
    appointmentCases: model.appointmentCases || []
  };
}

export function setCaseSearchItemFromRequest(raw = {}) {
  const model = CaseSearchItem();
  model.id = raw?.id;
  model.line = raw?.line;
  model.policyNumber = raw?.policy_number;
  model.appointmentCases = raw?.appointment_cases.map(
    setCaseSearchItemAppointmentCaseFromRequest
  );
  model.carrier = setCaseSearchItemCarrierFromRequest(raw?.carrier);

  return model;
}

function CaseSearchItemAppointmentCase(model = {}) {
  return {
    owner: CaseSearchItemAppointmentCaseOwner(model?.owner),
    marketingManager: CaseSearchItemAppointmentCaseMarketingManager(
      model?.marketingManager
    ),
    id: model?.id,
    splitPercent: model?.splitPercent
  };
}

function setCaseSearchItemAppointmentCaseFromRequest(raw = {}) {
  const model = CaseSearchItemAppointmentCase();
  model.owner = setCaseSearchItemAppointmentCaseOwnerFromRequest(raw?.owner);
  model.marketingManager = setCaseSearchItemAppointmentCaseMarketingManager(
    raw?.marketing_manager
  );
  model.id = raw?.id;
  model.splitPercent = raw?.split_percent;
  return model;
}

function CaseSearchItemAppointmentCaseMarketingManager(model = {}) {
  return {
    name: model?.name
  };
}

function setCaseSearchItemAppointmentCaseMarketingManager(raw = {}) {
  const model = CaseSearchItemAppointmentCaseMarketingManager();
  model.name = raw?.name;
  return model;
}

function CaseSearchItemAppointmentCaseOwner(model = {}) {
  return {
    avatarUrl: model?.avatarUrl,
    email: model?.email,
    id: model?.id,
    name: model?.name,
    npn: model?.npn,
    phoneWork: model?.phoneWork,
    title: model?.title,
    type: model?.type
  };
}

function setCaseSearchItemAppointmentCaseOwnerFromRequest(raw = {}) {
  const model = CaseSearchItemAppointmentCaseOwner();
  model.avatarUrl = raw?.avatar_url;
  model.email = raw?.email;
  model.id = raw?.id;
  model.name = raw?.name;
  model.npn = raw?.npn;
  model.phoneWork = raw?.phone_work;
  model.title = raw?.title;
  model.type = raw?.type?.model;
  return model;
}

function CaseSearchItemCarrier(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    payorId: model?.payorId
  };
}

function setCaseSearchItemCarrierFromRequest(raw = {}) {
  const model = CaseSearchItemCarrier();
  model.id = raw?.id;
  model.name = raw?.name;
  model.payorId = raw?.payor_id;
  return model;
}
