import { setActiveRollbarUser } from "@/logger";
import { useInstanceStore } from "@/stores/instance";

import { getHttpClient } from "@/http-client";
import { useUserStore } from "@/stores/user";
import { getMe } from "@/api/boss.service";
import { AUTH_STEPS } from "@/stores/user";

const baseUrl = "/auth";

function formatAffiliations(user) {
  const affiliations = [];
  if (user.membership !== "Other" && user.membership) {
    affiliations.push({ id: user.membership });
  }

  if (user.management !== "Other" && user.management) {
    affiliations.push({ id: user.management });
  }

  return affiliations.length ? affiliations : undefined;
}

function formatDiscovery(user) {
  let discovery = [];
  if (user.discovery) discovery.push(user.discovery);
  if (user.discovery_explanation) discovery.push(user.discovery_explanation);
  if (user.marketingManager?.id) discovery.push(user.marketingManager.name);
  if (discovery.length) return discovery.join(": ");
  return undefined;
}

function formatReferral(user) {
  return user.referral || undefined;
}

function formatReferrer(user) {
  return user.referrer || undefined;
}

function formatMarketingManager(user) {
  return user.marketingManager?.id || undefined;
}

function formatCustomRater(user) {
  return user.otherMembership || undefined;
}

function formatCustomMembership(user) {
  return user.otherManagement || undefined;
}

export function signUp(user) {
  return getHttpClient().post(
    baseUrl,
    {
      config_name: "default",
      email: user.email,
      password: user.password,
      npn: user.npn,
      subscribe_to_marketing: user.marketing,

      discovery: formatDiscovery(user),
      referral: formatReferral(user),
      referrer: formatReferrer(user),
      marketing_manager_id: formatMarketingManager(user),
      affiliations: formatAffiliations(user),
      custom_rater: formatCustomRater(user),
      custom_membership: formatCustomMembership(user)
    },
    {
      headers: {
        Accept: "application/json"
      }
    }
  );
}

export async function signOut() {
  setActiveRollbarUser({ id: null, email: null, name: null });

  await getHttpClient().delete(`${baseUrl}/sign-out`, {
    headers: {
      Accept: "application/json"
    }
  });
}

export function heartbeat() {
  return getHttpClient().put(`${baseUrl}/refresh`);
}

export function getOauthProviderLink(provider) {
  const instance = useInstanceStore();
  return `${instance.apiUrl}${baseUrl}/sso/${provider}`;
}

export function getSamlLink() {
  const instance = useInstanceStore();

  return `${instance.apiUrl}${baseUrl}/saml/auth`;
}

export async function signIn(form) {
  const user = {
    email: form.email,
    password: form.password,
    otp_attempt: undefined
  };

  if (form.otpInput) user.otp_attempt = form.otpInput;

  let authRes;
  try {
    const response = await getHttpClient().post(
      `${baseUrl}/sign-in`,
      {
        user
      },
      {
        headers: {
          Accept: "application/json"
        }
      }
    );
    authRes = response.data;
  } catch (e) {
    if (e.response.status === 401) {
      const { provider, method } = e.response.data;
      authRes = { provider, method };
    } else {
      throw e;
    }
  }

  return authRes;
}

export async function resetPassword(email) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/password`,
    {
      user: {
        email
      }
    },
    {
      headers: {
        Accept: "application/json"
      }
    }
  );

  return data;
}

export function changePasswordWithToken({
  newPassword,
  newPasswordConfirmation,
  resetPasswordToken
}) {
  return getHttpClient().put(
    `${baseUrl}/password`,
    {
      user: {
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
        reset_password_token: resetPasswordToken
      }
    },
    {
      headers: {
        Accept: "application/json"
      }
    }
  );
}

export function reissueTokenIfExpired(resetPasswordToken) {
  return getHttpClient().post(
    `${baseUrl}/reissue-token-if-expired`,
    {
      reset_password_token: resetPasswordToken
    },
    {
      headers: {
        Accept: "application/json"
      }
    }
  );
}

export async function confirmTwoFactorData(otp_attempt) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/two-factor`,
    {
      otp_attempt
    },
    {
      headers: {
        Accept: "application/json"
      }
    }
  );
  return data;
}

export async function authenticateAndSetDetails() {
  const userStore = useUserStore();
  const instance = useInstanceStore();

  const response = await getMe();
  if (!userStore.id) await instance.setCsrfToken();

  userStore.storeVerboseDetails(response.data);
  instance.wss = response.data.wss;
  setActiveRollbarUser({
    id: userStore.id,
    username: userStore.email,
    email: userStore.email
  });
}

export function handleRedirectAfterAuthentication(router) {
  const user = useUserStore();
  const nextAuthStep = user.nextAuthStep();
  if (!router) return;
  const query = router.currentRoute.query;
  if (nextAuthStep === AUTH_STEPS.COMPLETED) {
    if (query?.redirect) {
      // Probably would be better to check if the route was resolved by router first then do the redirect
      if (query.redirect.startsWith("/auth/attestation")) {
        location.replace(query.redirect);
      } else return router.replace(query?.redirect);
    }
  }
  return router.replace({ name: nextAuthStep, query });
}
