<template>
  <v-card flat color="section">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <currency-input
            v-model="premium"
            label="Premium"
            include-decimals
            include-negative
            dense
            outlined
            data-testid="premium"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="amountValidation.success"
            :error-messages="amountValidation.errorMessages"
            @input="updatePremium"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.premium.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
        <v-col cols="12" md="6">
          <currency-input
            v-model="amount"
            label="Commission"
            include-decimals
            include-negative
            dense
            outlined
            data-testid="commission"
            :prepend-inner-icon="mdiCurrencyUsd"
            :success="premiumValidation.success"
            :error-messages="premiumValidation.errorMessages"
            @input="updateAmount"
          >
            <template #append-outer>
              <active-save-indicator
                :controller="savingBuffer.amount.controller.value"
              />
            </template>
          </currency-input>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { computedValidation } from "@/util/helpers";
import { mdiCurrencyUsd } from "@mdi/js";
import { useActiveSave } from "@/composables/active-save.composable";
import { storeToRefs } from "pinia";
import { useStatementView } from "@/stores/statement-view";
import useVuelidate from "@vuelidate/core";

const statementView = useStatementView();
const { premium, amount } = storeToRefs(statementView);

const savingBuffer = {
  premium: useActiveSave(),
  amount: useActiveSave()
};

const v$ = useVuelidate(
  {
    amount: { required: Boolean },
    premium: { required: Boolean }
  },
  { amount, premium },
  { $autoDirty: true, $scope: null }
);

const amountValidation = computedValidation(v$.value.amount, {
  required: "Required"
});
const premiumValidation = computedValidation(v$.value.premium, {
  required: "Required"
});

async function updateAmount() {
  savingBuffer.amount.debounceUpdate(async () => {
    const isValid = await v$.value.amount.$validate();
    if (!isValid) return;
    return statementView.updateAmount();
  });
}

async function updatePremium() {
  savingBuffer.premium.debounceUpdate(async () => {
    const isValid = await v$.value.premium.$validate();
    if (!isValid) return;
    return statementView.updatePremium();
  });
}
</script>
