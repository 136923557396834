<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        :items="table.mappedItems.value"
        :headers="table.tableHeaders.value"
        class="transparent-data-table"
        data-testid="party-table"
      >
        <template #[`item.name`]="{ item }">
          <router-link
            v-if="item.additional.ownable.type === 'individual'"
            :to="{
              name: 'IndividualView',
              params: {
                id: item.additional.ownable.id
              }
            }"
          >
            {{ item.name }}
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'ContractPartyView',
              params: {
                id: item.additional.ownable.id
              }
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template #[`item.birthdate`]="{ item }">
          <timestamp-formatter :value="item.birthdate" parser="sole-day" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import TableHeader from "@/classes/data-table/TableHeader";
import { storeToRefs } from "pinia";
import { useQuoteViewStore } from "@/stores/quote-view";
import { useTable } from "@/composables/table.composable";

const quoteView = useQuoteViewStore();
const { quoteRoles } = storeToRefs(quoteView);
const headers = [
  new TableHeader({
    text: "Name",
    value: "name",
    map: "ownable.name"
  }),
  new TableHeader({
    text: "Role",
    value: "role",
    map: "role"
  }),
  ["life", "ltc"].includes(quoteView.line)
    ? new TableHeader({
        text: "Underwriting Class",
        value: "underwriting",
        map: "underwritingClass"
      })
    : null,
  new TableHeader({
    text: "Birthdate",
    value: "birthdate",
    map: "ownable.birthdate"
  })
].filter(Boolean);
const table = useTable({ headers, items: quoteRoles.value });
</script>
