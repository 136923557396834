<template>
  <v-autocomplete
    v-model="policy"
    autocomplete="false"
    outlined
    dense
    return-object
    no-filter
    hide-no-data
    auto-select-first
    v-bind="$attrs"
    item-text="policyNumber"
    :data-testid="dataTestid"
    :label="label"
    :placeholder="placeholder"
    :prepend-inner-icon="mdiPound"
    :search-input.sync="search"
    :items="policies"
    :loading="loading"
    :no-data-text="loading ? 'Searching...' : 'No Data'"
    ref="searchInput"
    @blur="emit('blur')"
    @change="emit('change')"
  />
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, defineExpose } from "vue";
import { mdiPound } from "@mdi/js";
import {
  policyNumberSearch,
  simplePolicyNumberSearch
} from "@/api/search.service";
import { searchCaseTransactions } from "@/api/transactions.service";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";

const props = defineProps({
  value: { type: Object, required: false, default: () => {} },
  transaction: Boolean,
  simple: Boolean,
  dataTestid: { type: String, required: false, default: null },
  label: { type: String, required: false, default: "Policy Number" },
  placeholder: {
    type: String,
    required: false,
    default: "Search Policy Numbers"
  }
});

const snackbar = useSnackbarStore();
const emit = defineEmits(["input", "blur", "change"]);

const policies = ref([]);
const policy = ref(null);
const search = ref("");
const loading = ref(false);

if (props.value?.id) {
  policies.value.push(props.value);
  policy.value = props.value;
}

watch(
  () => props.value,
  () => {
    policies.value.splice(0, policies.value.length);

    if (!props.value?.id) {
      policy.value = null;
      return;
    }
    policy.value = props.value;
    policies.value.push(props.value);
  }
);

watch(policy, () => {
  if (policy.value?.id === props.value?.id) return;
  emit("input", policy.value);
});

watch(search, debounceAndSearch);

let policyTimer;
function debounceAndSearch() {
  if (policyTimer) clearTimeout(policyTimer);
  policyTimer = setTimeout(searchPolicies, 200);
}

async function searchPolicies() {
  if (!search.value) return;

  if (
    policies.value.length === 1 &&
    policies.value[0].policyNumber === search.value
  ) {
    return;
  }

  let method = policyNumberSearch;
  if (props.transaction) {
    method = v => searchCaseTransactions({ policy_number: v });
  } else if (props.simple) {
    method = simplePolicyNumberSearch;
  }

  try {
    loading.value = true;

    const response = await method(search.value);
    policies.value.splice(0, policies.value.length);
    policies.value.push(...response);
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loading.value = false;
  }
}

const searchInput = ref(null); //templateref

function focus() {
  searchInput.value.$refs.input.focus();
}

defineExpose({ focus });
</script>
