<template>
  <v-row dense>
    <v-col cols="12" v-if="props.quoteSave">
      <v-autocomplete
        v-model="quoteData.status"
        data-testid="input-status"
        :prepend-inner-icon="mdiListStatus"
        auto-select-first
        outlined
        dense
        label="Status"
        :disabled="props.disabled"
        :items="STATUSES"
        :success="statusValidation.success"
        :error-messages="statusValidation.errorMessages"
        @change="savingBuffer.status.update(saveWrapper('status'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.status.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" lg="6">
      <v-autocomplete
        v-model="quoteData.state"
        data-testid="input-state"
        :prepend-inner-icon="mdiSignRealEstate"
        outlined
        dense
        auto-select-first
        label="State"
        item-text="full"
        item-value="text"
        :disabled="props.disabled"
        :items="stateItems"
        :success="stateValidation.success"
        :error-messages="stateValidation.errorMessages"
        @change="savingBuffer.state.update(saveWrapper('state'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.state.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" lg="6">
      <v-autocomplete
        v-model="quoteData.mode"
        data-testid="input-mode"
        :prepend-inner-icon="mdiCalendar"
        outlined
        dense
        label="Mode"
        :disabled="props.disabled"
        :items="MODES"
        :success="modeValidation.success"
        :error-messages="modeValidation.errorMessages"
        @change="savingBuffer.mode.update(saveWrapper('mode'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.mode.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" lg="5">
      <currency-input
        v-model="quoteData.faceAmount"
        data-testid="input-face-amount"
        :prepend-inner-icon="mdiCurrencyUsd"
        outlined
        dense
        label="Death Benefit"
        :disabled="props.disabled"
        :success="faceAmountValidation.success"
        :error-messages="faceAmountValidation.errorMessages"
        @input="
          savingBuffer.faceAmount.debounceUpdate(saveWrapper('faceAmount'))
        "
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.faceAmount.controller.value"
          />
        </template>
      </currency-input>
    </v-col>
    <v-col cols="12" lg="2" align="center" justify="center">
      <h3 class="my-2">or</h3>
    </v-col>
    <v-col cols="12" lg="5">
      <currency-input
        v-model="quoteData.premium"
        data-testid="input-premium"
        :prepend-inner-icon="mdiCurrencyUsd"
        outlined
        dense
        label="Premium"
        include-decimals
        :disabled="props.disabled"
        :success="premiumValidation.success"
        :error-messages="premiumValidation.errorMessages"
        @input="savingBuffer.premium.debounceUpdate(saveWrapper('premium'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.premium.controller.value"
          />
        </template>
      </currency-input>
    </v-col>
    <v-col
      cols="12"
      class="rounded"
      :class="{
        'error-sheet-outlined': productTypeValidation.errorMessages.length
      }"
    >
      <v-expansion-panels multiple v-model="expandedTypes">
        <quote-request-product-type
          v-for="(type, index) in types"
          :key="index"
          v-bind="type.props"
          v-on="type.listeners"
          @expand="expand(index)"
        />
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script setup>
import QuoteRequestProductType from "@/components/quotes/quote-request/product-type/QuoteRequestProductType.vue";
import { states as stateItems } from "@/data/states";

import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import {
  basicTypeEnabled,
  baseTypeGenerator,
  addEnabled,
  compositeProductType,
  addIncome,
  addPayDurations,
  addProductTypes
} from "@/components/quotes/quote-request/product-type/product-type-util.mjs";
import {
  STATUSES,
  TERM_SUBTYPES,
  GUL_SUBTYPES,
  MODES,
  generatePayDurations,
  HELPER_TYPES,
  QuoteRequestLifeInfo
} from "@/factories/Quote";
import { computedValidation } from "@/util/helpers";
import { useActiveSave } from "@/composables/active-save.composable";
import { ref, defineProps, defineEmits, computed, watch, toRefs } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  mdiListStatus,
  mdiSignRealEstate,
  mdiCalendar,
  mdiCurrencyUsd
} from "@mdi/js";

const props = defineProps({
  disabled: Boolean,
  birthdate: {
    type: Date,
    required: false,
    default: null
  },
  quoteSave: {
    type: Function,
    required: false,
    default: null
  },

  // Savable Values
  faceAmount: { type: Number, required: false },
  incomeEnabled: Boolean,
  incomeEndAge: { type: Number, required: false, default: null },
  incomeStartAge: { type: Number, required: false, default: null },
  mode: { type: String, required: false },
  payDurations: { type: Array, required: false, default: () => [] },
  premium: { type: Number, required: false },
  productType: { type: Array, required: false, default: () => [] },
  state: { type: String, required: false },
  status: { type: String, required: false }
});

const { birthdate } = toRefs(props);

const quoteData = ref(QuoteRequestLifeInfo(props));

const savingBuffer = {
  status: useActiveSave(),
  state: useActiveSave(),
  mode: useActiveSave(),
  faceAmount: useActiveSave(),
  premium: useActiveSave()
};

const expandedTypes = ref([]);

const accidentalDeathEnabled = basicTypeEnabled(
  quoteData,
  "Accidental Death",
  promptForQuoteAndApply
);
const aiulEnabled = basicTypeEnabled(
  quoteData,
  "Accumulation Indexed Universal Life",
  promptForQuoteAndApply
);
const fexEnabled = basicTypeEnabled(
  quoteData,
  "Final Expense",
  promptForQuoteAndApply
);
const gulEnabled = basicTypeEnabled(
  quoteData,
  HELPER_TYPES.GUL,
  promptForQuoteAndApply
);
const piulEnabled = basicTypeEnabled(
  quoteData,
  "Protection Indexed Universal Life",
  promptForQuoteAndApply
);
const termEnabled = basicTypeEnabled(
  quoteData,
  HELPER_TYPES.TERM,
  promptForQuoteAndApply
);
const ulEnabled = basicTypeEnabled(
  quoteData,
  "Universal Life",
  promptForQuoteAndApply
);
const vulEnabled = basicTypeEnabled(
  quoteData,
  "Variable Universal Life",
  promptForQuoteAndApply
);
const wholeLifeEnabled = basicTypeEnabled(
  quoteData,
  "Whole Life",
  promptForQuoteAndApply
);

const termProductTypes = compositeProductType(
  quoteData,
  TERM_SUBTYPES.map(v => v.value)
);
const gulProductTypes = compositeProductType(
  quoteData,
  GUL_SUBTYPES.map(v => v.value)
);

const v$ = useVuelidate(
  {
    quoteData: {
      state: { required: v => !!v },
      mode: { required: v => !!v },
      faceAmount: {
        required: (value, parent) => {
          return Boolean(parent.premium) || Boolean(value);
        }
      },
      premium: {
        required: (value, parent) => {
          return Boolean(parent.faceAmount) || Boolean(value);
        }
      },
      status: {
        required: v => !props.quoteId || Boolean(v)
      },
      productType: {
        required: v => v.length
      }
    }
  },
  { quoteData },
  { $autoDirty: true, $scope: "quote-request" }
);

const statusValidation = computedValidation(v$.value.quoteData.status, [
  { key: "required", message: "Required" }
]);

const productTypeValidation = computedValidation(
  v$.value.quoteData.productType,
  [{ key: "required", message: "Required" }]
);

const stateValidation = computedValidation(v$.value.quoteData.state, [
  { key: "required", message: "Required" }
]);

const modeValidation = computedValidation(v$.value.quoteData.mode, [
  { key: "required", message: "Required" }
]);

const faceAmountValidation = computedValidation(v$.value.quoteData.faceAmount, [
  {
    key: "required",
    message: "Either Death Benefit or Premium is Required"
  }
]);

const premiumValidation = computedValidation(v$.value.quoteData.premium, [
  {
    key: "required",
    message: "Either Death Benefit or Premium is Required"
  }
]);

const payDurationItems = computed(() => {
  if (birthdate.value === null) return [];
  return generatePayDurations(
    new Date().getFullYear() - birthdate.value.getFullYear()
  );
});

const emit = defineEmits([
  ...Object.keys(QuoteRequestLifeInfo()).map(v => `update:${v}`),
  "save",
  "prompt-for-quote-and-apply"
]);

function expand(index) {
  const expandedIndex = expandedTypes.value.indexOf(index);
  if (expandedIndex === -1) {
    expandedTypes.value.push(index);
  }
}

function saveInjector(key) {
  if (props.quoteSave) return () => props.quoteSave(key);
  return null;
}

function saveWrapper(key) {
  if (props.quoteSave) return () => props.quoteSave(key);
  return () => {};
}

function promptForQuoteAndApply({ value }) {
  if (!value) return;
  emit("prompt-for-quote-and-apply", false);
}

const enabledProps = { save: saveInjector("productType") };

const incomeProps = {
  min: 1,
  max: 120,
  startAgeSave: saveInjector("incomeStartAge"),
  endAgeSave: saveInjector("incomeEndAge")
};

const payDurationsProps = computed(() => ({
  save: saveInjector("payDurations"),
  items: payDurationItems.value
}));

const accidentalDeathConfig = computed(() => {
  const config = baseTypeGenerator("Accidental Death", props.disabled);
  addEnabled(config, accidentalDeathEnabled, enabledProps);
  return config;
});

const fexConfig = computed(() => {
  const config = baseTypeGenerator("Final Expense", props.disabled);
  addEnabled(config, fexEnabled, enabledProps);
  return config;
});

const accumulationConfig = computed(() => {
  const config = baseTypeGenerator(
    "Accumulation Indexed Universal Life",
    props.disabled
  );
  addEnabled(config, aiulEnabled, enabledProps);
  addIncome(config, quoteData, incomeProps);
  addPayDurations(config, quoteData, payDurationsProps.value);
  return config;
});

const gulConfig = computed(() => {
  const config = baseTypeGenerator(
    "Guaranteed Universal Life (GUL)",
    props.disabled
  );
  addEnabled(config, gulEnabled, enabledProps);
  addProductTypes(config, gulProductTypes, {
    items: GUL_SUBTYPES,
    save: saveInjector("productType")
  });
  addPayDurations(config, quoteData, payDurationsProps.value);
  return config;
});

const piulConfig = computed(() => {
  const config = baseTypeGenerator(
    "Protection Indexed Universal Life",
    props.disabled
  );
  addEnabled(config, piulEnabled, enabledProps);
  addIncome(config, quoteData, incomeProps);
  addPayDurations(config, quoteData, payDurationsProps.value);
  return config;
});

const termConfig = computed(() => {
  const config = baseTypeGenerator("Term", props.disabled);
  addEnabled(config, termEnabled, enabledProps);
  addProductTypes(config, termProductTypes, {
    items: TERM_SUBTYPES,
    save: saveInjector("productType")
  });
  return config;
});

const ulConfig = computed(() => {
  const config = baseTypeGenerator("Universal Life", props.disabled);
  addEnabled(config, ulEnabled, enabledProps);
  addIncome(config, quoteData, incomeProps);
  addPayDurations(config, quoteData, payDurationsProps.value);
  return config;
});

const vulConfig = computed(() => {
  const config = baseTypeGenerator("Variable Universal Life", props.disabled);
  addEnabled(config, vulEnabled, enabledProps);
  addIncome(config, quoteData, incomeProps);
  addPayDurations(config, quoteData, payDurationsProps.value);
  return config;
});

const wholeLifeConfig = computed(() => {
  const config = baseTypeGenerator("Whole Life", props.disabled);
  addEnabled(config, wholeLifeEnabled, enabledProps);
  addIncome(config, quoteData, incomeProps);
  addPayDurations(config, quoteData, payDurationsProps.value);
  return config;
});

const types = computed(() => {
  return [
    accidentalDeathConfig.value,
    accumulationConfig.value,
    fexConfig.value,
    gulConfig.value,
    piulConfig.value,
    termConfig.value,
    ulConfig.value,
    vulConfig.value,
    wholeLifeConfig.value
  ];
});

Object.keys(QuoteRequestLifeInfo()).forEach(key => {
  watch(
    () => quoteData.value[key],
    (v, old) => {
      if (JSON.stringify(v) === JSON.stringify(old)) return;
      emit(`update:${key}`, quoteData.value[key]);
    },
    { deep: true }
  );
});
</script>
