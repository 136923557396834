<template>
  <v-fade-transition mode="out-in">
    <v-col v-if="loading" key="loader">
      <v-row dense>
        <v-col cols="12">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="table" />
        </v-col>
      </v-row>
    </v-col>
    <v-row v-else class="width-full" dense key="loaded">
      <v-col cols="12" lg="12" class="pb-0">
        <electronic-application-card />
      </v-col>
      <v-col cols="12" class="py-0">
        <electronic-application-actions @reload="getEApp" />
      </v-col>
      <v-col cols="12" lg="12" class="pt-0">
        <v-card flat tile>
          <v-tabs v-model="activeTab" show-arrows class="no-bottom-radius">
            <v-tab
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              class="text-none"
              :data-testid="`tab-${tab.title}`"
            >
              <v-icon class="pr-1">{{ tab.icon }}</v-icon>
              <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
                {{ tab.title }}
              </v-badge>
              <template v-else>{{ tab.title }}</template>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item
              v-for="tab in tabs"
              :key="'tab' + tab.title"
              :eager="tab.eager"
            >
              <component :is="tab.component" v-bind="tab.componentProps" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <need-help @click="focusChat" />
    </v-row>
  </v-fade-transition>
</template>

<script setup>
import NeedHelp from "@/components/shared/NeedHelp.vue";
import ElectronicApplicationCard from "@/components/electronic-applications/ElectronicApplicationCard.vue";
import ElectronicApplicationActions from "@/components/electronic-applications/ElectronicApplicationActions.vue";

import ElectronicApplicationAccesses from "@/components/electronic-applications/ElectronicApplicationAccesses.vue";
import ElectronicApplicationChat from "@/components/electronic-applications/ElectronicApplicationChat.vue";
import ElectronicApplicationExistingInsurance from "@/components/electronic-applications/ElectronicApplicationExistingInsurance.vue";
import ElectronicApplicationMedicalSources from "@/components/electronic-applications/ElectronicApplicationMedicalSources.vue";
import ElectronicApplicationMetadata from "@/components/electronic-applications/ElectronicApplicationMetadata.vue";
import ElectronicApplicationParties from "@/components/electronic-applications/ElectronicApplicationParties.vue";
import ElectronicApplicationQuoteHistory from "@/components/electronic-applications/ElectronicApplicationQuoteHistory.vue";
import ElectronicApplicationSigners from "@/components/electronic-applications/ElectronicApplicationSigners.vue";
import ElectronicApplicationToDoList from "@/components/electronic-applications/ElectronicApplicationToDoList.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { storeToRefs } from "pinia";
import { useInstanceStore } from "@/stores/instance";
import { useDialogStore } from "@/stores/dialog";
import { useEappViewStore } from "@/stores/eapp-view";
import { useHead } from "@unhead/vue";
import {
  computed,
  markRaw,
  nextTick,
  onBeforeUnmount,
  ref,
  watch,
  defineProps,
  toRef
} from "vue";
import { useRouter } from "@/composables/compatible.composables";
import { getRouterLink } from "@/util/helpers";
import {
  mdiAccountMultiple,
  mdiCheck,
  mdiCodeTags,
  mdiDraw,
  mdiHeartPulse,
  mdiHistory,
  mdiMessage,
  mdiShieldAccount,
  mdiTimerSandFull
} from "@mdi/js";
import { useFullEmailViewer } from "@/composables/full-email.composable";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

const props = defineProps({
  id: {
    type: [Number, String],
    required: true
  },
  page: {
    type: String,
    required: false,
    default: ""
  },
  fromCase: Boolean,
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  },
  highlightNote: {
    type: Number,
    required: false,
    default: null
  }
});

const head = useHead({ title: "eApp Viewer" });

const highlightTodo = toRef(props, "highlightTodo");

const router = useRouter();
const eappView = useEappViewStore();
const {
  id: eappId,
  insured,
  status,
  envelopeExpirationAt,
  parties,
  electronicApplicationCases,
  metadata,
  case: eAppCase,
  todoCount
} = storeToRefs(eappView);
const { breadcrumb } = storeToRefs(useInstanceStore());
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const loading = ref(false);
const activeTab = ref(0);

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  handleQueryChange();
});

const tabs = computed(() => {
  if (!eappId.value) return [];
  const tabs = [
    {
      title: "Chat",
      component: markRaw(ElectronicApplicationChat),
      icon: mdiMessage,
      eager: true,
      query: "chat"
    },
    {
      title: "Quote History",
      component: markRaw(ElectronicApplicationQuoteHistory),
      icon: mdiHistory,
      query: "quote-history"
    },
    {
      title: "Access",
      component: markRaw(ElectronicApplicationAccesses),
      icon: mdiShieldAccount,
      query: "access"
    },
    {
      title: "To-Do",
      component: markRaw(ElectronicApplicationToDoList),
      icon: mdiCheck,
      componentProps: {
        highlightTodo: highlightTodo.value
      },
      eager: true,
      query: "to-do",
      badgeProps: todoCount.value
        ? {
            color: "error",
            content: todoCount.value,
            "data-testid": "todo-badge"
          }
        : null
    }
  ];
  if (envelopeExpirationAt.value !== null) {
    tabs.push({
      title: "Signers",
      component: markRaw(ElectronicApplicationSigners),
      icon: mdiDraw,
      query: "signers"
    });
  }
  if (parties.value?.length > 0) {
    tabs.push({
      title: "Parties",
      component: markRaw(ElectronicApplicationParties),
      icon: mdiAccountMultiple,
      query: "parties"
    });
  }
  if (insured.value?.medicalSources?.length > 0) {
    tabs.push({
      title: "Medical Sources",
      component: markRaw(ElectronicApplicationMedicalSources),
      icon: mdiHeartPulse,
      query: "medical-sources"
    });
  }

  if (electronicApplicationCases.value?.length > 0) {
    tabs.push({
      title: "Existing Insurance",
      component: markRaw(ElectronicApplicationExistingInsurance),
      icon: mdiTimerSandFull,
      query: "existing-insurance"
    });
  }

  if (metadata.value?.length) {
    tabs.push({
      title: "Metadata",
      component: markRaw(ElectronicApplicationMetadata),
      icon: mdiCodeTags,
      query: "metadata"
    });
  }
  return tabs.toSorted((a, b) => a.title.localeCompare(b.title));
});

onBeforeUnmount(() => {
  breadcrumb.value = "";
  dialog.closeDialog();
});
async function getEApp() {
  try {
    loading.value = true;
    await eappView.initialize(props.id);
    breadcrumb.value = insured.value.name;
    head.patch({ title: insured.value.name });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: "Unable to find Electronic Application. Redirecting.",
      timeout: -1
    });
    router.push({ name: "QuoteAndApply", query: { view: "list" } });
  } finally {
    loading.value = false;
  }

  await nextTick();
  postLoadHandler();
}

async function postLoadHandler() {
  if (props.page) handleQueryChange(true);
  else if (
    status.value === "Pending DocuSign" &&
    envelopeExpirationAt.value !== null
  ) {
    activeTab.value = tabs.value.findIndex(tab => tab.title === "Signers");
  } else {
    activeTab.value = tabs.value.findIndex(tab => tab.title === "Chat");
  }

  const shouldShowCaseHelper =
    status.value === "Completed" && eAppCase.value?.id && !props.fromCase;

  if (shouldShowCaseHelper) {
    const res = await dialog.showDialog({
      component: markRaw(ConfirmationDialog),
      title: "Looking for the Case?",
      subtitle:
        "This Electronic Application has already been completed. Please proceed to the case to continue the application process.",
      confirmText: "Go to Case",
      cancelText: "Stay on eApp",
      func: () => router.push(getRouterLink("Case", eAppCase.value.id))
    });
    if (res?.confirm) return;
  }

  if (props.highlightNote) {
    router.replace({ query: { "highlight-note": undefined } });
    const fullEmailViewer = useFullEmailViewer(props.highlightNote);
    fullEmailViewer.retrieveFullEmail();
  }
}

function handleQueryChange(initializing = false) {
  let page;
  if (initializing) {
    const tempPage = tabs.value.findIndex(t => t.query === props.page);
    if (tempPage > -1) {
      page = tabs.value[tempPage].query;
      activeTab.value = tempPage;
    }
  } else {
    page = tabs.value[activeTab.value].query;
  }

  if (props.page === page || !page) return;
  if (router) router.replace({ query: { page: page } });
}

async function focusChat() {
  activeTab.value = tabs.value.findIndex(tab => tab.title === "Chat");
  await nextTick();
  document.querySelector('[data-testid="chat-message-input"]')?.focus();
  snackbar.showInfoSnackbar({
    message: "Please ask your question in the chat."
  });
}

getEApp();
</script>
