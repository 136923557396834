<template>
  <v-col class="pa-0">
    <color-picker-input
      v-model="theme.primary"
      label="Primary Color"
      :data-testid="`${dataTestid}-primary`"
      :default-color="defaultPrimary"
      @input="updateColor('primary')"
      @save="saveColor('primary', $event)"
    />
    <color-picker-input
      v-model="theme.accent"
      label="Accent Color"
      :data-testid="`${dataTestid}-accent`"
      :default-color="defaultAccent"
      @input="updateColor('accent')"
      @save="saveColor('accent', $event)"
    />
  </v-col>
</template>

<script setup>
import ColorPickerInput from "@/components/shared/ColorPickerInput.vue";
import { toRefs, defineProps, ref, defineEmits } from "vue";

const props = defineProps({
  dataTestid: { type: String, required: false, default: "" },
  primary: {
    type: String,
    required: true
  },
  defaultPrimary: {
    type: String,
    required: true
  },
  accent: {
    type: String,
    required: true
  },
  defaultAccent: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["update:primary", "update:accent", "save"]);

const { dataTestid, primary, defaultPrimary, accent, defaultAccent } =
  toRefs(props);

const theme = ref({
  primary: primary.value.toUpperCase(),
  accent: accent.value.toUpperCase()
});

function updateColor(color) {
  emit(`update:${color}`, theme.value[color]);
}
function saveColor(color, savingBuffer) {
  emit("save", { color, savingBuffer });
}
</script>
