<template>
  <v-card flat>
    <v-card-title> Commissions Paid To </v-card-title>
    <v-card-subtitle> Who gets paid for this eApp? </v-card-subtitle>
    <v-card-text>
      <v-checkbox
        v-model="overrideCommissionsPaidTo"
        class="mt-1"
        data-testid="override-commissions-paid-to"
        :disabled="disabled || saving"
        @change="handleChange"
      >
        <template #label>
          <div>
            Override Default Commissions. Based on its website, this eApp is
            assigning commissions to
            {{ approvedDomain.appointmentAssignmentOwnable.name }}.
            <router-link
              :to="{
                name: 'ApprovedDomains',
                params: {
                  id: approvedDomain.id
                },
                query: {
                  'scroll-to': 'commissions-assigned-to',
                  page: 'commissions'
                }
              }"
            >
              Change Default Commissions
            </router-link>
          </div>
        </template>
      </v-checkbox>
      <v-fade-transition mode="out-in">
        <advisor-search
          v-if="overrideCommissionsPaidTo"
          v-model="commissionsPaidTo"
          label="Commissions Assigned To"
          data-testid="advisor-assigned-commissions"
          :clearable="false"
          :success="Boolean(commissionsPaidTo?.id)"
          :disabled="disabled || saving"
          style="max-width: 35rem"
          @input="setCommissionsPaidTo"
        >
          <template #append-outer>
            <active-save-indicator
              :controller="savingBuffer.commissionsPaidTo.controller.value"
            />
          </template>
        </advisor-search>
      </v-fade-transition>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useEappViewStore } from "@/stores/eapp-view";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed, nextTick, onMounted, ref } from "vue";

const eappView = useEappViewStore();
const snackbar = useSnackbarStore();
const { approvedDomain, allowCommissionChange, commissionsPaidTo } =
  storeToRefs(eappView);

const savingBuffer = {
  commissionsPaidTo: useActiveSave()
};

const overrideCommissionsPaidTo = ref(Boolean(commissionsPaidTo.value?.id));
const isMounted = ref(false);
const saving = ref(false);

onMounted(() => nextTick(() => (isMounted.value = true)));

const disabled = computed(() => !allowCommissionChange.value);

async function setCommissionsPaidTo() {
  saving.value = true;

  await savingBuffer.commissionsPaidTo.update(() =>
    eappView.updateEapp("commissionsPaidTo")
  );

  if (savingBuffer.commissionsPaidTo.controller.value.error) return false;
  else {
    let message = `Successfully reverted commissions to ${approvedDomain.value.appointmentAssignmentOwnable.name}`;
    if (commissionsPaidTo.value.name) {
      message = `Successfully assigned commissions to ${commissionsPaidTo.value.name}`;
    }
    snackbar.showSuccessSnackbar({ message, timeout: 10000 });
  }

  saving.value = false;
  return true;
}
async function handleChange() {
  if (!overrideCommissionsPaidTo.value && commissionsPaidTo.value?.id) {
    commissionsPaidTo.value.id = null;
    commissionsPaidTo.value.type = null;
    commissionsPaidTo.value.name = null;

    const res = await setCommissionsPaidTo();

    if (!res) overrideCommissionsPaidTo.value = true;
  }
}
</script>
