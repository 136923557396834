<template>
  <v-card flat tile color="section">
    <v-card-text>
      <v-data-table
        data-testid="medical-sources-table"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        :items="items"
        :headers="headers"
      >
        <template #[`item.created_at`]="{ item }">
          <timestamp-formatter :value="item.created_at" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";
import { useEappViewStore } from "@/stores/eapp-view";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const eapp = useEappViewStore();
const { insured } = storeToRefs(eapp);

const headers = [
  { text: "Name", value: "name" },
  { text: "Status", value: "status" },
  { text: "Created", value: "created_at" }
];
const items = computed(() => insured.value?.medicalSources);
</script>
