<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="party.name"
            data-lpignore="true"
            :prepend-inner-icon="mdiDomain"
            data-testid="new-party-entity-name"
            outlined
            dense
            label="Entity Name"
            :success="nameValidation.success"
            :error-messages="nameValidation.errorMessages"
          />
        </v-col>

        <v-col v-if="!props.hideRole" cols="12">
          <case-role-input
            validation-scope="entity"
            type="Entity"
            :line="props.line"
            :roles.sync="party.roles"
            :beneficiary-amount.sync="party.beneficiaryAmount"
            :relationship.sync="party.relationship"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-sheet color="section" rounded class="pa-3">
        <h4 class="mb-3 text-h6">Optional Entity Information</h4>
        <v-row class="ma-0">
          <v-col cols="12" lg="6">
            <tin-input
              v-model="party.tin"
              data-lpignore="true"
              outlined
              dense
              :prepend-inner-icon="mdiAsterisk"
              label="TIN"
              data-testid="new-party-entity-tin"
              :success="party.tin && tinValidation.success"
              :error-messages="loadingTin ? [] : tinValidation.errorMessages"
              :loading="loadingTin"
              @input="validateTin"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="party.email"
              data-lpignore="true"
              :prepend-inner-icon="mdiEmail"
              label="E-mail Address"
              data-testid="new-party-entity-email"
              outlined
              dense
              :success="Boolean(party.email)"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <date-input
              v-model="party.date"
              :prepend-inner-icon="mdiCalendar"
              label="Entity Date"
              data-testid="new-party-entity-date"
              outlined
              dense
              :success="Boolean(party.date)"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <div class="checkbox-width">
              <v-checkbox
                v-model="party.irrevocable"
                data-testid="new-party-entity-irrevocable"
                label="Irrevocable"
                class="mt-0"
                :success="Boolean(party.irrevocable)"
              />
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script setup>
import CaseRoleInput from "@/components/cases/case-dialogs/CaseRoleInput.vue";
import DateInput from "@/components/shared/DateInput.vue";
import TinInput from "@/components/shared/TinInput.vue";

import { NewCaseEntity, NewCaseEntityToCreateRequest } from "@/factories/Party";

import { uniqueTin, tin } from "@/util/vuelidateValidators";

import { createContractParty } from "@/api/contract-party.service";
import { caseCreateContractParty } from "@/api/cases.service";
import { ref, defineProps, defineExpose } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation, someTextValidator } from "@/util/helpers";
import { mdiDomain, mdiAsterisk, mdiEmail, mdiCalendar } from "@mdi/js";

const props = defineProps({
  caseId: { type: Number, required: false, default: null },
  line: { type: String, required: true },
  hideRole: Boolean
});

const party = ref(NewCaseEntity());
const tinValidationMessage = ref("");
const loadingTin = ref(false);

const v$ = useVuelidate(
  {
    party: {
      name: { required: v => someTextValidator(true, v, 2) },
      tin: {
        valid: v => !v || !tinValidationMessage.value
      }
    }
  },
  { party },
  { $autoDirty: true, $scope: "entity" }
);

const nameValidation = computedValidation(v$.value.party.name, {
  required: "Required"
});
const tinValidation = computedValidation(v$.value.party.tin, {
  valid: tinValidationMessage.value || "Invalid TIN"
});

async function tinValidator() {
  if (!party.value.tin) return "";
  if (!tin(party.value.tin)) return "Invalid TIN";

  let message = "";
  loadingTin.value = true;
  try {
    const isUnique = await uniqueTin(party.value.tin);
    if (!isUnique) message = "A party exists with this TIN";
  } catch (e) {
    message = "Unable to verify TIN, please try again";
  } finally {
    loadingTin.value = false;
  }
  loadingTin.value = false;
  return message;
}
async function validateTin() {
  tinValidationMessage.value = await tinValidator();
}

async function create() {
  if (loadingTin.value) return;
  const valid = await v$.value.$validate();
  if (!valid) return;
  let result;

  const body = NewCaseEntityToCreateRequest(party.value);
  if (props.caseId) {
    result = await caseCreateContractParty(props.caseId, body);
  } else {
    result = await createContractParty(body);
  }

  return result;
}

defineExpose({ create });
</script>
