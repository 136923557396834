<template>
  <v-row class="pa-3" dense v-if="loaded">
    <template v-if="!hasCategoryStates">
      <h3>No Editable Form Relations.</h3>
    </template>
    <template v-if="hasCategoryStates">
      <div v-for="set in states" :key="set.id">
        <v-col cols="12" style="text-align: left">
          <v-row class="pa-3">
            <h2 class="mb-2">{{ set.name }} - Category States</h2>
            <active-save-indicator
              :controller="savingBuffer[`state_${set.id}`]"
            />
          </v-row>
        </v-col>
        <v-row class="ma-0 flex-wrap" style="gap: 2px">
          <div v-for="state in set.states" :key="state.value">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :key="state.value"
                  :value="state.value"
                  :color="state.color"
                  :class="{ 'white--text': state.formId }"
                  :data-testid="`state-association-toggle-${state.text}`"
                  class="text-none pr-1 rounded-r-0"
                  rounded
                  depressed
                  @click="updateCategoryState(set, state)"
                >
                  {{ state.text }}
                </v-btn>
              </template>
              <span v-if="state.associatedWithCurrentForm">
                Remove {{ name }}'s association with
                {{ state.text }}
              </span>
              <span v-else-if="state.associatedWithOtherForm">
                Remove {{ name }}'s existing association and associate it with
                {{ state.text }}
              </span>
              <span v-else> Associate {{ name }} with {{ state.text }} </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  style="min-width: 36px"
                  class="text-none px-0 rounded-l-0"
                  rounded
                  depressed
                  :data-testid="`state-required-toggle-${state.text}`"
                  :color="state.color"
                  :class="{ 'white--text': state.formId }"
                  @click="toggleCategoryStateStatus(set, state)"
                >
                  <v-icon
                    v-if="state.isNotRequired"
                    data-testid="not-required-icon"
                  >
                    {{ mdiCircleOffOutline }}
                  </v-icon>
                  <v-icon v-else data-testid="required-icon">
                    {{ mdiAlertCircleOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-if="state.isNotRequired"> Mark as Required </span>
              <span v-else> Mark as not required </span>
            </v-tooltip>
          </div>
        </v-row>
      </div>
      <v-card flat class="pa-3 mt-3" outlined>
        <h4>Category States Legend</h4>
        <v-divider class="mt-1 mb-3" />
        <v-row align="center" class="pa-3">
          <div class="color-sample mr-3 ma-1 primary" />
          States Mapped to this Form
        </v-row>
        <v-row align="center" class="pa-3">
          <div class="color-sample mr-3 ma-1 orange" />
          States Mapped to other Form
        </v-row>
        <v-row align="center" class="pa-3">
          <div class="color-sample mr-3 ma-1 secondary" />
          Unmapped States
        </v-row>
      </v-card>
    </template>
  </v-row>
  <v-skeleton-loader v-else type="card" />
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { mdiCircleOffOutline, mdiAlertCircleOutline } from "@mdi/js";
import {
  searchForCategoryStates,
  updateIndividualCategoryState
} from "@/api/category-states.service";
import { formatToFullState, parseErrorMessage } from "@/util/helpers";
import { computed, ref, set, defineProps } from "vue";
import { storeToRefs } from "pinia";
import { useMappedFormStore } from "@/stores/mapped-form";
import { useSnackbarStore } from "@/stores/snackbar";
import { useActiveSave } from "@/composables/active-save.composable";

const props = defineProps({
  formId: [String, Number]
});
const snackbar = useSnackbarStore();
const formStore = useMappedFormStore(props.formId);
const { name, isContracting } = storeToRefs(formStore);
const loaded = ref(false);
const otherCategoryStates = ref([]);
const savingBuffer = {};

const states = computed(() => {
  const mappedStates = parent =>
    parent.category_states.map(stateObj => {
      const associatedWithCurrentForm = +stateObj.form?.id === +props.formId;
      const associatedWithOtherForm =
        stateObj.form?.id && +stateObj.form?.id !== +props.formId;

      let color = null;
      if (associatedWithCurrentForm) color = "primary";
      else if (associatedWithOtherForm) color = "orange";

      return {
        text: formatToFullState(stateObj.state.name),
        value: stateObj.id,
        formId: stateObj.form?.id,
        hasForm: Boolean(stateObj.form),
        isNotRequired: stateObj.status === "Not Required",
        associatedWithCurrentForm,
        associatedWithOtherForm,
        color,
        original: stateObj
      };
    });

  return otherCategoryStates.value.map(parent => ({
    name: parent.name,
    id: parent.id,
    states: mappedStates(parent).toSorted((a, b) =>
      a.text.localeCompare(b.text)
    )
  }));
});

const hasCategoryStates = computed(() => !isContracting.value);

async function getData() {
  loaded.value = false;
  try {
    const res = await searchForCategoryStates({ form_id: props.formId });
    otherCategoryStates.value = res;
    otherCategoryStates.value.forEach(s => {
      savingBuffer[`state_${s.id}`] = useActiveSave();
    });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
    otherCategoryStates.value = [];
  } finally {
    loaded.value = true;
  }
}

function updateCategoryState(parent, state) {
  const parentState = otherCategoryStates.value.find(p => p.id === parent.id);
  const currentState = parentState.category_states.find(
    cs => cs.id === state.value
  );
  let value = null;
  if (+currentState.form?.id !== +props.formId) {
    value = props.formId;
  }
  const body = { form_id: value, status: undefined };
  if (value) {
    body.status = "Incomplete";
    set(currentState, "status", "Incomplete");
  }
  set(currentState, "form", value ? { id: value } : null);

  savingBuffer[`state_${parentState.id}`].update(() =>
    updateIndividualCategoryState(currentState.id, body)
  );
}

function toggleCategoryStateStatus(parent, state) {
  const parentState = otherCategoryStates.value.find(p => p.id === parent.id);
  const currentState = parentState.category_states.find(
    cs => cs.id === state.value
  );
  let body = {};
  if (currentState.status !== "Not Required") {
    body = { status: "Not Required", form_id: null };
    set(currentState, "status", "Not Required");
    set(currentState, "form", null);
  } else {
    body = { status: "Incomplete" };
    set(currentState, "status", "Incomplete");
  }

  savingBuffer[`state_${parentState.id}`].update(() =>
    updateIndividualCategoryState(currentState.id, body)
  );
}

getData();
</script>
