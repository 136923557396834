<template>
  <v-row dense>
    <v-col
      v-for="(item, index) in resources"
      :key="index"
      cols="12"
      md="3"
      sm="6"
    >
      <quote-method
        v-if="item.href"
        :href="item.href"
        target="_blank"
        :item="item"
      />
      <quote-method v-else-if="item.to" :to="item.to" :item="item" />
      <quote-method v-else @click="item.click" :item="item" />
    </v-col>
    <v-col cols="12">
      <questionnaires-table class="pa-3" />
    </v-col>
  </v-row>
</template>

<script>
import QuoteMethod from "@/components/quotes/QuoteMethod.vue";
import QuestionnairesTable from "@/components/underwriting/QuestionnairesTable.vue";
import { mdiFilePdfBox } from "@mdi/js";

export default {
  name: "ApplicationsView",
  components: { QuestionnairesTable, QuoteMethod },
  data() {
    const resources = [
      {
        text: "PDF Applications",
        type: "Annuity & Life",
        description:
          "Download PDFs for new business and policy holder services. Requires a BackNine iPipeline account.",
        href: "https://formspipe.ipipeline.com/?GAID=2479",
        img: require("@/assets/img/ipipeline.png")
      },
      {
        text: "Application Request",
        description: "Retrieve new application forms.",
        type: "All Lines",
        to: { name: "ApplicationRequest" },
        img: "https://portal-pictures.back9ins.com/BOSS-Logo.png"
      },
      {
        text: "Informal Inquiry",
        type: "Life",
        description:
          "Your client's medical records are sent to carriers for underwriting offers. Available for premiums $10,000+.",
        href: "https://cdn.back9ins.com/forms/Informal+Inquiry.pdf",
        icon: mdiFilePdfBox,
        iconColor: "error"
      },
      {
        text: "Policy Review",
        type: "Annuity",
        description: "Used to obtain your client’s annuity policy information.",
        href: "https://cdn.back9ins.com/forms/Policy+Review+Authorization+-+Annuity.pdf",
        icon: mdiFilePdfBox,
        iconColor: "error"
      },
      {
        text: "Policy Review",
        type: "Life",
        description: "Used to obtain your client’s life policy information.",
        href: "https://cdn.back9ins.com/forms/Policy+Review+Authorization+-+Life.pdf",
        icon: mdiFilePdfBox,
        iconColor: "error"
      },
      {
        text: "iGo eApp",
        type: "Life",
        description:
          "Log-in to iPipeline's eApp. Requires a BackNine iPipeline Account.",
        href: "https://pipepasstoigo.ipipeline.com/default.aspx?gaid=2479",
        img: require("@/assets/img/ipipeline.png")
      },
      {
        text: "Prequalification Worksheet",
        type: "Life Settlement",
        description:
          "Get help determining if your life insurance policy is a good candidate for a life settlement.",
        href: "https://cdn.back9ins.com/help-desk/uploads/FDUB8MH4329ZACRY",
        icon: mdiFilePdfBox,
        iconColor: "error"
      },
      {
        text: "Application",
        type: "Life Settlement",
        description: "Start a life settlement application.",
        href: "https://cdn.back9ins.com/forms/BackNine.Life.Settlement.Application.pdf",
        icon: mdiFilePdfBox,
        iconColor: "error"
      }
    ];

    return {
      resources
    };
  }
};
</script>
