<template>
  <v-card flat tile>
    <v-card-text class="pt-0">
      <v-row class="pa-3 ma-0">
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <v-btn
          v-if="displayViewCaseButton"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="primary"
          rounded
          data-testid="action-view-case"
          :block="$vuetify.breakpoint.smAndDown"
          outlined
          :to="getRouterLink('Case', eAppCase.id)"
        >
          <v-icon class="mr-1"> {{ mdiBriefcase }} </v-icon> View Case
        </v-btn>
        <template v-if="displayContinueElectronicApplicationButton">
          <div :class="{ 'width-full': $vuetify.breakpoint.smAndDown }">
            <div class="row flex-nowrap ma-0 mr-1">
              <v-btn
                @click="resume"
                :loading="resuming"
                class="text-none my-1 grouped-button-left"
                :class="{
                  'ml-1': $vuetify.breakpoint.mdAndUp,
                  'grouped-centered-block-button': $vuetify.breakpoint.smAndDown
                }"
                color="primary"
                outlined
                rounded
                data-testid="action-continue"
              >
                <v-icon class="mr-1"> {{ mdiRocketLaunch }} </v-icon>Continue
              </v-btn>
              <v-menu offset-y left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="px-1 my-1 grouped-button-right"
                    outlined
                    rounded
                    data-testid="action-continue-options"
                    :class="{ 'mr-1': $vuetify.breakpoint.mdAndUp }"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-1"> {{ mdiMenuDown }} </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="originalApplyLinkText"
                    link
                    target="_blank"
                    data-testid="action-continue-domain"
                    :href="applyLink"
                  >
                    <v-list-item-icon>
                      <v-icon class="mr-1"> {{ mdiMenuDown }} </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        data-testid="action-continue-domain-text"
                      >
                        Open in {{ originalApplyLinkText }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Original URL</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    link
                    target="_blank"
                    data-testid="action-continue-backnine"
                    :href="publicApplyLink"
                  >
                    <v-list-item-icon>
                      <v-icon class="mr-1"> {{ mdiWeb }} </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        Open in back9ins.com
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Public, shareable with clients
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-menu v-if="canShare" offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                data-testid="action-share"
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="text-none my-1"
                outlined
                rounded
                :class="{
                  'mx-1': $vuetify.breakpoint.mdAndUp
                }"
                :block="$vuetify.breakpoint.smAndDown"
              >
                <v-icon class="mr-1"> {{ mdiShare }} </v-icon> Share
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                target="_blank"
                data-testid="action-copy-link"
                @click="copyLink"
              >
                <v-list-item-icon>
                  <v-icon class="mr-1"> {{ mdiContentCopy }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Copy Link </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link :href="shareLink" data-testid="action-email">
                <v-list-item-icon>
                  <v-icon class="mr-1"> {{ mdiEmail }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Email</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                data-testid="action-invite-client"
                @click="inviteEappClient"
              >
                <v-list-item-icon>
                  <v-icon class="mr-1"> {{ mdiAccountPlus }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Send Email/Text to Client
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn
          v-if="canSendChatTemplate"
          color="primary"
          class="text-none ma-1"
          outlined
          rounded
          data-testid="action-send-chat-template"
          @click="sendCustomChatTemplate"
        >
          <v-icon class="mr-1"> {{ mdiEmail }} </v-icon>
          Send Custom Chat Template
        </v-btn>
        <v-btn
          v-if="displayContinueDigitalApplicationButton"
          color="primary"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          outlined
          rounded
          data-testid="action-continue-carrier-eapp"
          :block="$vuetify.breakpoint.smAndDown"
          :loading="loadingCarrierEappLink"
          @click="resumeCarrierEappAction"
        >
          <v-icon class="mr-1"> {{ mdiRocketLaunch }} </v-icon>
          Continue Carrier eApp
        </v-btn>
        <v-btn
          v-if="isResendable"
          target="_blank"
          class="text-none my-1"
          data-testid="action-resend-docusign"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="primary"
          outlined
          rounded
          :block="$vuetify.breakpoint.smAndDown"
          @click="resend"
        >
          <v-icon class="mr-1"> {{ mdiSend }} </v-icon>
          Resend DocuSign Email
        </v-btn>
        <v-btn
          v-if="displayReopenApplicationForChangesButton"
          target="_blank"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="warning"
          outlined
          data-testid="action-reopen-eapp"
          :block="$vuetify.breakpoint.smAndDown"
          rounded
          @click="reopen"
        >
          <v-icon class="mr-1"> {{ mdiOpenInNew }} </v-icon>
          Reopen Application for Changes
        </v-btn>
        <v-btn
          v-if="isRedatable"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="warning"
          outlined
          rounded
          data-testid="action-redate"
          :block="$vuetify.breakpoint.smAndDown"
          @click="redate"
        >
          <v-icon class="mr-1"> {{ mdiCalendar }} </v-icon>
          Re-Date Application
        </v-btn>
        <v-btn
          v-if="isEditable"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="success"
          outlined
          rounded
          data-testid="action-edit"
          :block="$vuetify.breakpoint.smAndDown"
          @click="invalidateDocusign"
        >
          <v-icon class="mr-1"> {{ mdiPencil }} </v-icon>
          Edit eApp
        </v-btn>
        <v-btn
          v-if="canSkipPreAppointment"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="warning"
          rounded
          outlined
          data-testid="action-skip-preappointment"
          :block="$vuetify.breakpoint.smAndDown"
          @click="showSkipPreappointmentConfirmation"
        >
          <v-icon class="mr-1"> {{ mdiDebugStepOver }} </v-icon>
          Skip Pre-Appointment
        </v-btn>
        <v-btn
          v-if="canBypassAppointmentHold"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="warning"
          rounded
          outlined
          data-testid="action-bypass-appointment-hold"
          :block="$vuetify.breakpoint.smAndDown"
          @click="showAppointmentBypassConfirmation"
        >
          <v-icon class="mr-1"> {{ mdiDebugStepOver }} </v-icon>
          Bypass Appointment Hold
        </v-btn>
        <v-btn
          target="_blank"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="primary"
          outlined
          :block="$vuetify.breakpoint.smAndDown"
          data-testid="action-copy"
          rounded
          @click="copy"
        >
          <v-icon class="mr-1"> {{ mdiContentCopy }} </v-icon>
          Duplicate
        </v-btn>
        <v-btn
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="primary"
          outlined
          rounded
          data-testid="action-request-quote"
          :block="$vuetify.breakpoint.smAndDown"
          @click="requestQuote"
        >
          <v-icon class="mr-1"> {{ mdiClipboardAccount }} </v-icon>
          Request Quote
        </v-btn>
        <v-btn
          v-if="isDeletable"
          data-testid="action-delete"
          class="text-none my-1"
          :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }"
          color="error"
          outlined
          rounded
          :block="$vuetify.breakpoint.smAndDown"
          @click="destroy"
        >
          <v-icon class="mr-1"> {{ mdiDelete }} </v-icon>
          Delete
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import SendCustomChatTemplateDialog from "@/components/custom-chat-templates/SendCustomChatTemplateDialog.vue";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import SynthesizeQuoteRequestDialog from "@/dialogs/SynthesizeQuoteRequestDialog.vue";
import CopyDialog from "@/dialogs/CopyDialog.vue";

import { isMobile, parseErrorMessage, getRouterLink } from "@/util/helpers";

import { storeToRefs } from "pinia";

import {
  inviteClient,
  getResumeEAppUrl,
  requestCarrierEappUrl,
  copyElectronicApplication,
  destroyElectronicApplication,
  voidDocusign,
  reopenElectronicApplication,
  redateElectronicApplication,
  resendEnvelope,
  skipPreappointment,
  bypassAppointmentHold
} from "@/api/electronic-application.service";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { useEappViewStore } from "@/stores/eapp-view";
import { ref, computed, defineEmits, markRaw } from "vue";
import { useRouter } from "@/composables/compatible.composables";

import {
  mdiAccountPlus,
  mdiBriefcase,
  mdiCalendar,
  mdiClipboardAccount,
  mdiContentCopy,
  mdiDebugStepOver,
  mdiDelete,
  mdiEmail,
  mdiMenuDown,
  mdiOpenInNew,
  mdiPencil,
  mdiRocketLaunch,
  mdiSend,
  mdiShare,
  mdiWeb
} from "@mdi/js";

const emit = defineEmits(["reload"]);
const router = useRouter();
const eappView = useEappViewStore();
const snackbar = useSnackbarStore();
const dialog = useDialogStore();

const loadingCarrierEappLink = ref(false);
const resuming = ref(false);

const {
  case: eAppCase,
  insured,
  status,
  npn,
  resumeCarrierEapp,
  envelopeExpirationAt,
  id,
  isResendable,
  isRedatable,
  isEditable,
  isDeletable,
  canSendChatTemplate,
  latestQuotes,
  applyLink,
  canSkipPreAppointment,
  canBypassAppointmentHold
} = storeToRefs(eappView);

const canShare = computed(() => !latestQuotes.value.insured.advisor_use_only);

const publicApplyLink = computed(() => {
  const base = "https://back9ins.com/apply";
  const indexOfSearch = applyLink.value.indexOf("?");
  const search = applyLink.value.substr(indexOfSearch);
  return `${base}${search}&prefill&npn=${npn.value}`;
});
const originalApplyLinkText = computed(() => {
  if (!applyLink.value) return null;
  try {
    const url = new URL(applyLink.value);
    return url.origin;
  } catch (e) {
    return null;
  }
});
const shareLink = computed(() => {
  return `mailto:?subject=${
    insured.value.name
  }'s Quote %26 Apply Application&body=Here's a link to ${
    insured.value.name
  }'s application ${encodeURIComponent(applyLink.value)}`;
});
const displayReopenApplicationForChangesButton = computed(() => {
  if (resumeCarrierEapp.value) return false;
  return ["Sent", "Delivered"].includes(status.value);
});
const displayContinueElectronicApplicationButton = computed(() => {
  if (resumeCarrierEapp.value) return false;
  return Boolean(applyLink.value) && !envelopeExpirationAt.value;
});
const displayContinueDigitalApplicationButton = computed(() =>
  Boolean(resumeCarrierEapp.value)
);
const displayViewCaseButton = computed(() => Boolean(eAppCase.value?.id));

async function resume() {
  resuming.value = true;
  try {
    const url = await getResumeEAppUrl(id.value);
    if (isMobile()) window.location = url;
    else window.open(url);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    resuming.value = false;
  }
}

async function inviteEappClient() {
  snackbar.showInfoSnackbar({
    message: `Inviting ${insured.value.name}`
  });

  try {
    await inviteClient(id.value);
    snackbar.showSuccessSnackbar({
      message: `Successfully invited ${insured.value.name}`
    });
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  }
}

function showSkipPreappointmentConfirmation() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Skip Pre-Appointment",
    subtitle:
      "Please confirm that you would like this eApp to skip pre-appointment",
    func: async () => {
      try {
        const message = await skipPreappointment(id.value);
        canSkipPreAppointment.value = false;
        snackbar.showSuccessSnackbar({ message });
      } catch (e) {
        snackbar.showErrorSnackbar({
          message: parseErrorMessage(e),
          timeout: 10000
        });
      }
    }
  });
}

function showAppointmentBypassConfirmation() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Bypass Appointment Hold",
    subtitle:
      "Please confirm that you would like this eApp to bypass its appointment hold.",
    func: async () => {
      try {
        const message = await bypassAppointmentHold(id.value);
        canBypassAppointmentHold.value = false;
        snackbar.showSuccessSnackbar({ message });
      } catch (e) {
        snackbar.showErrorSnackbar({
          message: parseErrorMessage(e),
          timeout: 10000
        });
      }
    }
  });
}

function copyLink() {
  dialog.showDialog({
    component: markRaw(CopyDialog),
    text: applyLink.value,
    title: `${insured.value.name}'s eApp`,
    subtitle: "Just click copy to copy!"
  });
}

async function resumeCarrierEappAction() {
  loadingCarrierEappLink.value = true;
  try {
    const url = await requestCarrierEappUrl(id.value);
    if (isMobile()) window.location = url;
    else window.open(url, "_blank");
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e),
      timeout: -1
    });
  } finally {
    loadingCarrierEappLink.value = false;
  }
}

async function resend() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to resend the DocuSign?",
    confirmText: "Resend",
    subtitle: "This cannot be undone",
    func: async () => {
      await resendEnvelope(id.value);
      snackbar.showSuccessSnackbar({
        message: "DocuSign Successfully Resent",
        timeout: 6000
      });
    }
  });
}

async function reopen() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to reopen this eApp?",
    confirmText: "Reopen",
    subtitle: "This cannot be undone",
    func: async () => {
      await reopenElectronicApplication(id.value);
      emit("reload");
    }
  });
}

async function redate() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to redate this eApp?",
    confirmText: "Redate",
    subtitle: "This cannot be undone",
    func: async () => {
      await redateElectronicApplication(id.value);
      emit("reload");
    }
  });
}

async function invalidateDocusign() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to edit this eApp?",
    confirmText: "Void",
    subtitle:
      "This will void this eApp's Docusign. If a signer has already eSigned they will need to eSign again.",
    func: async () => {
      await voidDocusign(id.value);
      emit("reload");
    }
  });
}

async function destroy() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to permanently delete eApp?",
    confirmText: "Destroy",
    subtitle: "This cannot be undone",
    func: async () => {
      await destroyElectronicApplication(id.value);
      if (router) {
        router.push({ name: "QuoteAndApply", query: { view: "list" } });
      }
    }
  });
}

function copy() {
  const func = async () => {
    const newId = await copyElectronicApplication(id.value);
    if (router) {
      router.push({
        name: "ElectronicApplicationView",
        params: {
          id: newId
        }
      });
    }
  };

  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Are you sure you want to copy this electronic application?",
    func
  });
}

function requestQuote() {
  dialog.showDialog({
    component: markRaw(SynthesizeQuoteRequestDialog),
    id: id.value,
    idBodyKey: "eapp_id",
    scrollable: true
  });
}

function sendCustomChatTemplate() {
  dialog.showDialog({
    component: markRaw(SendCustomChatTemplateDialog),
    width: "100%",
    scrollable: true,
    persistent: true,
    emailSubject: {
      type: "ElectronicApplication",
      id: id.value
    }
  });
}
</script>
