import { Carrier, setCarrierFromRaw } from "@/factories/CarrierFactory";
import { setNoteFromRequest } from "@/factories/Note";
export function InformalOffer(model = {}) {
  return {
    carrier: Carrier(model?.carrier),
    id: model?.id || null,
    name: model?.name || null,
    offer: model?.offer || "",
    underwritingRationale: model?.underwritingRationale || "",
    underwriter: InformalOfferUnderwriter(model?.underwriter),
    notes: model?.notes || []
  };
}

export function setInformalOfferFromRequest(model = {}) {
  const informalOffer = InformalOffer();
  informalOffer.id = model?.id;
  informalOffer.name = model?.name;
  informalOffer.offer = model?.underwriting_offer;
  informalOffer.carrier = setCarrierFromRaw(model?.carrier);
  informalOffer.underwritingRationale = model?.underwriting_rational;
  informalOffer.underwriter = setInformalOfferUnderwriterFromRequest(
    model?.underwriter
  );

  model?.notes?.forEach(n => {
    informalOffer.notes.push(setNoteFromRequest(n));
  });
  return informalOffer;
}

export function InformalOfferToRequest(informal, sendEmail = false) {
  return {
    send_email: sendEmail,
    informal_offer: {
      carrier_id: informal.carrier.id,
      name: informal.name,
      personnel_id: informal.underwriter?.id,
      underwriting_offer: informal.offer,
      underwriting_rational: informal.underwritingRationale
    }
  };
}

function InformalOfferUnderwriter(model = {}) {
  return {
    name: model?.name || "",
    id: model?.id || 0
  };
}

function setInformalOfferUnderwriterFromRequest(model = {}) {
  const underwriter = InformalOfferUnderwriter();
  underwriter.name = model?.name;
  underwriter.id = model?.id;
  return underwriter;
}
