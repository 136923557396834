<template>
  <div :id="parentName" style="line-height: 0"></div>
</template>

<script setup>
import { createHighDPICanvas } from "@/util/helpers";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  watch,
  defineProps,
  defineEmits
} from "vue";
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  height: {
    type: [Number, String],
    required: false,
    default: 0
  },
  width: {
    type: [Number, String],
    required: false,
    default: 0
  },
  scale: {
    type: [Number],
    required: false,
    default: 1
  }
});

const emit = defineEmits([
  "ready",
  "redraw",
  "mousedown",
  "mousemove",
  "mouseup",
  "mouseout",
  "redraw",
  "ready",
  "click"
]);

let timer;

const parentName = computed(() => `${props.id}-parent`);

onMounted(() => {
  instantiate();
  emit("ready");
});

watch([() => props.height, () => props.width], debounceAndRedraw);
onBeforeUnmount(() => clearTimeout(timer));

function debounceAndRedraw() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    instantiate();
    emit("redraw");
  }, 10);
}

function instantiate() {
  const existingEl = document.getElementById(props.id);
  if (existingEl) existingEl.remove();

  const div = document.getElementById(parentName.value);
  const canvas = createHighDPICanvas(props.width, props.height);
  canvas.id = props.id;
  canvas.classList.add("bordered-canvas");
  const ctx = canvas.getContext("2d");
  if (props.scale) ctx.scale(props.scale, props.scale);
  const events = ["mousedown", "mousemove", "mouseup", "mouseout", "click"];
  events.forEach(e => canvas.addEventListener(e, v => emit(e, v)));

  div.appendChild(canvas);
}
</script>

<style>
.bordered-canvas {
  border: 1px solid #ccc;
}
</style>
