<template>
  <v-col v-if="loading" cols="12" class="pa-0">
    <v-skeleton-loader type="card" style="height: 50px" />
    <v-skeleton-loader type="card" />
    <v-skeleton-loader type="card" />
  </v-col>
  <v-col v-else cols="12" class="pa-0">
    <lead-view-card />
    <lead-view-actions />
    <v-tabs v-model="activeTab">
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
        class="text-none"
        :data-testid="`${tab.page}-tab`"
      >
        <v-icon class="pr-1">{{ tab.icon }}</v-icon>
        <v-badge v-if="tab.badgeProps" v-bind="tab.badgeProps">
          {{ tab.title }}
        </v-badge>
        <template v-else>{{ tab.title }}</template>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.title" :eager="tab.eager">
        <component :is="tab.component" v-bind="tab.props || {}" />
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script setup>
import LeadViewCard from "@/components/leads/LeadViewCard.vue";
import LeadViewActions from "@/components/leads/LeadViewActions.vue";
import LeadViewChat from "@/components/leads/LeadViewChat.vue";
import LeadViewDetails from "@/components/leads/LeadViewDetails.vue";
import LeadViewToDoList from "@/components/leads/LeadViewToDoList.vue";

import { storeToRefs } from "pinia";
import { useSnackbarStore } from "@/stores/snackbar";
import { useInstanceStore } from "@/stores/instance";
import { useLeadView } from "@/stores/lead-view";

import { parseErrorMessage } from "@/util/helpers";
import { useHead } from "@unhead/vue";
import {
  onBeforeUnmount,
  ref,
  defineProps,
  watch,
  markRaw,
  toRef,
  computed
} from "vue";
import { useRouter } from "@/composables/compatible.composables";
import { mdiCheck, mdiFileDocumentEdit, mdiMessage } from "@mdi/js";
import { useFullEmailViewer } from "@/composables/full-email.composable";

const head = useHead({ title: "Lead Viewer" });

const props = defineProps({
  id: { type: [String, Number], required: true },
  page: { type: String, required: false, default: null },
  highlightTodo: {
    type: Number,
    required: false,
    default: null
  },
  highlightNote: {
    type: Number,
    required: false,
    default: null
  }
});
const highlightTodo = toRef(props, "highlightTodo");

const router = useRouter();
const snackbar = useSnackbarStore();
const leadView = useLeadView();
const instance = useInstanceStore();

const { breadcrumb } = storeToRefs(instance);
const { name, todoCount } = storeToRefs(leadView);

const loading = ref(false);
const activeTab = ref(0);

const tabs = computed(() => [
  {
    icon: mdiMessage,
    title: "Chat",
    page: "chat",
    eager: true,
    component: markRaw(LeadViewChat)
  },
  {
    icon: mdiCheck,
    title: "To-Do",
    page: "to-do",
    component: markRaw(LeadViewToDoList),
    props: {
      highlightTodo: highlightTodo.value
    },
    badgeProps: todoCount.value
      ? {
          color: "error",
          content: todoCount.value,
          "data-testid": "todo-badge"
        }
      : null,
    eager: true
  },
  {
    icon: mdiFileDocumentEdit,
    title: "Details",
    page: "details",
    component: markRaw(LeadViewDetails)
  }
]);

function syncQueryToTab() {
  if (!router) return;
  let page = tabs.value[activeTab.value].page;
  if (props.page === page || !page) return;
  router.replace({ query: { page } });
}

async function fetchLead() {
  try {
    loading.value = true;
    await leadView.initialize(props.id);
    const tempPage = tabs.value.findIndex(v => v.page === props.page);
    if (tempPage > -1) activeTab.value = tempPage;

    if (props.highlightNote) {
      router.replace({ query: { "highlight-note": undefined } });
      const fullEmailViewer = useFullEmailViewer(props.highlightNote);
      fullEmailViewer.retrieveFullEmail();
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    if (router) router.replace({ name: "Home" });
  } finally {
    loading.value = false;
  }
}

watch(activeTab, (newV, oldV) => {
  if (newV === oldV) return;
  syncQueryToTab();
});

watch(
  name,
  () => {
    breadcrumb.value = name.value;
    head.patch({ title: name.value });
  },
  { immediate: true }
);

onBeforeUnmount(() => (breadcrumb.value = ""));

fetchLead();
</script>
