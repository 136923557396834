export function QueuedTransaction(model = {}) {
  return {
    amount: model?.amount,
    case: model?.caseId,
    commissionType: model?.commissionType,
    errorMessage: model?.errorMessage,
    id: model?.id,
    isIgnored: model?.isIgnored,
    policyNumber: model?.policyNumber,
    premium: model?.premium,
    rawData: model?.rawData,
    transactorId: model?.transactorId,
    transactorName: model?.transactorName,
    transactorType: model?.transactorType,
    key: model?.key
  };
}

export function setQueuedTransactionFromRequest(raw = {}) {
  const model = QueuedTransaction();
  model.amount = raw?.amount;
  model.commissionType = raw?.commission_type;
  model.errorMessage = raw?.error_message;
  model.id = raw?.id;
  model.isIgnored = raw?.ignore;

  if (raw?.case) {
    model.case = setQueuedTransactionCaseFromRequest(raw.case);
  }

  if (raw?.statement) {
    model.statement = setQueuedTransactionStatementFromRequest(raw.statement);
  }

  model.policyNumber = raw?.policy_number;
  model.premium = raw?.premium;
  model.transactorId = raw?.transactor?.id;
  model.transactorName = raw?.transactor?.name;
  model.transactorType = raw?.transactor?.type;
  if (!model.case?.id && !model.errorMessage) {
    model.errorMessage = "Invalid Policy # (Unable to locate case)";
  }

  if (raw?.data) {
    model.rawData = Object.keys(raw.data)
      .toSorted((a, b) => a.localeCompare(b))
      .reduce((acc, key) => ({ ...acc, [key]: raw.data[key] }), {});
  }

  model.key = [
    model.id,
    model.caseId,
    model.premium,
    model.amount,
    model.commissionType,
    model.isIgnored
  ].join("-");

  return model;
}

function QueuedTransactionCase(model = {}) {
  return {
    id: model?.id,
    policyNumber: model?.policyNumber,
    insuredName: model?.insuredName
  };
}

function setQueuedTransactionCaseFromRequest(raw = {}) {
  const model = QueuedTransactionCase();
  model.id = raw?.id;
  model.policyNumber = raw?.policy_number;
  model.insuredName = raw?.insured_name;
  return model;
}

function QueuedTransactionStatement(model = {}) {
  return {
    id: model?.id,
    date: model?.date,
    payor: QueuedTransactionStatementPayor(model?.payor)
  };
}

function setQueuedTransactionStatementFromRequest(raw = {}) {
  const model = QueuedTransactionStatement();
  model.id = raw?.id;
  model.date = raw?.date;
  model.payor = setQueuedTransactionStatementPayorFromRequest(raw?.payor);
  return model;
}

function QueuedTransactionStatementPayor(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    type: model?.type
  };
}

function setQueuedTransactionStatementPayorFromRequest(raw = {}) {
  const model = QueuedTransactionStatementPayor();
  model.id = raw?.id;
  model.name = raw?.name;
  model.type = raw?.type;
  return model;
}
