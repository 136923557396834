import * as groups from "@/constants/group.constants";
import sortBy from "lodash/sortBy";
import { cloneDeep } from "@/util/helpers";
import { useDialogStore } from "@/stores/dialog";
import { PERMISSION } from "@/constants/permissions.constants";
import {
  mdiAccountSupervisor,
  mdiBriefcaseVariant,
  mdiBullhorn,
  mdiCalculator,
  mdiCash,
  mdiCubeOutline,
  mdiCurrencyUsd,
  mdiFileChart,
  mdiFileDocumentEdit,
  mdiFileDocumentOutline,
  mdiGolf,
  mdiHome,
  mdiLifebuoy,
  mdiRocketLaunch,
  mdiTable
} from "@mdi/js";

// Filters
const atLeastGroupTwoAccess = user => groups.GROUP_2 <= user.group;
const onlyGroupOneAccess = user => groups.GROUP_1 === user.group;
const canViewFormMap = user => user.permissions[PERMISSION.FORM_MAPPING];
const canViewAccounting = user => user.permissions[PERMISSION.ACCOUNTING];

const canViewAccountingAndCommissions = user =>
  user.loginable.show_backnine && !user.loginable.hide_commission;

const notGroupFourAndCommissions = user =>
  !user.permissions[PERMISSION.ACCOUNTING] &&
  !user.loginable.hide_commission &&
  user.loginable.show_backnine;
const isCaseManager = user => user.loginable.is_case_manager;
const showBacknine = user => user.loginable.show_backnine;

const hasApprovedDomain = user => user.loginable.approved_domain_url;
const missingApprovedDomain = user => !user.loginable.approved_domain_url;

const isGroupOneAndScorecard = user =>
  user.group === groups.GROUP_1 && user.permissions[PERMISSION.SCORECARD];

const home = {
  title: "Home",
  routeName: "Home",
  icon: mdiHome,
  id: "v-step-3"
};

const emailMarketing = {
  icon: mdiBullhorn,
  routeName: "MarketingEmails",
  title: "Marketing",
  if: hasApprovedDomain
};

const marketingCreateApprovedDomain = {
  icon: mdiBullhorn,
  click: () => {
    const dialog = useDialogStore();
    dialog.showDialog({
      component: "CreateQuoteAndApplySite",
      scrollable: true,
      title: "Marketing requires a Quote & Apply Website",
      subtitle: ""
    });
  },
  title: "Marketing",
  if: missingApprovedDomain
};

const quoting = {
  title: "Quoting",
  icon: mdiCalculator,
  if: showBacknine,
  routeName: "Quotes"
};

const quoteAndApply = {
  title: "Quote & Apply",
  icon: mdiRocketLaunch,
  routeName: "QuoteAndApply",
  id: "quote-and-apply-nav"
};

const commissions = {
  title: "Commissions",
  icon: mdiCash,
  if: canViewAccounting,
  id: "commissions-nav",
  subItems: [
    {
      title: "B9 Dashboard",
      routeName: "BackNineCommissionsDashboard",
      if: canViewAccounting
    },
    {
      title: "B9 Statements",
      routeName: "BackNineCommissionsStatements",
      if: canViewAccounting
    },
    {
      title: "Pending Transactions",
      routeName: "BackNinePendingTransactions",
      if: canViewAccounting
    },
    {
      title: "Commissions",
      routeName: "Commissions"
    }
  ]
};

const products = {
  title: "Products",
  routeName: "Products",
  icon: mdiCubeOutline,
  if: canViewAccountingAndCommissions
};

const payPeriods = {
  title: "Commissions",
  routeName: "Commissions",
  icon: mdiCurrencyUsd,
  if: notGroupFourAndCommissions
};

const formMapping = {
  title: "Form Mapping",
  routeName: "FormMappingForms",
  if: canViewFormMap,
  icon: mdiFileDocumentEdit
};

const cases = {
  icon: mdiBriefcaseVariant,
  title: "Cases",
  routeName: "CasesTable",
  id: "cases-nav"
};

const tables = {
  title: "Tables",
  icon: mdiTable,
  routeName: "Tables"
};

const caseManagerView = {
  title: "Case Management",
  icon: mdiAccountSupervisor,
  routeName: "CaseManagement",
  if: isCaseManager
};

const reports = {
  title: "Reports",
  icon: mdiFileChart,
  if: atLeastGroupTwoAccess,
  subItems: [
    {
      title: "BackNine",
      routeName: "BackNineReportView"
    },
    {
      title: "Case Managers",
      routeName: "CaseManagementReportView"
    },
    {
      title: "Holidays",
      routeName: "HolidaysView"
    },
    {
      title: "Marketing Managers",
      routeName: "MarketingManagerReportView"
    },
    {
      title: "Personal",
      routeName: "PersonalReportView"
    },
    {
      title: "Scorecard",
      routeName: "ScorecardReport"
    }
  ]
};

const reportsGroupOne = {
  title: "Reports",
  routeName: "PersonalReportView",
  icon: mdiFileChart,
  if: onlyGroupOneAccess
};

const scorecardGroupOne = {
  title: "Scorecard",
  routeName: "ScorecardReport",
  icon: mdiGolf,
  if: isGroupOneAndScorecard
};

const helpCenter = {
  title: "Help Center",
  icon: mdiLifebuoy,
  routeName: "HelpCenter"
};

const formsAndApplications = {
  title: "Forms & Applications",
  icon: mdiFileDocumentOutline,
  routeName: "Applications",
  if: showBacknine
};

const routes = [
  home,
  cases,
  formsAndApplications,
  commissions,
  formMapping,
  emailMarketing,
  marketingCreateApprovedDomain,
  helpCenter,
  quoteAndApply,
  quoting,
  reports,
  reportsGroupOne,
  scorecardGroupOne,
  tables,
  products,
  payPeriods,
  caseManagerView
];

const getFilteredRoutes = user => {
  const allowedRoutes = [];

  const sortFunc = value => {
    if (value.title === "Home") {
      return 1;
    }

    if (value.title === "eApps") {
      return "EApps";
    }
    if (value.title === "Quote & Apply") {
      return "a";
    }

    return value.title.toLowerCase();
  };

  routes.forEach(route => {
    const currRoute = cloneDeep(route);

    if (currRoute.if && !currRoute.if(user)) {
      return;
    }

    if (currRoute.linkFrom) {
      currRoute.externalLink = currRoute.linkFrom(user);
    }

    if (!route.subItems) {
      allowedRoutes.push(currRoute);
      return;
    }

    currRoute.subItems = sortBy(
      currRoute.subItems.filter(subRoute => {
        return !subRoute.if || subRoute.if(user);
      }),
      sortFunc
    );

    currRoute.subItems.map(subRoute => {
      if (subRoute.linkFrom) {
        subRoute.externalLink = subRoute.linkFrom(user);
      }

      if (!subRoute.subItems) return subRoute;

      subRoute.subItems = subRoute.subItems.filter(
        val => !val.if || val.if(user)
      );
      subRoute.subItems.forEach(route => {
        if (route.linkFrom) {
          route.externalLink = route.linkFrom(user);
        }
      });
      return route;
    });

    allowedRoutes.push(currRoute);
  });

  return sortBy(allowedRoutes, sortFunc);
};

export { routes, getFilteredRoutes };
