<template>
  <v-row dense>
    <v-col cols="12" v-if="props.quoteSave">
      <v-autocomplete
        v-model="quoteData.status"
        data-testid="input-status"
        :prepend-inner-icon="mdiListStatus"
        outlined
        dense
        label="Status"
        :disabled="props.disabled"
        :items="STATUSES"
        :success="statusValidation.success"
        :error-messages="statusValidation.errorMessages"
        @change="savingBuffer.status.update(saveWrapper('status'))"
      >
        <template #append-outer>
          <active-save-indicator
            :controller="savingBuffer.status.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="quoteData.state"
        data-testid="input-state"
        :prepend-inner-icon="mdiSignRealEstate"
        label="State"
        outlined
        dense
        auto-select-first
        item-text="full"
        item-value="text"
        :disabled="props.disabled"
        :items="stateItems"
        :success="stateValidation.success"
        :error-messages="stateValidation.errorMessages"
        @change="savingBuffer.state.update(saveWrapper('state'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.state.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="quoteData.mode"
        data-testid="input-mode"
        :prepend-inner-icon="mdiCalendar"
        label="Mode"
        outlined
        dense
        :disabled="props.disabled"
        :items="MODES"
        :success="modeValidation.success"
        :error-messages="modeValidation.errorMessages"
        @change="savingBuffer.mode.update(saveWrapper('mode'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.mode.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <currency-input
        v-model="quoteData.monthlyBenefit"
        data-testid="input-monthly-benefit"
        :prepend-inner-icon="mdiCurrencyUsd"
        label="Monthly Benefit"
        outlined
        dense
        include-decimals
        :disabled="props.disabled"
        :success="monthlyBenefitValidation.success"
        :error-messages="monthlyBenefitValidation.errorMessages"
        @input="
          savingBuffer.monthlyBenefit.debounceUpdate(
            saveWrapper('monthlyBenefit')
          )
        "
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.monthlyBenefit.controller.value"
          />
        </template>
      </currency-input>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="quoteData.benefitPeriod"
        data-testid="input-benefit-period"
        :prepend-inner-icon="mdiCurrencyUsd"
        label="Benefit Period"
        outlined
        dense
        item-value="text"
        :disabled="props.disabled"
        :items="BENEFIT_PERIODS"
        :success="benefitPeriodValidation.success"
        :error-messages="benefitPeriodValidation.errorMessages"
        @change="
          savingBuffer.benefitPeriod.update(saveWrapper('benefitPeriod'))
        "
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.benefitPeriod.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="quoteData.eliminationPeriod"
        data-testid="input-elimination-period"
        :prepend-inner-icon="mdiCalendar"
        label="Elimination Period"
        outlined
        dense
        :disabled="props.disabled"
        :items="ELIMINATION_PERIODS"
        :success="eliminationPeriodValidation.success"
        :error-messages="eliminationPeriodValidation.errorMessages"
        @change="
          savingBuffer.eliminationPeriod.update(
            saveWrapper('eliminationPeriod')
          )
        "
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.eliminationPeriod.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="quoteData.inflation"
        data-testid="input-inflation"
        :prepend-inner-icon="mdiChartAreaspline"
        label="Inflation"
        outlined
        dense
        item-text="text"
        item-value="value"
        :disabled="props.disabled"
        :items="INFLATION_ITEMS"
        :success="inflationValidation.success"
        :error-messages="inflationValidation.errorMessages"
        @change="savingBuffer.inflation.update(saveWrapper('inflation'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.inflation.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="quoteData.discount"
        data-testid="input-discount"
        :prepend-inner-icon="mdiChartAreaspline"
        label="Discount"
        outlined
        dense
        item-text="text"
        item-value="value"
        :disabled="props.disabled"
        :items="discountItems"
        :success="discountValidation.success"
        :error-messages="discountValidation.errorMessages"
        @change="savingBuffer.discount.update(saveWrapper('discount'))"
      >
        <template #append-outer v-if="props.quoteSave">
          <active-save-indicator
            :controller="savingBuffer.discount.controller.value"
          />
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { states as stateItems } from "@/data/states";
import { ELIMINATION_PERIODS } from "@/data/case-data";
import {
  INFLATION_ITEMS,
  BENEFIT_PERIODS,
  discountItems
} from "@/data/quote-request-items";
import { STATUSES, MODES, QuoteRequestLtcInfo } from "@/factories/Quote";
import { computedValidation } from "@/util/helpers";
import { useActiveSave } from "@/composables/active-save.composable";
import useVuelidate from "@vuelidate/core";

import { defineProps, defineEmits, ref, watch, onMounted } from "vue";

import {
  mdiListStatus,
  mdiSignRealEstate,
  mdiCalendar,
  mdiCurrencyUsd,
  mdiChartAreaspline
} from "@mdi/js";

const props = defineProps({
  disabled: Boolean,
  benefitPeriod: { type: String, default: "" },
  eliminationPeriod: { type: String, default: "" },
  inflation: { type: String, default: null },
  discount: { type: String, default: null },
  mode: { type: String, default: null },
  monthlyBenefit: { type: Number, default: null },
  state: { type: String, default: null },
  status: { type: String, default: "Pending" },
  quoteSave: {
    type: Function,
    required: false,
    default: null
  }
});

const quoteData = ref(QuoteRequestLtcInfo(props));
const emit = defineEmits([
  ...Object.keys(QuoteRequestLtcInfo()).map(key => `update:${key}`),
  "save",
  "prompt-for-quote-and-apply"
]);

const savingBuffer = {};
Object.keys(QuoteRequestLtcInfo()).forEach(key => {
  savingBuffer[key] = useActiveSave();
});

onMounted(() => emit("prompt-for-quote-and-apply"));

const v$ = useVuelidate(
  {
    quoteData: {
      state: { required: v => !!v },
      mode: { required: v => !!v },
      monthlyBenefit: { required: v => !!v },
      benefitPeriod: { required: v => !!v },
      eliminationPeriod: { required: v => !!v },
      inflation: { required: true },
      discount: {
        required: value => discountItems.some(v => v.value === value)
      }
    },
    status: { required: v => !props.quoteSave || !!v }
  },
  { quoteData },
  { $scope: "quote-request", $autoDirty: true }
);

const statusValidation = computedValidation(v$.value.quoteData.status, [
  { key: "required", message: "Required" }
]);

const stateValidation = computedValidation(v$.value.quoteData.state, [
  { key: "required", message: "Required" }
]);

const modeValidation = computedValidation(v$.value.quoteData.mode, [
  { key: "required", message: "Required" }
]);

const monthlyBenefitValidation = computedValidation(
  v$.value.quoteData.monthlyBenefit,
  [{ key: "required", message: "Required" }]
);

const benefitPeriodValidation = computedValidation(
  v$.value.quoteData.benefitPeriod,
  [{ key: "required", message: "Required" }]
);

const eliminationPeriodValidation = computedValidation(
  v$.value.quoteData.eliminationPeriod,
  [{ key: "required", message: "Required" }]
);

const inflationValidation = computedValidation(v$.value.quoteData.inflation, [
  { key: "required", message: "Required" }
]);

const discountValidation = computedValidation(v$.value.quoteData.discount, [
  { key: "required", message: "Required" }
]);

Object.keys(QuoteRequestLtcInfo()).forEach(k => {
  watch(
    () => quoteData.value[k],
    v => {
      emit(`update:${k}`, v);
    },
    { deep: true }
  );
});

function saveWrapper(key) {
  if (props.quoteSave) return () => props.quoteSave(key);
  return () => {};
}
</script>
