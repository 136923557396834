export const COMMISSION_TYPES = [
  "Bonus",
  "Excess",
  "First Year",
  "PUA",
  "Renewal",
  "Term Rider"
];

export const PAYMENT_TYPES = ["Flat", "Net Retained", "Retained", "Street"];
