<template>
  <v-card flat tile>
    <v-card-title> Email </v-card-title>
    <v-card-subtitle>
      Update email preferences and whitelist trusted senders.
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <email-address :module="props.module" :readonly="props.readonly" />
        </v-col>
        <v-col cols="12">
          <email-notifications
            :module="props.module"
            :readonly="props.readonly"
          />
        </v-col>
        <v-col cols="12">
          <email-trusted-senders
            :module="props.module"
            :readonly="props.readonly"
          />
        </v-col>
        <v-col cols="12">
          <email-signature
            v-if="showEmailSignature"
            data-testid="email-signature"
            :module="props.module"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script setup>
import EmailSignature from "@/components/settings/email/EmailSignature.vue";
import EmailNotifications from "@/components/settings/email/EmailNotifications.vue";
import EmailTrustedSenders from "@/components/settings/email/EmailTrustedSenders.vue";
import EmailAddress from "@/components/settings/email/EmailAddress.vue";

import { useUserStore } from "@/stores/user";
import { AGENT_SETTINGS } from "@/stores/agent-settings";
import { AGENCY_SETTINGS } from "@/stores/agency-settings";
import { computed, defineProps, onMounted, defineEmits } from "vue";
const props = defineProps({
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  },
  readonly: Boolean,
  isViewingSelf: Boolean
});
const user = useUserStore();

const showEmailSignature = computed(
  () => props.isViewingSelf && user.loginable.is_employee
);

const emit = defineEmits(["mounted"]);
onMounted(() => emit("mounted"));
</script>
