import { getHttpClient } from "@/http-client";
import { setApplicationQuestionLinkFromRequest } from "@/factories/FormMapping";
const baseUrl = "/api/boss/application_question_links";

export const updateApplicationQuestionLink = async (id, body) => {
  const { data } = await getHttpClient().put(`${baseUrl}/${id}`, {
    application_question_link: body
  });
  return setApplicationQuestionLinkFromRequest(data);
};

export const createApplicationQuestionLink = async ({ form_id, body }) => {
  const { data } = await getHttpClient().post(baseUrl, {
    application_question_link: { ...body, form_id }
  });

  return setApplicationQuestionLinkFromRequest(data);
};

export const deleteApplicationQuestionLink = id => {
  return getHttpClient().delete(`${baseUrl}/${id}`);
};
