<template>
  <chat-view
    no-documents
    :card-props="{ color: 'section', tile: false, flat: true }"
    :color="null"
    :title="title"
    :pusher-id="pusherId"
    :messages="notes"
    :room="room"
    :create-func="sendChatMessage"
    @new-note="newNote"
    @remove-message="removeFromMessages"
  >
    <template #title>
      <slot name="back-button" />
    </template>
  </chat-view>
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";

import {
  Note,
  NoteToCreateRequest,
  setNoteFromRequest
} from "@/factories/Note";

import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";

import { createNote } from "@/api/notes.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { computed } from "vue";
import { useImpairedRiskQuoteOffer } from "@/stores/impaired-risk-quote-offer";
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";

const snackbar = useSnackbarStore();
const impairedRiskQuote = useImpairedRiskQuoteView();
const activeOfferStore = useImpairedRiskQuoteOffer();
const { room } = storeToRefs(impairedRiskQuote);
const { notes, pusherId, carrier, id: offerId } = storeToRefs(activeOfferStore);

const title = computed(() => carrier.value.name);

function removeFromMessages(message) {
  const index = notes.value.findIndex(({ id }) => id === message.id);
  if (index > -1) notes.value.splice(index, 1);
}

function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}
async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: 1,
    phoneCall: false,
    note: note.message,
    notableId: offerId.value,
    notableType: "InformalOffer"
  });
  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}
</script>
