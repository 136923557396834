import { setNoteFromRequest } from "@/factories/Note";
export function CompBuilder(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    carrier: model?.carrier || null,
    street: Boolean(model?.street),
    noPay: Boolean(model?.noPay),
    notes: model?.notes || []
  };
}

export function setCompBuilderFromRequest(model = {}) {
  const compBuilder = CompBuilder();
  compBuilder.id = model?.id || null;
  compBuilder.name = model?.name || null;
  compBuilder.carrier = model?.carrier || null;
  compBuilder.street = Boolean(model?.street);
  compBuilder.noPay = Boolean(model?.no_pay);
  compBuilder.notes = model?.notes?.map(setNoteFromRequest);
  return compBuilder;
}
