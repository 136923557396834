<template>
  <v-tooltip v-if="props.fullEmail" top>
    <template #activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        x-small
        icon
        data-testid="inspect-full-email"
        color="primary"
        :loading="fullEmailViewer.gettingFullEmail.value"
        @click="fullEmailViewer.retrieveFullEmail"
      >
        <v-icon small> {{ mdiEmail }} </v-icon>
      </v-btn>
    </template>
    <span> Emailed - Inspect Full Email </span>
  </v-tooltip>
  <v-tooltip v-else top>
    <template #activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" style="margin: 2px" small>
        {{ mdiEmail }}
      </v-icon>
    </template>
    <span> Emailed </span>
  </v-tooltip>
</template>

<script setup>
import { mdiEmail } from "@mdi/js";
import { defineProps } from "vue";
import { useFullEmailViewer } from "@/composables/full-email.composable";
const props = defineProps({
  fullEmail: Boolean,
  messageId: { type: Number, required: true }
});

const fullEmailViewer = useFullEmailViewer(props.messageId);
</script>
