import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { useDialogStore } from "@/stores/dialog";
import { useSnackbarStore } from "@/stores/snackbar";
import { parseErrorMessage } from "@/util/helpers";
import { getFullEmail } from "@/api/notes.service";
import { markRaw, ref } from "vue";

export const useFullEmailViewer = messageId => {
  const gettingFullEmail = ref(false);
  const dialog = useDialogStore();
  const snackbar = useSnackbarStore();
  async function retrieveFullEmail() {
    try {
      gettingFullEmail.value = true;
      const html = await getFullEmail(messageId);
      dialog.showDialog({
        component: markRaw(ConfirmationDialog),
        title: "Full Email Viewer",
        html,
        htmlWrapperClass: "white-space-pre-wrap",
        scrollable: true,
        hideCancel: true,
        confirmText: "Close"
      });
    } catch (e) {
      snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
    } finally {
      gettingFullEmail.value = false;
    }
  }

  return { retrieveFullEmail, gettingFullEmail };
};
