<template>
  <v-card flat color="section">
    <v-card-title>Commission Admin Settings</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col v-if="!store.isSaas" cols="12" md="6">
          <div class="checkbox-width">
            <v-checkbox
              v-model="annualization"
              class="mt-1"
              data-testid="commission-annualization"
              hide-details
              :success="annualization"
              :disabled="!user.isGroupThreePlus || props.readonly"
              @change="updateAnnualization"
            >
              <template #label>
                Annualization
                <active-save-indicator
                  :controller="savingBuffer.annualization.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
          <div>
            <a
              v-if="!annualization"
              href="https://news.back9ins.com/reid_tattersall/2019/12/10/annualization-requirements.html"
              target="_blank"
            >
              Learn about annualization requirements
            </a>
          </div>
        </v-col>
        <v-col
          v-if="!props.isViewingSelf || user.isGroupTwoPlus"
          cols="12"
          md="6"
        >
          <div class="checkbox-width">
            <v-checkbox
              v-model="hideCommission"
              class="mt-1"
              data-testid="commission-hide"
              :disabled="props.readonly"
              :success="hideCommission"
              @change="updateHideCommission"
            >
              <template #label>
                Prevent {{ name }} from Viewing Commissions
                <active-save-indicator
                  :controller="savingBuffer.hideCommission.controller.value"
                />
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col cols="12">
          <commission-lock
            :is-viewing-self="props.isViewingSelf"
            :module="props.module"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import CommissionLock from "@/components/settings/commissions/CommissionLock.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import { useUserStore } from "@/stores/user";
import { AGENT_SETTINGS, useAgentSettingsStore } from "@/stores/agent-settings";
import {
  AGENCY_SETTINGS,
  useAgencySettingsStore
} from "@/stores/agency-settings";
import { useActiveSave } from "@/composables/active-save.composable";
import { defineProps } from "vue";
import { useSettingsViewStore } from "@/stores/settings-view";
import { storeToRefs } from "pinia";

const props = defineProps({
  readonly: Boolean,
  isViewingSelf: Boolean,
  module: {
    required: true,
    validator: val => [AGENT_SETTINGS, AGENCY_SETTINGS].includes(val),
    type: String
  }
});

let store;

if (props.module === AGENT_SETTINGS) {
  store = useAgentSettingsStore();
} else {
  const settings = useSettingsViewStore();
  store = useAgencySettingsStore(settings.currentAgencyId);
}

// used in template
const { annualization, hideCommission, name } = storeToRefs(store);
const savingBuffer = {
  hideCommission: useActiveSave(),
  annualization: useActiveSave()
};
const user = useUserStore();

function updateHideCommission() {
  savingBuffer.hideCommission.update(store.updateHideCommission);
}
function updateAnnualization() {
  savingBuffer.annualization.update(store.updateAnnualization);
}
</script>
