import { getHttpClient } from "@/http-client";
import {
  setCarrierTableItemFromRaw,
  setCarrierFromRequest
} from "@/factories/Carrier";
import { setDocumentFromRequest } from "@/factories/Document";
import { serializeObject } from "@/util/helpers";
const baseUrl = "api/boss/carriers";

export async function getCarrierData(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setCarrierFromRequest(data.carrier);
}

export async function uploadCarrierAvatar(id, file) {
  return getHttpClient().put(
    `${baseUrl}/${id}/avatar`,
    await serializeObject({ file })
  );
}

export async function uploadCarrierDocument({ id, file, category }) {
  const res = await getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    await serializeObject({ file, category })
  );
  return setDocumentFromRequest(res.data.document);
}

export const updateCarrierAddress = (carrierId, address) => {
  return getHttpClient().put(`${baseUrl}/${carrierId}/address`, address);
};

export async function getCarriers(
  params = new URLSearchParams(),
  cancelToken = null
) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });
  if (!response?.data) return;

  const carriers = response.data.carriers.map(c =>
    setCarrierTableItemFromRaw(c)
  );

  carriers.sort((a, b) => a.name.localeCompare(b.name));

  return carriers;
}

export function updateCarrier(id, carrier) {
  return getHttpClient().put(`${baseUrl}/${id}`, { carrier });
}

export function searchCarriers(search) {
  const params = new URLSearchParams();
  params.append("name", search);
  return getHttpClient().get(`${baseUrl}/search`, { params });
}
