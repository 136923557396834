<template>
  <v-card color="section" tile flat>
    <v-card-text>
      <v-sheet rounded class="pa-3">
        <v-select
          :value="appointmentManager"
          outlined
          dense
          return-object
          persistent-hint
          data-testid="appointment-manager"
          item-text="name"
          label="Appointment Manager"
          :prepend-inner-icon="mdiAccountSupervisor"
          hint="Add an appointment manager to the access table below to make them appear in this list."
          :items="appointmentManagers"
          @change="changeAppointmentManager"
        >
          <template #append-outer>
            <active-save-indicator
              :controller="savingBuffer.appointmentManager.controller.value"
            />
          </template>
        </v-select>
      </v-sheet>
    </v-card-text>
    <v-card-text>
      <v-sheet rounded class="pa-3">
        <access-table
          v-model="room"
          data-testid="appointment-access-table"
          :new-access.sync="newAccess"
          :checkboxes="checkboxes"
          :readonly="!isGroupTwoPlus"
          :deletable="deletable"
          :update-func="updateAccess"
          :delete-func="removeAccess"
          :add-func="addAccess"
        >
          <template #new-access>
            <audience-search
              v-model="newAccess"
              hide-details
              :carrier-id="carrier.id"
              data-testid="access-audience-search"
            />
          </template>
        </access-table>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AudienceSearch from "@/components/shared/AudienceSearch.vue";
import AccessTable from "@/components/shared/AccessTable.vue";
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";

import { useActiveSave } from "@/composables/active-save.composable";
import { storeToRefs } from "pinia";
import { useAppointmentView } from "@/stores/appointment-view";
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { mdiAccountSupervisor } from "@mdi/js";

const user = useUserStore();
const { isGroupTwoPlus } = storeToRefs(user);

const appointment = useAppointmentView();
const { room, carrier } = storeToRefs(appointment);
const newAccess = ref(null);

const checkboxes = [
  { text: "Advisor Access", value: "advisorAccess" },
  { text: "Advisor Email", value: "email" },
  { text: "Carrier Access", value: "carrierAccess" },
  { text: "Carrier Email", value: "carrierEmail" }
];

const savingBuffer = ref({ appointmentManager: useActiveSave() });

const appointmentManagers = computed(() =>
  room.value.filter(v => v.canBeAppointmentManager)
);
const appointmentManager = computed(() =>
  room.value.find(v => v.appointmentManager)
);

function changeAppointmentManager(v) {
  savingBuffer.value.appointmentManager.update(() =>
    appointment.updateAppointmentManager(v)
  );
}

async function addAccess(access) {
  return appointment.createRoomAccess(access);
}

async function removeAccess(access) {
  return appointment.removeRoomAccess(access);
}

function updateAccess(access, attribute) {
  return appointment.updateRoomAccess(access, attribute);
}

function deletable(access) {
  return !access.owner;
}
</script>
