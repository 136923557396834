<template>
  <v-card color="section" tile flat>
    <v-card-text>
      <chat-view
        title="Personnel Chat"
        data-testid="personnel-chat"
        :pusher-id="`private-${id}-Personnel`"
        :messages="messages"
        :create-func="sendChatMessage"
        @new-note="newNote"
        @remove-message="removeFromMessages"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";
import {
  Note,
  setNoteFromRequest,
  NoteToCreateRequest
} from "@/factories/Note";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { createNote } from "@/api/notes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { usePersonnelView } from "@/stores/personnel-view";
import { computed } from "vue";

const user = useUserStore();
const personnelView = usePersonnelView();
const snackbar = useSnackbarStore();

const { notes, id } = storeToRefs(personnelView);

const messages = computed(() => [
  {
    note: `Hi ${user.loginable.name}, feel free to make notes here.`,
    ownable: { name: "BackNine" }
  },
  ...notes.value.toSorted(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  )
]);

function removeFromMessages(message) {
  const index = notes.value.findIndex(({ id }) => id === message.id);
  notes.value.splice(index, 1);
}

async function sendChatMessage(note) {
  const newNote = Note({
    documents: note.files,
    email: note.sendEmail,
    phoneCall: note.sendPhoneCall,
    note: note.message,
    notableId: id.value,
    notableType: "Personnel"
  });

  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}
function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}
</script>
