import { serializeObject } from "@/util/helpers";

import {
  setStatementFromRequest,
  setStatementSearchItemFromRequest
} from "@/factories/Statement";
import { getHttpClient } from "@/http-client";
import { setDocumentFromRequest } from "@/factories/Document";

const baseUrl = "api/boss/statements";
let transactionCancelToken = null;
let backNineStatementCancelToken = null;
let paymentCancelToken = null;

export async function createStatement(params) {
  const { data } = await getHttpClient().post(
    baseUrl,
    await serializeObject({ statement: params })
  );
  return data.statement;
}

export async function statementSearch(params) {
  const { data } = await getHttpClient().get(`${baseUrl}/search`, {
    params
  });

  return data.statements.map(setStatementSearchItemFromRequest);
}

function BackNineStatementTableItem(model) {
  return {
    id: model?.id,
    payorName: model?.payorName,
    statementType: model?.statementType,
    premium: model?.premium,
    premiumSum: model?.premiumSum,
    amount: model?.amount,
    commissionSum: model?.commissionSum,
    status: model?.status,
    statementDate: model?.statementDate,
    createdAt: model?.createdAt
  };
}

function setBackNineStatementTableItemFromRequest(raw) {
  const item = BackNineStatementTableItem();

  item.id = raw.id;
  item.payorName = raw.payor?.name;
  item.statementType = raw.statement_type;
  item.premium = raw.premium;
  item.premiumSum = raw.premium_sum;
  item.amount = raw.amount;
  item.commissionSum = raw.commission_sum;
  item.status = raw.status;
  item.statementDate = raw.statement_date;
  item.createdAt = raw.created_at;

  return item;
}

export async function getBackNineStatementData(params) {
  if (backNineStatementCancelToken !== null) {
    backNineStatementCancelToken.cancel(
      "Operation canceled due to new request."
    );
  }
  backNineStatementCancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: backNineStatementCancelToken.token
  });

  if (!response?.data) return;
  const data = response.data;
  const items = data.statements.map(setBackNineStatementTableItemFromRequest);
  return { meta: data.meta, items };
}

export async function getBackNineStatement(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setStatementFromRequest(data.statement);
}

function StatementPaymentItem(model) {
  return {
    policyNumber: model?.policyNumber,
    caseId: model?.caseId,
    commissionAssignedPayableName: model?.commissionAssignedPayableName,
    commissionType: model?.commissionType,
    paymentType: model?.commissionPaymentType,
    premium: model?.premium,
    percent: model?.percent,
    dollar: model?.dollar
  };
}

function setStatementPaymentItemFromRaw(raw) {
  const item = StatementPaymentItem();
  item.policyNumber = raw.case?.policy_number;
  item.caseId = raw.case?.id;
  item.commissionAssignedPayableName = raw.commission?.assigned_payable.name;
  item.commissionType = raw.commission_type;
  item.paymentType = raw.commission.payment_type;
  item.premium = raw.premium;
  item.percent = raw.percent;
  item.dollar = raw.dollar;
  return item;
}

export async function getPaymentsRelatedToStatement(statementId, params) {
  if (paymentCancelToken !== null) {
    paymentCancelToken.cancel("Operation canceled due to new request.");
  }
  paymentCancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(
    `${baseUrl}/${statementId}/payments`,
    {
      params,
      cancelToken: paymentCancelToken.token
    }
  );

  if (!response.data) return;

  return {
    items: response.data.payments.map(setStatementPaymentItemFromRaw),
    meta: response.data.meta
  };
}

function BackNineStatementTransaction(model = {}) {
  return {
    case: model?.case,
    amount: model?.amount,
    commissionType: model?.commissionType,
    directCommission: model?.directCommission,
    id: model?.id,
    override: model?.override
  };
}

function setBackNineStatementTransactionFromRequest(raw = {}) {
  const item = BackNineStatementTransaction();
  if (!raw.id) return item;

  item.case = {
    id: raw.case?.id,
    policyNumber: raw.case?.policy_number
  };

  item.amount = raw.amount;
  item.commissionType = raw.commission_type;
  item.directCommission = raw.direct_commission;
  item.override = raw.override;
  item.id = raw.id;

  return item;
}

export async function getTransactionsRelatedToStatement(statementId, params) {
  if (transactionCancelToken !== null) {
    transactionCancelToken.cancel("Operation canceled due to new request.");
  }
  transactionCancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(
    `${baseUrl}/${statementId}/transactions`,
    {
      params,
      cancelToken: transactionCancelToken.token
    }
  );

  if (!response?.data) return;

  return {
    items: response.data.transactions.map(
      setBackNineStatementTransactionFromRequest
    ),
    meta: response.data.meta
  };
}

export function deleteStatement(statementId) {
  return getHttpClient().delete(`${baseUrl}/${statementId}`);
}

export function updateStatement(statementId, statement) {
  return getHttpClient().put(`${baseUrl}/${statementId}`, { statement });
}

export async function uploadStatementFile(id, body) {
  const serializedBody = await serializeObject(body);

  const { data } = await getHttpClient().post(
    `${baseUrl}/${id}/documents`,
    serializedBody
  );

  return setDocumentFromRequest(data);
}
