import { getHttpClient } from "@/http-client";

import { CaseCommissionFactory } from "@/factories/case/CaseCommission";
import { CaseTotalFactory } from "@/factories/case/CaseTotal";
import { setChatTemplateFromRequest } from "@/factories/ChatTemplate";
import {
  setCaseFromRequest,
  setCaseRevenueFromRequest,
  setCaseRoomAccessFromRequest,
  setCaseTransactionFromRequest
} from "@/factories/Case";

import { setInformalOfferFromRequest } from "@/factories/InformalOffer";
import ContractPartyFactory from "@/factories/ContractPartyFactory";
import { setDocumentFromRequest } from "@/factories/Document";

import { convertFileToBase64 } from "@/util/helpers";
import { useInstanceStore } from "@/stores/instance";

const baseUrl = "api/boss/cases";

export async function getCaseRequirementTemplates(caseId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${caseId}/requirements_templates`
  );
  return data.map(setChatTemplateFromRequest);
}

export async function getCaseRequirementTemplate(caseId, chatTemplateId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${caseId}/requirements_templates/${chatTemplateId}`
  );
  return setChatTemplateFromRequest(data);
}

export const getCaseCommissions = async caseId => {
  const { data } = await getHttpClient().get(`${baseUrl}/${caseId}/financials`);
  const {
    original_commissions: rawCommissions,
    payments: rawPayments,
    totals: rawTotals,
    transactions: rawTransactions
  } = data;

  const commissions = rawCommissions.map(rawCommission => {
    const commission = new CaseCommissionFactory();
    commission.setFromRequest(rawCommission);
    return commission;
  });

  const revenue = rawPayments.map(setCaseRevenueFromRequest);

  const totals = rawTotals.map(rawTotal => {
    const total = new CaseTotalFactory();
    total.setFromRequest(rawTotal);
    return total;
  });

  const transactions = rawTransactions.map(setCaseTransactionFromRequest);

  return { commissions, revenue, totals, transactions };
};

export async function createCaseUpload({
  insuredName,
  description,
  policyNumber,
  documents
}) {
  const base64Documents = [];
  for (const document of documents) {
    base64Documents.push(await convertFileToBase64(document));
  }

  const body = {
    case_upload: {
      insured_name: insuredName,
      description,
      policy_number: policyNumber,
      files: base64Documents
    }
  };
  return getHttpClient().post(`${baseUrl}/uploads`, body);
}

export async function uploadCaseFile({ caseId, file, category }) {
  const formData = new FormData();
  formData.append("file", file);
  if (category) formData.append("category", category);

  const { data } = await getHttpClient().post(
    `${baseUrl}/${caseId}/documents`,
    formData
  );

  return setDocumentFromRequest(data);
}

let cancelToken = null;
export async function getCases(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  return {
    items: res.data.cases,
    meta: res.data.meta
  };
}

export function emailCases(params = {}) {
  return getHttpClient().post(`${baseUrl}/email`, params);
}

export async function getCaseData(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setCaseFromRequest(data.case);
}

export function sendCaseTemplate(id, documents_attributes, name, template) {
  return getHttpClient().post(`${baseUrl}/${id}/send_template`, {
    id,
    template,
    documents_attributes,
    name
  });
}

export function updateCase(id, body) {
  return getHttpClient().put(`${baseUrl}/${id}`, { case: body });
}

export async function createCase(params) {
  const { data } = await getHttpClient().post(`${baseUrl}`, params);
  return data.case.id;
}

export function getSnapshotHref(id) {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${id}/snapshot`;
}

export function getPaperclipHref(id) {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${id}/paperclip`;
}

export function deleteInformalOffer(caseId, id) {
  return getHttpClient().delete(`${baseUrl}/${caseId}/informal_offers/${id}`);
}

export async function createInformalOffer(caseId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${caseId}/informal_offers`,
    body
  );

  return setInformalOfferFromRequest(data.informal_offer);
}

export async function updateInformalOffer(caseId, informalId, body) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${caseId}/informal_offers/${informalId}`,
    body
  );

  return setInformalOfferFromRequest(data.informal_offer);
}

export function updateCaseAccess(id, caseAccessId, case_access) {
  return getHttpClient().put(`${baseUrl}/${id}/accesses/${caseAccessId}`, {
    case_access
  });
}

export function removeCaseAccess(id, caseAccessId) {
  return getHttpClient().delete(`${baseUrl}/${id}/accesses/${caseAccessId}`);
}

export async function createCaseAccess(id, case_access) {
  const { data } = await getHttpClient().post(`${baseUrl}/${id}/accesses`, {
    case_access
  });

  return setCaseRoomAccessFromRequest(data.case_access);
}

export async function caseContractPartyCrud(id, contractPartyId, body) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${id}/contract_parties/${contractPartyId}`,
    body
  );

  const contractParty = new ContractPartyFactory();
  contractParty.setFromRequest(data.contract_party);
  return contractParty;
}

export async function caseCreateContractParty(caseId, body) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${caseId}/contract_parties`,
    { contract_party: body }
  );
  const contractParty = new ContractPartyFactory();
  contractParty.setFromRequest(data.contract_party);
  return contractParty;
}

export async function getCaseTemplates(caseId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${caseId}/chat_templates`
  );

  return data.map(setChatTemplateFromRequest);
}

export async function getCaseTemplate(caseId, templateId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${caseId}/chat_templates/${templateId}`,
    {
      headers: {
        Accept: "application/json"
      }
    }
  );

  return setChatTemplateFromRequest(data);
}

export function downloadCaseTemplateUrl(caseId, templateId) {
  const instance = useInstanceStore();
  return `${instance.apiUrl}/${baseUrl}/${caseId}/chat_templates/${templateId}/download`;
}

export function resetCaseCommissions(caseId) {
  return getHttpClient().post(`${baseUrl}/${caseId}/reset_commissions`);
}

function ManagementCombination(model = {}) {
  return {
    caseId: model?.caseId,
    policyNumber: model?.policyNumber,
    caseManager: model?.caseManager,
    caseConcierge: model?.caseConcierge,
    sameManagerForParty: model?.sameManagerForParty
  };
}

function setManagementCombinationFromRequest(req = {}) {
  const mc = ManagementCombination();

  mc.caseId = req?.case_id;
  mc.policyNumber = req?.policy_number;
  mc.caseManager = {
    id: req?.case_manager?.id,
    name: req?.case_manager?.name,
    type: "Agent"
  };
  mc.caseConcierge = {
    id: req?.case_concierge?.id,
    name: req?.case_concierge?.name,
    type: "Agent"
  };

  mc.sameManagerForParty = Boolean(req?.same_manager_for_party);

  return mc;
}

export async function managerCheck(caseId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${caseId}/manager_check`
  );

  const { management_combinations } = data;

  return (
    management_combinations?.map(setManagementCombinationFromRequest) || []
  );
}
