<template>
  <div>
    <v-divider class="pb-3" />
    <v-row class="px-6 pb-3" v-if="checkboxes && checkboxes.length">
      <v-checkbox
        v-for="checkbox in checkboxes"
        v-model="options[checkbox.key]"
        class="mr-4"
        hide-details
        :key="checkbox.key"
        :label="checkbox.label"
        :data-testid="`chat-${checkbox.dataTestid}`"
        :disabled="loading"
      />
    </v-row>
    <v-textarea
      v-if="noDocuments"
      v-model="message"
      v-cloak
      data-testid="chat-message-input"
      dense
      outlined
      class="pa-3"
      rows="2"
      auto-grow
      :prepend-inner-icon="mdiText"
      :disabled="loading"
      :placeholder="
        files.length ? filesOnlyPlaceholderMessage : 'Write a comment'
      "
      :hide-details="!messageValidation.errorMessages.length"
      :error-messages="messageValidation.errorMessages"
      :success="messageValidation.success"
    />
    <v-textarea
      v-else
      v-model="message"
      data-testid="chat-message-input"
      :prepend-inner-icon="mdiText"
      dense
      outlined
      class="pa-3"
      v-cloak
      rows="2"
      auto-grow
      :placeholder="
        files && files.length ? filesOnlyPlaceholderMessage : 'Write a comment'
      "
      :disabled="loading"
      :hide-details="!messageValidation.errorMessages.length"
      :error-messages="messageValidation.errorMessages"
      :success="messageValidation.success"
      @drop.prevent="addDropFile"
      @dragover.prevent
    >
      <template #append>
        <v-file-input
          v-model="compFiles"
          hide-input
          hide-details
          class="pt-0 mt-0"
          multiple
          style="max-width: 34px !important"
          :disabled="loading"
          :accept="ALL_FILE_EXTENSIONS"
        />
      </template>
    </v-textarea>
    <v-row class="px-6 pt-2" align="center">
      <v-chip-group column>
        <v-chip
          :disabled="loading"
          pill
          v-for="(file, index) in files"
          :key="index"
          close
          @click:close="removeFile(index)"
          :close-icon="mdiClose"
        >
          {{ file.name }}
        </v-chip>
      </v-chip-group>
    </v-row>
    <v-row class="px-6 mb-3">
      <v-spacer />
      <template v-if="additionalButtons?.length">
        <v-btn
          v-for="button in additionalButtons"
          :key="button.text"
          :data-testid="button.dataTestid"
          class="text-none"
          @click="button.method"
        >
          {{ button.text }}
        </v-btn>
      </template>
      <v-btn
        color="primary"
        class="text-none ml-1"
        data-testid="chat-send-message"
        :loading="loading"
        @click="submitMessage"
      >
        Comment
      </v-btn>
    </v-row>
  </div>
</template>

<script setup>
import { ALL_FILE_EXTENSIONS } from "@/data/allowed-file-extensions";

import { useUserStore } from "@/stores/user";
import { mdiText, mdiClose } from "@mdi/js";
import { computed, defineProps, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation } from "@/util/helpers";

const props = defineProps({
  noDocuments: Boolean,
  checkboxes: { type: Array, required: false, default: () => [] },
  additionalButtons: { type: Array, required: false, default: () => [] },
  createFunc: { type: Function, required: true }
});

const user = useUserStore();

const options = ref(createOptions());
const files = ref([]);
const message = ref("");
const loading = ref(false);

const v$ = useVuelidate(
  {
    message: {
      required: (value, parent) => {
        if (parent.files?.length) return true;
        return Boolean(value);
      }
    },
    files: {}
  },
  { message, files },
  { $scope: null, $autoDirty: true }
);

const compFiles = computed({
  get() {
    return files.value;
  },
  set(val) {
    files.value = files.value.concat(val);
  }
});

const messageValidation = computedValidation(v$.value.message, {
  required: "Required"
});

const filesOnlyPlaceholderMessage = computed(() => {
  let attachmentText = "attachment";
  if (files.value?.length > 1) attachmentText += "s";
  return `${user.loginable.name} uploaded ${files.value?.length} ${attachmentText}.`;
});

function createOptions() {
  if (!props.checkboxes?.length) return {};

  return props.checkboxes?.reduce(
    (acc, cb) => ({ ...acc, [cb.key]: cb.default || false }),
    {}
  );
}

function removeFile(index) {
  files.value.splice(index, 1);
}

// props.createFunc handles errors
async function submitMessage() {
  const isValid = v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  const res = await props.createFunc({
    ...options.value,
    message: message.value || filesOnlyPlaceholderMessage.value,
    files: files.value
  });

  if (res) {
    files.value.splice(0, files.value.length);
    message.value = "";
    options.value = createOptions();
    v$.value.$reset();
  }

  loading.value = false;
}

function addDropFile(e) {
  const droppedFiles = [];
  if (Array.isArray(e)) {
    droppedFiles.push(...e);
  } else {
    droppedFiles.push(...Array.from(e.dataTransfer.files));
  }

  const fileDict = {};
  ALL_FILE_EXTENSIONS.replaceAll(",", "")
    .replaceAll(".", "")
    .split(" ")
    .filter(Boolean)
    .map(v => v.trim())
    .forEach(fe => (fileDict[fe] = true));
  const filteredFiles = droppedFiles.filter(v => {
    const chunks = v.name.split(".");
    return fileDict[chunks[chunks.length - 1].toLowerCase()];
  });

  files.value = [...files.value, ...filteredFiles];
}
</script>
