<template>
  <v-card v-cloak>
    <v-card-title>Change Avatar</v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col cols="12">
          <file-drag-and-drop
            class="pt-0"
            :success="fileValidation.success"
            :error-messages="fileValidation.errorMessages"
            label="Avatar Image"
            v-model="file"
            accept=".png, .jpg, .jpeg, .svg, .webp"
          />
        </v-col>
        <v-col v-if="checkboxText" cols="12">
          <div class="checkbox-width">
            <v-checkbox :label="checkboxText" v-model="checkboxChecked" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="checkboxText" class="px-6">
      <v-spacer />
      <v-btn
        class="text-none"
        color="primary"
        :loading="loading"
        :disabled="deletingAvatar"
        @click="uploadAvatar"
      >
        Upload
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer />
      <v-btn
        outlined
        class="text-none"
        :loading="loading"
        :disabled="deletingAvatar"
        @click="closeDialog()"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="deletable"
        class="text-none"
        outlined
        :disabled="loading"
        :loading="deletingAvatar"
        color="error"
        @click="deleteAvatar"
      >
        Delete Avatar
      </v-btn>
      <v-btn
        class="text-none"
        color="primary"
        data-testid="change-avatar-confirm"
        :loading="loading"
        :disabled="deletingAvatar"
        @click="uploadAvatar"
      >
        Upload
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import FileDragAndDrop from "@/components/shared/FileDragAndDrop.vue";

import {
  uploadAgencyAvatar,
  useAgencyOwnersAvatar
} from "@/api/agencies.service";
import { uploadAgentAvatar, useAgentsOwnersAvatar } from "@/api/agents.service";
import { uploadCarrierAvatar } from "@/api/carrier.service";
import { uploadPersonnelAvatar } from "@/api/personnel.service";
import {
  uploadApprovedDomainAvatar,
  deleteApprovedDomainAvatar
} from "@/api/approved-domain.service";
import { mapActions } from "pinia";
import { parseErrorMessage } from "@/util/helpers";
import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";

export default {
  components: {
    FileDragAndDrop
  },
  props: {
    id: Number,
    deletable: Boolean,
    type: String,
    checkboxText: String
  },
  data() {
    return {
      file: null,
      loading: false,
      deletingAvatar: false,
      checkboxChecked: false
    };
  },
  computed: {
    fileValidation() {
      const success = !this.$v.file.$invalid;
      const errorMessages = [];
      if (!this.$v.file.$dirty) return { success, errorMessages };
      if (!this.$v.file.required) errorMessages.push("Required");
      if (!this.$v.file.validSize)
        errorMessages.push(
          "Please confirm this file has data or try re-uploading the file"
        );
      return { success, errorMessages };
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["closeDialog"]),
    uploadAvatar() {
      if (this.loading || this.deletingAvatar) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      let func = () => {};
      if (this.type === "ApprovedDomain") {
        func = uploadApprovedDomainAvatar;
      } else if (this.type === "Agent") {
        func = uploadAgentAvatar;
      } else if (this.type === "Agency") {
        func = uploadAgencyAvatar;
      } else if (this.type === "Carrier") {
        func = uploadCarrierAvatar;
      } else if (this.type === "Personnel") {
        func = uploadPersonnelAvatar;
      } else {
        throw Error("Unimplemented Type in ChangeAvatarDialog");
      }

      Promise.all(
        [
          func(this.id, this.file),
          this.checkboxChecked && this.checkboxText
            ? this.useOwnersAvatar()
            : null
        ].filter(Boolean)
      )
        .then(response => {
          this.closeDialog({ updated: true, newUrl: response[0].data.url });
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    useOwnersAvatar() {
      let func = useAgentsOwnersAvatar;
      let root = "agent";
      if (this.type === "Agency") {
        func = useAgencyOwnersAvatar;
        root = "agency";
      }

      return func(this.id, { [root]: { use_owners_avatar: true } });
    },
    deleteAvatar() {
      if (this.deletingAvatar || this.loading) {
        return;
      }
      this.deletingAvatar = true;
      let func = () => {};
      if (this.type === "ApprovedDomain") {
        func = deleteApprovedDomainAvatar;
      } else {
        throw Error("Unimplemented Type in ChangeAvatarDialog");
      }

      func(this.id)
        .then(() => {
          this.closeDialog({ deleted: true });
        })
        .catch(e => {
          this.showErrorSnackbar({ message: parseErrorMessage(e) });
        })
        .finally(() => {
          this.deletingAvatar = false;
        });
    }
  },
  validations: {
    file: {
      required,
      validSize: val => {
        return val?.size > 0;
      }
    }
  }
};
</script>
