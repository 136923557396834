import { StatementSearchItem } from "./Statement";

export function Transaction(model = {}) {
  return {
    queuedTransactionId: model?.queuedTransactionId,
    memo: model?.memo || null,
    premium: model?.premium || null,
    amount: model?.amount || null,
    commissionType: model?.commissionType || "First Year",
    statement: model?.statement ? StatementSearchItem(model?.statement) : null,
    policy: model?.policy ? TransactionCaseSearchItem(model?.policy) : null
  };
}

export function TransactionToCreateRequest(model = {}) {
  return {
    memo: model.memo,
    premium: model.premium,
    case_id: model.policy?.id,
    commission_type: model.commissionType,
    statement_id: model.statement?.id,
    amount: model.amount,
    queued_transaction_id: model.queuedTransactionId || undefined
  };
}

export function TransactionCaseSearchItem(model = {}) {
  return {
    id: model.id,
    appointmentCases: model.appointmentCases || [],
    firstYear: model.firstYear || null,
    line: model.line || null,
    mode: model.mode || null,
    policyDate: model.policyDate || null,
    policyNumber: model.policyNumber || null,
    premium: model.premium || null,
    product: Product(model.product),
    targetPremium: model.targetPremium || null
  };
}

export function setTransactionCaseSearchItemFromRequest(raw = {}) {
  const model = TransactionCaseSearchItem();
  model.id = raw?.id;
  model.appointmentCases = raw?.appointment_cases.map(
    setAppointmentCaseFromRequest
  );
  model.firstYear = raw?.first_year;
  model.line = raw?.line;
  model.mode = raw?.mode;
  model.policyDate = raw?.policy_date;
  model.policyNumber = raw?.policy_number;
  model.premium = raw?.premium;
  model.product = setProductFromRequest(raw?.product);
  model.targetPremium = raw?.target_premium;

  return model;
}

function AppointmentCase(model = {}) {
  return {
    advisor: AppointmentCaseAdvisor(model?.advisor),
    id: model?.id,
    splitPercent: model?.splitPercent
  };
}

function setAppointmentCaseFromRequest(raw = {}) {
  const model = AppointmentCase();
  model.advisor = setAppointmentCaseAdvisorFromRequest(raw?.advisor);
  model.id = raw?.id;
  model.splitPercent = raw?.split_percent;
  return model;
}

function AppointmentCaseAdvisor(model = {}) {
  return {
    avatarUrl: model?.avatarUrl,
    email: model?.email,
    id: model?.id,
    name: model?.name,
    npn: model?.npn,
    phoneWork: model?.phoneWork,
    title: model?.title,
    type: model?.type
  };
}

function setAppointmentCaseAdvisorFromRequest(raw = {}) {
  const model = AppointmentCaseAdvisor();
  model.avatarUrl = raw?.avatar_url;
  model.email = raw?.email;
  model.id = raw?.id;
  model.name = raw?.name;
  model.npn = raw?.npn;
  model.phoneWork = raw?.phone_work;
  model.title = raw?.title;
  model.type = raw?.type?.model;
  return model;
}

function Product(model = {}) {
  return {
    carrier: Carrier(model?.carrier),
    id: model?.id,
    name: model?.name,
    totalBonus: model?.totalBonus,
    totalExcess: model?.totalExcess,
    totalFirst: model?.totalFirst,
    totalPua: model?.totalPua,
    totalRenewal: model?.totalRenewal,
    totalTermRider: model?.totalTermRider
  };
}

function setProductFromRequest(raw = {}) {
  const model = Product();
  model.carrier = setCarrierFromRequest(raw?.carrier);
  model.id = raw?.id;
  model.name = raw?.name;
  model.totalBonus = raw?.total_bonus;
  model.totalExcess = raw?.total_excess;
  model.totalFirst = raw?.total_first;
  model.totalPua = raw?.total_pua;
  model.totalRenewal = raw?.total_renewal;
  model.totalTermRider = raw?.total_term_rider;
  return model;
}

function Carrier(model = {}) {
  return {
    avatarUrl: model?.avatarUrl,
    id: model?.id,
    instantAps: model?.instantAps,
    name: model?.name
  };
}

function setCarrierFromRequest(raw = {}) {
  const model = Carrier();
  model.avatarUrl = raw?.avatar_url;
  model.id = raw?.id;
  model.instantAps = raw?.instant_aps;
  model.name = raw?.name;
  return model;
}

function CaseTransactionParty(model = {}) {
  return {
    amount: model?.amount,
    payable: CaseTransactionPayable(model?.payable),
    paymentTypes: model?.paymentTypes || []
  };
}

function CaseTransactionPayable(model = {}) {
  return {
    id: model?.id,
    name: model?.name,
    type: model?.type
  };
}

function setCaseTransactionPayableFromRequest(raw = {}) {
  const model = CaseTransactionPayable();
  model.id = raw.id;
  model.name = raw.name;
  model.type = raw.type;
  return model;
}

export function setCaseTransactionPartyFromRequest(raw = {}) {
  const model = CaseTransactionParty();
  model.amount = raw.amount;
  model.payable = setCaseTransactionPayableFromRequest(raw.payable);
  model.paymentTypes = raw.payment_types;
  return model;
}

function CaseTransaction(model = {}) {
  return {
    amount: model?.amount,
    anyPaid: model?.anyPaid,
    auditMessages: model?.auditMessages || [],
    auditedAt: model?.auditedAt || null,
    caseId: model?.caseId,
    commissionType: model?.commissionType,
    directCommission: model?.directCommission,
    id: model?.id,
    override: model?.override,
    reflowedFrom: model?.reflowedFrom,
    revertedBy: model?.revertedBy,
    reverts: model?.reverts,
    statementDate: model?.statementDate,
    statementId: model?.statementId
  };
}

export function setCaseTransactionFromRequest(raw = {}) {
  const model = CaseTransaction();
  model.amount = raw.amount;
  model.anyPaid = raw.any_paid;
  model.auditedAt = raw.audited_at;
  model.caseId = raw.case_id;
  model.commissionType = raw.commission_type;
  model.directCommission = raw.direct_commission;
  model.id = raw.id;
  model.override = raw.override;
  model.reflowedFrom = raw.reflowed_from;
  model.revertedBy = raw.reverted_by;
  model.reverts = raw.reverts;
  model.statementDate = raw.statement_date;
  model.statementId = raw.statement_id;

  if (Array.isArray(raw.audit_messages)) {
    model.auditMessages = raw.audit_messages.map(m => ({
      level: m.level,
      message: m.message
    }));
  }
  return model;
}

const LINK_TYPE_DICT = {
  agents: "Agent",
  agencies: "Agency",
  carriers: "Carrier"
};

function SimulatedTransactionPayment(model = {}) {
  return {
    paymentType: model?.paymentType,
    commissionType: model?.commissionType,
    premium: model?.premium,
    percent: model?.percent,
    dollar: model?.dollar,
    splitPercent: model?.splitPercent,
    errors: model?.errors,
    payable: {
      id: model?.payable?.id,
      name: model?.payable?.name,
      type: model?.payable?.type
    },
    assignedPayable: {
      id: model?.assignedPayable?.id,
      name: model?.assignedPayable?.name,
      type: model?.assignedPayable?.type
    },
    payor: {
      id: model?.payor?.id,
      name: model?.payor?.name,
      type: model?.payor?.type
    }
  };
}

export function setSimulatedTransactionPaymentFromRequest(raw = {}) {
  const model = SimulatedTransactionPayment();
  model.paymentType = raw.payment_type;
  model.commissionType = raw.commission_type;
  model.premium = raw.premium;
  model.percent = raw.percent;
  model.dollar = raw.dollar;
  model.splitPercent = raw.split_percent;
  model.errors = raw.errors;
  if (raw?.payable) {
    model.payable = {
      id: raw.payable.id,
      name: raw.payable.name,
      type: LINK_TYPE_DICT[raw.payable.type]
    };
  }
  if (raw?.assigned_payable) {
    model.assignedPayable = {
      id: raw.assigned_payable.id,
      name: raw.assigned_payable.name,
      type: LINK_TYPE_DICT[raw.assigned_payable.type]
    };
  }
  if (raw?.payor) {
    model.payor = {
      id: raw.payor.id,
      name: raw.payor.name,
      type: LINK_TYPE_DICT[raw.payor.type]
    };
  }
  return model;
}

function SimulatedTransactionCommission(model = {}) {
  return {
    id: model?.id,
    commissionType: model?.commissionType,
    paymentType: model?.paymentType,
    percent: model?.percent,
    startYear: model?.startYear,
    endYear: model?.endYear,
    paid: model?.paid,
    payable: {
      id: model?.payable?.id,
      name: model?.payable?.name,
      type: model?.payable?.type
    },
    assignedPayable: {
      id: model?.assignedPayable?.id,
      name: model?.assignedPayable?.name,
      type: model?.assignedPayable?.type
    },
    errors: model?.errors,
    appointmentCase: {
      id: model?.appointmentCase?.id,
      splitPercent: model?.appointmentCase?.splitPercent,
      advisor: {
        id: model?.appointmentCase?.advisor?.id,
        name: model?.appointmentCase?.advisor?.name,
        avatarUrl: model?.appointmentCase?.advisor?.avatarUrl,
        type: model?.appointmentCase?.advisor?.type,
        email: model?.appointmentCase?.advisor?.email,
        phoneWork: model?.appointmentCase?.advisor?.phoneWork,
        title: model?.appointmentCase?.advisor?.title,
        npn: model?.appointmentCase?.advisor?.npn
      }
    },
    payor: {
      id: model?.payor?.id,
      name: model?.payor?.name,
      payableType: model?.payor?.payableType
    }
  };
}

export function setSimulatedTransactionCommissionFromRequest(raw = {}) {
  const model = SimulatedTransactionCommission();
  model.id = raw.id;
  model.commissionType = raw.commission_type;
  model.paymentType = raw.payment_type;
  model.percent = raw.percent;
  model.startYear = raw.start_year;
  model.endYear = raw.end_year;
  model.paid = raw.paid;

  model.payable = {
    id: raw.payable.id,
    name: raw.payable.name,
    type: raw.payable.type
  };
  model.assignedPayable = {
    id: raw.assigned_payable.id,
    name: raw.assigned_payable.name,
    type: raw.assigned_payable.type
  };

  model.errors = raw.errors;
  model.appointmentCase = {
    id: raw.appointment_case.id,
    splitPercent: raw.appointment_case.split_percent,
    advisor: {
      id: raw.appointment_case.advisor.id,
      name: raw.appointment_case.advisor.name,
      avatarUrl: raw.appointment_case.advisor.avatar_url,
      type: raw.appointment_case.advisor.type.model,
      email: raw.appointment_case.advisor.email,
      phoneWork: raw.appointment_case.advisor.phone_work,
      title: raw.appointment_case.advisor.title,
      npn: raw.appointment_case.advisor.npn
    }
  };

  model.payor = {
    id: raw.payor.id,
    name: raw.payor.name,
    payableType: raw.payor.payable_type
  };

  return model;
}
