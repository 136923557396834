<template>
  <v-card flat tile color="section">
    <v-card-text>
      <access-table
        v-model="room"
        data-testid="impaired-risk-access-table"
        :new-access.sync="newAccess"
        :checkboxes="checkboxes"
        :update-func="update"
        :delete-func="deleteAccess"
        :add-func="addAccess"
      >
        <template #new-access>
          <advisor-search
            v-model="newAccess"
            hide-details
            data-testid="access-advisor-search"
          />
        </template>
      </access-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import AccessTable from "@/components/shared/AccessTable.vue";
import AdvisorSearch from "@/components/shared/AdvisorSearch.vue";

import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
import { useDialogStore } from "@/stores/dialog";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const impairedRiskQuote = useImpairedRiskQuoteView();
const dialog = useDialogStore();
const { room } = storeToRefs(impairedRiskQuote);

const newAccess = ref(null);
const checkboxes = [{ text: "Email", value: "email" }];
async function update(access, attribute) {
  return impairedRiskQuote.updateAccess(access.id, attribute);
}

function deleteAccess(access) {
  return dialog.showDialog({
    component: "ConfirmationDialog",
    title: "Are you sure that you want remove access?",
    subtitle: "Please confirm your intent",
    func: () => impairedRiskQuote.deleteAccess(access.id)
  });
}

function addAccess(advisor) {
  return impairedRiskQuote.createAccess(advisor);
}
</script>
