<template>
  <v-card v-if="user.isGroupTwoPlus" flat tile>
    <v-card-text>
      <v-row class="ma-0">
        <v-spacer />
        <v-btn
          outlined
          rounded
          color="error"
          class="text-none"
          data-testid="delete-personnel"
          @click="deleteConfirmation"
        >
          <v-icon class="ml-1"> {{ mdiDelete }} </v-icon> Delete Personnel
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";

import { useSnackbarStore } from "@/stores/snackbar";
import { useDialogStore } from "@/stores/dialog";
import { usePersonnelView } from "@/stores/personnel-view";
import { useUserStore } from "@/stores/user";
import { mdiDelete } from "@mdi/js";
import { markRaw } from "vue";
import { useRouter } from "@/composables/compatible.composables";
import { storeToRefs } from "pinia";

const snackbar = useSnackbarStore();
const dialog = useDialogStore();
const user = useUserStore();

const personnelView = usePersonnelView();
const router = useRouter();

const { firstName, lastName } = storeToRefs(personnelView);

function deleteConfirmation() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: `Are you sure you want to permanently delete this personnel, ${firstName.value} ${lastName.value}?`,
    subtitle: "This cannot be undone",
    func: async () => {
      await personnelView.deletePersonnel();
      snackbar.showSuccessSnackbar({
        message: `${firstName.value} ${lastName.value} has been deleted`,
        timeout: 6000
      });

      router.replace({ name: "Tables", query: { page: "carrier-personnel" } });
    }
  });
}
</script>
