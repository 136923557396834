import { uuidv4, generateRandomColor } from "@/util/helpers";

export const RADIO_OPTION_RADIUS = 7;
export const RADIO_OPTION_DIAMETER = RADIO_OPTION_RADIUS * 2;
export const CHECKBOX_SIDE_LENGTH = 14;
export const CHECKBOX_RADII = 2;

export const RADIO_OPTION_TYPE = "radio";
export const SELECT_OPTION_TYPE = "select";
export const TEXT_OPTION_TYPE = "text";
export const TEXT_VERBATIM_OPTION_TYPE = "text_output_verbatim_question";
export const NOT_ON_PDF_OPTION_TYPE = "not_on_pdf";
export const SIGNATURE_OPTION_TYPE = "signature";
export const SIGNATURE_DATE_OPTION_TYPE = "date_signed";
export const CHECKBOX_OPTION_TYPE = "checkbox";
export const DATE_OPTION_TYPE = "date";
export const INTEGER_OPTION_TYPE = "integer";

export const VERBATIM_QUESTION = "Verbatim Question";

export const pdfFieldTypes = [
  { value: CHECKBOX_OPTION_TYPE, text: "Checkbox" },
  { value: NOT_ON_PDF_OPTION_TYPE, text: "Not on PDF" },
  { value: RADIO_OPTION_TYPE, text: "Radio" },
  { value: TEXT_OPTION_TYPE, text: "Text" },
  { value: TEXT_VERBATIM_OPTION_TYPE, text: "Text - Output Verbatim Question" }
];

export const textOptions = [
  { value: true, text: "Yes" },
  { value: 0, text: "No - No Character Limit" },
  { value: false, text: "No - Limit Characters" }
];

export const QUOTE_AND_APPLY_FIELD_TYPES = [
  {
    value: SELECT_OPTION_TYPE,
    text: "Select",
    textCompatible: true,
    radioCompatible: false
  },
  {
    value: TEXT_OPTION_TYPE,
    text: "Text",
    textCompatible: true,
    radioCompatible: false
  },
  {
    value: RADIO_OPTION_TYPE,
    text: "Radio",
    textCompatible: false,
    radioCompatible: true
  },
  {
    value: CHECKBOX_OPTION_TYPE,
    text: "Checkbox",
    textCompatible: false,
    radioCompatible: true
  },
  {
    value: DATE_OPTION_TYPE,
    text: "Date",
    textCompatible: true,
    radioCompatible: false
  },
  {
    value: INTEGER_OPTION_TYPE,
    text: "Integer",
    textCompatible: true,
    radioCompatible: false
  }
];

export const DOCUSIGN_REQUIRED_OF = [
  { text: "agent1", value: "agent1" },
  { text: "insured1", value: "insured1" },
  { text: "insured1/responsible1", value: "insured1/responsible1" },
  { text: "insured1/owner1", value: "insured1/owner1" },
  { text: "owner1", value: "owner1" }
];

export const INSURED_INDEX_OPTIONS = [
  { text: "Insured", value: 0 },
  { text: "Joint Insured", value: 1 }
];

export const FORM_STATUS = {
  INCOMPLETE: "Incomplete",
  COMPLETE: "Complete",
  COMPLETE_NOT_LATEST: "Complete - Not Latest",
  COMPLETE_READY_FOR_REVIEW: "Complete - Ready for Review"
};

export const CATEGORY_STATES_STATUS = [
  "Incomplete",
  "Complete",
  "Not Required"
];
// Application Question Link
export function ApplicationQuestionLink(model = {}) {
  return {
    applicationQuestion: model?.applicationQuestion,
    childAqls: model?.childAqls || [],
    parentAql: model?.parentAql,
    color: model?.color,
    coordinates: model?.coordinates || [],
    smartApplicationQuestion: model?.smartApplicationQuestion || null,
    id: model?.id,
    question: model?.question,
    isTiaField: model?.isTiaField,
    isDocusignField: model?.isDocusignField,
    isDocusignRequired: model?.isDocusignRequired,
    docusignRequiredOf: model?.docusignRequiredOf,
    pdfFieldType: model?.pdfFieldType,
    additionalForm: model?.additionalForm,
    requiredValue: model?.requiredValue,
    quoteAndApplyType: model?.quoteAndApplyType,
    parentQuestion: model?.parentQuestion || null,
    verbatimQuestion: model?.verbatimQuestion,
    insuredIndex: model?.insuredIndex,
    requiredParentValue: model?.requiredParentValue,
    referenceField: model?.referenceField,
    referenceForm: model?.referenceForm,
    positions: model?.positions,
    comb: model?.comb,
    obscureBackground: model?.obscureBackground
  };
}

export function setApplicationQuestionLinkFromRequest(raw = {}) {
  const model = ApplicationQuestionLink();

  const valOrNull = val => (val || val === 0 ? val : null);
  model.color = generateRandomColor();

  model.id = valOrNull(raw?.id?.toString());
  model.question = raw?.question;
  model.isTiaField = raw.tia;
  model.isDocusignField = raw.docusign;
  model.isDocusignRequired = raw.docusign_required;
  model.docusignRequiredOf = raw.required_of;
  model.pdfFieldType = raw.application_field_type;
  model.additionalForm = raw.additional_form;
  model.requiredValue = raw.required_value || null;
  model.quoteAndApplyType = raw.field_type;
  model.parentQuestion = raw.parent_application_question_link?.id?.toString();
  model.verbatimQuestion = raw.verbatim_question;
  model.insuredIndex = raw.insured_index || 0;
  model.requiredParentValue = raw.required_parent_value;
  model.referenceField =
    raw?.reference_application_question_link?.id?.toString();
  model.referenceForm =
    raw?.reference_application_question_link?.form?.id?.toString();
  model.positions = raw?.positions;
  model.comb = raw?.comb;
  model.obscureBackground = Boolean(raw?.background);

  if (raw?.application_question) {
    model.applicationQuestion = setApplicationQuestionFromRequest(
      raw.application_question
    );
  }

  if (raw?.child_application_question_links?.length) {
    model.childAqls = raw.child_application_question_links.map(
      setApplicationQuestionLinkFromRequest
    );
  }

  if (raw?.parent_application_question_link) {
    model.parentAql = setApplicationQuestionLinkFromRequest(
      raw.parent_application_question_link
    );
  }

  // field options
  if (Array.isArray(raw?.field)) {
    model.coordinates = setCoordinatesFromFieldOption(raw);
  } else if (raw?.field) {
    model.coordinates = [setCoordinatesFromText(raw)];
  }

  if (raw?.smart_application_question) {
    model.smartApplicationQuestion = setApplicationQuestionFromRequest(
      raw.smart_application_question
    );
  }

  return model;
}

export function applicationQuestionDisplayText(item) {
  let page;
  if (item.coordinates.length) {
    page = item.coordinates.find(({ page }) => Boolean(page))?.page;
  }
  let pageNum;
  if (item.pdfFieldType === NOT_ON_PDF_OPTION_TYPE && !page) {
    pageNum = "Not on PDF";
  } else if (page) {
    pageNum = page;
  }
  let copiedFromText = "";
  if (item.referenceField) {
    copiedFromText = `- Copied From ${item.referenceField} on ${item.referenceForm}`;
  }

  let textArr = [pageNum, item.id, copiedFromText];
  if (!item.id) {
    textArr = ["[NOT CREATED]", pageNum, item.field];
  }

  return textArr.filter(Boolean).join(" ");
}

export function getAqlCopyRequestBody(aql) {
  const field = getFieldData(aql);
  let reference_application_question_link_id = null;
  if (aql.referenceField) {
    reference_application_question_link_id = +aql.referenceField;
  }
  return {
    field,
    reference_application_question_link_id
  };
}

function getRadioFieldData(aql) {
  return aql.coordinates.map(coordinate => ({
    x: coordinate.x,
    y: coordinate.y,
    page: coordinate.page,
    text: coordinate.text,
    value: coordinate.value,
    virtual: coordinate.virtual
  }));
}

function getTextFieldData(aql) {
  if (!aql.coordinates.length) return {};
  const { x, y, page, width, height, options } = aql.coordinates[0];

  let vals = [];
  if (options?.length) {
    vals = options.map(({ text, value, virtual }) => ({
      text,
      value,
      virtual
    }));
  }

  const field = {
    x,
    y,
    page,
    width,
    height,
    options: vals
  };

  return field;
}

function getNotOnPdfFieldData(aql) {
  if (!aql.coordinates.length) return undefined;
  const coordinate = aql.coordinates[0];
  const field = coordinate.options.map(({ text, value }) => ({
    text,
    value,
    virtual: true
  }));

  return field;
}

function getFieldRequestData(aql) {
  let pdfTypeNeedsOptions = [RADIO_OPTION_TYPE, CHECKBOX_OPTION_TYPE].includes(
    aql.pdfFieldType
  );

  if (
    aql.pdfFieldType === NOT_ON_PDF_OPTION_TYPE &&
    [SELECT_OPTION_TYPE, RADIO_OPTION_TYPE, CHECKBOX_OPTION_TYPE].includes(
      aql.quoteAndApplyType
    )
  ) {
    pdfTypeNeedsOptions = true;
  }
  const isOnPdf = aql.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE;

  if (pdfTypeNeedsOptions) {
    return aql.coordinates.map(coordinate => {
      if (coordinate.virtual) {
        return {
          text: coordinate.text,
          value: coordinate.value,
          virtual: coordinate.virtual
        };
      }

      return {
        x: coordinate.x,
        y: coordinate.y,
        page: coordinate.page,
        text: coordinate.text,
        value: coordinate.value,
        virtual: coordinate.virtual
      };
    });
  }

  if (isOnPdf && aql.coordinates.length) {
    const coordinate = aql.coordinates[0];

    return {
      x: coordinate.x,
      y: coordinate.y,
      page: coordinate.page,
      width: coordinate.width,
      height: coordinate.height,
      options: coordinate.options.map(({ text, value, virtual }) => ({
        text,
        value,
        virtual
      }))
    };
  }

  if (!aql.coordinates.length) return undefined;

  return aql.coordinates[0].options.map(({ text, value }) => ({
    text,
    value,
    virtual: true
  }));
}

function getFieldData(aql) {
  const pdfTypes = [RADIO_OPTION_TYPE, CHECKBOX_OPTION_TYPE];
  let pdfTypeNeedsOptions = pdfTypes.includes(aql.pdfFieldType);
  if (
    aql.pdfFieldType === NOT_ON_PDF_OPTION_TYPE &&
    [SELECT_OPTION_TYPE, RADIO_OPTION_TYPE, CHECKBOX_OPTION_TYPE].includes(
      aql.quoteAndApplyType
    )
  ) {
    pdfTypeNeedsOptions = true;
  }
  const isOnPdf = aql.pdfFieldType !== NOT_ON_PDF_OPTION_TYPE;

  if (pdfTypeNeedsOptions) return getRadioFieldData(aql);
  else if (isOnPdf) return getTextFieldData(aql);
  return getNotOnPdfFieldData(aql);
}

export function getAqlUpdateRequestBody(aql) {
  const field = getFieldRequestData(aql);
  let reference_application_question_link_id = null;
  if (aql.referenceField) {
    reference_application_question_link_id = +aql.referenceField;
  }

  const body = {
    field,
    question: aql.question,
    reference_application_question_link_id,
    tia: aql.isTiaField,
    docusign: aql.isDocusignField,
    docusign_required: aql.isDocusignRequired,
    required_of: aql.docusignRequiredOf,
    application_field_type: aql.pdfFieldType,
    additional_form: aql.additionalForm,
    required_value: aql.requiredValue,
    parent_application_question_link_id: aql.parentQuestion || null,
    required_parent_value: aql.requiredParentValue,
    field_type: aql.quoteAndApplyType,
    verbatim_question: aql.verbatimQuestion,
    insured_index: aql.insuredIndex,
    comb: aql.comb,
    background: aql.obscureBackground
  };

  if (aql.applicationQuestion) {
    body.method_name = aql.applicationQuestion.name;
  } else {
    body.method_name = null;
  }

  if (aql.smartApplicationQuestion) {
    body.smart_method_name = aql.smartApplicationQuestion.name;
  } else {
    body.smart_method_name = null;
  }
  return body;
}

export function getAqlCreateRequestBody(aql) {
  const ds = {};

  const field = getFieldData(aql);
  if (Array.isArray(field)) {
    if (field.length) ds.field = getFieldData(aql);
  } else if (field) {
    ds.field = getFieldData(aql);
  }

  if (aql.parentQuestion) {
    ds.parent_application_question_link_id = aql.parentQuestion;
  }

  if (aql.pdfFieldType) {
    ds.application_field_type = aql.pdfFieldType;
  }

  if (aql.applicationQuestion?.name) {
    ds.method_name = aql.applicationQuestion?.name;
  }

  if (aql.insuredIndex || aql.insuredIndex === 0) {
    ds.insured_index = aql.insuredIndex;
  }

  if (aql.referenceField) {
    ds.reference_application_question_link_id = aql.referenceField;
  }

  return ds;
}

// Application Question

export function ApplicationQuestion(model = {}) {
  return {
    application_field_type: model?.application_field_type,
    created_at: model?.created_at,
    deleted_at: model?.deleted_at,
    id: model?.id,
    name: model?.name,
    required_of: model?.required_of,
    updated_at: model?.updated_at,
    valid_answers: model?.valid_answers,
    verbatim_question: model?.verbatim_question,
    result: model?.result
  };
}

export function setApplicationQuestionFromRequest(raw = {}) {
  const model = ApplicationQuestion();

  model.application_field_type = raw?.application_field_type;
  model.created_at = raw?.created_at;
  model.deleted_at = raw?.deleted_at;
  model.id = raw?.id;
  model.name = raw?.name;
  model.required_of = raw?.required_of;
  model.updated_at = raw?.updated_at;
  model.valid_answers = raw?.valid_answers;
  model.verbatim_question = raw?.verbatim_question;
  model.result = raw?.result;

  return model;
}

// Application Question Link Field

export function AqlField(model = {}) {
  const { text, value, virtual } = AqlFieldOption(model);

  return {
    page: model?.page,
    x: model?.x,
    y: model?.y,
    width: model?.width,
    height: model?.height,
    text,
    value,
    virtual,
    options: model?.options || [],
    order: 0,
    isFieldOption: model?.isFieldOption || false,
    uuid: uuidv4()
  };
}

// Application Question Link Field Option

export function AqlFieldOption(model = {}) {
  return {
    text: model?.text || "",
    value: model?.value || "",
    virtual: model?.virtual || false
  };
}

function setCoordinatesFromFieldOption(aql) {
  return aql?.field?.map(({ page, x, y, value, text, virtual }) =>
    AqlField({
      text,
      value,
      virtual,
      page,
      x,
      y,
      isFieldOption: true
    })
  );
}

function makeCoordinatesPositive(coordinate) {
  let { width, x, y, height } = coordinate;
  if (width < 0) {
    x = x + width;
    width = Math.abs(width);
  }

  if (height < 0) {
    y = y + width;
    height = Math.abs(height);
  }

  return { x, width, height, y };
}

function setCoordinatesFromText(raw) {
  const model = AqlField({
    page: raw.field.page,
    ...makeCoordinatesPositive(raw.field)
  });

  if (raw?.field?.options?.length) {
    model.options = raw.field.options.map(AqlFieldOption);
  }

  return model;
}
