<template>
  <chat-view
    title="Lead Chat"
    :pusher-id="pusherId"
    :messages="notes"
    :create-func="sendChatMessage"
    @new-note="newNote"
    @remove-message="removeFromMessages"
  />
</template>

<script setup>
import ChatView from "@/components/shared/chat/ChatView.vue";

import {
  Note,
  setNoteFromRequest,
  NoteToCreateRequest
} from "@/factories/Note";
import { parseErrorMessage } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { createNote } from "@/api/notes.service";
import { useSnackbarStore } from "@/stores/snackbar";
import { useLeadView } from "@/stores/lead-view";

const leadView = useLeadView();
const snackbar = useSnackbarStore();

const { id, pusherId, notes } = storeToRefs(leadView);

function removeFromMessages(message) {
  const index = notes.value.findIndex(({ id }) => id === message.id);
  if (index > -1) notes.value.splice(index, 1);
}
async function sendChatMessage(note) {
  const newNote = new Note({
    documents: note.files,
    email: note.sendEmail ? 1 : 0,
    phoneCall: note.sendPhoneCall,
    note: note.message,
    notableId: id.value,
    notableType: "Lead"
  });
  try {
    const res = await createNote(NoteToCreateRequest(newNote));
    return res;
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  }
}
function newNote({ note: rawNote }) {
  notes.value.push(setNoteFromRequest(rawNote));
}
</script>
