import { setAgencyProfileFromRequest } from "@/factories/AdvisorProfile";
import { RawWebhooksToObj } from "@/factories/Webhook";
import { setInsuranceLicenseFromRequest } from "@/factories/AdvisorInsuranceLicense";
import {
  setConnectionFromRawCreate,
  setRawCreateFromConnection
} from "@/factories/ConnectionFactory";
import { setAgencySettingsFromRaw } from "@/factories/AgencySettingsFactory";
import { setAddressFromRaw } from "@/factories/AddressFactory";
import { setDocumentFromRequest } from "@/factories/Document";

import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";
const baseUrl = "api/boss/agencies";

export const uploadAgencyAvatar = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return getHttpClient().put(`${baseUrl}/${id}/avatar`, formData);
};

/**
 * getAgencySummary
 * Utilized on the agent profile page
 * @param {*} id
 * @returns {Promise<AgencyProfileFactory>}
 */
export const getAgencySummary = async id => {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  const advisor = setAgencyProfileFromRequest(data?.agency);

  const canViewContracting = Boolean(data?.access?.contracting);
  return { advisor, canViewContracting };
};

/**
 * getAgencySettings (getAgentContracting)
 * @param {*} id
 * @returns {Promise}
 */
export async function getAgencySettings(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/contracting`);
  return setAgencySettingsFromRaw(data);
}

export async function getAgencyWebhooks(id) {
  const { data } = await getHttpClient().get(`/${baseUrl}/${id}/webhooks`);
  return RawWebhooksToObj(data);
}

export const createAgencyWebhook = (id, webhook) => {
  return getHttpClient().post(`/${baseUrl}/${id}/webhooks`, {
    webhook
  });
};

export const getAgencyWebhookApiKey = id => {
  return new Promise((resolve, reject) => {
    getHttpClient()
      .post(`/${baseUrl}/${id}/api-key`)
      .then(({ data }) => {
        resolve(data?.secret);
      })
      .catch(reject);
  });
};

export async function getAgencyLicenses(id) {
  const { data } = await getHttpClient().get(
    `/${baseUrl}/${id}/insurance-licenses`
  );

  const mappedLicenses = data.map(setInsuranceLicenseFromRequest);
  mappedLicenses.sort((a, b) => a.state.localeCompare(b.state));

  return mappedLicenses;
}

/**
 * returns an array of trusted sender emails
 * @param {number} id agentid
 * @returns {Promise<Array>}  Array of emails
 */
export const getAgencyExternalResponders = async id => {
  const { data } = await getHttpClient().get(
    `/${baseUrl}/${id}/external-responders`
  );
  return data;
};

/**
 * Creates a trusted sender
 * @param {number} id agentid
 * @returns {Promise<Array>}  Array of emails
 */
export const addAgencyExternalResponder = (id, email) => {
  return getHttpClient().post(`/${baseUrl}/${id}/external-responders`, {
    email
  });
};

/**
 * Deletes a trusted sender
 * @param {number} id agentid
 * @returns {Promise<Array>}  Array of emails
 */
export const deleteAgencyExternalResponder = (id, email) => {
  return getHttpClient().delete(`/${baseUrl}/${id}/external-responders`, {
    params: { email }
  });
};

/**
 * Created
 * @param {number} agencyId
 * @returns
 */
export async function createAgencyConnection(agencyId, connection) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${agencyId}/connections`,
    {
      connection: setRawCreateFromConnection(connection)
    }
  );

  return setConnectionFromRawCreate(data.connection);
}

export function resetAgencyApiKey(agencyId) {
  return getHttpClient().delete(`${baseUrl}/${agencyId}/api-key`);
}

export function refreshAgencyLicenses(agencyId) {
  return getHttpClient().put(`${baseUrl}/${agencyId}/insurance-licenses`);
}

/**
 *
 * @param {Number} id
 * @param {Object} params
 * @returns {Promise} Resulting in a no-content
 */
export function updateAgency(id, agency) {
  return getHttpClient().put(`/${baseUrl}/${id}`, { agency });
}

/**
 * @param {Agency} agencyModel
 * @param {Agent} agentModel
 * @returns {Promise}
 */
export async function createAgency(npn, email, agent_id) {
  const { data } = await getHttpClient().post(
    `/${baseUrl}/contracting_create`,
    {
      agency: {
        npn,
        email
      },
      agent_id
    }
  );
  return data?.id;
}
export function useAgencyOwnersAvatar(ownerId, body) {
  return getHttpClient().put(`${baseUrl}/${ownerId}/use_owners_avatar`, body);
}
export async function getAgencyTin(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}/sensitive`);
  return data.tin;
}

export function updateAgencyCommunicationPreferences(
  agencyId,
  communication_preference
) {
  return getHttpClient().put(
    `${baseUrl}/${agencyId}/communication-preferences`,
    {
      communication_preference
    }
  );
}

export async function updateAddressRecord(agencyId, addressId, body) {
  const { data } = await getHttpClient().put(
    `${baseUrl}/${agencyId}/addresses/${addressId}`,
    body
  );

  return setAddressFromRaw(data);
}

export async function authorizeNewAgencySubscription(
  id,
  { channelName, socketId, callback } = {}
) {
  try {
    const { data } = await getHttpClient().post(
      `${baseUrl}/${id}/authorize-new-advisor-subscription`,
      {
        channel_name: channelName,
        socket_id: socketId
      }
    );
    callback(null, data);
  } catch (e) {
    callback(e, { auth: false });
  }
}

export async function deleteAgency(agencyId) {
  return getHttpClient().delete(`${baseUrl}/${agencyId}`);
}

export async function uploadAgencyDocument(agencyId, file) {
  const body = await serializeObject({ file });
  const { data } = await getHttpClient().post(
    `${baseUrl}/${agencyId}/documents`,
    body
  );
  return setDocumentFromRequest(data.document);
}
