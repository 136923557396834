var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":"","tile":"","color":"section"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"transparent-data-table",attrs:{"items":_setup.table.mappedItems.value,"headers":_setup.table.tableHeaders.value,"data-testid":"party-table"},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [(item.additional.ownable.type === 'individual')?_c('router-link',{attrs:{"to":{
            name: 'IndividualView',
            params: {
              id: item.additional.ownable.id
            }
          }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('router-link',{attrs:{"to":{
            name: 'ContractPartyView',
            params: {
              id: item.additional.ownable.id
            }
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.birthdate`,fn:function({ item }){return [_c(_setup.TimestampFormatter,{attrs:{"value":item.birthdate,"parser":"sole-day"}})]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }