<template>
  <v-card color="section" flat tile>
    <v-card-text>
      <!--Policy-->
      <v-card flat class="mb-3">
        <v-card-title v-if="!LINE.INFORMAL">Policy</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" lg="6">
              <v-text-field
                v-model="policyNumber"
                data-lpignore="true"
                :prepend-inner-icon="mdiPound"
                dense
                outlined
                label="Policy Number"
                data-testid="details-policy-number"
                :success="Boolean(policyNumber)"
                :disabled="user.isGroupOne"
                @input="saveAttribute('policyNumber')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.policyNumber.controller.value"
                  />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
              <v-autocomplete
                auto-select-first
                v-model="status"
                :prepend-inner-icon="mdiListStatus"
                dense
                outlined
                label="Status"
                data-testid="details-status"
                :disabled="user.isGroupOne"
                :items="STATUS_OPTIONS"
                :success="Boolean(status)"
                @change="saveAttribute('status')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.status.controller.value"
                  />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="6">
              <v-autocomplete
                v-model="state"
                dense
                item-text="full"
                item-value="text"
                outlined
                label="State"
                :prepend-inner-icon="mdiHomeGroup"
                data-testid="details-state"
                :disabled="user.isGroupOne"
                :items="stateData"
                :success="Boolean(state)"
                @change="saveAttribute('state')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.state.controller.value"
                  />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="6">
              <v-autocomplete
                auto-select-first
                v-model="product"
                dense
                outlined
                label="Product"
                item-text="name"
                return-object
                :prepend-inner-icon="mdiSitemap"
                data-testid="details-product"
                :disabled="user.isGroupOne"
                :items="products"
                :success="Boolean(product)"
                @change="saveAttribute('product')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.product.controller.value"
                  />
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="6" v-if="LINE.LIFE || LINE.INFORMAL">
              <currency-input
                v-model="faceAmount"
                include-decimals
                :prepend-inner-icon="mdiCurrencyUsd"
                dense
                outlined
                label="Death Benefit"
                data-testid="details-face-amount"
                :success="Boolean(faceAmount)"
                :disabled="user.isGroupOne"
                @input="saveAttribute('faceAmount')"
              >
                <template #append-outer>
                  <active-save-indicator
                    :controller="savingBuffer.faceAmount.controller.value"
                  />
                </template>
              </currency-input>
            </v-col>
            <v-col cols="12" lg="6" v-if="product.ltcRider">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="ltcRider"
                  dense
                  outlined
                  :success="Boolean(ltcRider)"
                  data-testid="details-ltc-rider"
                  :disabled="user.isGroupOne"
                  @change="saveAttribute('ltcRider')"
                >
                  <template #label>
                    LTC Rider
                    <active-save-indicator
                      :controller="savingBuffer.ltcRider.controller.value"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" lg="6" v-if="product.chronicIllnessRider">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="chronicIllnessRider"
                  dense
                  outlined
                  :success="Boolean(chronicIllnessRider)"
                  data-testid="details-chronic-illness-rider"
                  :disabled="user.isGroupOne"
                  @change="saveAttribute('chronicIllnessRider')"
                >
                  <template #label>
                    Chronic Illness Rider
                    <active-save-indicator
                      :controller="
                        savingBuffer.chronicIllnessRider.controller.value
                      "
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" lg="6" v-if="LINE.LIFE || LINE.DISABILITY">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="tia"
                  dense
                  outlined
                  :success="Boolean(tia)"
                  data-testid="details-tia"
                  :disabled="user.isGroupOne"
                  @change="saveAttribute('tia')"
                >
                  <template #label>
                    Temporary Insurance
                    <active-save-indicator
                      :controller="savingBuffer.tia.controller.value"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="saveAge"
                  :prepend-inner-icon="mdiCalendar"
                  data-testid="save-age"
                  dense
                  :disabled="user.isGroupOne"
                  :success="Boolean(saveAge)"
                  @change="saveAttribute('saveAge')"
                >
                  <template #label>
                    Save Age
                    <active-save-indicator
                      :controller="savingBuffer.saveAge.controller.value"
                    />
                  </template>
                </v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="checkbox-width">
                <v-checkbox
                  v-model="messageClient"
                  dense
                  outlined
                  hide-details
                  data-testid="message-client"
                  :success="Boolean(messageClient)"
                  @change="saveAttribute('messageClient')"
                >
                  <template #label>
                    Message Clients Status Updates
                    <active-save-indicator
                      :controller="savingBuffer.messageClient.controller.value"
                    />
                  </template>
                </v-checkbox>
              </div>
              <div>
                Send automated status emails and text messages when your cases'
                status is changed to "Submitted", "Approved", or "Active".
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template v-if="!LINE.INFORMAL">
        <!-- Applied Premium -->
        <v-card flat class="mb-3">
          <v-card-title>Applied</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" lg="6">
                <v-autocomplete
                  auto-select-first
                  v-model="appliedMode"
                  dense
                  outlined
                  label="Applied Mode"
                  :prepend-inner-icon="mdiViewWeek"
                  data-testid="details-applied-mode"
                  :items="modeOptions"
                  :disabled="user.isGroupOne"
                  :success="Boolean(appliedMode)"
                  @change="saveAttribute('appliedMode')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.appliedMode.controller.value"
                    />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="appliedPremium"
                  include-decimals
                  dense
                  outlined
                  :prepend-inner-icon="mdiCurrencyUsd"
                  label="Applied Premium"
                  data-testid="details-applied-premium"
                  :disabled="user.isGroupOne"
                  :success="Boolean(appliedPremium)"
                  @input="saveAttribute('appliedPremium')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.appliedPremium.controller.value"
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <v-autocomplete
                  auto-select-first
                  v-model="insuredRole.ratingApplied"
                  dense
                  outlined
                  :prepend-inner-icon="mdiStar"
                  label="Applied Rating"
                  data-testid="details-applied-rating"
                  :disabled="user.isGroupOne"
                  :items="RATINGS_BY_LINE[line]"
                  :success="Boolean(insuredRole.ratingApplied)"
                  @input="saveAttribute('ratingApplied')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.ratingApplied.controller.value"
                    />
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--Approved Premium-->
        <v-card flat class="mb-3">
          <v-card-title>Approved</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" lg="6">
                <v-autocomplete
                  auto-select-first
                  v-model="mode"
                  dense
                  outlined
                  label="Approved Mode"
                  :prepend-inner-icon="mdiViewWeek"
                  data-testid="approved-mode"
                  :disabled="user.isGroupOne"
                  :success="Boolean(mode)"
                  :items="modeOptions"
                  @change="saveAttribute('mode')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.mode.controller.value"
                    />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="premium"
                  include-decimals
                  dense
                  outlined
                  :prepend-inner-icon="mdiCurrencyUsd"
                  label="Approved Premium"
                  data-testid="approved-premium"
                  :disabled="user.isGroupOne"
                  :success="Boolean(premium)"
                  @input="saveAttribute('premium')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.premium.controller.value"
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <v-autocomplete
                  auto-select-first
                  v-model="insuredRole.ratingApproved"
                  dense
                  outlined
                  data-testid="details-approved-rating"
                  clearable
                  label="Approved Rating"
                  :prepend-inner-icon="mdiStar"
                  :disabled="user.isGroupOne"
                  :items="RATINGS_BY_LINE[line]"
                  :success="Boolean(insuredRole.ratingApproved)"
                  @input="saveAttribute('ratingApproved')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.ratingApproved.controller.value"
                    />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" lg="6" v-if="isTargetPremiumProduct">
                <currency-input
                  v-model="targetPremium"
                  include-decimals
                  :prepend-inner-icon="mdiCurrencyUsd"
                  dense
                  outlined
                  label="Target Premium"
                  data-testid="details-target-premium"
                  :disabled="user.isGroupOne"
                  :success="Boolean(targetPremium)"
                  @input="saveAttribute('targetPremium')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.targetPremium.controller.value"
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col
                v-if="(LINE.LIFE && isTargetPremiumProduct) || LINE.ANNUITY"
                cols="12"
                lg="6"
              >
                <currency-input
                  v-model="exchangeAmount"
                  include-decimals
                  :prepend-inner-icon="mdiCurrencyUsd"
                  dense
                  outlined
                  data-testid="details-exchange-amount"
                  label="Exchange Amount"
                  :disabled="user.isGroupOne"
                  :success="Boolean(exchangeAmount)"
                  @input="saveAttribute('exchangeAmount')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.exchangeAmount.controller.value"
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col
                v-if="(LINE.LIFE && isTargetPremiumProduct) || LINE.ANNUITY"
                cols="12"
                lg="6"
              >
                <currency-input
                  v-model="lumpSum"
                  include-decimals
                  :prepend-inner-icon="mdiCurrencyUsd"
                  dense
                  outlined
                  label="Lump Sum"
                  data-testid="lump-sum"
                  :disabled="user.isGroupOne"
                  :success="Boolean(lumpSum)"
                  @input="saveAttribute('lumpSum')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.lumpSum.controller.value"
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6">
                <currency-input
                  v-model="cashReceived"
                  include-decimals
                  :prepend-inner-icon="mdiCurrencyUsd"
                  dense
                  outlined
                  label="Cash Received"
                  data-testid="cash-received"
                  :disabled="user.isGroupOne"
                  :success="Boolean(cashReceived)"
                  @input="saveAttribute('cashReceived')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.cashReceived.controller.value"
                    />
                  </template>
                </currency-input>
              </v-col>
              <v-col cols="12" lg="6" v-if="LINE.ANNUITY">
                <v-autocomplete
                  auto-select-first
                  v-model="taxType"
                  :prepend-inner-icon="mdiBank"
                  dense
                  outlined
                  label="Tax Type"
                  data-testid="details-tax-type"
                  :disabled="user.isGroupOne"
                  :items="TAX_OPTIONS"
                  :success="Boolean(taxType)"
                  @change="saveAttribute('taxType')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.taxType.controller.value"
                    />
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat class="mb-3">
          <v-card-title>Underwriting Rationale</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" v-if="LINE.DISABILITY">
                <v-autocomplete
                  auto-select-first
                  v-model="insuredRole.occupationClass"
                  label="Occupation Class"
                  outlined
                  dense
                  :prepend-inner-icon="mdiBriefcase"
                  data-testid="occupation-class"
                  :items="OCCUPATION_CLASSES"
                  :success="Boolean(insuredRole.occupationClass)"
                  @change="saveAttribute('occupationClass')"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="
                        savingBuffer.occupationClass.controller.value
                      "
                    />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <underwriting-rationale
                  v-model="insuredRole.underwritingRationale"
                  :disabled="user.isGroupOne"
                  :func="
                    () => caseView.saveAttributes(['underwritingRationale'])
                  "
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- Policy Delivery -->
        <v-card flat class="mb-3">
          <v-card-title>Policy Delivery</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <div class="checkbox-width">
                  <v-checkbox
                    v-model="carrierEDelivery"
                    dense
                    outlined
                    data-testid="carrier-e-delivery"
                    :disabled="user.isGroupOne"
                    :success="Boolean(carrierEDelivery)"
                    @change="saveAttribute('carrierEDelivery')"
                  >
                    <template #label>
                      eDelivery
                      <active-save-indicator
                        :controller="
                          savingBuffer.carrierEDelivery.controller.value
                        "
                      />
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <template v-if="!carrierEDelivery">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="address"
                    dense
                    outlined
                    auto-select-first
                    data-testid="shipping-address"
                    label="Shipping Address"
                    :prepend-inner-icon="mdiSignRealEstate"
                    :disabled="user.isGroupOne"
                    :items="addresses"
                    :success="Boolean(address)"
                    @change="saveAttribute('address')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.address.controller.value"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    auto-select-first
                    v-model="trackingNumberType"
                    dense
                    item-value="text"
                    outlined
                    label="Tracking Number Type"
                    :prepend-inner-icon="mdiTruckDelivery"
                    data-testid="tracking-number-type"
                    :disabled="user.isGroupOne"
                    :items="trackingTypes"
                    :success="!!trackingNumberType"
                    @change="saveAttribute('trackingNumberType')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.trackingNumberType.controller.value
                        "
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="trackingNumber"
                    data-lpignore="true"
                    dense
                    outlined
                    label="Tracking Number"
                    :prepend-inner-icon="mdiBarcode"
                    class="prepend-slot has-appended-button"
                    data-testid="tracking-number"
                    :disabled="user.isGroupOne"
                    :success="!!trackingNumber"
                    @input="saveAttribute('trackingNumber')"
                  >
                    <template #prepend-inner>
                      <span class="prepend-text">
                        {{ trackingNumberPrefix }}
                      </span>
                    </template>
                    <template #append v-if="Boolean(trackingNumber)">
                      <v-btn icon :href="trackingNumberLink" target="_blank">
                        <v-icon>{{ mdiOpenInNew }}</v-icon>
                      </v-btn>
                    </template>
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.trackingNumber.controller.value
                        "
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
        <!--Dates-->
        <v-card flat class="mb-3">
          <v-card-title>Dates</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" lg="6">
                <date-input
                  v-model="appSignDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  label="App Sign Date"
                  data-testid="app-sign-date"
                  :disabled="user.isGroupOne"
                  :success="Boolean(appSignDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.appSignDate.controller.value"
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="submittedDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  label="Submitted Date"
                  data-testid="submitted-date"
                  :disabled="user.isGroupOne"
                  :success="Boolean(submittedDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.submittedDate.controller.value"
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="approvedDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  data-testid="approved-date"
                  label="Approved Date"
                  :success="Boolean(approvedDate)"
                  :disabled="user.isGroupOne"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.approvedDate.controller.value"
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="issueDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  label="Issue Date"
                  data-testid="issue-date"
                  :disabled="user.isGroupOne"
                  :success="Boolean(issueDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.issueDate.controller.value"
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="deliveryRequirementsDueDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  data-testid="delivery-requirements-date"
                  label="Delivery Requirements Due Date"
                  :disabled="user.isGroupOne"
                  :success="Boolean(deliveryRequirementsDueDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="
                        savingBuffer.deliveryRequirementsDueDate.controller
                          .value
                      "
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="sentToAgentDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  label="Sent to Agent Date"
                  data-testid="sent-to-agent-date"
                  :success="Boolean(sentToAgentDate)"
                  :disabled="user.isGroupOne"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="
                        savingBuffer.sentToAgentDate.controller.value
                      "
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="policyDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  label="Policy Date"
                  data-testid="policy-date"
                  :disabled="user.isGroupOne"
                  :success="Boolean(policyDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.policyDate.controller.value"
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6" v-if="isTermProduct">
                <date-input
                  v-model="conversionExpirationDate"
                  label="Conversion Expiry"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  data-testid="conversion-expiry"
                  :disabled="user.isGroupOne"
                  :success="Boolean(conversionExpirationDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="
                        savingBuffer.conversionExpirationDate.controller.value
                      "
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="inforceDate"
                  label="Inforce Date"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  data-testid="inforce-date"
                  :disabled="user.isGroupOne"
                  :success="Boolean(inforceDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.inforceDate.controller.value"
                    />
                  </template>
                </date-input>
              </v-col>

              <v-col cols="12" lg="6">
                <date-input
                  v-model="closedDate"
                  :prepend-inner-icon="mdiCalendar"
                  dense
                  outlined
                  label="Closed Date"
                  data-testid="closed-date"
                  :disabled="user.isGroupOne"
                  :success="Boolean(closedDate)"
                >
                  <template #append-outer>
                    <active-save-indicator
                      :controller="savingBuffer.closedDate.controller.value"
                    />
                  </template>
                </date-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--Benefits-->
        <template v-if="LINE.LTC || LINE.DISABILITY || LINE.LINKED_BENEFIT">
          <v-card flat class="mb-3">
            <v-card-title>Benefits</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" lg="6">
                  <v-autocomplete
                    auto-select-first
                    v-model="eliminationPeriod"
                    :prepend-inner-icon="mdiCalendar"
                    dense
                    outlined
                    data-testid="elimination-period"
                    label="Elimination Period"
                    :disabled="user.isGroupOne"
                    :items="ELIMINATION_PERIODS"
                    :success="Boolean(eliminationPeriod)"
                    @change="saveAttribute('eliminationPeriod')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.eliminationPeriod.controller.value
                        "
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-autocomplete
                    auto-select-first
                    v-model="benefitPeriod"
                    :prepend-inner-icon="mdiCalendar"
                    dense
                    outlined
                    label="Benefit Period"
                    data-testid="benefit-period"
                    :disabled="user.isGroupOne"
                    :items="BENEFIT_PERIOD_OPTIONS"
                    :success="Boolean(benefitPeriod)"
                    @input="saveAttribute('benefitPeriod')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.benefitPeriod.controller.value
                        "
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6">
                  <currency-input
                    v-model="benefitAmount"
                    include-decimals
                    :prepend-inner-icon="mdiCurrencyUsd"
                    dense
                    outlined
                    label="Benefit Amount"
                    data-testid="benefit-amount"
                    :disabled="user.isGroupOne"
                    :success="Boolean(benefitAmount)"
                    @input="saveAttribute('benefitAmount')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="
                          savingBuffer.benefitAmount.controller.value
                        "
                      />
                    </template>
                  </currency-input>
                </v-col>
                <v-col cols="12" lg="6" v-if="LINE.LTC">
                  <v-autocomplete
                    auto-select-first
                    v-model="benefitMode"
                    dense
                    outlined
                    label="Benefit Mode"
                    :prepend-inner-icon="mdiViewWeek"
                    data-testid="benefit-mode"
                    :disabled="user.isGroupOne"
                    :items="['Daily', 'Monthly']"
                    :success="Boolean(benefitMode)"
                    @change="saveAttribute('benefitMode')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.benefitMode.controller.value"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6" v-if="LINE.LTC || LINE.LINKED_BENEFIT">
                  <v-autocomplete
                    auto-select-first
                    v-model="inflation"
                    :prepend-inner-icon="mdiCurrencyUsd"
                    dense
                    outlined
                    label="Inflation"
                    item-text="text"
                    item-value="value"
                    data-testid="inflation"
                    :disabled="user.isGroupOne"
                    :success="validInflationItem"
                    :items="INFLATION_ITEMS"
                    @change="saveAttribute('inflation')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.inflation.controller.value"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col v-else-if="LINE.DISABILITY" cols="12" md="6">
                  <v-autocomplete
                    auto-select-first
                    v-model="inflation"
                    :prepend-inner-icon="mdiCalendar"
                    label="COLA"
                    outlined
                    dense
                    item-value="value"
                    item-text="text"
                    data-testid="cola"
                    :items="COLA_OPTIONS"
                    :success="Boolean(inflation)"
                    @change="saveAttribute('inflation')"
                  >
                    <template #append-outer>
                      <active-save-indicator
                        :controller="savingBuffer.inflation.controller.value"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActiveSaveIndicator from "@/components/shared/active-save/ActiveSaveIndicator.vue";
import UnderwritingRationale from "@/components/shared/UnderwritingRationale.vue";
import DateInput from "@/components/shared/DateInput.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

import { states as stateData } from "@/data/states";
import {
  STATUSES,
  modeOptions,
  trackingTypes,
  RATINGS_BY_LINE,
  TAX_OPTIONS,
  ELIMINATION_PERIODS,
  INFORMAL_STATUSES,
  OCCUPATION_CLASSES,
  COLA_OPTIONS,
  DISABILITY_BENEFIT_PERIODS
} from "@/data/case-data";
import { BENEFIT_PERIODS, INFLATION_ITEMS } from "@/data/quote-request-items";
import { storeToRefs } from "pinia";

import { useUserStore } from "@/stores/user";
import { useCaseViewStore } from "@/stores/case-view";
import { useActiveSave } from "@/composables/active-save.composable";
import { computed } from "vue";

import {
  mdiPound,
  mdiListStatus,
  mdiHomeGroup,
  mdiSitemap,
  mdiCurrencyUsd,
  mdiViewWeek,
  mdiStar,
  mdiBank,
  mdiBriefcase,
  mdiSignRealEstate,
  mdiTruckDelivery,
  mdiBarcode,
  mdiOpenInNew,
  mdiCalendar
} from "@mdi/js";

const user = useUserStore();
const caseView = useCaseViewStore();
const {
  insuredRole: _insuredRole,
  addresses,
  product,
  products,
  timeline,
  address,
  appliedMode,
  appliedPremium,
  benefitAmount,
  benefitMode,
  benefitPeriod,
  carrierEDelivery,
  cashReceived,
  chronicIllnessRider,
  eliminationPeriod,
  exchangeAmount,
  faceAmount,
  inflation,
  ltcRider,
  lumpSum,
  LINE,
  line,
  messageClient,
  mode,
  policyNumber,
  premium,
  saveAge,
  state,
  status,
  targetPremium,
  taxType,
  tia,
  trackingNumber,
  trackingNumberType
} = storeToRefs(caseView);

const savingBuffer = {
  address: useActiveSave(),
  appliedMode: useActiveSave(),
  appliedPremium: useActiveSave(),
  approvedDate: useActiveSave(),
  appSignDate: useActiveSave(),
  benefitAmount: useActiveSave(),
  benefitMode: useActiveSave(),
  benefitPeriod: useActiveSave(),
  cashReceived: useActiveSave(),
  carrierEDelivery: useActiveSave(),
  chronicIllnessRider: useActiveSave(),
  closedDate: useActiveSave(),
  conversionExpirationDate: useActiveSave(),
  deliveryRequirementsDueDate: useActiveSave(),
  eliminationPeriod: useActiveSave(),
  exchangeAmount: useActiveSave(),
  faceAmount: useActiveSave(),
  inflation: useActiveSave(),
  inforceDate: useActiveSave(),
  issueDate: useActiveSave(),
  ltcRider: useActiveSave(),
  lumpSum: useActiveSave(),
  messageClient: useActiveSave(),
  mode: useActiveSave(),
  occupationClass: useActiveSave(),
  policyDate: useActiveSave(),
  policyNumber: useActiveSave(),
  premium: useActiveSave(),
  product: useActiveSave(),
  ratingApplied: useActiveSave(),
  ratingApproved: useActiveSave(),
  saveAge: useActiveSave(),
  sentToAgentDate: useActiveSave(),
  state: useActiveSave(),
  status: useActiveSave(),
  submittedDate: useActiveSave(),
  targetPremium: useActiveSave(),
  taxType: useActiveSave(),
  tia: useActiveSave(),
  trackingNumber: useActiveSave(),
  trackingNumberType: useActiveSave(),
  underwritingRationale: useActiveSave()
};

const insuredRole = computed({
  get() {
    return _insuredRole.value;
  },
  set(v) {
    caseView.setInsuredRole(v);
  }
});

const BENEFIT_PERIOD_OPTIONS = LINE.value.DISABILITY
  ? DISABILITY_BENEFIT_PERIODS
  : BENEFIT_PERIODS;
const STATUS_OPTIONS = LINE.value.INFORMAL ? INFORMAL_STATUSES : STATUSES;
const validInflationItem = computed(() =>
  INFLATION_ITEMS.some(v => v.value === inflation.value)
);

const savableTimelineItem = (key, savingBufferKey) =>
  computed({
    get() {
      return timeline.value.find(i => i.key === key)?.date;
    },
    set(val) {
      const index = timeline.value.findIndex(i => i.key === key);
      if (index === -1) return;
      timeline.value[index].date = val;
      saveAttribute(savingBufferKey);
    }
  });

const appSignDate = savableTimelineItem("app_sign_date", "appSignDate");
const submittedDate = savableTimelineItem("submitted_date", "submittedDate");
const approvedDate = savableTimelineItem("approved_date", "approvedDate");
const issueDate = savableTimelineItem("issue_date", "issueDate");
const deliveryRequirementsDueDate = savableTimelineItem(
  "delivery_requirements_due_date",
  "deliveryRequirementsDueDate"
);
const sentToAgentDate = savableTimelineItem("sent_to_agent", "sentToAgentDate");
const policyDate = savableTimelineItem("policy_date", "policyDate");
const conversionExpirationDate = savableTimelineItem(
  "conversion_expiry",
  "conversionExpirationDate"
);
const inforceDate = savableTimelineItem("inforce_date", "inforceDate");
const closedDate = savableTimelineItem("closed_date", "closedDate");

const trackingNumberPrefix = computed(() => {
  return (
    {
      USPS: "https://tools.usps.com/go/TrackConfirmAction?tLabels=",
      FedEx: "https://www.fedex.com/fedextrack/?tracknumbers="
    }[trackingNumberType.value] || ""
  );
});
const trackingNumberLink = computed(() => {
  if (!trackingNumberPrefix.value || !trackingNumber.value) return "";
  return trackingNumberPrefix.value + trackingNumber.value;
});

const isTargetPremiumProduct = computed(() =>
  ["UL", "VUL", "Whole Life", "Whole Life - Non Participating", "IUL"].includes(
    product.value.planType
  )
);

const isTermProduct = computed(() =>
  ["TRM", "TRM - ROP", "TRM - Non Medical"].includes(product.value.planType)
);

function saveAttribute(attribute) {
  savingBuffer[attribute].controller.value.needsUpdate = true;
  updateAttributes();
}

let timer;
function updateAttributes() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    const attributesToUpdate = Object.keys(savingBuffer).filter(
      k => savingBuffer[k].controller.value.needsUpdate
    );

    const promise = caseView.saveAttributes(attributesToUpdate);

    attributesToUpdate.forEach(attr =>
      savingBuffer[attr].attachToPromise(promise)
    );
  }, 500);
}
</script>
