export function Payor(model = {}) {
  return {
    id: model?.id || null,
    name: model?.name || null,
    payableType: model?.payableType || null,
    avatar: model?.avatar || null
  };
}

export function setPayorFromRequest(raw = {}) {
  const model = Payor();
  model.id = raw?.id;
  model.name = raw?.name;
  model.payableType = raw?.payable_type;
  model.avatar = raw?.avatar_url;
  return model;
}
