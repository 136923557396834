import { getHttpClient } from "@/http-client";
import { serializeObject } from "@/util/helpers";

import {
  setQuoteFromRequest,
  setQuoteRoomAccessFromRequest
} from "@/factories/Quote";

const baseUrl = "api/boss/quotes";
let cancelToken = null;
export const getQuoteTable = async params => {
  params.append("model", "quotes");
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const res = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!res?.data) return;
  return {
    items: res.data.quotes,
    meta: res.data.meta
  };
};

export async function createQuote(body) {
  const formBody = await serializeObject(body);
  const { data } = await getHttpClient().post(baseUrl, formBody);
  return data.quote.id;
}

export const getQuote = async id => {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  const quote = setQuoteFromRequest(data.quote);
  quote.id = id;
  return quote;
};

export const updateQuote = (quoteId, body) => {
  return getHttpClient().put(`${baseUrl}/${quoteId}`, body);
};

export const addQuoteAccess = async (quoteId, advisor) => {
  const opportunity = {
    ownable_id: advisor.id,
    ownable_type: advisor.type
  };
  const { data } = await getHttpClient().post(
    `${baseUrl}/${quoteId}/accesses`,
    {
      opportunity
    }
  );

  return setQuoteRoomAccessFromRequest(data.opportunity);
};

export const updateQuoteAccess = (quoteId, accessId, body) => {
  return getHttpClient().put(`${baseUrl}/${quoteId}/accesses/${accessId}`, {
    opportunity: body
  });
};

export const removeQuoteAccess = (quoteId, accessId) => {
  return getHttpClient().delete(`${baseUrl}/${quoteId}/accesses/${accessId}`);
};
